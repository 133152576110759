<template>
    <div>
        <v-layout row wrap>
            <v-flex>
                <v-card>
                    <v-card-title primary-title>
                        <v-layout>
                            <v-flex xs12 sm3>
                                <v-menu ref="startDayMenu"
                                        v-model="startDayMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="filter.startDate"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.startDate"
                                                      label="Start date"
                                                      prepend-icon="event"
                                                      hint="YYYY/MM/DD format"
                                                      readonly
                                                      v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.startDate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="error" @click="startDayMenu = false">Cancel</v-btn>
                                        <v-btn flat
                                               color="green"
                                               @click="$refs.startDayMenu.save(filter.startDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>

                            <v-flex xs12 sm3>
                                <v-menu ref="endDayMenu"
                                        v-model="endDayMenu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="filter.endDate"
                                        lazy
                                        transition="scale-transition"
                                        offset-y
                                        full-width
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.endDate"
                                                      label="End Date"
                                                      prepend-icon="event"
                                                      hint="YYYY/MM/DD format"
                                                      readonly
                                                      v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.endDate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn flat color="error" @click="endDayMenu = false">Cancel</v-btn>
                                        <v-btn flat
                                               color="green"
                                               @click="$refs.endDayMenu.save(filter.endDate)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-flex>

                            <v-flex xs12 sm3>
                                <v-btn color="teal" class="white--text" @click="fetchLogs"> Filter </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                    <v-data-table disable-initial-sort
                                  :expand="true"
                                  item-key="date"
                                  :items="logs"
                                  :rows-per-page-items="[20]"
                                  :search="search"
                                  :headers="headers"
                                  :loading="loading">
                        <template slot="no-data">
                            No Logs
                        </template>
                        <template slot="items" slot-scope="props">
                            <td @click="props.expanded = !props.expanded">{{ fullDateFormat(props.item.date) }}</td>
                            <td @click="props.expanded = !props.expanded"> {{props.item.user.fullName}}</td>
                            <td @click="props.expanded = !props.expanded">{{ props.item.store }}</td>
                            <td @click="props.expanded = !props.expanded">{{ props.item.address }}</td>
                        </template>
                        <template v-slot:expand="props">
                            <log-details :data="props" @showResponse="showResponse" />
                        </template>
                    </v-data-table>

                    <!-- Item dialog "-->
                    <v-dialog v-model="showItemDialog" :max-width="width">
                        <v-card flat>
                            <v-card-title primary-title>
                                <v-flex xs12 sm6 px-2>
                                    <h3>Response details</h3>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
                            </v-card-title>
                            <template>
                                <v-data-table :headers="responseHeaders"
                                              :items="selectedData"
                                              disable-initial-sort
                                              hide-actions
                                              class="elevation-1">
                                    <template slot="items" slot-scope="myprops">
                                        <td v-for="header in responseHeaders">
                                            <template v-if="header.value === 'updated_at' || header.value === 'return_time' || header.value === 'date_time'">
                                                {{ fullDateFormat(myprops.item[header.value]) }}
                                            </template>
                                            <template v-else>
                                                {{ myprops.item[header.value]}}
                                            </template>
                                        </td>
                                    </template>
                                </v-data-table>
                            </template>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- Item dialog -->
                </v-card>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import JsonCSV from "vue-json-csv";
    import Layout from "@/components/Layout";
    import LogDetails from "./LoggingDetails";

    export default {
        components: {
            Layout, LogDetails,
            DownloadCSV: JsonCSV
        },
        data() {
            return {
                filter: {
                    endDate: new Date().toISOString().substr(0, 10),
                    startDate: new Date().toISOString().substr(0, 10)
                },
                headers: [
                    { text: "Date #", value: "date" },
                    { text: "Initiator", value: "fullName" },
                    { text: "Initiator Store", value: "store" },
                    { text: "Initiator Store Address", value: "address" }
                ],
                loading: false,
                filterLogs: [],
                search: "",
                startDayMenu: false,
                endDayMenu: false,
                stores: [],
                logs: [],
                logsList:[],
                mobileOnly: false,
                selectedItem: {},
                responseHeaders: [],
                width:"550px",
                selectedData: [],
                showItemDialog: false,
            };
        },
        mounted() {
            this.fetchLogs();
        },
        methods: {
            async fetchLogs() {
                const params = {
                    BusinessId:this.selectedBusiness.id,
                    startDate: this.filter.startDate,
                    endDate: this.filter.endDate
                };
                this.loading = true;
                try {
                    const { data } =
                        await this.$http.get(`${this.apiBaseUrl}/logging/activity_logs`, { params });
                    this.logs = data.data.result;
                } catch (error) {
                    //console.log(error);
                    this.handleError(error, "Error fetching activity logs");
                }
                this.loading = false;
            },
            showResponse(result) {
                this.responseHeaders = [];
                this.selectedData = [];
                this.width = "500px";
                result = JSON.parse(result);
                if (result.data) {
                    this.selectedItem = result.data;
                    this.selectedItem.forEach((value, index) => {
                        this.selectedData[index] = value;
                        if (index != 0) return;
                        var keys = Object.keys(value);
                        if (keys.length > 5) this.width = "1200px";
                        for (var i = 0; i < keys.length; i++)
                            this.responseHeaders.push({ text: keys[i], value: keys[i] });
                    });
                }
                this.showItemDialog = true;
            }
        },
        watch: {
        }
    };
</script>

<style scoped>
    #registration {
        /*margin-top: 60px;*/
    }

    .intro {
        background-color: #066ec1;
        color: #fff;
        /*padding: 90px 0;*/
    }

    .sub {
        max-width: 450px;
        margin: 0 auto;
    }

    @media screen and (max-width: 450px) {
        .intro {
            padding: 40px 0;
        }
    }
</style>
