<template>
  <v-card flat>
    <v-card-title>
      <h2>Add variants</h2>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-list>
        <v-list-group
          prepend-icon="local_offer"
          v-for="item in variantsData"
          :key="item.id"
        >
          <v-list-tile slot="activator">
            <v-list-tile-title>{{ item.attribute.name }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile v-for="option in item.options" :key="option.id">
            <v-list-tile-title>
              {{ option.name }}
            </v-list-tile-title>
            <v-list-tile-action>
              <v-checkbox
                :input-value="optionIsSelected(option)"
                @change="handleOptionSelection(option, $event)"
              ></v-checkbox>
            </v-list-tile-action>
          </v-list-tile>
        </v-list-group>
      </v-list>
      <br />
      <div class="preview-area">
        <p>Preview</p>
        <h4>{{ preview }}</h4>
      </div>
      <br />
      <v-divider></v-divider>
      <v-form ref="variant_form" lazy-validation @submit.prevent="addVariant">
        <v-layout row>
          <v-flex sm8>
            <v-text-field
              label="Price"
              v-model="price"
              :rules="formRules.amount"
              type="number"
              min="0"
              step="0.01"
            ></v-text-field>
          </v-flex>
          <v-flex sm4>
            <v-layout justify-end fill-height align-center>
              <v-btn color="primary ma-0" type="submit" :disabled="!valid">
                Add
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
      <br />
      <v-layout row wrap>
        <v-flex xs12>
          <selected-product-variants
            :products="variants"
          ></selected-product-variants>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import SelectedProductVariants from "./SelectedProductVariants";
export default {
  components: { SelectedProductVariants },
  computed: {
    preview() {
      const currentName = this.options.map(x => x.name).join(" | ");
      const exists = this.variants_.find(x => {
        return x.options.map(x => x.name).join(" | ") === currentName;
      });
      this.valid = !exists;
      return this.valid ? currentName : "This variant already exists.";
    }
  },
  data() {
    return {
      price: "",
      options: [],
      valid: true,
      variants_: []
    };
  },
  methods: {
    addVariant() {
      if (!this.$refs.variant_form.validate()) return;
      const result = {
        price: this.price,
        options: this.options.filter(() => true)
      };
      this.variants.push(result);
      this.options = [];
      this.$refs.variant_form.reset();
    },
    handleOptionSelection(option, evt) {
      if (evt) {
        this.options.push(option);
      } else {
        this.options = this.options.filter(x => x.id != option.id);
      }
    },
    optionIsSelected(option) {
      return this.options.find(x => x.id == option.id);
    }
  },
  props: {
    variants: Array,
    variantsData: Array
  },
  watch: {
    variants: {
      immediate: true,
      handler(variants) {
        this.variants_ = variants;
      }
    }
  }
};
</script>

<style scoped>
.preview-area {
  padding: 10px;
  border-radius: 5px;
  background-color: #0000000f;
}

.preview-area p {
  font-size: 0.8em;
}
</style>
