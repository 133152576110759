<template>
  <v-card class="dash-card">
    <v-card-title class="orange darken-2 white--text">
      Payment Methods
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="entries"
      :rows-per-page-items="[5]"
      class="elevation-1"
    >
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name }}</td>
        <td class="text-sm-right">{{ moneyFormat(props.item.amount) }}</td>
        <td class="text-sm-right">{{ numberFormat(props.item.count) }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Method", value: "name" },
        { text: "Amount", value: "amount", align: "right" },
        { text: "Count", value: "count", align: "right" }
      ],
      data: []
    };
  },
  props: {
    entries: Array
  }
};
</script>

<style scoped></style>
