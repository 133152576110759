<template>
  <v-card class="dash-card">
    <v-card-title class="teal darken-2 white--text">
      Sales Statistics
    </v-card-title>

    <pie-chart
      v-if="loaded"
      text-xs-center
      :data="chart.data"
      :options="chart.options"
      :height="150"
    ></pie-chart>
  </v-card>
</template>

<script>
import PieChart from "@/components/charts/PieChart";

export default {
  name: "TopProducts",
  components: {
    PieChart
  },
  data() {
    return {
      loaded: false,
      chart: {
        data: {
          labels: [
            "Completed Sales",
            "Pending Sales",
          ],
          datasets: [
            {
              label: "Sales Statistics",
              backgroundColor: [
                "darkgreen",
                "darkred",
              ],
              data: [100, 0]
            }
          ]
        },
        options: {
          height: 50,
        }
      }
    };
  },
  mounted() {
    this.getReceiptStatistics();
  },
  methods: {
    async getReceiptStatistics() {
      let url = `${this.apiBaseUrl}/dashboard/receipt-ratio`;
      try {
        this.loaded = false;
        const { data } = await this.$http.get(url);
        const { data: statistics } = data;
        this.chart.data.datasets[0].data = [statistics.completedReceipt, statistics.pendingReceipt];
        this.loaded = true;
      } catch (error) {
        console.log("ERROR", error);
      }
    },
  }
};
</script>

<style scoped></style>
