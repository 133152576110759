<template>
    <v-card v-if="!loading">
        <v-card-title class="blue-grey darken-2 white--text">
            Most Used Subscription
        </v-card-title>
        <v-card-text class="text-sm-right p-8">
            <div v-if="names">
                <h2 v-for="name in names">{{name  }}</h2>
            </div>
            <h3 v-else>...</h3>
        </v-card-text>
    </v-card>
    <skeleton-loader :color="'blue-grey darken-2 white--text'" :title="'Most Used Subscription'" :height="'10px'" :count="5" v-else></skeleton-loader>
</template>

<script>
export default {
  name: "SubscriptionUsed",
  data() {
    return {
    };
  },
  props: ['names', 'loading'],
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped></style>
