<template>
    <v-card v-if="!loading">
        <v-card-title class="orange darken-2 white--text">
            <h4>Active Business (7 days)</h4>
            <v-radio-group v-model="radios" style="display: flex; justify-content: end; margin-top: 4px; margin-bottom: -15px;"
                           row>
                <v-radio label="Active" color="white" class="white--text" style="font-weight:bold"
                         value="active"></v-radio>
                <v-radio label="Inactive" color="white" class="white--text" style="font-weight:bold"
                         value="inactive"></v-radio>
            </v-radio-group>
        </v-card-title>
        <v-card-text class="text-sm-right p-8">
            <div v-if="items.length > 0" class="p-1" style="overflow: auto; max-height: 140px;">
                <h2 v-for="name in items" style="padding-right:5px">{{name}}</h2>
            </div>
            <h3 v-else>...</h3>
        </v-card-text>
    </v-card>
    <skeleton-loader :color="'orange darken-2 white--text'" :title="'Active Business (7 days)'" :height="'10px'" :count="5" v-else></skeleton-loader>
</template>

<script>
export default {
  name: "ActiveBusiness",
  data() {
      return {
          count: "-",
          radios: 'active',
          items: []

    };
  },
  props: {
      "names": Object,
      'loading': Boolean
  },
    watch: {
        names: {
        handler: function (val) {
            this.items = val[this.radios];
        },
        deep: true
        },
        radios: {
            handler() {
                this.items = this.names[this.radios];
            },
            deep: true
        }
        },
  mounted() {
    //this.getData();
  },
  methods: {
  }
};
</script>

<style scoped>
    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #f57c00c9;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #ad5b07;
    }
</style>
