<template>
  <div>
    <v-toolbar app :clipped-left="true" :fixed="true" dark>
      <v-toolbar-side-icon @click="toggleDrawer"></v-toolbar-side-icon>
      <v-toolbar-title>
        <app-logo></app-logo>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn flat>
          {{ user.full_name }} <br />
          <small>({{ user.role }})</small>
        </v-btn>
      </v-toolbar-items>
      <v-menu bottom left offset-y min-width="250px">
        <v-btn slot="activator" icon>
          <v-icon>more_vert</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile avatar dark>
            <v-list-tile-avatar>
              <img src="/static/img/default_thumb.jpg" alt="" />
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ user.full_name }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-tile to="/profile/update">
            <v-list-tile-title>
              My profile
            </v-list-tile-title>
          </v-list-tile>

          <v-list-tile to="/subscribe" v-if="subscribe">
            <v-list-tile-title>
              Subscription
            </v-list-tile-title>
          </v-list-tile>

          <!--<v-list-tile to="/profile/settings">
            <v-list-tile-title>
              Setting
            </v-list-tile-title>
          </v-list-tile>-->

          <v-list-tile to="/logout">
            <v-list-tile-title>
              Log out
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-progress-linear
      :active="loadingPage"
      indeterminate
      :height="6"
      color="red darken-2"
      class="top-progress"
    ></v-progress-linear>
  </div>
</template>

<script>
import Logo from "@/components/shared/Logo";
export default {
  name: "MainHeader",
  components: {
    appLogo: Logo
  },
  data() {
    return {
      loadingPage: this.$store.getters.loadingPage,
      user: this.$store.getters.userData,
    };
  },
  computed: {
    subscribe() {
        return this.$store.state.subscription.isActive && this.isLoggedIn.role.toLowerCase() == 'business admin'
    }
  },
  methods: {
    toggleDrawer() {
      this.$store.commit("toggleDrawer");
    }
  }
};
</script>

<style scoped>
.top-progress {
  z-index: 50;
  margin: 0;
}
</style>
