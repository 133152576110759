import Setup from "@/views/Setup.vue";
import BusinessSetupRoutes from "@/router/setup/business";
//import StoreSetupRoutes from "@/router/setup/store";

export default [
  {
    path: "/setup",
    name: "Setup",
    component: Setup,
    icon: "settings",
    showInNav: true,
    access: "setup",
    children: [...BusinessSetupRoutes],
    meta:["admin"]
  }
];
