<template>
    <layout>
        <template>
            <v-layout>
                <v-flex xs12 text-sm-right>
                    <v-btn @click="manageSubscription({})" color="teal" class="white--text">
                        Add subscription plan
                    </v-btn>
                    <v-divider></v-divider>
                    <br />
                </v-flex>
            </v-layout>
            <div>
                <br />
                <template>
                    <v-data-table :items="subscriptionsPlan"
                                  :rows-per-page-items="[10]"
                                  :search="search"
                                  :headers="headers"
                                  :loading="loading">
                        <template slot="no-data">
                            No Subscription Plan.
                        </template>
                        <template slot="items" slot-scope="props">
                            <td>
                                {{ props.item.name }}
                            </td>
                            <td>
                                <div>
                                    Monthly- &nbsp; <span style="font-weight:bold">{{moneyFormat(props.item.amount_monthly)}}</span>
                                </div>
                                <div>
                                    Yearly- &nbsp; <span style="font-weight:bold">{{moneyFormat(props.item.amount_yearly)}}</span>
                                </div>
                            </td>
                            <td>
                                {{props.item.store_number == 0 ? 'Unlimited' : props.item.store_number}}-<span>Store(s)</span>,
                                {{props.item.user_number == 0 ? 'Unlimited' : props.item.user_number}}-<span>User(s)</span>,
                                {{props.item.product_number == 0 ? 'Unlimited' : props.item.product_number}}-<span>Product(s)</span>
                            </td>
                            <td>
                                <v-icon small :color="props.item.status ? 'green' : 'red'">
                                    {{ props.item.status ? "check_circle" : "remove_circle" }}
                                </v-icon>
                            </td>
                            <td>
                                <v-icon small :color="props.item.is_default ? 'green' : 'red'">
                                    {{ props.item.status ? "check_circle" : "remove_circle" }}
                                </v-icon>
                            </td>
                            <td>
                                <v-icon small :color="props.item.is_trial ? 'green' : 'red'">
                                    {{ props.item.status ? "check_circle" : "remove_circle" }}
                                </v-icon>
                            </td>
                            <td>
                                <v-btn icon small @click="manageSubscription(props.item)">
                                    <v-icon small>
                                        edit
                                    </v-icon>
                                </v-btn>
                                <v-btn icon small @click="deleteSubscription(props.item)">
                                    <v-icon small color="red">
                                        close
                                    </v-icon>
                                </v-btn>
                            </td>
                        </template>
                    </v-data-table>
                    <v-dialog v-model="subscriptionDialog" max-width="900px" persistent>
                        <v-card>
                            <v-card-title primary-title>
                                <h3>
                                    Subscription plan details
                                </h3>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                                <p></p>
                                <v-form lazy-validation ref="subscription_form" @submit.prevent="post">
                                    <v-layout row wrap>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.name"
                                                          label="Name"></v-text-field>
                                        </v-flex>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.month"
                                                          hide-details
                                                          single-line
                                                          type="number"
                                                          label="Month(s)"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.amount_monthly"
                                                          label="Monthly payment"></v-text-field>
                                        </v-flex>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.year"
                                                          hide-details
                                                          single-line
                                                          type="number"
                                                          label="Year(s)"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.amount_yearly"
                                                          label="Yearly payment"></v-text-field>
                                        </v-flex>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.user_number"
                                                          hide-details
                                                          single-line
                                                          type="number"
                                                          label="No of user(s)"></v-text-field>
                                            <span style="color:brown">(For <em>unlimited</em> use the value 0)</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.store_number"
                                                          hide-details
                                                          single-line
                                                          type="number"
                                                          label="No of store(s)"></v-text-field>
                                            <span style="color:brown">(For <em>unlimited</em> use the value 0)</span>
                                        </v-flex>
                                        <v-flex sm6 px-2>
                                            <v-text-field v-model="subscriptionPlan.product_number"
                                                          hide-details
                                                          single-line
                                                          type="number"
                                                          label="No of product(s)"></v-text-field>
                                            <span style="color:brown">(For <em>unlimited</em> use the value 0)</span>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row wrap style="margin-top:13px">
                                        <v-flex sm2 px-2>
                                            <v-checkbox required
                                                        v-model="subscriptionPlan.status"
                                                        label="Active"></v-checkbox>
                                        </v-flex>
                                        <v-flex sm2 px-2>
                                            <v-checkbox required
                                                        v-model="subscriptionPlan.is_default"
                                                        label="Is default"></v-checkbox>
                                        </v-flex>
                                        <v-flex sm2 px-2>
                                            <v-checkbox required
                                                        v-model="subscriptionPlan.is_trial"
                                                        label="Is trial"></v-checkbox>
                                        </v-flex>

                                        <v-flex xs12 sm3 v-if="subscriptionPlan.is_trial">
                                            <v-menu ref="startDayMenu"
                                                    v-model="startDayMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="subscriptionPlan.trial_start_date"
                                                    lazy
                                                    transition="scale-transition"
                                                    offset-y
                                                    full-width
                                                    min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="subscriptionPlan.trial_start_date"
                                                                  label="Start Date"
                                                                  prepend-icon="event"
                                                                  hint="YYYY/MM/DD format"
                                                                  readonly
                                                                  v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="subscriptionPlan.trial_start_date" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn flat color="red" @click="startDayMenu = false">Cancel</v-btn>
                                                    <v-btn flat
                                                           color="green"
                                                           @click="$refs.startDayMenu.save(subscriptionPlan.trial_start_date)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>

                                        <v-flex xs12 sm3 v-if="subscriptionPlan.is_trial">
                                            <v-menu ref="endDayMenu"
                                                    v-model="endDayMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="subscriptionPlan.trial_end_date"
                                                    lazy
                                                    transition="scale-transition"
                                                    offset-y
                                                    full-width
                                                    min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field v-model="subscriptionPlan.trial_end_date"
                                                                  label="End Date"
                                                                  prepend-icon="event"
                                                                  hint="YYYY/MM/DD format"
                                                                  readonly
                                                                  v-on="on"></v-text-field>
                                                </template>
                                                <v-date-picker v-model="subscriptionPlan.trial_end_date" no-title scrollable>
                                                    <v-spacer></v-spacer>
                                                    <v-btn flat color="red" @click="endDayMenu = false">Cancel</v-btn>
                                                    <v-btn flat
                                                           color="green"
                                                           @click="$refs.endDayMenu.save(subscriptionPlan.trial_end_date)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-layout>
                                        <v-spacer></v-spacer>
                                        <v-btn @click="closeSubscriptionDialog" flat :disabled="posting">Close</v-btn>
                                        <v-btn type="submit"
                                               flat
                                               :disabled="posting"
                                               color="primary">{{ saveText }}</v-btn>
                                    </v-layout>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </template>
            </div>
            <v-dialog v-model="alertDialog" max-width="290">
                <v-card>
                    <v-card-text text-center style="text-align:center">
                        Are you sure you want to continue.
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn flat="flat" @click="alertDialog = false">
                            Cancel
                        </v-btn>
                        <v-btn flat="flat" @click="confirm">
                            Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </template>
        </layout>
    </template>

<script>
    import Layout from "@/components/Layout";
    export default {
        components: {
            Layout
        },
        computed: {
            saveText() {
                const texts = [
                    {
                        active: "Saving",
                        default: "Save"
                    },
                    {
                        active: "Updating",
                        default: "Update"
                    }
                ];

                const index = this.postAction !== "update" ? 0 : 1;
                return this.posting ? texts[index].active : texts[index].default;
            }
        },
        created() {
            this.getSubscriptions();
        },
        data() {
            return {
                subscriptionPlan: {},
                subscriptionsPlan: [],
                selectedSubscriptionPlan: {},
                subscriptionDialog: false,
                alertDialog: false,
                startDayMenu: false,
                endDayMenu: false,
                headers: [
                    {
                        text: "Name",
                        value: "name"
                    },
                    {
                        text: "Amount",
                        value: "amount"

                    },
                    {
                        text: "Descriptions",
                        value: "pending_transact"

                    },
                    {
                        text: "Status",
                        value: "status"
                    },
                    {
                        text: "Default",
                        value: "is_default"
                    },
                    {
                        text: "Trial",
                        value: "is_trial"
                    },
                    {
                        text: "Actions",
                        align: "left",
                        value: ""
                    }
                ],
                loading: false,
                postAction: "",
                search: ""
            };
        },
        methods: {
            deleteSubscription(plan) {
                this.alertDialog = true;
                this.subscriptionPlan = plan;

            },
            async confirm() {
                this.loading = true;
                this.alertDialog = false
                try {
                    const res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/subscribe/delete-subscription?Id=` + this.subscriptionPlan.id);
                    this.handleResponse(res, data => {
                        this.subscriptionsPlan = data;
                        this.showSnackbar(`Subscription plan deleted successfully.`);
                    });
                } catch (error) {
                    this.handleError(
                        error,
                        "Couldn't fetch subscriptions. Please try again later."
                    );
                }
                this.loading = false;
            },
            manageSubscription(plan) {
                this.subscriptionPlan = plan;
                this.subscriptionDialog = true;
                this.postAction = plan.name ? "update" : "";
                //console.log(this.postAction);
            },
            closeSubscriptionDialog() {
                this.postAction = "";
                this.subscriptionDialog = false;
                this.subscriptionPlan = {};
                this.$refs.subscription_form.reset();
            },
            async getSubscriptions() {
                try {
                    const res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/subscribe`);
                    this.handleResponse(res, data => {
                        this.subscriptionsPlan = data;
                    });
                } catch (error) {
                    this.handleError(
                        error,
                        "We couldn't fetch subscription plans. Please try again."
                    );
                }
            },
            async post() {
                if (!this.$refs.subscription_form.validate()) return;
                if (this.subscriptionPlan.is_trial) {
                    if (!this.subscriptionPlan.trial_start_date && !this.subscriptionPlan.trial_end_date) {
                        this.showSnackbar("Date interval is required", {
                            type: "error"
                        });
                        return;
                    }
                }
                const plan = Object.assign({}, this.subscriptionPlan);
                this.posting = true;
                try {
                    let url = `${this.apiBaseUrl}/superAdmin/subscribe`;
                    const res = await this.$http.post(url, plan);
                    this.posting = false;
                    this.handleResponse(res, data => {
                        let action = "added";
                        if (this.postAction === "update") {
                            action = "updated";
                            this.updateSubscription(plan);
                        } else {
                            this.subscriptionsPlan.unshift(plan)
                        }
                        this.closeSubscriptionDialog();
                        this.showSnackbar(`Subscription plan ${action} successfully.`);
                    });
                } catch (error) {
                    this.posting = false;
                    this.handleError(error, "An error occurred. Please try again.");
                }
                window.location.reload();
            },
            updateSubscription(plan) {
                const index = this.subscriptionsPlan.findIndex(x => x.id == plan.id);
                this.$set(this.subscriptionsPlan, index, plan);
            }
        }
    };
</script>

<style scoped>
    .filtersss {
        background-color: #5a5a5a29;
        padding: 10px;
        border-radius: 4px;
    }
</style>
