let data = {
  headers: [
    {
      text: "Store name",
      align: "left",
      value: "name"
    },
    {
      text: "Store code",
      value: "code"
    },
    {
      text: "Address",
      value: "address"
    },
    {
      text: "Phone Number",
      value: "phone_number"
    },
    // {
    //   text: "Subscribed",
    //   value: "is_subscribed"
    // },
    {
      text: "Active",
      value: "is_active"
    },
    {
      text: "Actions",
      value: ""
    }
  ],
  storeTypes: [
    {
      id: 1,
      name: "Restaurant/Bar"
    },
    {
      id: 2,
      name: "Shop"
    }
  ],
  pickers: {
    closing: false,
    opening: false
  },
  buttonTexts: [
    {
      active: "Saving..",
      default: "Save"
    },
    {
      active: "Updating..",
      default: "Update"
    }
  ],
  loading: {
    stores: true,
    storeTypes: true,
    removeItem: false
  }
};

export default data;
