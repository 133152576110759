<template>
    <v-card v-if="!loading">
        <v-card-title class="orange darken-2 white--text">
            Total Number Of Customers
        </v-card-title>
        <v-card-text class="text-sm-right p-8">
            <h2>{{numbers}}</h2>
        </v-card-text>
    </v-card>
    <skeleton-loader :color="'orange darken-2 white--text'" :title="'Total Number Of Customers'" :height="'30px'" :count="1" v-else></skeleton-loader>
</template>

<script>
export default {
  name: "TotalAmountOwed",
  data() {
    return {
      amount: "-"
    };
  },
  props: ['numbers', 'loading'],
  mounted() {
    //this.getData();
  },
  methods: {
  }
};
</script>

<style scoped></style>
