<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-menu
            ref="startDayMenu"
            v-model="startDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.startDate"
                label="Start date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="startDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.startDayMenu.save(filter.startDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-menu
            ref="endDayMenu"
            v-model="endDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.endDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.endDate"
                label="End Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="endDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.endDayMenu.save(filter.endDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm2>
          <v-btn color="info" @click="filterData"> Filter </v-btn>
        </v-flex>

        <v-flex xs12 sm4>
          <div>
            <DownloadCSV
              class="v-btn theme--light info"
              :data="filterProductReceipts"
              :name="filename"
              v-if="filterProductReceipts.length"
            >
              Export Product Report
            </DownloadCSV>
            <DownloadCSV
              class="v-btn theme--light success"
              :data="filterReceipts"
              name="export-receipts.csv"
              v-if="filterReceipts.length"
            >
              Export
            </DownloadCSV>
          </div>
        </v-flex>
      </v-layout>

      <v-data-table
        disable-initial-sort
        :items="filterReceipts"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data"> No receipts. </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.transactionId }}</td>
          <td>{{ props.item.date }}</td>
          <td>{{ props.item.productCode }}</td>
          <td>{{ props.item.productName }}</td>
          <td>
            {{
              props.item.customerName
                ? props.item.customerName
                : "One-Time Customer"
            }}
          </td>
          <td class="text-xs-right">
            {{ props.item.salePrice }}
          </td>
          <td class="text-xs-right">
            {{ props.item.costPrice }}
          </td>
          <td class="text-xs-right">
            {{ props.item.quantity }}
          </td>
          <!-- <td class="text-xs-right">
                {{ props.item.discountAmount }}
              </td> -->
          <td class="text-xs-right">
            {{ props.item.totalAmount }}
          </td>
          <td>
            {{ props.item.paymentMethod }}
          </td>
          <td>{{ props.item.cashierName }}</td>
          <td>{{ props.item.deviceName }}</td>
        </template>
      </v-data-table>
    </template>
    <template v-else> No store selected </template>
  </layout>
</template>

<script>
import JsonCSV from "vue-json-csv";
import Layout from "@/components/Layout";
import { forEach } from "lodash";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV,
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10),
      },
      headers: [
        { text: "Receipt #", value: "transactionId" },
        { text: "Date", value: "date", align: "right" },
        { text: "Product Code", value: "productCode" },
        { text: "Product Name", value: "productName" },
        { text: "Customer Name", value: "customerName" },
        { text: "Sale Price", value: "salePrice", align: "right" },
        { text: "Cost Price", value: "costPrice", align: "right" },
        { text: "Quantity", value: "quantity", align: "right" },
        // { text: "Discount", value: "discountAmount", align: "right" },
        { text: "Total Amount", value: "totalAmount", align: "right" },
        { text: "Payment Method", value: "paymentMethod" },
        { text: "Cashier", value: "cashierName" },
        { text: "Device", value: "deviceName" },
      ],
      loading: false,
      receipts: [],
      search: "",
      startDayMenu: false,
      endDayMenu: false,
      filename: "",
      products: [],
      cashiers: [],
      partners: [],
    };
  },
  mounted() {
    this.fetchProducts();
    this.fetchPartners();
    this.fetchCashiers();
  },
  computed: {
    filterReceipts() {
      return this.receipts.map((item) => ({
        transactionId: item.transactionId,
        date: this.fullDateFormat(item.date),
        productCode: this.getProduct(item.productId).code,
        productName: this.getProduct(item.productId).name,
        customerName: this.getPartner(item.partnerId).name,
        salePrice: this.moneyFormat(item.salePrice),
        costPrice: this.moneyFormat(
          item.costPrices.length
            ? item.costPrices[item.costPrices.length - 1]
            : item.costPrice
        ),
        quantity: this.numberFormat(item.quantity),
        // discountAmount: this.moneyFormat(item.discountAmount),
        totalAmount: this.moneyFormat(
          item.salePrice * item.quantity - item.discountAmount
        ),
        cashierName: item.cashierName,
        deviceName: this.getCashier(item.deviceCode).device_name,
        paymentMethod: item.paymentMethod,
      }));
    },

    filterProductReceipts() {
      var list = [];
      this.receipts.forEach((receipt, index) => {
        var product_name = this.getProduct(receipt.productId).name || "";

        if (list.length > 0) {
          const productIndex = list.findIndex(
            (x) => x.ProductName == product_name
          );
          if (productIndex > -1) {
            const price = receipt.salePrice * receipt.quantity;
            list[productIndex]["Quantity"] =
              list[productIndex]["Quantity"] + receipt.quantity;
            list[productIndex]["Total"] = list[productIndex]["Total"] + price;
          } else {
            list.push({
              ProductName: product_name,
              SalePrice: receipt.salePrice,
              CostPrice: this.moneyFormat(
                receipt.costPrices.length
                  ? receipt.costPrices[receipt.costPrices.length - 1]
                  : receipt.costPrice
              ),
              Quantity: receipt.quantity,
              Total: receipt.salePrice * receipt.quantity,
            });
          }
        } else {
          list.push({
            ProductName: product_name,
            SalePrice: receipt.salePrice,
            CostPrice: this.moneyFormat(
              receipt.costPrices.length
                ? receipt.costPrices[receipt.costPrices.length - 1]
                : receipt.costPrice
            ),
            Quantity: receipt.quantity,
            Total: receipt.salePrice * receipt.quantity,
          });
        }
      });
      return list;

      //return this.receipts.map((item) => ({
      //    ProductName: this.getProduct(item.productId).name,
      //    SalePrice: this.moneyFormat(item.salePrice),
      //    CostPrice: this.moneyFormat(
      //        item.costPrices.length
      //            ? item.costPrices[item.costPrices.length - 1]
      //            : item.costPrice
      //    ),
      //    Quantity: this.numberFormat(item.quantity)
      //}));
    },
  },
  methods: {
    async fetchProducts() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(
          `${this.apiBaseUrl}/products/get-products`
        );
        this.products = data.data;
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error fetching products");
      }
      this.loading = false;
    },
    async fetchCashiers() {
      const storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      this.loading = true;
      try {
        const { data } = await this.$http.get(
          `${this.apiBaseUrl}/cashregisters?storeId=${storeId}`
        );
        this.cashiers = data.data;
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error fetching cashiers");
      }
      this.loading = false;
    },
    async fetchPartners() {
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/partners`);
        this.partners = data.data;
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error fetching partners");
      }
      this.loading = false;
    },
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      const params = {
        storeId,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
      };

      this.filename =
        this.filter.startDate +
        "--" +
        this.filter.endDate +
        "--export-receipts.csv";
      this.loading = true;
      try {
        const [receipts] = await Promise.all([
          this.$http.get(`${this.apiBaseUrl}/receipts/product-receipts`, {
            params,
          }),
        ]);
        this.receipts = receipts.data.data;
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error loading data");
      }
      this.loading = false;
    },
    filterData() {
      this.getData();
    },
    totalQty(items) {
      return items.reduce(function(a, b) {
        return a + b["quantity"];
      }, 0);
    },
    getProduct(id) {
      const product = this.products.find((x) => x.id == id);

      return product || {};
    },
    getPartner(id) {
      const partner = this.partners.find((x) => x.id == id);

      return partner || {};
    },
    getCashier(device_code) {
      return this.cashiers.find((x) => x.device_code == device_code) || {};
    },
  },
  watch: {
    selectedStore: {
      handler() {
        this.fetchCashiers();
        this.fetchPartners();
        this.getData();
      },
      deep: true,
    },
  },
};
</script>
