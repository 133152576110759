<template>
  <layout>
    <v-layout row wrap>
      <v-flex xs12 sm3>
                  <v-menu ref="startDayMenu"
                          v-model="startDayMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="filter.startDate"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          min-width="290px">
                      <template v-slot:activator="{ on }">
                          <v-text-field v-model="filter.startDate"
                                        label="Start date"
                                        prepend-icon="event"
                                        hint="YYYY/MM/DD format"
                                        readonly
                                        v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="filter.startDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="startDayMenu = false">Cancel</v-btn>
                          <v-btn flat
                                 color="primary"
                                 @click="$refs.startDayMenu.save(filter.startDate)">OK</v-btn>
                      </v-date-picker>
                  </v-menu>
      </v-flex>
      <v-flex xs12 sm3>
                  <v-menu ref="endDayMenu"
                          v-model="endDayMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="filter.endDate"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          min-width="290px">
                      <template v-slot:activator="{ on }">
                          <v-text-field v-model="filter.endDate"
                                        label="End Date"
                                        prepend-icon="event"
                                        hint="YYYY/MM/DD format"
                                        readonly
                                        v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="filter.endDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="endDayMenu = false">Cancel</v-btn>
                          <v-btn flat
                                 color="primary"
                                 @click="$refs.endDayMenu.save(filter.endDate)">OK</v-btn>
                      </v-date-picker>
                  </v-menu>
      </v-flex>
      <v-flex xs12 sm3>
           <v-btn color="info" @click="getReceiptsFilter"> Filter </v-btn>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <template v-if="!loaded">
        <v-progress-linear height="2" indeterminate></v-progress-linear>
      </template>
      <template v-else>
        <v-spacer></v-spacer>
        <v-flex xs6 sm4 md2>
          <v-btn
            block
            @click="showReceiptDialog"
            color="blue-grey darken-4 white--text"
          >
            <v-icon>add_circle_outline</v-icon>&nbsp; New Receipt
          </v-btn>
        </v-flex>
        <v-flex xs6 sm4 md1>
            <div>
                <DownloadCSV class="v-btn theme--light success"
                            :data="formatIncomingReceiptsForCSV"
                            :name="filename"
                            v-if="incomingReceipts.length">
                    Export
                </DownloadCSV>
            </div>
        </v-flex>
      </template>
    </v-layout>
    <br />
    <!-- <hr> -->
    <!-- <v-divider></v-divider> -->
    <v-layout>
      <v-flex sm4 pt3>
        <h4>Click on an item to view details</h4>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex sm4>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search receipts"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex sm12>
        <v-data-table
          :headers="headers"
          :expand="true"
          item-key="name"
          :items="incomingReceipts"
          :rows-per-page-items="[25]"
          :search="search"
          :loading="loading.receipts"
        >
          <template v-slot:items="props">
            <tr @click="props.expanded = !props.expanded">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.transaction_date }}</td>
              <td>{{ props.item.receipt_date }}</td>
              <td>{{ props.item.companyName }}</td>
              <td>{{ props.item.warehouse }}</td>
              <td>
                <v-icon
                  :class="`${props.item.delivered ? 'primary' : 'red'}--text`"
                >
                  {{ props.item.delivered ? "check" : "clear" }}
                </v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:expand="props">
            <incoming-details :data="props" />
          </template>
        </v-data-table>

        <new-incoming-receipt
          :show-dialog="newReceiptDialog"
          @closeDialog="closeReceiptDialog"
          :currencies="currencies"
          :warehouses="warehouses"
          :currency="currency"
          :products="products"
          @receiptAdded="addReceiptToTable"
        ></new-incoming-receipt>
      </v-flex>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import NewIncomingReceipt from "./NewIncomingReceipt.vue";
import IncomingDetails from "../../../components/warehouse/incoming/IncomingDetails";
import warehouses from "../../../data/warehouses";
import JsonCSV from "vue-json-csv";

export default {
  components: {
    Layout,
    NewIncomingReceipt,
    IncomingDetails,
    DownloadCSV: JsonCSV
  },
  computed: {
    loaded() {
      const { currencies, products, warehouses } = this.loading;
      return !(currencies && products && warehouses);
    },

    incomingReceipts() {
      return this.receipts.map(r => ({
        name: r.name,
        transaction_date: this.momentFormat(r.transaction_date),
        receipt_date: this.momentFormat(r.receipt_date),
        companyName: r.productSupplier ? r.productSupplier.companyName : '',
        warehouse: this.selectWarehouse(r.warehouse_id).name,
        delivered: r.delivered,

        products: JSON.stringify(r.products.map(product => ({
          productName: product.product_name,
          quantity: product.quantity,
          cost_price: product.cost_price,
          selling_price: product.selling_price,
        })))
      }))
    },

    formatIncomingReceiptsForCSV() {
      const formattedData = [];
      for (const receipt of this.incomingReceipts) {
        const products = JSON.parse(receipt.products);
        for (const product of products) {
          formattedData.push({
            name: receipt.name,
            supplier: receipt.companyName,
            warehouse: receipt.warehouse,
            productName: product.productName,
            quantity: product.quantity,
            cost_price: product.cost_price,
            selling_price: product.selling_price,
            receipt_date: receipt.receipt_date,
          });
        }
      }
      return formattedData;
    },
  },
  async mounted() {
    await this.getWarehouses();
    await this.getCurrencies();
    await this.getProducts();
    await this.getReceipts();
    
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10),
    },
      currencies: [],
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Transaction date",
          value: "transaction_date"
        },
        {
          text: "Receipt date",
          value: "receipt_date"
        },
        {
          text: "Supplier",
          value: "companyName"
        },
        {
          text: "Warehouse",
          value: "warehouse"
        },
        {
          text: "Delivered",
          value: "delivered"
        }
      ],
      loading: {
        currencies: false,
        products: false,
        receipts: false,
        warehouses: false,

      },
      newReceiptDialog: false,
      products: [],
      startDayMenu: false,
      endDayMenu:false,
      receipts: [],
      search: "",
      fileName:"",
      warehouses: [],
      currency: null
    };
  },
  methods: {
    addReceiptToTable(receipt) {
      receipt.receipt_date = receipt.receiptDate;
      receipt.transaction_date = receipt.transactionDate;
      receipt.warehouse_id = receipt.warehouse_id;
      this.receipts.push(receipt);
      this.showSnackbar("Incoming receipt added successfully.");
    },
    closeReceiptDialog() {
      this.newReceiptDialog = false;
    },
    async getCurrencies() {
      this.loading.currencies = true;
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/business/currencies`
        );
        this.loading.currencies = false;
        this.handleResponse(res, data => {
          this.currencies = data;
          var currency = this.currencies.find(s => s.currency.code == "NGN");
            this.currency = currency ? currency.currency_id : null;
        });
      } catch (error) {
        this.loading.currencies = false;
        this.handleError(error, "We couldn't fetch your currencies.", () => {});
      }
    },
    async getProducts() {
      this.loading.products = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/products/get-products`);
        this.loading.products = false;
        this.handleResponse(res, data => {
          this.products = data.filter(x => !(x.is_service || x.has_variance));
        });
      } catch (error) {
        this.loading.products = false;
        this.handleError(error, "We couldn't fetch your products.");
      }
    },
    async getReceipts() {
      this.loading.receipts = true;
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/transactions/incoming`
        );
        this.loading.receipts = false;
        this.handleResponse(res, data => {
          this.receipts = data;
        });
      } catch (error) {
        this.loading.receipts = false;
        this.handleError(error, "We couldn't fetch your receipts.");
      }
    },
    async getReceiptsFilter() {
      this.loading.receipts = true;       
      const params = {
           startDate: this.filter.startDate,
           endDate: this.filter.endDate
      };
      this.filename = this.filter.startDate + "--" + this.filter.endDate + "--incoming-receipts.csv";
      let url = `${this.apiBaseUrl}/transactions/incoming?startDate=${this.filter.startDate}&endDate=${this.filter.endDate}`;

      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.receipts = data;
          this.loading.receipts = false;
        });
      } catch (error) {
        this.loading.receipts = false;
        this.handleError(error, "Error loading data");
      }
    },
    
    async getWarehouses() {
      this.loading.warehouses = true;
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/warehouses?status=active`
        );
        this.handleResponse(res, data => {
          this.loading.warehouses = false;
          this.warehouses = data;
        });
      } catch (error) {
        this.loading.warehouses = false;
        this.handleError(error, "We couldn't fetch your warehouses.");
      }
    },
    selectWarehouse(id) {
      return this.warehouses.find(x => x.id == id) || {};
    },
    getSupplierById(id) {
      return this.suppliers.find(sup => sup.id ===  id);
    },
    showReceiptDialog() {
      this.newReceiptDialog = true;
    }
  }
};
</script>
