<template>
  <div id="fbody">
    <top-header></top-header>
    <banner></banner>
  </div>
</template>

<script>
import Banner from "@/components/frontpage/Banner";
import TopHeader from "@/components/shared/TopHeader";

export default {
  components: {
    Banner,
    TopHeader
  }
};
</script>

<style>
.logdiv {
  background-color: #fff;
  padding: 15px;
  /* border-radius: 4px; */
}
</style>
