<template>
  <layout>
      <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex sm2 md2 mr-1>
              <v-btn block @click="allProductsDialog = true" color="info">
                  <v-icon>add_circle_outline</v-icon>&nbsp; Add product
              </v-btn>
          </v-flex>
          <v-flex sm2 md3>
              <v-btn block color="warning"                     
                @click="$router.push(`../return/returnable_reasons`)">
                  <v-icon>bubble_chart</v-icon>&nbsp; Returnable Reasons
              </v-btn>
          </v-flex>
      </v-layout>
    <br />
    <!-- <hr> -->
    <v-divider></v-divider>
    <br />
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-title class="pt-0">
            <v-layout>
              <v-flex sm6 md4 offset-sm6 offset-md8>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search.products"
                  append-icon="search"
                  label="Search product"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-data-table
            :items="returnableProducts"
            :rows-per-page-items="[10]"
            :search="search.products"
            :headers="headers.products"
            :loading="loading.products"
          >
            <template slot="no-data">
              No products. Add a new product above.
            </template>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.product_group.name || "" }}</td>
              <td>
                <v-btn small flat fab light>
                  <v-icon small @click="deleteItem(props.item)">
                    delete
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout row justify-center>
      <v-dialog fullscreen hide-overlay v-model="allProductsDialog">
        <v-toolbar dark color="primary">
          <v-btn flat @click.native="allProductsDialog = false">
            <v-icon>close</v-icon>&nbsp;Close
          </v-btn>
          <!-- <v-toolbar-title>Settings</v-toolbar-title> -->
        </v-toolbar>
        <v-card flat>
          <v-card-title class="pt-0">
            <v-layout>
              <v-flex sm6 md4 offset-sm6 offset-md8>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search.products"
                  append-icon="search"
                  label="Search product"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-data-table
            :items="nonReturnableProducts"
            :rows-per-page-items="[10]"
            :search="search.products"
            :headers="headers.products"
            :loading="loading.products"
          >
            <template slot="no-data">
              No products. Add a new product above.
            </template>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.product_group.name || "" }}</td>
              <td>
                <v-btn left flat fab light>
                  <v-icon @click="addItem(props.item)">
                    add_circle
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout>
      <v-dialog v-model="newProductDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Add product
            </h3>
          </v-card-title>
          <transition name="fade">
            <div class="px-3" v-if="alert.single.show">
              <v-alert
                v-model="alert.single.show"
                :type="alert.single.type"
                dismissible
              >
                {{ alert.single.msg }}
              </v-alert>
            </div>
          </transition>
          <v-divider></v-divider>
          <v-card-text>
            <v-form lazy-validation ref="new_product_form">
              <v-layout row wrap>
                <v-flex sm12 px-2>
                  <v-text-field
                    readonly
                    v-model="item.name"
                    label="Product name"
                    placeholder="Enter product name"
                    name="name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 px-2>
                  <br />
                  <v-switch
                    v-model="item.is_returnable"
                    label="Is returnable?"
                  ></v-switch>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn
                  @click="closeProductDialog(false)"
                  flat
                  :disabled="posting"
                  >Close</v-btn
                >
                <v-btn
                  @click="addProduct(item)"
                  :disabled="posting"
                  flat
                  color="primary"
                  >{{ updateText }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeItemDialog" max-width="400px" persistent>
        <v-card flat>
          <v-card-title primary-title>
            <h3>Remove product</h3>
          </v-card-title>
          <v-card-text>
            <v-progress-linear
              v-if="loading.removingProduct"
              indeterminate
              color="secondary"
              height="2"
              value="15"
            ></v-progress-linear>
            <div v-if="!loading.removingProduct">
              Do you want to remove this product from the returnable list?
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              flat
              :disabled="loading.removingProduct"
              @click.native="removeItemDialog = false"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              :disabled="loading.removingProduct"
              flat
              @click.native="removeProduct(item)"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    nonReturnableProducts() {
      return this.products.filter(x => !x.is_returnable);
    },
    returnableProducts() {
      return this.products.filter(x => x.is_returnable);
    },
    saveText() {
      return this.posting ? "Saving.." : "Add product";
    },
    updateText() {
      return this.posting ? "Updating.." : "Update product";
    }
  },
  created() {
    this.getProducts();
    this.getProductOptions();
  },
  data() {
    return {
      allProductsDialog: false,
      headers: {
        products: [
          {
            text: "Product name",
            align: "left",
            value: "name"
          },
          {
            text: "Product group",
            value: "product_group.name"
          },
          {
            text: "Actions",
            value: ""
          }
        ]
      },
      item: {},
      loading: {
        products: false,
        removingProduct: false
      },
      newProduct: {},
      products: [],
      newMeasurement: {},
      newProductDialog: false,
      removeItemDialog: false,
      productOptions: [],
      posting: false,
      search: {
        products: ""
      }
    };
  },
  methods: {
    addItem(item) {
      //item.name = this.getProductName_(item);
      this.item = Object.assign({}, item);
      this.newProductDialog = true;
    },
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
    async getProductOptions() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributeoptions`
        );
        await this.handleResponse(res, data => {
          this.productOptions = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We couldn't fetch your product options."
        );
      }
    },
    closeProductDialog(reset = false) {
      this.newProductDialog = false;
      if (reset) this.$refs.edit_measurement_form.reset();
    },
    deleteItem(item) {
      this.item = item;
      this.removeItemDialog = true;
    },
    async getProducts() {
      this.loading.products = true;
      this.$http
        .get(`${this.apiBaseUrl}/products/get-products`)
        .then(res => {
          this.loading.products = false;
          const response = res.data;
          if (!response.status)
            return this.showAlert(response.message, "error");

          this.products = response.data;
        })
        .catch(error => {
          this.loading.products = false;
          const response = error.response;
          if (!(response && response.data && response.data.message))
            return this.showAlert(
              "We couldn't fetch your products. Please try again.",
              "error"
            );

          const data = error.response.data;
          this.showAlert(data.message, "error");
        });
    },
    async addProduct(item) {
      if (item.is_returnable == true) {
        await this.toggleReturnable(item);
      }
      this.newProductDialog = false;
    },
    async removeProduct(item) {
      await this.toggleReturnable(item);
      this.removeItemDialog = false;
    },
    async toggleReturnable(item) {
      this.loading.removingProduct = true;
      try {
        const res = await this.$http.patch(
          `${this.apiBaseUrl}/products/${item.id}/returnable`
        );
        this.showSnackbar(res.data.message);

        this.loading.removingProduct = false;
        this.getProducts();
      } catch {
        this.loading.removingProduct = false;
        this.showSnackbar("An error occured", {
          type: "error"
        });
      }
    },
    updateProduct() {
      console.log(this.item);
    }
  }
};
</script>
