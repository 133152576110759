<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex sm4>
          <p class="pt-2">
            Click on an item to see more details
          </p>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex sm4>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search products"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <br />
        </v-flex>
      </v-layout>
      <br />
      <v-layout v-if="loading">
        <v-flex sm12>
          <v-progress-linear height="2" indeterminate></v-progress-linear>
        </v-flex>
      </v-layout>
      <v-layout v-else>
        <v-flex sm12>
          <v-data-table
            :headers="headers"
            :expand="true"
            item-key="name"
            :items="products_"
            :rows-per-page-items="[25]"
            :search="search"
            :loading="loading.receipts"
          >
            <template v-slot:items="props">
              <tr @click="props.expanded = !props.expanded">
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.in_stock }}</td>
                <td>{{ moneyFormat(props.item.selling_price) }}</td>
                <td>{{ props.item.is_for_sale ? "Yes" : "No" }}</td>
                <!-- <td>{{ props.item.is_subscribed ? "Yes" : "No" }}</td> -->
                <td>{{ props.item.is_returnable ? "Yes" : "No" }}</td>
                <!-- <td>{{ props.item.is_complex ? "Yes" : "No" }}</td> -->
                <td>{{ props.item.is_service ? "Yes" : "No" }}</td>
                <td>
                  <v-menu offset-y>
                    <v-btn
                      flat
                      fab
                      small
                      slot="activator"
                      class="ma-0"
                      color="primary"
                      dark
                    >
                      <v-icon>
                        more_vert
                      </v-icon>
                    </v-btn>
                    <v-list>
                      <v-list-tile @click="showItemInfo(props.item)">
                        <v-list-tile-title>Edit</v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile @click="showReturnInfo(props.item)">
                        <v-list-tile-title>Return goods</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </template>

            <template v-slot:expand="props">
              <v-card flat>
                <v-card-text>
                  <ShareNetwork
                    network="facebook"
                    :url="baseUrl"
                    :title="props.item.product.name"
                    description="Buy our product"
                    quote="Our simple product"
                  >
                    Share on Facebook
                  </ShareNetwork>
                </v-card-text>
              </v-card>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>

      <!-- Item dialog -->
      <v-dialog v-model="itemDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>Update product info for {{ selectedProduct.product.name }}</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form
              lazy-validation
              ref="item_form"
              @submit.prevent="updateProductRequest"
            >
              <v-layout row wrap>
                <v-flex sm12>
                  <v-text-field
                    required
                    v-model="product.selling_price"
                    autofocus
                    label="Selling price"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-flex>

                <v-flex sm12>
                  <v-text-field
                    required
                    v-model="product.minimum_stock"
                    autofocus
                    label="Minimum stock"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeItemDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn type="submit" flat :disabled="posting" color="primary"
                  >Update</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Item dialog -->

      <!-- Return goods dialog -->
      <v-dialog v-model="returnDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>Return goods for: {{ selectedProduct.product.name }}</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form
              lazy-validation
              ref="return_form"
              @submit.prevent="returnGoodsRequest"
            >
              <v-layout row wrap>
                <v-flex sm12>
                  <v-text-field
                    required
                    v-model="returnGoodData.quantity"
                    autofocus
                    label="Quantity"
                    :rules="formRules.required"
                  ></v-text-field>
                </v-flex>

                <v-flex sm12>
                  <v-autocomplete
                    required
                    :items="otherWarehouses"
                    v-model="returnGoodData.destination_warehouse_id"
                    item-text="name"
                    item-value="id"
                    label="Destination warehouse"
                    :rules="formRules.required"
                  ></v-autocomplete>
                </v-flex>

                <v-flex sm12>
                  <v-switch
                    :label="`Is product deprecated?`"
                    v-model="isDeprecated"
                  ></v-switch>
                </v-flex>
                <v-flex sm12>
                  <v-autocomplete
                    required
                    v-if="isDeprecated"
                    :items="depreciationTypes"
                    v-model="returnGoodData.depreciation_type"
                    item-text="name"
                    item-value="name"
                    label="Depreciation type"
                    :rules="formRules.required"
                  ></v-autocomplete>
                </v-flex>

                <v-flex sm12>
                  <v-textarea
                    required
                    v-model="returnGoodData.reason"
                    autofocus
                    label="Reason"
                    :rules="formRules.required"
                  ></v-textarea>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeReturnDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn type="submit" flat :disabled="posting" color="primary"
                  >Update</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Return goods dialog -->
    </template>
    <template v-else>
      No store selected
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  computed: {
    products_() {
      if (this.products.length < 1) return [];

      return this.products.map(x => {
        const product = Object.assign({}, x);
        product.name = x.product.name;
        product.code = x.product.code;
        //product.is_subscribed = x.is_subscribed;
        product.is_for_sale = x.product.is_for_sale;
        // product.is_complex = x.product.is_complex;
        product.is_service = x.product.is_service;
        product.is_returnable = x.product.is_returnable;
        product.self = x.product;
        return product;
      });
    },
    otherWarehouses() {
      return this.warehouses.filter(function(ele) {
        return ele.store_id == 0;
      });
    }
  },
  created() {
    this.getProductOptions(); 
    this.getProducts();
    
  },
  data() {
    return {
      headers: [
        {
          text: "Product name",
          align: "left",
          value: "name"
        },
        {
          text: "In stock",
          value: "in_stock"
        },
        {
          text: "Selling price",
          value: "selling_price"
        },
        {
          text: "For sale",
          value: "is_for_sale"
        },
        // {
        //   text: "Is Subscribed",
        //   value: "is_subscribed"
        // },
        {
          text: "Returnable",
          value: "is_returnable"
        },
        // {
        //   text: "Complex",
        //   value: "is_complex"
        // },
        {
          text: "Service",
          value: "is_service"
        },
        {
          text: "Actions",
          align: "center",
          value: ""
        }
      ],
      isDeprecated: false,
      depreciationTypes: [
        {
          name: "Wastage"
        },
        {
          name: "Break"
        }
      ],
      loading: false,
      products: [],
      product: {},
      returnGoodData: {},
      itemDialog: false,
      returnDialog: false,
      postAction: "",
      search: "",
      warehouses: [],
      productOptions: [],
      selectedProduct: {
        product: {}
      },
      productPrice: {
        old: 0,
        new: 0
      }
    };
  },
  methods: {
    async getProducts() {
      this.loading = true;
      try {
        let url = `${this.apiBaseUrl}/storeproducts/get-store-products`;
        if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.loading = false;
          this.products = data;
        });
      } catch (error) {
        this.handleError(error, "We couldn't fetch your products.", () => {
          this.loading = false;
        });
      }
    },
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
    async getProductOptions() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributeoptions`
        );
        await this.handleResponse(res, data => {
          this.productOptions = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We couldn't fetch your product options."
        );
      }
    },
    closeItemDialog() {
      this.postAction = "";
      this.itemDialog = false;
      this.product = {};
      this.$refs.item_form.reset();
    },
    share(id, type) {
      const origin = window.location.origin;
      const url = `${origin}/SocialShare/products/${type}/${id}`;
      window.FB.ui(
        {
          method: "share",
          href: url
        },
        function(response) {
          console.log(response);
        }
      );
    },
    showItemDialog() {
      this.itemDialog = true;
    },
    showItemInfo(product) {
      this.productPrice.old = product.selling_price;
      this.selectedProduct = product;
      this.postAction = "update";
      this.product = Object.assign({}, product);
      this.itemDialog = true;
    },
    showReturnInfo(product) {
      this.selectedProduct = product;

      if (!this.warehouses.length) {
        this.getWarehouses();
      }
      this.postAction = "update";
      this.returnGoodData.store_product_id = product.id;
      this.returnDialog = true;
    },
    returnGoods() {
      this.returnDialog = true;
    },
    closeReturnDialog() {
      this.postAction = "";
      this.returnDialog = false;
      this.returnGoodData = {};
      this.$refs.return_form.reset();
    },
    async updateProductRequest() {
      if (!this.$refs.item_form.validate()) return;
      const product = Object.assign({}, this.product);
      this.posting = true;
      try {
        let url = `${this.apiBaseUrl}/storeproducts`;
        const res = await this.$http.patch(url, product);
        this.posting = false;
        this.handleResponse(res, () => {
          let action = "updated";
          this.updateProductInfo(product);
          this.closeItemDialog();
          this.showSnackbar(`Product ${action} successfully.`);

          this.productPrice.new = product.selling_price;
          if (this.productPrice.old !== this.productPrice.new) {
            this.updatePriceChange(
              this.productPrice.old,
              this.productPrice.new,
              product
            );
          }
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "An error occurred. Please try again.");
      }
    },
    updatePriceChange(oldPrice, newPrice, product) {
      var payload = {
        product_id: product.id,
        old_price: oldPrice,
        new_price: newPrice,
        product_name: product.product.name,
        store_id: this.selectedStore.id,
        store_name: this.selectedStore.name,
        user_id: this.authUser.id,
        user_name: this.authUser.full_name,
        date_time: new Date()
      };

      try {
        let url = `${this.apiBaseUrl}/price-changes/single`;
        this.$http.patch(url, payload);
      } catch (error) {
        console.log("Error saving price changes", error);
      }
    },
    async returnGoodsRequest() {
      if (!this.$refs.return_form.validate()) return;

      const postData = Object.assign({}, this.returnGoodData);
      this.posting = true;

      var path = this.isDeprecated ? "depreciation" : "return";

      try {
        let url = `${this.apiBaseUrl}/storeproducts/${path}`;
        const res = await this.$http.post(url, postData);
        this.posting = false;
        this.handleResponse(res, () => {
          this.closeReturnDialog();
          this.showSnackbar(`Return of goods was successful.`);
          this.getProducts();
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "An error occurred. Please try again.");
      } finally {
        this.isDeprecated = false;
      }
    },
    async getWarehouses() {
      try {
        let url = `${this.apiBaseUrl}/warehouses?storeId=-1`; // -1 for warehouses without store id
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.warehouses = data;
        });
      } catch (error) {
        this.handleError(error, "Couldn't fetch your warehouses");
      }
    },
    updateProductInfo(product) {
      const index = this.products.findIndex(x => x.id == product.id);
      this.$set(this.products, index, product);
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getProducts();
      },
      deep: true
    }
  }
};
</script>
