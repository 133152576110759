<template>
  <div class="showcase" :style="{backgroundColor: showcaseBg}">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex xl10 offset-xl1>
          <v-layout row wrap>
            <v-flex sm6 lg5 class="intro">
              <!-- <img src="/static/img/ecr-showcase.jpg" alt="Mock images"> -->
              <h1>{{appName}}</h1>
              <p></p>
              <p>
                {{appName}} is a secure, scalable and convenient store and inventory
                management solution for retail outlets like bars and
                restaurants.
              </p>
              <p>
                With {{appName}} you define your products, track sales, minimise
                register errors and simplify payment collection with a
                360-degree overview of the entire process
              </p>
            </v-flex>
            <v-flex sm5 lg4 offset-sm1 offset-lg3>
              <v-card>
                <v-container fill-height>
                  <v-layout fill-height>
                    <v-flex xs12>
                      <v-card-title primary-title>
                        <h3>Log In</h3>
                      </v-card-title>
                      <v-alert
                        v-model="alert.all.show"
                        :type="alert.all.type"
                        transition="fade-transition"
                        dismissible
                      >
                        {{ alert.all.msg }}
                      </v-alert>
                      <!-- <hr> -->
                      <v-form
                        ref="loginform"
                        :lazy-validation="true"
                        v-model="valid"
                        @submit.prevent="login"
                      >
                        <!-- <h3>Log In</h3>
                                                    <br> -->
                        <v-text-field
                          v-model="loginInfo.phone_number"
                          :rules="formRules.phone"
                          label="Phone number"
                          name="phone_number"
                          required
                          prepend-icon="phone"
                        ></v-text-field>
                        <v-text-field
                          v-model="loginInfo.pin_code"
                          label="Pin Code"
                          :rules="formRules.pin"
                          placeholder="Enter pin"
                          type="password"
                          required
                          prepend-icon="lock"
                        ></v-text-field>

                        <v-layout>
                          <v-flex xs12 class="text-xs-right">
                            <router-link tag="a" to="/forgot-password">
                              Forgot PIN?
                            </router-link>
                          </v-flex>
                        </v-layout>

                        <v-layout>
                          <v-flex xs12 class="text-xs-right">
                            <v-btn
                              color="info"
                              type="submit"
                              :disabled="posting"
                              block
                            >
                              {{ buttonText }}
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <p></p>
                        <v-divider></v-divider>
                        <v-layout>
                          <v-flex xs12>
                            <p></p>
                            <p class="text-xs-center">
                              Need an account?
                              <router-link tag="a" to="/register">
                                Register
                              </router-link>
                            </p>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    appName() {
      return this.$store.getters.appName
    },
    showcaseBg() {
      return this.$store.getters.bgColor
    },
    buttonText() {
      return this.posting ? "Logging in.." : "Log in";
    }
  },
  data() {
    return {
      loginInfo: {},
      valid: true
    };
  },
  methods: {
    async login() {
      if (!this.$refs.loginform.validate()) return;
      this.posting = true;

      try {
        const res = await this.$http.post(
          `${this.urls.api}/auth/login`,
          this.loginInfo
        );
        this.posting = false;
        await this.handleResponse(res, data => {
          // Else
          const { business, user, token } = data;
          const userData = user;
          userData.accessToken = token;
          this.$store.commit("updateUserData", userData);
          this.$store.commit("updateBusinessInfo", business);
          window.location = "/";
        });
      } catch (error) {
          this.posting = false;
          if (error.response) {
              return this.showAlert(
                  error.response.data.message,
                  "error"
              );
          }
        //await this.handleError(
        //  error,
        //  "An error occurred. Please try again later."
        //);
      }
    },
    register() {
      console.log("Registering..");
    }
  }
};
</script>

<style scoped>
.showcase {
  padding: 140px 0;
  min-height: 650px;
  background-size: cover;
}

.intro {
  color: #fff;
}

.intro h1 {
  font-size: 2.8em;
}

@media screen and (max-width: 400px) {
  .showcase {
    padding-bottom: 40px;
  }
}
</style>
