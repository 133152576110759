<template>
    <div>
        <v-layout row wrap>
            <v-flex sm4 px-2>
                <v-text-field required
                              v-model="incoming.name"
                              :validate-on-blur="false"
                              label="Receipt name"
                              :rules="formRules.required"
                              name="receipt_name"></v-text-field>
            </v-flex>
            <v-flex sm4 px-2>
                <v-menu ref="trans_menu"
                        :close-on-content-click="false"
                        :v-model="true"
                        :nudge-right="40"
                        :return-value.sync="incoming.transaction_date"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px">
                    <v-text-field slot="activator"
                                  v-model="incoming.transaction_date"
                                  :rules="formRules.required"
                                  label="Transaction date"
                                  readonly></v-text-field>
                    <v-date-picker v-model="incoming.transaction_date"
                                   no-title
                                   scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn flat
                               color="primary"
                               @click="$refs.trans_menu.save(incoming.transaction_date)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
            <v-flex sm4 px-2>
                <v-menu ref="receipt_menu"
                        :close-on-content-click="false"
                        :v-model="true"
                        :nudge-right="40"
                        :return-value.sync="incoming.receipt_date"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                        @blur="incoming.receipt_date = formatDate(incoming.receipt_date)">
                    <v-text-field slot="activator"
                                  v-model="incoming.receipt_date"
                                  :rules="formRules.required"
                                  label="Receipt date"
                                  readonly></v-text-field>
                    <v-date-picker v-model="incoming.receipt_date"
                                   no-title
                                   scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn flat
                               color="primary"
                               @click="$refs.receipt_menu.save(incoming.receipt_date)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>
        </v-layout>
        <v-layout row wrap>
            <v-flex sm4 px-2>
                <v-select required
                          v-model="incoming.warehouse_id"
                          :items="warehouses"
                          :loading="loading.warehouses"
                          item-text="name"
                          item-value="id"
                          @change="loadWarehouseProducts"
                          :validate-on-blur="true"
                          label="Warehouse"
                          :rules="formRules.required"
                          name="warehouse_id"></v-select>
            </v-flex>
            <v-flex sm4 px-2>
                <v-select required
                          v-model="incoming.document_type"
                          :items="documents"
                          item-text="name"
                          item-value="value"
                          label="Document type"
                          :rules="formRules.required"
                          name="document_type_id"></v-select>
            </v-flex>
            <v-flex sm4 px-2>
                <!-- <v-text-field
                  required
                  v-model="incoming.supplier"
                  :validate-on-blur="true"
                  label="Select a Supplier"
                  :rules="formRules.required"
                  name="supplier_name"
                ></v-text-field> -->
                <v-select required
                          v-model="incoming.supplier_id"
                          :items="suppliers"
                          item-text="companyName"
                          item-value="id"
                          label="Select a Supplier"
                          :rules="formRules.required"
                          name="supplier_id"></v-select>
            </v-flex>
        </v-layout>
        <v-layout row wrap>
            <v-flex sm4 px-2>
                <v-select required
                          v-model="incoming.currency_id"
                          :items="currencies"
                          item-text="currency.name"
                          item-value="currency.id"
                          label="Currency"
                          :rules="formRules.required"
                          name="document_type_id"></v-select>
            </v-flex>
            <v-flex sm4 px-2>
                <v-text-field required
                              v-model="incoming.amount_paid"
                              :validate-on-blur="false"
                              label="Amount Paid (NGN)"
                              :rules="formRules.required"
                              name="amount_paid"></v-text-field>
            </v-flex>
            <v-flex sm4 px-2>
                <v-text-field required
                              v-model="incoming.payment_date"
                              type="date"
                              :validate-on-blur="true"
                              label="Payment Due Date (optional)"
                              name="payment_due_date"></v-text-field>
            </v-flex>
        </v-layout>

        <v-layout row wrap>
            <v-flex sm4 px-2>
                <v-switch v-model="incoming.delivered"
                          :value="false"
                          label="Delivered?"></v-switch>
            </v-flex>
            <v-flex sm4 px-2>
                <v-switch v-model="incoming.mass_upload"
                          @change="changeType"
                          label="Mass Upload?"></v-switch>
            </v-flex>
        </v-layout>
    </div>

</template>

<script>

export default {
   props: { 
        incoming: Object,
        warehouses: Array,
        currencies: Array,
        loading:Object,
        suppliers: Array,
        documents: Array,
        suppliers: Array,
   },
  data() {
      return {
          alertDialog: false,

      }
  },
  methods:{
      loadWarehouseProducts() {
          this.$emit("loadWarehouseProducts");
      },
      changeType() {
          this.$emit("changeType");
      },
  }
};
</script>
