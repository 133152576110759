<template>
  <layout>
    <v-layout row wrap>
      <v-flex sm8 lg6 xl4 offset-sm4 offset-lg6 offset-xl8>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex sm6 px-2>
            <v-btn block @click="newWarehouseInfo" color="info" pre>
              <v-icon>add_circle_outline</v-icon>&nbsp; Add warehouse
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <!-- <hr> -->
    <v-divider></v-divider>
    <br />
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-title class="pt-0">
            <v-layout>
              <v-flex sm6 md4 offset-sm6 offset-md8>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search.warehouses"
                  append-icon="search"
                  label="Search warehouse"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-data-table
            :items="warehouses"
            :rows-per-page-items="[10]"
            :search="search.warehouses"
            :headers="headers.warehouse"
            :loading="loading.warehouses"
          >
            <template slot="no-data">
              No warehouse. Add a new warehouse above.
            </template>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.location || "-" }}</td>
              <td>{{ props.item.type }}</td>
              <td>{{ props.item.capacity || "-" }}</td>
              <td>
                <v-icon small :color="props.item.active ? 'green' : 'red'">
                  {{ props.item.active ? "check_circle" : "remove_circle" }}
                </v-icon>
              </td>
              <td>
                <v-icon small @click="showWarehouseInfo(props.item)">
                  edit
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-dialog v-model="newWarehouseDialog" max-width="800px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter warehouse details
            </h3>
          </v-card-title>
          <transition name="fade">
            <div class="px-3" v-if="alert.single.show">
              <v-alert
                v-model="alert.single.show"
                :type="alert.single.type"
                dismissible
              >
                {{ alert.single.msg }}
              </v-alert>
            </div>
          </transition>
          <v-divider></v-divider>
          <v-card-text>
            <v-form lazy-validation ref="new_warehouse_form">
              <v-layout row wrap>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newWarehouse.name"
                    label="Warehouse name"
                    :rules="formRules.required"
                    name="name"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newWarehouse.location"
                    type="address"
                    validate-on-blur
                    label="Location"
                    :rules="formRules.required"
                    name="location"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newWarehouse.type_of_goods"
                    label="Type of goods"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newWarehouse.capacity"
                    label="Storage capacity"
                    validate-on-blur
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex
                  sm6
                  px-2
                  v-if="!newWarehouse['id'] || newWarehouse.store_id == 0"
                >
                  <v-select
                    required
                    v-model="newWarehouse.type"
                    :items="warehouseTypes"
                    validate-on-blur
                    label="Type"
                  ></v-select>
                </v-flex>

                <v-flex sm6 px-2 v-if="!newWarehouse.store_id">
                  <v-switch
                    label="Is Active?"
                    v-model="newWarehouse.active"
                  ></v-switch>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeWarehouseDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn
                  @click="saveWarehouse"
                  flat
                  :disabled="posting"
                  color="primary"
                  >{{ saveText }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    saveText() {
      const texts = [
        {
          active: "Saving..",
          default: "Save"
        },
        {
          active: "Updating..",
          default: "Update"
        }
      ];

      const index = this.action == "update" ? 1 : 0;
      return this.posting ? texts[index].active : texts[index].default;
    }
  },
  created() {
    this.getWarehouses();
  },
  data() {
    return {
      action: "save",
      headers: {
        warehouse: [
          {
            text: "Warehouse Name",
            align: "left",
            value: "name"
          },
          {
            text: "Location",
            value: "location"
          },
          {
            text: "Type",
            value: "type"
          },
          {
            text: "Capacity",
            value: "capacity"
          },
          {
            text: "Status",
            value: "active"
          },
          {
            text: "Actions",
            value: ""
          }
        ]
      },
      loading: {
        warehouses: false
      },
      newWarehouse: {},
      newWarehouseDialog: false,
      posting: false,
      warehouse: {},
      warehouses: [],
      warehouseTypes: ["Climate-controlled", "Others"],
      search: {
        warehouses: ""
      }
    };
  },
  methods: {
    closeWarehouseDialog() {
      this.action = "save";
      this.newWarehouseDialog = false;
      this.newWarehouse = {};
    },
    async getWarehouses() {
      this.loading.warehouses = true;
      const user = this.isLoggedIn;
      const storeId = user.store_id;
      let url = `${this.apiBaseUrl}/warehouses`;
      if (storeId) url += `?store_id=${storeId}`;
      try {
        const res = await this.$http.get(url);
        this.loading.warehouses = false;
        this.handleResponse(res, data => {
          this.warehouses = data;
        });
      } catch (error) {
        this.loading.warehouses = false;
        this.handleError(error, "We couldn't load your warehouses.");
      }
    },
    async saveWarehouse() {
      if (!this.$refs.new_warehouse_form.validate()) return;
      this.posting = true;
      const newWarehouse = Object.assign({}, this.newWarehouse);
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/warehouses`,
          newWarehouse
        );
        this.posting = false;
        this.handleResponse(res, data => {
          let action = "saved";
          if (this.action == "update") {
            this.updateEntry(data);
            action = "updated";
          } else this.warehouses.push(data);
          this.newWarehouse = {};
          this.closeWarehouseDialog();
          this.showSnackbar(`Warehouse ${action} successfully.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error performing this operation. Please try again later."
        );
      }
    },
    newWarehouseInfo() {
      this.newWarehouse = {};
      this.action = "create";

      this.newWarehouseDialog = true;
    },
    showWarehouseInfo(warehouse) {
      this.newWarehouse = Object.assign({}, warehouse);
      this.action = "update";
      console.log(this.newWarehouse.store_id);

      this.newWarehouseDialog = true;
    },
    updateEntry(data) {
      const index = this.warehouses.findIndex(x => x.id == data.id);
      this.$set(this.warehouses, index, data);
    }
  }
};
</script>
