<template>
  <div id="base-layout">
    <vue-headful
      :title="
        `${this.$store.state.appName} - ${this.$router.currentRoute.name}`
      "
    />

    <v-navigation-drawer persistent fixed app :clipped="true" :value="drawer">
      <drawer-menus></drawer-menus>
    </v-navigation-drawer>

    <main-header></main-header>

    <v-content>
      <div class="page-content">
        <!--<v-alert
          :value="true"
          type="error"
          v-if="!$store.state.subscription.isActive &&  this.isLoggedIn.role.toLowerCase() == 'business admin'"
        >
          You currently have basic active subscription. Your account can be upgraded.
          <br />
          <v-btn color="info" @click="subscribe">Subscribe now</v-btn>
        </v-alert>

        <v-alert
          :value="true"
          type="info"
          v-else-if="$store.state.subscription.isTrial"
        >
          You are on a free trial and it will expire on
          {{
            $moment($store.state.subscription.endDate).format("DD MMM, YYYY")
          }}
        </v-alert>-->

        <v-card>
          <v-card-title primary-title :class="colorplate" style="display:flex;justify-content:space-between">
            <h3 v-if="isLoggedIn.role.toLowerCase() == 'business admin'">{{ $router.currentRoute.name || "" }}</h3>

            <h3 v-else-if="isLoggedIn.role.toLowerCase() == 'administrator' 
                    && $router.currentRoute.name != 'Dashboard'">{{ $router.currentRoute.name || "" }}</h3>

            <span class="text--white mr-1"  v-if="this.isLoggedIn.role.toLowerCase() == 'administrator' && $router.currentRoute.name == 'Dashboard'">{{ $router.currentRoute.name || "" }}</span>
            <div class="dashboard" style="display:flex;justify-content:end"   v-if="this.isLoggedIn.role.toLowerCase() == 'administrator' && $router.currentRoute.name == 'Dashboard'">
                <v-menu ref="startDateMenu" style="margin-right:4px"
                        :close-on-content-click="false"
                        :v-model="startDateMenu"
                        :nudge-right="40"
                        :return-value.sync="filter.startDate"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                        @blur="
                filter.startDate = $moment(filter.startdate).format(
                    'YYYY-MM-DD'
                )
                ">
                    <v-text-field slot="activator"
                                    v-model="filter.startDate"
                                    label="Start date"
                                    solo
                                    :hide-details="true"
                                    readonly
                                    prepend-inner-icon="event"></v-text-field>
                    <v-date-picker v-model="filter.startDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="error" @click="startDateMenu = false">Cancel</v-btn>
                        <v-btn flat
                                color="green"
                                @click="$refs.startDateMenu.save(filter.startDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>

                <v-menu ref="endDateMenu"  style="margin-right:4px"
                        :close-on-content-click="false"
                        :v-model="endDateMenu"
                        :nudge-right="40"
                        :return-value.sync="filter.endDate"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px"
                        @blur="
                filter.endDate = $moment(filter.endDate).format(
                    'YYYY-MM-DD'
                )
                ">
                    <v-text-field slot="activator"
                                    v-model="filter.endDate"
                                    label="End date"
                                    solo
                                    :hide-details="true"
                                    readonly
                                    prepend-inner-icon="event"></v-text-field>
                    <v-date-picker v-model="filter.endDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="error" @click="endDateMenu = false">Cancel</v-btn>
                        <v-btn flat
                                color="green"
                                @click="$refs.endDateMenu.save(filter.endDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
                <v-btn outlined color="white" class="black--text" style="margin: 0; padding: 0 6px" @click="Filter">
                    Filter &nbsp;
                    <v-icon dark>
                        filter_list
                    </v-icon>
                </v-btn>
            </div>
          </v-card-title>

          <store-picker
            v-if="
              requiresStore &&
                this.isLoggedIn.role.toLowerCase() == 'business admin'
            "
          ></store-picker>
          <business-picker
            v-if="
              requiresBusiness &&
                this.isLoggedIn.role.toLowerCase() == 'administrator'
            "
          ></business-picker>
          <warehouse-picker
            v-if="requiresWarehouse"
            @warehouseSelected="warehouseSelected"
          ></warehouse-picker>
          <v-divider></v-divider>
          <v-layout row>
            <v-flex xs12 pa-2 mb-3>
              <transition name="fade">
                <v-layout v-if="alert.all.show">
                  <v-flex xs12>
                    <v-alert
                      v-model="alert.all.show"
                      :type="alert.all.type"
                      transition="fade-transition"
                      dismissible
                      >{{ alert.all.msg }}</v-alert
                    >
                    <br />
                  </v-flex>
                </v-layout>
              </transition>
              <slot></slot>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-content>
  </div>
</template>

<script>
import Logo from "@/components/shared/Logo";
import MainHeader from "@/components/shared/MainHeader";
import DrawerMenus from "@/components/shared/DrawerMenus";
import StorePicker from "./shared/StorePicker.vue";
import BusinessPicker from "./shared/BusinessPicker.vue";
import WarehousePicker from "./shared/WarehousePicker.vue";

export default {
  components: {
    appLogo: Logo,
    DrawerMenus,
    MainHeader,
    StorePicker,
    WarehousePicker,
    BusinessPicker
  },
  computed: {
    drawer() {
      const drawer_ = this.$store.getters.drawer;

      return drawer_;
    },
    colorplate() {
        //return this.isLoggedIn.role.toLowerCase() == 'administrator' ? 'teal darken-2 white--text' : 'red darken-2 white--text';
        return this.isLoggedIn.role.toLowerCase() == 'administrator' ? 'teal darken-2 white--text' : 'info darken-1 white--text';
    }
  },
  created() {
    if (!this.isLoggedIn) window.location = "/";
  },
  data() {
    return {
        currentUser: this.isLoggedIn,
        filter: {
            startDate: "",
            endDate: ""
        },
        endDateMenu: false,
        startDateMenu: false,
        pickers: {
            start: false,
            end: false
        },
    };
  },
  mounted() {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$store.commit("updateDrawer", false);
    }

    this.checkSubscriptionStatus();
  },
  methods: {
    async checkSubscriptionStatus() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/subscriptions/status`
        );

        let status = res.data.data;
        this.$store.commit("updateSubscription", status);
      } catch (error) {
        alert("An error occured while getting subscription details");
      }
    },
    change(e) {
      this.$store.commit("updateDrawer", e);
    },
    warehouseSelected(warehouse) {
      this.$emit("warehouseSelected", warehouse);
      },
    Filter() {
        this.$emit("FilterData", this.filter);
    },
    async subscribe() {
      try {
        this.$router.push("/subscribe");
      } catch (error) {
        console.error(error);
        alert("An error occured");
      }
    }
  },
  props: {
    "requires-store": Boolean,
    "requires-warehouse": Boolean,
    "requires-business": Boolean
  },
  updated() {
    if (!this.isLoggedIn) this.$router.replace("/");
  }
};
</script>
<style>

    .dashboard .v-text-field.v-text-field--solo .v-input__control {
        min-height: 36px !important;
        padding: 0 !important;
        margin-right: 6px;
    }
</style>
