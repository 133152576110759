<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-data-table
        disable-initial-sort
        :items="returnOfGoods"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No return of goods.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.original_transaction }}</td>
          <td>{{ props.item.recipient_user_name }}</td>
          <td>{{ props.item.amount_returned }}</td>
          <td>
            {{
              props.item.returned_products
                ? props.item.returned_products.length
                : ""
            }}
          </td>
          <td>{{ fullDateFormat(props.item.return_time) || "" }}</td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>

    <!-- Item dialog -->
    <v-dialog v-model="showItemDialog" max-width="400px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <h3>View Details</h3>
        </v-card-title>
        <v-card-text>
          <strong>Reason:</strong> <br />
          {{ selectedItem.reason }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Item dialog -->
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
      },
      headers: [
        { text: "Transacction #", value: "original_transaction" },
        { text: "Cashier", value: "recipient_user_name" },
        { text: "Amount Tendered", value: "amount_returned" },
        { text: "No of Products", value: "returned_products.length" },
        { text: "Date", value: "return_time" }
      ],
      loading: false,
      selectedItem: {},
      returnOfGoods: [],
      search: "",
      showItemDialog: false
    };
  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let url = `${
        this.apiBaseUrl
      }/storeproducts/new-return?storeId=${storeId}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.returnOfGoods = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    },
    showInfo(returnOfGood) {
      if (returnOfGood) {
        this.selectedItem = returnOfGood;
      }
      this.showItemDialog = true;
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
