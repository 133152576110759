<template>
    <div>

        <div id="registration">
            <div class="intro">
                <v-container>
                    <v-layout row>
                        <v-flex xs12 sm8 md8 xl6 offset-sm2 offset-md2 offset-xl3>
                            <h1 class="text-xs-center">Privacy policy</h1>

                            <p class="text-xs-center sub">
                            </p>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>

            <v-container grid-list-md style="font-size:16px;background-color:white">
                <v-layout row wrap>
                    <v-flex xl10 offset-xl1>
                        <v-layout row wrap>
                            <div>
                                <v-card-text style="padding:10px" class="content">
                                    <p>
                                        Kashiar is a store and inventory management application that enables users to track sales and manage store transactions.
                                        It is a software registered under parent company: Soft Alliance and Resources Ltd, whose business address is at 9 Nerdc Road, Ikeja, Lagos State
                                    </p>
                                    <div style="background-color: #5d9bce; color: white;margin-bottom:15px">
                                        <h4>Contents:</h4>
                                        <ol>
                                            <li><a href="#about">About</a></li>
                                            <li><a href="#data">The Data we collect from you</a></li>
                                            <li><a href="#we-use-your-data">How we use your Data</a></li>
                                            <li><a href="#we-share-your-data">How we Share your Data (Third Party Disclosure)</a></li>
                                            <li><a href="#data-security">Data Security</a></li>
                                            <li><a href="#cookies">Cookies and How we use them</a></li>
                                        </ol>
                                        <br />
                                    </div>
                                    <div id="about">
                                        <h3>1. About</h3>
                                        <p>
                                            This Privacy Policy Notice provides information on how Kashiar collects and processes your personal, usage and transactional
                                            data when you visit our website or use our mobile application.
                                        </p>
                                    </div>
                                    <div id="data">
                                        <h3>2. The Data we collect from you</h3>
                                        <p>
                                            We collect your personal and usage data in for analytics purposes; which is used to continually improve your user experience and
                                            application performance. We may collect, use, store and transfer the following different kinds of personal and usage data about you:
                                        </p>
                                        <ul>
                                            <li>

                                                Information you provide to us: We receive and store the information you provide to us including your identity data, contact data, usage data, store address and financial data.
                                            </li>
                                            <li>
                                                Information on your use of our website and/or mobile applications: We automatically collect and store certain types of information
                                                regarding your use of the Kashiar application including information about your searches, views, downloads and purchases.
                                            </li>
                                            <li>
                                                Information from third parties and publicly available sources: We may receive information about you from third parties including our carriers;
                                                payment service providers; merchants/brands; and advertising service providers.
                                            </li>

                                        </ul>
                                    </div>
                                    <div id="we-use-your-data">
                                        <h3>3. How we use your Data</h3>
                                        <p>We use your personal data to operate, provide, develop and improve the products and services that we offer, including the following:</p>

                                        <ul>
                                            <li>Registering you as a new customer.</li>
                                            <li>Processing and delivering your orders.</li>
                                            <li>Managing your relationship with us.</li>
                                            <li>Enabling you to participate in promotions, competitions and surveys.</li>
                                            <li>Improving our website, applications, products and services</li>
                                            <li>Recommending/advertising products or services which may be of interest to you.</li>
                                            <li>Complying with our legal obligations, including verifying your identity where necessary.</li>
                                            <li>Detecting fraud.</li>
                                        </ul>
                                    </div>
                                    <div id="we-share-your-data">
                                        <h3>4. How we share your Data</h3>
                                        <p>We may need to share your data with third parties for the following purposes:</p>

                                        <ul>
                                            <li>
                                                Sale of products and services: In order to deliver your products and services purchased on our office or from third parties, we may be required to provide your personal data to such third parties.
                                            </li>
                                            <li>
                                                Working with third party service providers: We engage third parties to perform certain functions on our behalf. Examples include fulfilling orders for products or services, delivering packages, analyzing data, providing marketing assistance,
                                                processing payments, transmitting content, assessing and managing credit risk, and providing customer service.
                                            </li>
                                            <li>
                                                Business transfers: As we continue to develop our business, we might sell or buy other businesses or services.
                                                In such transactions, customer information may be transferred together with other business assets.
                                            </li>
                                            <li>Detecting fraud and abuse: We release account and other personal data to other companies and organizations for fraud protection and credit risk reduction, and to comply with the law.</li>
                                        </ul>
                                        <p>When we share your data with third parties we:</p>
                                        <ul>
                                            <li>Require them to agree to use your data in accordance with the terms of this Privacy Policy and in accordance with the law; and</li>
                                            <li>only permit them to process your data for specified purposes and in accordance with our instructions. We do not allow our third-party service providers to use your data for their own purposes.</li>
                                        </ul>
                                    </div>
                                    <div id="data-security">
                                        <h3>5. Data Security</h3>
                                        <p>
                                            We have put in place appropriate security measures to prevent your data from being accidentally lost, used or accessed in an unauthorized way, altered or disclosed.
                                            In addition, we limit access to your data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your data on our instructions and they are subject to a duty of confidentiality.
                                            We have put in place procedures to deal with any suspected data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
                                        </p>
                                    </div>
                                    <div id="cookies">
                                        <h3>6. Cookies and How we use them</h3>
                                        <p>
                                            A cookie is a small file of letters and numbers that we put on your computer if you agree.
                                            Cookies allow us to distinguish you from other users of our website and mobile applications,
                                            which helps us to provide you with an enhanced browsing experience. For example, we use cookies for the following purposes:
                                        </p>
                                        <ul>
                                            <li>
                                                Recognizing and counting the number of visitors and to see how visitors move around the site when they are using it (this helps us to improve the way our website works,
                                                for example by ensuring that users can find what they are looking for).
                                            </li>
                                            <li>
                                                Identifying your preferences and subscriptions e.g. language settings, saved items, items stored in your basket and Prime membership; and
                                            </li>
                                            <li>Sending you newsletters and commercial/advertising messages tailored to your interests.</li>
                                        </ul>
                                        <p>
                                            Our approved third parties may also set cookies when you use our marketplace. Third parties include search engines,
                                            providers of measurement and analytics services, social media networks and advertising companies.
                                        </p>
                                    </div>
                                    <h3>Further Details</h3>

                                    <p>Contacting Us</p>
                                    <p>
                                        If there are any questions regarding this privacy policy, you may contact us using the information below.
                                    </p>
                                    <p>Soft Alliance and Resources,</p>
                                    <address>9, Nerdc road,</address>
                                    <address>Ojuolape house, Ikeja.</address>
                                    <address>Lagos</address>
                                    <address>Nigeria</address>
                                    <address> <em>info@softalliance.com</em></address>
                                    <aside>Last Edited on 2022-06-21</aside>
                                </v-card-text>
                            </div>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-container>
        </div>
    </div>
</template>

<script>
    import Layout from "@/components/Layout";

    export default {
        components: {
            Layout,
        },
        data() {
            return {
                mobileOnly: false
            };
        },
        methods: {
        },
        watch: {
        }
    };
</script>

<style scoped>
    #registration {
        /*margin-top: 60px;*/
        background-color:white;
    }

    .intro {
        background-color: #066ec1;
        color: #fff;
        /*padding: 90px 0;*/
    }
    h3{
        color:#066ec1 !important;
    }
    a{
        color:white !important;
    }

    .sub {
        max-width: 450px;
        margin: 0 auto;
    }

    @media screen and (max-width: 450px) {
        .intro {
            padding: 40px 0;
        }
    }
</style>
