<template>
  <div class="business-picker">
    <v-card light flat>
      <v-layout row wrap>
        <v-flex sm3 md2 px-4>
          <br />
          Select a business
        </v-flex>
        <v-flex sm6 md4 px-4>
          <v-autocomplete
            :items="business"
            return-object
            :value="selectedBusiness"
            item-text="name"
            :loading="loading"
            autocomplete
            item-value="id"
            label="Select Business"
            single-line
            @change="change"
          ></v-autocomplete>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  created() {
    this.getBusinesses();
  },
  data() {
    return {
      loading: true,
      business: []
    };
  },
  methods: {
    change(business) {
      this.$store.commit("selectBusiness", business);
      this.selectedBusiness_ = business;
    },
    async getBusinesses() {
      this.loading = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/business`);
        this.loading = false;
        this.handleResponse(res, data => {
          this.business = data;
          this.$store.commit("updateBusinesses", data);
        });
      } catch (error) {
        this.loading = false;
        this.handleError(error, "We couldn't fetch your stores");
      }
    }
  }
};
</script>
