<template>
  <div>
    <top-header></top-header>
    <div id="registration">
      <div class="intro">
        <v-container>
          <v-layout row>
            <v-flex xs12 sm8 md8 xl6 offset-sm2 offset-md2 offset-xl3>
              <h1 class="text-xs-center">Get started with {{ appName }}</h1>
              <p class="text-xs-center sub">
                Fill out the information below and we will walk you through
                setting up your own business in no time.
              </p>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <v-container>
        <v-dialog max-width="500" v-model="dialog" persistent>
          <v-card>
            <v-card-text>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
              Please wait while we setup your account.
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-layout row wrap>
          <v-flex lg8 offset-lg2>
            <v-card>
              <v-card-title primary-title>
                <v-layout>
                  <v-flex xs12>
                    <h2 class="text-xs-center">
                      Register
                    </h2>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-layout row wrap>
                <v-flex xs12 px-3>
                  <v-alert
                    v-model="alert.all.show"
                    :type="alert.all.type"
                    transition="fade-transition"
                    dismissible
                  >
                    {{ alert.all.msg }}
                  </v-alert>
                  <transition name="fade" mode="out-in">
                    <div v-if="stage == 'business-reg'">
                      <v-form
                        ref="register_form"
                        lazy-validation
                        @submit.prevent="register"
                        class="register-form"
                      >
                        <v-layout row wrap>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.business_name"
                              :rules="formRules.required"
                              label="Business name"
                              required
                              prepend-icon="bookmark"
                              id="business_name"
                            ></v-text-field>
                          </v-flex>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.business_address"
                              :rules="formRules.required"
                              type="address"
                              label="Business address"
                              required
                              prepend-icon="place"
                              id="business_address"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.business_email"
                              type="email"
                              label="Business email"
                              required
                              prepend-icon="email"
                              id="business_email"
                            ></v-text-field>
                          </v-flex>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.business_phone"
                              type="tel"
                              :rules="formRules.phone"
                              label="Business phone no."
                              required
                              prepend-icon="phone"
                              id="business_phone"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <!-- <v-layout row wrap>
                                                    <v-flex sm6 pa-2>
                                                        <v-text-field v-model="businessInfo.business_tin" label="Tax Identification no. (TIN)"
                                                            prepend-icon="call_to_action" id="business_tin"></v-text-field>
                                                    </v-flex>
                                                    <v-flex xs12 sm6 pa-2>
                                                        <v-switch v-model="businessInfo.is_vat_payer" value="0"
                                                            true-value="1" false-value="0" label="Is VAT payer?" id="business_vat"></v-switch>
                                                    </v-flex>
                                                </v-layout>
                                                <v-layout row wrap>
                                                    <v-flex sm6 pa-2>
                                                        <v-text-field v-model="businessInfo.registration_number" label="Registration no."
                                                            required prepend-icon="note" id="business_registration_number"></v-text-field>
                                                    </v-flex>
                                                    <v-flex sm6 pa-2>
                                                        <v-text-field v-model="businessInfo.authority" label="Authority."
                                                            prepend-icon="perm_identity" id="business_authority"></v-text-field>
                                                    </v-flex>
                                                </v-layout> -->
                        <v-layout row wrap>
                          <v-flex sm6 pa-2>
                            <v-autocomplete
                              :items="currencies_"
                              v-model="businessInfo.default_currency"
                              prepend-icon="attach_money"
                              item-text="name"
                              item-value="id"
                              label="Select default currency"
                              single-line
                              :rules="formRules.required"
                              validate-on-blur
                              id="business_currency"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                        <br />
                        <v-layout>
                          <v-flex xs12>
                            <h2 class="text-xs-center">
                              Contact Information
                            </h2>
                          </v-flex>
                        </v-layout>
                        <br />
                        <v-layout row wrap>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.contact_first_name"
                              :rules="formRules.required"
                              label="Contact's first name"
                              required
                              prepend-icon="contacts"
                              id="contact_first_name"
                            ></v-text-field>
                          </v-flex>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.contact_last_name"
                              label="Contact's last name"
                              :rules="formRules.required"
                              required
                              prepend-icon="contacts"
                              id="contact_last_name"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.contact_email"
                              label="Contact's email"
                              :rules="formRules.required"
                              required
                              prepend-icon="email"
                              id="contact_email"
                            ></v-text-field>
                          </v-flex>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.contact_phone"
                              :rules="formRules.phone"
                              label="Contact's phone no."
                              required
                              prepend-icon="contact_phone"
                              id="contact_phone"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.pin_code"
                              type="password"
                              :rules="formRules.all('required', 'pin')"
                              label="Pin Code"
                              required
                              prepend-icon="lock"
                              id="contact_password"
                            ></v-text-field>
                          </v-flex>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              type="password"
                              v-model="businessInfo.confirm_pin_code"
                              label="Confirm pin code"
                              required
                              prepend-icon="lock"
                              :rules="
                                formRules.equal(
                                  businessInfo.confirm_pin_code,
                                  businessInfo.pin_code,
                                  'Your passwords do not match.'
                                )
                              "
                              id="contact_confirm_password"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                          <v-flex sm6 pa-2>
                            <v-text-field
                              v-model="businessInfo.referral_code"
                              type="text"
                              label="Referral Code"
                              prepend-icon="access-point"
                              id="referral_code"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>

                        <v-layout>
                          <v-flex xs6 offset-xs6>
                            <p></p>
                            <p></p>
                            <v-btn
                              type="submit"
                              color="info"
                              block
                              :disabled="posting"
                            >
                              {{ buttons.user.text }}
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex xs12>
                            <p></p>
                            <v-divider></v-divider>
                            <p></p>
                            <p class="text-xs-center">
                              Have an account?
                              <router-link tag="a" to="/">
                                Log In
                              </router-link>
                            </p>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </div>
                  </transition>
                  <transition name="fade">
                    <div v-show="stage == 'token-val'">
                      <v-form
                        ref="token_form"
                        @submit.prevent="validateToken"
                        :lazy-validation="true"
                        class="token-form"
                      >
                        <v-card-text>
                          <p class="text-xs-center">
                            Please enter the OTP sent to your phone number or
                            email address.
                          </p>
                        </v-card-text>
                        <v-text-field
                          v-model="tokenInfo.phone_number"
                          label="Phone number"
                          placeholder="Phone number"
                          :rules="formRules.phone"
                          required
                          prepend-icon="contact_phone"
                          id="otp_phone"
                        ></v-text-field>

                        <v-text-field
                          v-model="tokenInfo.otp"
                          label="OTP Code"
                          placeholder="Enter Code"
                          :rules="formRules.token"
                          required
                          prepend-icon="lock"
                          id="otp_code"
                        ></v-text-field>
                        <v-layout>
                          <v-flex xs12>
                            <p></p>
                            <p></p>
                            <v-btn
                              type="submit"
                              color="info"
                              block
                              :disabled="posting"
                            >
                              {{ buttons.token.text }}
                            </v-btn>

                            <br />

                            <v-btn
                              type="button"
                              color="warning"
                              block
                              flat
                              small
                              :disabled="posting"
                              @click="requestOtp()"
                            >
                              Click here to request for a new OTP
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </div>
                  </transition>
                  <transition name="fade">
                    <div v-show="stage == 'update-password'">
                      <v-form ref="password_form" lazy-validation>
                        <v-card-text>
                          <p class="text-xs-center">
                            Please add a password to your account.
                          </p>
                        </v-card-text>
                        <v-text-field
                          v-model="passwordInfo.password"
                          label="New password"
                          type="password"
                          required
                          prepend-icon="lock"
                          :rules="formRules.password"
                        ></v-text-field>
                        <v-text-field
                          v-model="passwordInfo.cpassword"
                          label="Confirm password"
                          type="password"
                          :rules="[v => !!v && v == passwordInfo.password]"
                          required
                          prepend-icon="lock"
                        ></v-text-field>
                        <v-layout>
                          <v-flex xs8 offset-xs4>
                            <p></p>
                            <p></p>
                            <v-btn
                              @click="updatePassword"
                              color="info"
                              block
                              :disabled="posting"
                            >
                              {{ buttons.password.text }}
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-form>
                    </div>
                  </transition>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/shared/TopHeader";
export default {
  components: {
    TopHeader
  },
  computed: {
    appName() {
      return this.$store.getters.appName;
    },
    buttons() {
      return {
        user: {
          text: this.posting ? "Please wait..." : "Register"
        },
        token: {
          text: this.posting ? "Validating token..." : "Validate token"
        },
        password: {
          text: this.posting ? "Updating..." : "Update password"
        }
      };
    },
    currencies_() {
      let currencies = [];
      this.currencies.forEach((item, index) => {
        currencies[index] = item;
        currencies[index].id = index + 1;
        currencies[index].name = `${item.name} (${item.symbol})`;
      });

      return currencies;
    }
  },
  created() {
    this.getCurrencies();
  },
  data() {
    return {
      businessInfo: {
        is_vat_payer: "0",
        referral_code: ""
      },
      currencies: [],
      dialog: false,
      //   stage: "token-val",
      stage: "business-reg",
      posting: false,
      passwordInfo: {},
      registerInfo: {},
      tokenInfo: {},
      userData: {},
      valid: {
        biz: true,
        reg: false,
        token: true
      }
    };
  },
  methods: {
    clearValidation() {
      this.$refs.register_form.reset();
    },
    async getCurrencies() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/currencies`);
        this.handleResponse(res, data => {
          this.currencies = data;
        });
      } catch (error) {
        this.handleError(error, "We couldn't fetch currencies.");
      }
    },
    async register() {
      if (!this.$refs.register_form.validate()) return;
      this.posting = true;
      let payload = this.businessInfo;
      payload.is_vat_payer = this.businessInfo ? true : false;

      try {
        const res = await this.$http.post(
          `${this.baseUrl}/api/account`,
          payload
        );
        this.posting = false;
        await this.handleResponse(res, () => {
          this.tokenInfo.phone_number = this.businessInfo.contact_phone;
          this.passwordInfo.phone_number = this.businessInfo.contact_phone;

          // Else
          this.stage = "token-val";
          this.requestOtp();
        });
      } catch (error) {
        this.posting = false;
        console.log(error);
        await this.handleError(
          error,
          "An error occurred. Please try again later"
        );
      }
    },
    async requestOtp() {
      try {
        this.posting = true;
        const res = await this.$http.post(`${this.baseUrl}/api/auth/sendotp`, {
          phone_number: this.tokenInfo.phone_number
        });
        this.posting = false;
        await this.handleResponse(res, () => {
          return this.showSnackbar("OTP sent successfully", {
            type: "success"
          });
        });
      } catch (error) {
        this.posting = false;
        console.log(error);
        await this.handleError(
          error,
          "An error occurred. Please try again later"
        );
      } finally {
        this.posting = false;
      }
    },
    updatePassword() {
      if (!this.$refs.password_form.validate()) return;
      this.posting = true;
      this.$http
        .post(`${this.baseUrl}/api/auth/password`, this.passwordInfo)
        .then(res => {
          this.posting = false;
          const response = res.data;
          const data = response.data;
          this.showAlert(response.message, "success");
          this.userData = data.user;
          //   this.userData.business = data.business;
          this.userData.token = data.token;
          this.$store.commit("updateUserData", this.userData);
          this.$store.commit("updateBusinessInfo", data.business);
          window.location = "/";
        })
        .catch(error => {
          this.posting = false;
          if (!error.response) {
            return this.showAlert(
              "An error occurred. Please try again",
              "error"
            );
          }

          const data = error.response.data;
          const msg = data.message;
          return this.showAlert(msg, "error");
        });
    },
    async validateToken() {
      if (!this.$refs.token_form.validate()) return;

      this.posting = true;

      try {
        const res = await this.$http.post(
          `${this.baseUrl}/api/auth/otp`,
          this.tokenInfo
        );
        this.posting = false;

        if (res.data.status == 0) {
          return this.showSnackbar(res.data.message, {
            type: "error"
          });
        }

        const { business, user, token } = res.data.data;
        const userData = user;
        userData.accessToken = token;
        this.$store.commit("updateUserData", userData);
        this.$store.commit("updateBusinessInfo", business);
        window.location = "/";
      } catch (error) {
        this.posting = false;
        await this.handleError(
          error,
          "We couldn't complete the registration process. Please try again later."
        );
      }
    }
  }
};
</script>

<style scoped>
#registration {
  margin-top: 60px;
}

.intro {
  background-color: #066ec1;
  color: #fff;
  padding: 90px 0;
}

.sub {
  max-width: 450px;
  margin: 0 auto;
}

@media screen and (max-width: 450px) {
  .intro {
    padding: 40px 0;
  }
}
</style>
