<template>
    <div>
        <v-card flat>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn @click="showUploadDialog" color="pink darken-2 white--text" pre>
                    <v-icon>file_upload</v-icon>&nbsp; Upload customers
                </v-btn>
                <v-btn color="primary" @click="newPartnerDialog = true">
                    Add customer
                </v-btn>
                <div style="margin-left: auto;">
                    <DownloadCSV class="v-btn theme--light success"
                                 :data="exportPartners"
                                 name="customers.csv"
                                 v-if="exportPartners.length">
                        Export
                    </DownloadCSV>
                </div>
                <!-- <v-btn color="blue-grey darken-2 white--text" @click="mergePartners">
                    Merge customer
                </v-btn> -->
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-layout row wrap>
                    <v-spacer></v-spacer>
                    <v-flex sm4>
                        <v-text-field v-model="search"
                                      append-icon="search"
                                      label="Search customer"
                                      single-line
                                      hide-details
                                      clearable></v-text-field>
                        <br />
                    </v-flex>
                    <v-flex xs12>
                        <template>
                            <v-data-table v-model="selected"
                                          disable-initial-sort
                                          :rows-per-page-items="[20]"
                                          :headers="headers"
                                          :items="items"
                                          :search="search"
                                          item-key="id"
                                          class="elevation-1">


                                <!--select-all-->
                                <!-- <template v-slot:headers="props">
                                    <tr> -->
                                        <!--<th>
                                            <v-checkbox :input-value="props.all"
                                                        :indeterminate="props.indeterminate"
                                                        primary
                                                        hide-details
                                                        @click.stop="toggleAll"></v-checkbox>
                                        </th>-->
                                        <!-- <th v-for="header in props.headers"
                                            :key="header.text">
                                            {{ header.text }}
                                        </th>
                                    </tr>
                                </template> -->

                                <template slot="no-data">
                                    No partners. Add a new partner above.
                                </template>
                                <!--:active="props.selected" @click="props.selected = !props.selected"-->
                                <template slot="items" slot-scope="props">
                                    <tr>
                                        <!--<td>
                                            <v-checkbox :input-value="props.selected"
                                                        primary
                                                        hide-details></v-checkbox>
                                        </td>-->
                                        <td style="text-align:left">
                                            {{ props.item.name }} <br />
                                            <small>{{ props.item.address || "" }}</small>
                                        </td>
                                        <td style="text-align:left">{{ props.item.phone_number }}</td>
                                        <!--<td>
                                            {{
                                            props.item.partner_group
                                              ? props.item.partner_group.name
                                              : ""
                                            }}
                                        </td>-->
                                        <td style="text-align:left">{{ props.item.email }}</td>
                                        <td style="text-align:left">
                                            {{ props.item.wallet_balance }}
                                        </td>
                                        <td style="text-align:center">
                                            <v-icon small :color="props.item.use_discount ? 'green' : 'red'">
                                                {{ props.item.use_discount ? "check_circle" : "remove_circle" }}
                                            </v-icon>
                                        </td>
                                        <td style="text-align:center">
                                            <v-icon small :color="props.item.active ? 'green' : 'red'">
                                                {{ props.item.active ? "check_circle" : "remove_circle" }}
                                            </v-icon>
                                        </td>
                                        <!--<td>{{ props.item.default_discount || '' }}</td>-->
                                        <td style="text-align:center">
                                            <v-icon style="margin-left: 15px;"
                                                    small
                                                    @click="$router.push(`partners/view/${props.item.id}`)">
                                                info
                                            </v-icon>
                                            &nbsp;&nbsp;
                                        </td>
                                        <td style="text-align:center">
                                            <v-icon small @click="showGroupInfo(props.item)">
                                                edit
                                            </v-icon>
                                            &nbsp;&nbsp;
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </template>
                    </v-flex>
                </v-layout>
            </v-card-text>
        </v-card>
        <v-dialog v-model="newPartnerDialog" max-width="800px" persistent>
            <v-card>
                <v-card-title primary-title>
                    <h3>
                        Enter partner details
                    </h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form lazy-validation ref="new_partner_form" @submit.prevent="post">
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="newPartner.name"
                                              label="Name"
                                              autofocus
                                              :rules="formRules.required"></v-text-field>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="newPartner.address"
                                              label="Address"
                                              :rules="formRules.required"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="newPartner.phone_number"
                                              label="Phone number"></v-text-field>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="newPartner.email"
                                              label="Email address"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-autocomplete :items="activeGroups"
                                                v-model="newPartner.partner_group_id"
                                                item-text="name"
                                                item-value="id"
                                                label="Partner group"></v-autocomplete>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-select v-model="newPartner.discount_id"
                                            :disabled="newPartner.use_discount !==true"
                                            :required="newPartner.use_discount ==true"
                                          :items="discounts"
                                          item-text="name"
                                          item-value="id"
                                          :validate-on-blur="true"
                                          label="Discount"
                                          placeholder="Select discount"
                                          name="discount"></v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-switch label="Is Active?"
                                      v-model="newPartner.active"></v-switch>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-switch label="Use Discount?"
                                      v-model="newPartner.use_discount"></v-switch>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-btn @click="closePartnerDialog" flat :disabled="posting">Close</v-btn>
                            <v-btn type="submit"
                                   flat
                                   :disabled="posting || partnerGroups.length < 1"
                                   color="primary">{{ actionText }}</v-btn>
                        </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="uploadFormDialog" max-width="500px" persistent>
            <v-card>
                <v-card-title primary-title class="headline grey lighten-2">
                    <h3>Upload Customers Details</h3>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    <v-btn @click="downloadReportFile" flat v-if="hasFIle">Click here to download report file here</v-btn>
                    <v-btn @click="downloadFile" flat v-else>Click here to download sample file here</v-btn>

                    <v-form lazy-validation ref="upload_form">
                        <upload-btn :fileChangedCallback="fileChanged">
                            <template slot="icon-left">
                                <v-icon color="white">file_upload</v-icon>
                            </template>
                        </upload-btn>
                        {{ fileupload.name ? fileupload.name : "No file selected" }}
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeUploadFormDialog" flat :disabled="upload">Close</v-btn>
                            <v-btn @click="uploadPartnersFile" flat color="primary" :disabled="upload">{{ uploadText }}</v-btn>
                        </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="mergerPartnerDialog" max-width="550px" persistent>
            <v-card>
                <v-card-title primary-title :class="color">
                    <h3>Merge information</h3>
                    &nbsp;<v-icon class="white--text" v-if="hasSelected">warning</v-icon>
                    <v-spacer></v-spacer>
                    <v-btn @click="closeMergeDialog" color="white--black" v-if="!hasSelected">Cancel</v-btn>
                    <v-btn v-if="!hasSelected" @click="okayMergedPartner" color="blue-grey darken-2 white--text" :disabled="contactSelected.length < 1 && !hasSelected">Okay</v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <div v-if="!hasSelected">
                    <p style="padding: 8px; font-size: 16px; font-weight: bold;
                                border-left: 5px solid #455a64; margin: 5px; background: #455a641c;">
                        Select the primary customer
                    </p>
                    <v-data-table v-model="contactSelected"
                                  disable-initial-sort
                                  :items="selected"
                                  :rows-per-page-items="[10]"
                                  :search="search"
                                  item-key="name"
                                  :single-select="singleSelect"
                                  @item-selected="selected"
                                  :headers="headersMerge">
                        <template slot="no-data">
                            No records.
                        </template>
                        <template slot="items" slot-scope="props">
                            <tr :active="props.selected" @click="props.selected = !props.selected">
                                <td>
                                    <v-checkbox :input-value="props.selected"
                                                primary
                                                hide-details></v-checkbox>
                                </td>
                                <td>{{ props.item.name }}</td>
                                <td>
                                    {{ props.item.phone_number }}
                                </td>
                                <td>{{ props.item.email }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </div>

                <v-card-text v-else>
                    <h4>This will merge customer's (profile, transaction reports and wallet details) records.</h4>
                    <v-layout>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeMergeDialog" flat :disabled="merge">Close</v-btn>
                        <v-btn @click="sendMergedPartner" flat color="primary" :disabled="merge">{{ mergeText }}</v-btn>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    import axios from "axios";
    import UploadButton from "vuetify-upload-button";
    import JsonCSV from "vue-json-csv";

    export default {
        components: {
            "upload-btn": UploadButton,
            DownloadCSV: JsonCSV
        },
        created() {
            this.getDiscounts();
        },
        computed: {
            actionText() {
                const texts = [
                    {
                        active: "Saving",
                        default: "Save"
                    },
                    {
                        active: "Updating",
                        default: "Update"
                    }
                ];

                const index = this.action ? 1 : 0;
                return this.posting ? texts[index].active : texts[index].default;
            },
            exportPartners() {
                return this.items
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((x) => {
                    return {
                        Name: x.name,
                        Address: x.address,
                        PhoneNumber: x.phone_number,
                        Email: x.email,
                        WalletBalance: x.wallet_balance,
                    };

                });
            },
            uploadText() {
                return this.upload ? "Uploading..." : "Upload file"
            },
            mergeText() {
                return this.merge ? "Merging..." : "Merge customers"
            },
            activeGroups() {
                return this.partnerGroups
                    .filter(x => x.active == true)
                    .sort((a, b) => a.name > b.name);
            },
            color() {
                return this.hasSelected ? "red darken-2 white--text" : "blue-grey darken-2 white--text";
            }
        },
        watch: {
            contactSelected() {
                if (this.contactSelected.length > 1)
                    this.contactSelected = this.contactSelected.slice(-1);
            }
        },
        data() {
            return {
                action: "",
                singleSelect: true,
                selected: [],
                contactSelected: [],
                headersMerge: [
                    {
                        text: "",
                        value: ""
                    },
                    {
                        text: "Name",
                        value: "name"
                    },
                    {
                        text: "Phone Number",
                        value: "phone_number"
                    },
                    {
                        text: "Email",
                        value: "email"
                    }
                ],
                headers: [
                    {
                        text: "Name",
                        value: "name"
                    },
                    {
                        text: "Phone number",
                        value: "phone_number"
                    },
                    //{
                    //    text: "Partner Group",
                    //    value: "partner_group"
                    //},
                    {
                        text: "Email",
                        value: "email"
                    },
                    {
                        text: "Wallet Balance",
                        value: "wallet_balance"
                    },
                    {
                       text: "Discount",
                       value: "use_discount"
                    },
                    {
                        text: "Status",
                        value: "active"
                    },
                    {
                        text: "Ledger",
                        value: ""
                    },
                    {
                        text: "Actions",
                        value: ""
                    }
                ],
                amountTypes: [
                    {
                        id: 0,
                        name: "Flat"
                    },
                    {
                        id: 1,
                        name: "Percent (%)"
                    }
                ],
                newPartner: {
                    use_discount: true
                },
                newPartnerDialog: false,
                search: "",
                uploadFormDialog: false,
                mergerPartnerDialog: false,
                fileupload: {},
                payload: {},
                discounts: [],
                upload: false,
                merge: false,
                hasFIle: false,
                hasSelected: false,
                ids: 0,
                path: "",
            };
        },
        methods: {
            toggleAll() {
                if (this.selected.length) this.selected = []
                else this.selected = this.items.slice();
            },
            okayMergedPartner() {
                this.hasSelected = true;
            },
            downloadFile() {
                window.open(`${this.apiBaseUrl}/misc/partner-sample-file`, "_blank");
            },
            downloadReportFile() {
                window.open(`${this.apiBaseUrl}/misc/partner-report-file?hash=${this.path}`, "_blank");
            },
            async getDiscounts() {
                this.loaded = false;
                let url = `${this.apiBaseUrl}/discounts`;
                if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
                const res = await this.$http.get(url);
                this.loaded = true;
                this.handleResponse(res, data => {
                    this.discounts = data;
                });
            },
            closePartnerDialog() {
                this.action = "";
                this.newPartnerDialog = false;
                this.$refs.new_partner_form.reset();
            },
            showActivationDialog() { },
            showGroupInfo(item) {
                this.newPartner = Object.assign({}, item);
                this.action = "update";
                this.newPartnerDialog = true;
            },
            async post() {
                var phone = this.newPartner.phone_number;
                var email = this.newPartner.email;
                if (phone == undefined && email == undefined) {
                    this.showSnackbar("Either phone number or email should have a value", {
                        type: "error"
                    });
                    return;
                }
                if (!phone.trim() && !email.trim()) {
                    this.showSnackbar("Either phone number or email should have a value", {
                        type: "error"
                    });
                    return;
                }
                if (this.action !== "switchActivation") {
                    if (!this.$refs.new_partner_form.validate()) return;
                }
                const partner = Object.assign({}, this.newPartner);
                console.log("customer-object", partner)
                this.posting = true;
                try {
                    const res = await this.$http.post(
                        `${this.apiBaseUrl}/partners`,
                        partner
                    );
                    this.posting = false;
                    this.handleResponse(res, () => {
                        this.$emit("refreshData");
                        this.closePartnerDialog();
                    });
                } catch (error) {
                    this.posting = false;
                    this.handleError(error, "Operation failed. Please try again later.");
                }
            },
            async mergePartners() {
                if (this.selected.length < 2) {
                    this.showSnackbar("Select two or more profile to merge.", {
                        type: "error"
                    });
                    return;
                }
                this.mergerPartnerDialog = true
            },
            async sendMergedPartner() {
                //console.log("Destination contact--->", this.contactSelected);
                let collection = [];
                this.selected.forEach(item => {
                    if (item.id != this.contactSelected[0].id) collection.push(item.id);
                });
                this.payload.destinationId = this.contactSelected[0].id;
                this.payload.partner_list = collection;

                //console.log("ALl the contact-->",this.selected);
                this.merge = true;
                axios
                    .post(`${this.apiBaseUrl}/partners/merge`, this.payload)
                    .then(response => {
                        const { message, data } = response.data;
                        //this.$emit("refreshData");
                        this.showSnackbar(message);
                        this.merge = false;
                        window.location = "/partners"
                    })
                    .catch(error => {
                        this.merge = false;
                    });
                this.closeMergeDialog();
            },
            fileChanged(file) {
                this.fileupload = file;
            },
            closeUploadFormDialog() {
                this.uploadFormDialog = false;
                this.fileupload = {};
                this.$emit("refreshData");
            },
            closeMergeDialog() {
                this.mergerPartnerDialog = false;
                this.payload = {};
                this.$emit("refreshData");
            },
            showUploadDialog() {
                this.uploadFormDialog = true;
            },
            showMergeDialog() {
                this.mergerPartnerDialog = true;
            },
            uploadPartnersFile() {
                let formData = new FormData();
                this.upload = true;
                formData.append("file", this.fileupload);
                const headers = {
                    headers: {
                        "Content-Type": "miltipart/form-data"
                    }
                };
                axios
                    .post(`${this.apiBaseUrl}/partners/upload`, formData, headers)
                    .then(response => {
                        const { message, data } = response.data;
                        const errors = data.errors;
                        this.path = data.hash;
                        if (this.path) this.hasFIle = true;
                        //this.$emit("refreshData");
                        if (errors.length) {
                            this.upload = false;
                            return this.showSnackbar(errors.join("<br />"), {
                                type: "error",
                                position: "top",
                                timeout: 5000
                            });
                        }
                        this.showSnackbar(message);
                        this.upload = false;
                        //this.closeUploadFormDialog();
                    })
                    .catch(error => {
                        console.log("error", error);
                        this.upload = false;
                    });
            },

        },
        props: {
            items: Array,
            partnerGroups: Array
        }
    };
</script>

<style scoped>
    .inverse,
    .img-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;
        opacity: 0;
        transition: all ease-in-out 0.4s;
    }

    .img-loader {
        background-color: #fff;
        opacity: 0.85;
        display: flex;
        visibility: hidden;
        align-items: center;
        justify-content: center;
    }

    .upload-btn {
        display: inline-flex;
    }
</style>
