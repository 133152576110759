import Main from "@/views/users/Main";
import Users from "@/views/users/Users";

export default [
  {
    name: "Manage Users",
    path: "/users",
    component: Main,
    icon: "people",
    showInNav: true,
    access: "users",
    children: [
      {
        name: "Users",
        path: "/users",
        component: Users,
        icon: "people"
      }
    ],
    meta:["admin"]
  }
];
