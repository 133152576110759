<template>
  <v-card>
    <v-card-title class="green darken-2 white--text">
      Most Sold Product
    </v-card-title>
    <v-card-text class="text-sm-right p-8">
        <h2 v-if="mostSoldProduct">{{ mostSoldProduct.name || "_" }} ({{ mostSoldProduct.count || "" }})</h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MostSoldCount",
  data() {
    return {
      count: "-"
    };
  },
  props: ['mostSoldProduct'],
  mounted() {
    // this.getData();
  },
  methods: {
  }
};
</script>

<style scoped></style>
