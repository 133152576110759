import PasswordChange from "@/views/profile/PasswordChange";
import UserProfile from "@/views/profile/UserProfile";

export default [
  {
    name: "Password Change",
    path: "/profile/change-password",
    component: PasswordChange,
    meta:["admin","administrator"]
  },
  {
    name: "Details",
    path: "/profile/update",
    component: UserProfile,
    meta:["admin","administrator"]
  },
];
