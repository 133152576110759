<template>
    <layout requires-business>
        <template>
            <logging v-if="selectedBusiness"></logging>
        </template>
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import Logging from "@/components/superadmin/logging/Logging.vue";
    import LoggingDetails from "@/components/superadmin/logging/LoggingDetails.vue";

    export default {
        components: {
            Layout,
            Logging,
            LoggingDetails
        },
        data() {
            return {
                loading: false,
            };
        },
        watch: {
            selectedBusiness: {
                handler() {
                },
                deep: true
            }
        }
    };
</script>
<style scoped>
    .customer-name {
        font-size: 15px;
        margin-bottom: 15px;
        padding: 10px;
    }
</style>