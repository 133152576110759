<template>
  <div v-if="!loaded">
    <v-progress-linear height="2" indeterminate></v-progress-linear>
  </div>
  <div v-else>
    <v-card flat>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="posting" @click="saveChanges">
          <v-icon>save</v-icon>&nbsp; {{ saveText }}
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <br />
      <v-form ref="settings_form" lazy-validation>
        <v-layout row>
          <v-flex xs12>
            <v-list>
              <v-layout row wrap>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Use Fiscal Module
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.fiscal_module"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Use Storage
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.use_storage"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Use Partners
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.use_partners"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>

                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Use Returnable Packaging
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch
                        v-model="settings.returnable_packaging"
                      ></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Enable printing of current orders
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.print_orders"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>

                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Sync payments from cash register to back office/PayGATE
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.payment_sync"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Sync from mobile waiter to cash register
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch
                        v-model="settings.waiter_register_sync"
                      ></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Sync bills from cash register to back office/PayGATE
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.bill_sync"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Sync products from/to cash register
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.products_sync"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Sync warehouse/stock level from/to cash register
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.warehouse_sync"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Sync settings from/to cash register
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.settings_sync"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
                <v-flex sm6>
                  <v-list-tile class="px-0">
                    <v-list-tile-content>
                      <v-list-tile-title>
                        Sync receipts from/to cash register
                      </v-list-tile-title>
                    </v-list-tile-content>
                    <v-list-tile-action>
                      <v-switch v-model="settings.receipt_sync"></v-switch>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-flex>
              </v-layout>
            </v-list>
          </v-flex>
        </v-layout>
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  computed: {
    saveText() {
      return this.posting ? "Saving" : "save";
    }
  },
  created() {
    this.getSettings();
  },
  data() {
    return {
      loaded: false,
      screenOptions: ["Tables", "Products/Orders"],
      settings: {}
    };
  },
  methods: {
    async getSettings() {
      this.loaded = false;
      let url = `${this.apiBaseUrl}/settings/others`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;

      try {
        const res = await this.$http.get(url);
        this.loaded = true;
        this.handleResponse(res, data => {
          this.settings = data || {};
        });
      } catch (error) {
        this.loaded = true;
        this.showSnackbar("We couldn't get your other settings", {
          type: "error"
        });
      }
    },
    async saveChanges() {
      console.log(this.settings);
      if (!this.$refs.settings_form.validate()) return;

      try {
        const settings = Object.assign({}, this.settings);
        const selectedStore = this.selectedStore;
        if (selectedStore) settings.store_id = selectedStore.id;
        this.posting = true;
        const res = await this.$http.post(
          `${this.apiBaseUrl}/settings/others`,
          settings
        );
        this.posting = false;
        this.handleResponse(res, data => {
          this.settings = data;
          this.showSnackbar("Settings updated successfully.");
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error while updating your other settings."
        );
      }
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getSettings();
      }
    }
  }
};
</script>
