<template>
  <layout>
    <template>
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-menu
            ref="startDayMenu"
            v-model="startDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.startDate"
                label="Start date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="startDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.startDayMenu.save(filter.startDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-menu
            ref="endDayMenu"
            v-model="endDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.endDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.endDate"
                label="End Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="endDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.endDayMenu.save(filter.endDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-btn color="info" @click="fetchLogs"> Filter </v-btn>
        </v-flex>

        <v-flex xs12 sm3>
          <v-layout row wrap>
            <div class="text-xs-left" style="padding-top: 15px;">
              <div>
                <input type="checkbox" v-model="mobileOnly" :checked="mobileOnly"/>
                Mobile Only
              </div>
            </div>
            <div class="text-xs-right">
              <DownloadCSV
                class="v-btn theme--light success"
                :data="filterLogs"
                name="export-activity-logs.csv"
                v-if="filterLogs.length"
              >
                Export
              </DownloadCSV>
            </div>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex sm9></v-flex>
        <v-flex sm3>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search Logs"
            single-line
            hide-details
            clearable
          ></v-text-field>
        </v-flex>
        <v-divider></v-divider>
      </v-layout>

       <v-data-table
        disable-initial-sort
        :items="filterLogs"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data"> No Activity Logs </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.store }}</td>
          <td>{{ props.item.action }}</td>
          <td>{{ props.item.message }}</td>
          <td>{{ props.item.path }}</td>
          <td>{{ props.item.method }}</td>
          <td>{{ props.item.status }}</td>
          <!-- <td>{{ props.item.code }}</td> -->
          <td>{{ props.item.date }}</td>
          <td>{{ props.item.payload }}</td>
        </template>
      </v-data-table>
    </template>
  </layout>
</template>

<script>
import JsonCSV from "vue-json-csv";
import Layout from "@/components/Layout";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "Log #", value: "id" },
        { text: "Initiator", value: "name" },
        { text: "Initiator Store", value: "store" },
        { text: "Action", value: "action" },
        { text: "System Response", value: "message" },
        { text: "Path", value: "path" },
        { text: "Method", value: "method" },
        { text: "Status", value: "status" },
        { text: "Date", value: "date" },
        { text: "Payload", value: "payload" },
      ],
      loading: false,
      search: "",
      startDayMenu: false,
      endDayMenu: false,
      stores: [],
      logs: [],
      mobileOnly: false
    };
  },
  mounted() {
    this.fetchStores();
    this.fetchLogs();
  },
  computed: {
    filterLogs () {
      let logs = [...this.logs];
      if (this.mobileOnly) {
        logs = this.logs.filter(log =>
          log.data && log.data.toUpperCase() == "MOBILE")
      }
      return logs.map((item) => ({
          id: item.id,
          name: item.user.fullName,
          store: this.getStore(item.storeId).name,
          message: item.errorMessage || item.message,
          action: item.actionName,
          path: item.path,
          method: item.method,
        //   code: item.httpStatus,
          status: item.status > 0 ? 'Passed' : 'Failed',
          date: this.fullDateFormat(item.createdAt),
          payload: item.payload,
      }));
    }
  },
  methods: {
    async fetchStores() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.stores = data.data;
        console.log('this.stores', this.stores);
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error fetching stores");
      }
      this.loading = false;
    },
    async fetchLogs() {
        const params = {
            startDate: this.filter.startDate,
            endDate: this.filter.endDate
        };
        this.loading = true;
        try {
            const { data } =
            await this.$http.get(`${this.apiBaseUrl}/business/activity_logs`, { params });
            this.logs = data.data;
        } catch (error) {
            console.log(error);
            this.handleError(error, "Error fetching activity logs");
        }
        this.loading = false;
    },
    getStore(storeId) {
      return this.stores.find(x => x.id == storeId) || {name: 'Admin'};
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.fetchLogs();
      },
      deep: true
    }
  }
};
</script>
