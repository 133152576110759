<template>
  <layout>
    <template>
      <v-layout row wrap style="margin-bottom: 40px;">
        <v-flex xs12 sm3 px-2>
          <v-btn large color="white" @click="$router.back()">
            Back
          </v-btn>
        </v-flex>
        <v-flex xs12 sm6 px-2>
          <h2 style="text-align: center; padding-top: 5px;">{{customerName}}</h2>
        </v-flex>
      </v-layout>

      <v-layout>
          <v-flex xs12 sm3>
              <DownloadCSV class="v-btn theme--light success"
                           :data="records"
                           :name="'export-customer-ledger-for-' + customerName + '.csv'"
                           v-if="records.length">
                  Export
              </DownloadCSV>
          </v-flex> 
      </v-layout>

      <v-data-table
        :expand="true"
        item-key="id"
        disable-initial-sort
        :items="histories"
        :rows-per-page-items="[25]"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data"> No histories. </template>
        <template slot="items" slot-scope="props">
          <tr>
            <td>{{ props.item.transactionId || "No Transaction ID" }}</td>
            <td>{{ moneyFormat(props.item.amount) }}</td>
            <td>{{ moneyFormat(props.item.previousBalance) }}</td>
            <td>{{
                props.item.isCredit
                ? moneyFormat(props.item.previousBalance + (props.item.amount))
                : moneyFormat((props.item.previousBalance) - (props.item.amount))
            }}</td>
            <td>{{ props.item.isCredit ? "Credit" : "Debit" }}</td>
            <td>{{ props.item.description }}</td>
            <td>{{ identifyUser(props.item.initiatingUserId) }}</td>
            <td>{{ props.item.transactionDate
                ? new Date(props.item.transactionDate).toLocaleString()
                : "Not recorded"
            }}</td>
            <td>{{ new Date(props.item.createdAt).toLocaleString() }}</td>
          </tr>
        </template>
      </v-data-table>
    </template>
  </layout>
</template>

<script>
import JsonCSV from "vue-json-csv";
import Layout from "@/components/Layout";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      customerName: "",    
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "Transaction ID", value: "transactionId" },
        { text: "Amount (NGN)", value: "amount" },
        { text: "Opening Balance" },
        { text: "Closing Balance" },
        { text: "Transaction Type", value: "isCredit" },
        { text: "Description", value: "description" },
        { text: "Initiator", value: "initiator" },
        { text: "Transaction Date", value: "transactionDate" },
        { text: "Effective Date", value: "createdAt" },
      ],
      loading: false,
      histories: [],
      users: []
    };
  },
  mounted() {
    this.fetchWalletLog();
    this.fetchUsers();
  },
  computed: {
      records() {
      return this.histories.map((x) => {
        return {
          TransactionID: x.transactionId ? x.transactionId : "No TransactionID",
          Amount: x.amount,
          Opening_Balance: x.previousBalance,
          Closing_Balance:  x.isCredit
                            ? this.moneyFormat(x.previousBalance + (x.amount))
                            : this.moneyFormat((x.previousBalance) - (x.amount)),
          Transaction_Type: x.isCredit ? "Credit" : "Debit",
          Description: x.description,
          Initiator: this.identifyUser(x.initiatingUserId),
          TransactionDate: x.transactionDate
                                            ? new Date(x.transactionDate).toLocaleString()
                                            : "Not recorded",
          EffectiveDate: new Date(x.createdAt).toLocaleString()
        };
      });
    }
  },
  methods: {
    async fetchWalletLog() {
      const id = this.$route.params.id;
      this.customerName = this.$route.params.customerName;
      this.loading = true;
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/partners/wallet_log?partnerId=${id}`);
        this.histories = data.data;
        // console.log('this.stores', this.stores);
      } catch (error) {
        // console.log(error);
        this.handleError(error, "Error fetching wallet log");
      }
      this.loading = false;
    },
    async fetchUsers() {
      this.loading = true;
      try {
        const { data } =
          await this.$http.get(`${this.apiBaseUrl}/users`);
        this.users = data.data;
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error fetching cashiers");
      }
      this.loading = false;
    },
    identifyUser(id) {
      const user = this.users.find(x => x.id == id)
      return user ? user.full_name : "User not found";
    }
  }
};
</script>
<style scoped>
.customer-name {
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
</style>