<template>
  <v-card class="dash-card">
    <v-card-title class="blue darken-2 white--text">
      Revenue per customer
    </v-card-title>

    <v-data-table :headers="headers" :items="data" class="elevation-1">
      <template slot="items" slot-scope="props">
        <td>{{ props.item.customer }}</td>
        <td class="text-sm-right">{{ props.item.revenue }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      startDate: false,
      endDate: false,
      headers: [
        { text: "Customer", value: "customer" },
        { text: "Revenue", value: "revenue", align: "right" }
      ],
      data: [
        { customer: "Customer 1", revenue: "10000" },
        { customer: "Customer 2", revenue: "10200" },
        { customer: "Customer 3", revenue: "21000" },
        { customer: "Customer 4", revenue: "50000" },
        { customer: "Customer 5", revenue: "800" }
      ]
    };
  },
  methods: {}
};
</script>

<style scoped></style>
