<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-data-table
        disable-initial-sort
        :items="priceChanges"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No data.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.product_name }}</td>
          <td>{{ props.item.old_price }}</td>
          <td>{{ props.item.new_price }}</td>
          <td>{{ fullDateFormat(props.item.date_time) || "" }}</td>
          <td>{{ props.item.source || "Web" }}</td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    this.getPriceChanges();
  },
  data() {
    return {
      filter: {
        end_date: "",
        start_date: "",
        paymentMethod: ""
      },
      headers: [
        { text: "Product name", value: "prodcut_name" },
        { text: "Old price", value: "old_price" },
        { text: "New price", value: "new_price" },
        { text: "Date", value: "date_time" },
        { text: "Source", value: "source" }
      ],
      loading: false,
      priceChanges: [],
      search: ""
    };
  },
  methods: {
    async getPriceChanges() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let url = `${this.apiBaseUrl}/price-changes?storeId=${storeId}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.priceChanges = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading price changes data");
      }
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getPriceChanges();
      },
      deep: true
    }
  }
};
</script>
