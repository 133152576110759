<template>
    <div>
        <v-card>
            <v-card-text>

                <v-layout row wrap mt-3>
                    <v-flex sm3 md3 px-4>
                        <br />
                        Select a product
                    </v-flex>
                    <v-flex sm6 md4 px-4>
                        <v-autocomplete :items="products"
                                        :value="selectedProduct"
                                        item-text="name"
                                        return-object
                                        :loading="loading"
                                        autocomplete
                                        item-value="id"
                                        label="Select Product"
                                        single-line
                                        @change="change"></v-autocomplete>
                    </v-flex>
                    <v-flex sm3 md4></v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout row wrap mt-2 mb-2>
                    <v-flex sm3 md3 px-4>
                    </v-flex>
                    <v-flex sm8 md8 px-4>
                        <v-multiselect-listbox :options="variances"
                                               :reduce-display-property="(option) => option.name"
                                               :reduce-value-property="(option) => option.id"
                                               selected-no-options-text="No variance selected"
                                               v-model="selectedVariance">

                        </v-multiselect-listbox>
                        <!--hide-search-inputs-->
                    </v-flex>
                </v-layout>

                <v-layout row wrap>
                    <!--<v-flex sm3 px-2>
                        <v-switch v-model="use_product_name"
                                  :value="false"
                                  label="Use product variance name?"></v-switch>
                    </v-flex>-->
                    <!--<v-flex sm3 px-2>
                        <v-switch v-model="remove_list"
                                  label="Remove list after insertion?"></v-switch>
                    </v-flex>-->
                </v-layout>

                <br />
                <v-divider></v-divider>
                <v-layout row justify-end>

                    <v-btn @click="alertDialog = true"
                           :disabled="posting || selectedVariance.length < 1"
                           color="primary">{{ saveText }}</v-btn>
                </v-layout>
            </v-card-text>
        </v-card>

        <v-dialog v-model="alertDialog" max-width="290" persistent>
            <v-card>
                <v-card-text>
                    This action is irreversible. Do you want to continue the process?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn flat="flat" color="teal" @click="saveProductVariance" :disabled="posting">
                        {{saveText}}
                    </v-btn>
                    <v-btn flat="flat" color="red" @click="alertDialog = false" :disabled="posting">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
  data() {
    return {
      postAction: "",
      alertDialog: false,
      posting: false,
      search: "",
      use_product_name: false,
      selectedProduct: {},
      selectedProduct_: {},
      remove_list: false,
      groupImg: "",
      discounts: [],
      selectedVariance:[],
      paymentMethods: []
    };
  },
  computed: {
    saveText() {
      const texts = [
        {
          active: "Applying",
          default: "Apply"
        },
        {
          active: "Updating",
          default: "Update"
        }
      ];

      const index = this.postAction !== "update" ? 0 : 1;
      return this.posting ? texts[index].active : texts[index].default;
    },
  },
  methods: {
    change(product) {
      this.selectedProduct_ = product;
      },
     isEmpty(obj) {
        for (const prop in obj) {
            if (Object.hasOwn(obj, prop)) {
                return false;
            }
        }

        return true;
    },
      async saveProductVariance() {

          if (this.isEmpty( this.selectedProduct_)) {
              this.showSnackbar("No selected product to apply", {
                  type: "error"
              });
              return;
          }
          if (this.selectedVariance.length < 1) {
              this.showSnackbar("No selected variance product to apply", {
                  type: "error"
              });
              return;
          }
          this.posting = true;
          const data = {
              selected: this.selectedVariance,
              product_id: this.selectedProduct_.id,
              //use_product_name: this.use_product_name,
              //remove_list: this.remove_list
          };
          const payload = Object.assign({}, data);
          this.posting = true;
          try {
              const res = await this.$http.post(
                  `${this.apiBaseUrl}/products/save-upload-product-variance`,
                  payload
              );
              this.posting = false;
              this.handleResponse(res, data => {
                  this.$emit("syncedVariance");
                  this.$emit("syncedProducts");
                  this.alertDialog = false;
                  this.showSnackbar(data.message);
                  this.variances.filter(item => !this.selectedVariance.includes(item));
                  this.selectedVariance = [];
              });
          } catch (error) {
              this.posting = false;
              this.handleError(error, "Operation failed. Please try again later.");
          }
      },
  },
  props: {
    groups: Array,
    variances: Array,
    products: Array
  }
};
</script>

<style scoped>
.product-img {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
}

#img-picker {
  height: 0;
  visibility: hidden;
}

.product-img:hover {
  cursor: pointer;
}

.product-img > span {
  font-style: italic;
  font-weight: bold;
  display: block;
}

.product-img > img {
  width: auto;
  max-width: 100%;
  max-height: 200px;
  text-align: center;
  margin: 0 auto;
  text-align: center;
}

.product-img:hover .inverse {
  opacity: 0.2;
}

.inverse,
.img-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  transition: all ease-in-out 0.4s;
}

.msl-searchable-list__item:hover {
    background: #4285f4 !important;
    color: #fff !important;
}

.img-loader {
    background-color: #fff;
    opacity: 0.85;
    display: flex;
    visibility: hidden;
    align-items: center;
    justify-content: center;
}
</style>
