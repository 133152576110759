<template>
    <layout>
        <div>
            <v-layout row wrap>
                <v-spacer></v-spacer>
                <v-flex xs6 sm4 offset-xs6 offset-sm8>
                    <v-btn block @click="manageUser({})" color="info">
                        <v-icon>add_circle_outline</v-icon>&nbsp; Add Admin
                    </v-btn>
                </v-flex>
                <v-flex xs12>
                    <h3>All Admins</h3>
                    <v-card flat>
                        <v-card-title class="pt-0">
                            <v-layout>
                                <v-flex sm6 md4 offset-sm6 offset-md8>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search.users"
                                                  append-icon="search"
                                                  label="Search users"
                                                  single-line
                                                  hide-details
                                                  clearable></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-card-title>
                        <v-data-table :items="users"
                                      :rows-per-page-items="[20]"
                                      :search="search.users"
                                      :headers="headers.users"
                                      :loading="loading"
                                      :sort-by="[]"
                                      :sort-desc="[]">
                            <template slot="no-data">
                                No Admins.
                            </template>
                            <template slot="items" slot-scope="props">
                                <td>{{ props.index + 1 }}</td>
                                <td>{{ props.item.full_name }}</td>
                                <td>{{ props.item.phone_number }}</td>
                                <td>{{ props.item.email }}</td>
                                <td>
                                    <v-icon small :color="props.item.active ? 'green' : 'red'">
                                        {{ props.item.active ? "check_circle" : "remove_circle" }}
                                    </v-icon>
                                </td>
                                <td>
                                    <v-tooltip bottom v-if="!isDefaultUser(props.item.id)">
                                        <v-icon slot="activator" @click="manageUser(props.item)" class="mr-2">edit</v-icon>
                                        <span>Edit admin</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="!isDefaultUser(props.item.id)">
                                        <v-icon slot="activator" flat value="disabled" @click="showConfirmDialog(props.item.active ? 'Deactivate' : 'Activate', props.item)"
                                                class="light-blue--text mr-2">sync_disabled</v-icon>
                                        <span>{{ props.item.active ? 'Deactivate' : 'Activate' }} admin</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="!isDefaultUser(props.item.id)">
                                        <v-icon slot="activator" flat @click="showConfirmDialog('Delete', props.item)"
                                                class="red--text">delete</v-icon>
                                        <span>Delete admin</span>
                                    </v-tooltip>

                                </td>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-flex>
            </v-layout>

            <v-layout>
                <v-dialog v-model="userDialog" max-width="900px" persistent>
                    <v-card>
                        <v-card-title primary-title>
                            <h3>
                                Enter admin's details
                            </h3>
                        </v-card-title>
                        <transition name="fade">
                            <div class="px-3" v-if="alert.single.show">
                                <v-alert v-model="alert.single.show"
                                         :type="alert.single.type"
                                         dismissible>
                                    {{ alert.single.msg }}
                                </v-alert>
                            </div>
                        </transition>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-form lazy-validation ref="user_form" @submit.prevent="saveUser">
                                <v-layout row wrap>
                                    <v-flex sm6 px-2>
                                        <v-text-field required
                                                      v-model="selectedUser.first_name"
                                                      :validate-on-blur="true"
                                                      label="First name"
                                                      placeholder="Enter first name"
                                                      :rules="formRules.required"
                                                      name="name"></v-text-field>
                                    </v-flex>
                                    <v-flex sm6 px-2>
                                        <v-text-field required
                                                      v-model="selectedUser.last_name"
                                                      :validate-on-blur="true"
                                                      label="Last name"
                                                      placeholder="Enter last name"
                                                      :rules="formRules.required"
                                                      name="name"></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row wrap>
                                    <v-flex sm6 px-2>
                                        <v-text-field required
                                                      v-model="selectedUser.phone_number"
                                                      @keydown="checkPhoneNumber"
                                                      :validate-on-blur="true"
                                                      label="Phone number"
                                                      placeholder="Enter phone number"
                                                      :rules="formRules.required"
                                                      name="description"></v-text-field>
                                    </v-flex>
                                    <v-flex sm6 px-2>
                                        <v-text-field required
                                                      type="email"
                                                      v-model="selectedUser.email"
                                                      :validate-on-blur="true"
                                                      label="Email address"
                                                      placeholder="Enter email address"
                                                      :rules="formRules.required"
                                                      name="email"></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex sm6 px-2>
                                        <v-text-field v-model="selectedUser.contact_address"
                                                      :validate-on-blur="true"
                                                      label="Contact address"
                                                      placeholder="Enter contact address"
                                                      :rules="formRules.required"
                                                      name="contact_address"
                                                      type="address"></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="closeUserDialog" flat :disabled="posting">Close</v-btn>
                                    <v-btn type="submit"
                                           flat
                                           :disabled="posting"
                                           color="primary">{{ saveText }}</v-btn>
                                </v-layout>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-layout>
            <v-layout>
                <v-dialog v-model="deleteDeactivateDialog" max-width="500px" persistent>
                    <v-card>
                        <v-card-title primary-title class="headline grey lighten-2">
                            <h6>{{type}} Admin Details</h6>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            Are you sure you want to {{type}} admin?
                            <v-layout>
                                <v-spacer></v-spacer>
                                <v-btn @click="closedeleteDeactivateDialog" flat :disabled="loading">Close</v-btn>
                                <v-btn @click="AltAdmin" flat color="primary" :disabled="loading">{{type}}</v-btn>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-layout>
        </div>
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    export default {
        components: {
            Layout
        },
  computed: {
    saveText() {
      return this.posting ? "Saving.." : "Save";
    },
  },
  data() {
    return {
      filters: {
        store: null
      },
      headers: {
        users: [
          {
            text: "S/N",
            value: ""
          },
          {
            text: "Name",
            value: "full_name"
          },
          {
            text: "Phone",
            value: "phone_number"
          },
          {
            text: "Email",
            value: "email"
          },
          {
            text: "Status",
            value: "active"
          },
          {
            text: "Actions",
            value: ""
          }
        ]
      },
      loading: false,
      selectedUser: {},
      userDialog: false,
      deleteDeactivateDialog: false,
      search: {
        user: ""
      },
      roles: [],
      users: [],
      defaultUser: [],
      payload: {},
      type:"",  
      loggedinUser: this.$store.getters.userData
    };
  },
  mounted() {
    this.getUsers();
    this.getDefaultUsers();
  },
  methods: {
    manageUser(user) {
      this.selectedUser = user;
      this.userDialog = true;
    },

    async saveUser() {
      if (!this.$refs.user_form.validate()) return;
      const selectedUser = Object.assign({}, this.selectedUser);
      this.posting = true;
      try {
        const url = `${this.apiBaseUrl}/admin`;
        const isUpdate = this.selectedUser.id;
        const res = isUpdate
          ? await this.$http.patch(url, selectedUser)
          : await this.$http.post(url, selectedUser);

        this.posting = false;
        this.handleResponse(res, data => {
          isUpdate ? this.updateUserInfo : this.users.push(data);
          const action = isUpdate ? "updated" : "added";
          this.closeUserDialog();
          this.showSnackbar(`User ${action} successfully.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "Operation failed. Please try again later.");
      }
    },
    checkPhoneNumber(e) {
      const value = e.key;
      if (!value.match(/[0-9]+/) && value.toLowerCase() != "backspace") {
        e.preventDefault();
      }
    },

    closeUserDialog() {
      this.selectedUser = Object.assign({}, {});
      this.userDialog = false;
     // this.deleteDeactivateDialog = false;
      this.$refs.user_form.reset();
    },
    closedeleteDeactivateDialog() {
      this.deleteDeactivateDialog = false;
    },
    async getUsers() {
      this.loading = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/admin`);
        this.loading = false;
        this.handleResponse(res, data => {
          data.sort((b, a) => (b.id) - (a.id));
          this.users = data;
        });
      } catch (error) {
        this.loading = false;
        this.handleError(
          error,
          "We couldn't fetch your users. Please try again later."
        );
      }
    },
    async getDefaultUsers() {
      this.loading = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/admin`);
        this.loading = false;
        this.handleResponse(res, data => {
          data.sort((b, a) => (b.id) - (a.id));

          const defaultData = data[0]
          this.defaultUser = defaultData.id;
        });
      } catch (error) {
        this.loading = false;
        this.handleError(
          error,
          "We couldn't fetch your users. Please try again later."
        );
      }
    },
    async AltAdmin() {       
        this.payload.Id = this.selectedUser.id;
        this.payload.businessId = this.selectedUser.business_id;
        this.payload.type = this.type;
        
        const payload = Object.assign({}, this.payload);

        let url = `${this.apiBaseUrl}/admin/alt-admin`;
        this.loading = true;

        try {
            await this.$http.post(url, payload);
            this.loading = false;
            this.closedeleteDeactivateDialog();
            this.showSnackbar(`Admin ${this.type}d Successfully`);
            this.getUsers();
        } catch (error) {
            this.loading = false;
            this.handleError(error, "Error Occurred");
        }
    },

    isDefaultUser(userId) {
      return this.defaultUser === userId;
    },
    showConfirmDialog(type, selectedUser) {
      if (this.type.length > 0) 
        this.deleteDeactivateDialog = true;
      this.type = type;

      this.selectedUser = selectedUser;
    },
    updateUserInfo(user) {
      const index = this.users.findIndex(x => x.id == user.id);
      this.$set(this.users, index, user);
    },
    titleCase(value) {
      let sentence = value.toLowerCase().split(" ");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
        //console.log(sentence);
      return sentence.join(" ");
    }
  },
  // watch: {
  //   selectedBusiness: {
  //       handler() {
  //           this.user = data;
  //           this.getUsers();
  //       },
  //       deep: true
  //   }
  // }
};
</script>
