<template>
    <layout requires-business>
        <template v-if="selectedBusiness">
            <v-layout row wrap>
                <v-flex xs12>
                    <div>
                        <!--<v-toolbar-title class="font-weight-bold">
                            {{bussinessName.toUpperCase()}}
                            <v-tooltip bottom>
                                <v-icon slot="activator" @click="refresh">refresh</v-icon>
                                <span>Refresh details</span>
                            </v-tooltip>
                        </v-toolbar-title>-->
                        <v-tabs slider-color="teal" grow>
                            <v-tab>
                                <h4>Dashboard</h4>
                            </v-tab>
                            <v-tab>
                                <h4>Customers</h4>
                            </v-tab>
                            <v-tab>
                                <h4>Transactions</h4>
                            </v-tab>
                            <v-tab>
                                <h4>Usage Analysis</h4>
                            </v-tab>
                            <br />
                            <v-tab-item>
                                <br />
                                <v-layout row wrap>                                    
                                    <v-flex md6 px-2>
                                        <analysis-payment-methods :entries="paymentMethodEntries" :loading="loading" />
                                    </v-flex>
                                    <v-flex md6 px-2>
                                        <analysis-sale-statistics />
                                    </v-flex>
                                </v-layout>

                                <br />
                                <v-divider></v-divider>
                                <br />

                                <v-layout row wrap>
                                    <v-flex xs12 sm12 px-2>
                                        <analysis-profit-and-loss />
                                    </v-flex>
                                </v-layout>
                            </v-tab-item>
                            <v-tab-item>
                                <customers />
                            </v-tab-item>
                            <v-tab-item>
                                <transaction />
                            </v-tab-item>
                            <v-tab-item>
                                <analysis-usage />
                            </v-tab-item>
                        </v-tabs>
                    </div>
                </v-flex>
            </v-layout>
        </template>
        <template v-else>No Business Selected</template>
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import AnalysisSaleStatistics from "@/components/superadmin/analysis/AnalysisSaleStatistics";
    import AnalysisProfitAndLoss from "@/components/superadmin/analysis/AnalysisProfitAndLoss";
    import AnalysisPaymentMethods from "@/components/superadmin/analysis/AnalysisPaymentMethods";
    //import AnalysisPendingReceipts from "@/components/superadmin/analysis/AnalysisPendingReceipts";
    import Transaction from "@/components/superadmin/analysis/Transactions";
    import Customers from "@/components/superadmin/analysis/Customers";
    import AnalysisUsage from "@/components/superadmin/analysis/AnalysisUsage";

    export default {
        components: {
            Layout,
            AnalysisSaleStatistics,
            AnalysisProfitAndLoss,
            //AnalysisPendingReceipts,
            AnalysisPaymentMethods,
            Transaction,
            Customers,
            AnalysisUsage
        },
        data() {
            return {
                loading: false,
                search: "",
                sales:[]
                //paymentMethodEntries: []
            };
        },
        mounted() {
            //console.log(this.selectedBusiness);
            this.getSales();
        },
        computed: {
            bussinessName() {
                return this.selectedBusiness ? this.selectedBusiness.name : "";
            },
            paymentMethodEntries() {
                const bucket = {};
                this.sales.map(x => {
                    const { payment_method_name, transaction_amount } = x;
                    const name = payment_method_name;
                    const entry = bucket[name];
                    if (!entry) {
                        bucket[name] = { amount: transaction_amount, count: 1 };
                    } else {
                        bucket[name] = {
                            amount: entry.amount + transaction_amount,
                            count: entry.count + 1
                        };
                    }
                });

                return Object.keys(bucket).map(x => {
                    return {
                        name: x,
                        amount: bucket[x].amount,
                        count: bucket[x].count
                    };
                });
            },
            //loaded() {
            //    return !(this.loader.partners || this.loader.partnerGroups);
            //}
        },
        methods: {
            refresh() {
                //console.log("here");
                const temp = this.selectedBusiness;
                this.selectedBusiness = [];
                this.selectedBusiness = temp;
            },
            getStore(storeId) {
                return this.stores.find(x => x.id == storeId) || { name: 'Admin' };
            },
            async getSales() {
                //this.showGlobalLoader(true);
                try {
                    this.loading = true;
                    const res = await this.$http.get(
                        `${this.apiBaseUrl}/superAdmin/analysis/sales?businessId=${this.selectedBusiness.id}`
                    );
                    await this.handleResponse(res, data => {
                        this.sales = data;
                    });
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch sales data.");
                }
                this.loading = false;
                //this.showGlobalLoader(false);
            },
        },
        watch: {
            selectedBusiness: {
                handler() {
                    this.getSales();
                },
                deep: true
            }
        }
    };
</script>
<style scoped>
    .customer-name {
        font-size: 15px;
        margin-bottom: 15px;
        padding: 10px;
    }
</style>