<template>
  <div>
    <br />
    <v-data-table
      :items="productsFormatted"
      :rows-per-page-items="[20]"
      search
      :headers="headers"
    >
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.price }}</td>
        <!-- <td>
        </td> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  computed: {
    productsFormatted() {
      const records = [];
      this.products.forEach(x => {
        const record = { price: x.price };
        const name = x.options.map(y => y.name).join(" | ");
        record.name = name;
        records.push(record);
      });

      return records;
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Variant name",
          value: "name"
        },
        {
          text: "Price",
          value: "price"
        }
      ]
    };
  },
  props: {
    products: Array
  }
};
</script>
