<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-menu
            ref="startDayMenu"
            v-model="startDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.startDate"
                label="Start Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="startDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.startDayMenu.save(filter.startDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-menu
            ref="endDayMenu"
            v-model="endDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.endDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.endDate"
                label="End Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="endDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.endDayMenu.save(filter.endDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-btn color="info" @click="filterData">
            Filter
          </v-btn>
        </v-flex>

        <v-flex xs12 sm3>
          <div class="text-xs-right">
            <DownloadCSV
              class="v-btn theme--light success"
              :data="records"
              name="export.csv"
              v-if="records.length"
            >
              Export
            </DownloadCSV>
          </div>
        </v-flex>
      </v-layout>

      <div v-if="filterData != null && records.length > 0">
        <v-flex :class="total >= 0 ? 'greenColor' : 'redColor'" class="totalProfit" >
          {{ total >= 0 ? 'Total Profit' : 'Total Loss' }}: {{ totalProfitLoss }}
        </v-flex>
      </div>

      <v-data-table
        disable-initial-sort
        :items="records"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
        :item-key="records.number"
      >
        <template slot="no-data">
          No records.
        </template>
        <template slot="items" slot-scope="props">
            <td>{{ props.item.number }}</td>
            <td>{{ props.item.date || "" }}</td>
            <td class="truncate">
                {{ props.item.seller_name }}
            </td>
            <td>{{ props.item.costPrice }}</td>
            <td>{{ props.item.salePrice }}</td>
            <td class="font-weight-bold" :style="[props.item.color ?
                        props.item.color == 'profit' ?
                        {'color': 'green !important'} :
                        {'color': 'red !important'} :
                        {'color': 'black !important'}] ">
                {{ props.item.stats }}
            </td>
            <td class="text-xs-right">
                {{ props.item.products }}
            </td>
            <td>
                <v-tooltip bottom>
                    <v-icon slot="activator" @click="showInfo(props.item)">info</v-icon>
                    <span>View details</span>
                </v-tooltip>
            </td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store
    </template>
      
    <!-- Item dialog -->
    <v-dialog v-model="showItemDialog" max-width="400px" persistent>
      <v-card flat>
        <!--<v-card-title primary-title>
          <v-flex xs12 sm6 px-2>
            <h3>Products</h3>
          </v-flex>
          <v-flex xs12 sm6 px-2 text-xs-right v-if="selectedItem.amount_tendered < 1">
            <v-btn small color="blue" style="color: white" @click="$router.push(`invoice/${selectedItem.id}`)">
              Generate Invoice
            </v-btn>
          </v-flex>
        </v-card-title>-->
        <template>
          <v-data-table
            :headers="productHeaders"
            :items="selectedItem.store_products"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td class="text-xs-right">{{ props.item.quantity }}</td>
              <td class="text-xs-right">{{ moneyFormat(props.item.price) }}</td>
            </template>
          </v-data-table>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Item dialog -->
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import JsonCSV from "vue-json-csv";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: ""
      },
      headers: [
        { text: "Receipt number", value: "number" },
        { text: "Date", value: "date" },
        { text: "Seller Name", value: "seller_name" },
        { text: "Cost Price", value: "costPrice" },
        { text: "Sale Price", value: "salePrice" },
        { text: "Profit/Loss", value: "stats"},
        { text: "Products", value: "products", align: "right"},
        { text: "", value: "" }
      ],
      productHeaders: [
        {
          text: "Name",
          value: "id"
        },
        {
          text: "Quantity",
          value: "quantity",
          align: "right"
        },
        {
          text: "Price",
          value: "price",
          align: "right"
        }
      ],
      loading: false,
      search: "",
      products: [],
      suppliers: [],
      selectedItem: {},
      total: 0,
      // selectedDay: new Date().toISOString().substr(0, 10),
      startDayMenu: false,
      showItemDialog: false,
      endDayMenu: false
    };
  },
  computed: {
      records() {
      return this.products.map((x,index) => {
        return {
          date: this.justDate(x.date_time) || "",
          number: x.number,
          salePrice: this.salePrice(x.store_products),
          costPrice: this.costPrice(x.store_products),
          seller_name: x.seller_name,
          stats: this.moneyFormat(this.salePrice(x.store_products) - this.costPrice(x.store_products)),
          color:this.showprofitLoss(x.store_products),
          products: x.store_products.length > 0 ? x.store_products.length : 0,
          store_products: x.store_products

        };
      });
    },
          
    totalProfitLoss() {
    const total = this.records.reduce((total, record) => {
        return total + parseFloat(record.stats.replace(',', ''));
    }, 0);

    this.total = total;
    return this.moneyFormat(total);
},


  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
              : this.isLoggedIn.store_id;

        const params = {
        storeId: storeId,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      };
      let url = `${this.apiBaseUrl}/receipts/profit-and-loss`;
      this.loading = true;
      try {
        const { data } = await this.$http.get(url, { params });
        this.products = data.data;
      } catch (error) {
        this.handleError(error, "Error loading data");
      }
      this.loading = false;
    },
    filterData() {
      this.getData();
    },
      totalQty(items) {
          return items.reduce(function (a, b) {
              return a + b["quantity"];
          }, 0);
      },
      costPrice(items) {
          var costprice = 0;
          items.forEach(product => {
              //costprice += product.quantity * product.price;
              costprice += (product.cost_prices.length > 0 ? product.cost_prices[product.cost_prices.length - 1] : product.price) * product.quantity
          });
          return costprice;
      },
      salePrice(items) {
          var saleprice = 0;
          items.forEach(product => {
              saleprice += product.quantity * product.sale_price;
          });
          return saleprice;
      },
      showprofitLoss(object) {
          if ((this.salePrice(object) - this.costPrice(object)) > 0)
              return "profit";
          else if ((this.salePrice(object) - this.costPrice(object)) < 0)
              return "loss";
          else return "";
     },
      showInfo(receipt) {
      if (receipt) {
        this.selectedItem = receipt;
      }
      this.showItemDialog = true;
    },
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
<style scoped>
    .truncate {
        max-width: 1px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .totalProfit {
      font-weight :bold;
      font-size: 18px;
      padding: 16px 24px;
    }
    .greenColor {
        color: green !important;
    }

    .redColor {
        color: red !important;
    }
    /* } */
</style>
