<template>
    <div>
        <v-layout row wrap>
            <v-flex sm6 md6 lg6 px-2 py-2>
                <v-layout>
                    <v-flex sm4 px-2>
                        <v-text-field required
                                      v-model="incoming.name"
                                      :validate-on-blur="false"
                                      label="Receipt name"
                                      :rules="formRules.required"
                                      name="receipt_name"></v-text-field>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <v-menu ref="trans_menu"
                                :close-on-content-click="false"
                                :v-model="true"
                                :nudge-right="40"
                                :return-value.sync="incoming.transaction_date"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px">
                            <v-text-field slot="activator"
                                          v-model="incoming.transaction_date"
                                          :rules="formRules.required"
                                          label="Transaction date"
                                          readonly></v-text-field>
                            <v-date-picker v-model="incoming.transaction_date"
                                           no-title
                                           scrollable>
                                <v-spacer></v-spacer>
                                <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                                <v-btn flat
                                       color="primary"
                                       @click="$refs.trans_menu.save(incoming.transaction_date)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <v-menu ref="receipt_menu"
                                :close-on-content-click="false"
                                :v-model="true"
                                :nudge-right="40"
                                :return-value.sync="incoming.receipt_date"
                                lazy
                                transition="scale-transition"
                                offset-y
                                full-width
                                min-width="290px"
                                @blur="incoming.receipt_date = formatDate(incoming.receipt_date)">
                            <v-text-field slot="activator"
                                          v-model="incoming.receipt_date"
                                          :rules="formRules.required"
                                          label="Receipt date"
                                          readonly></v-text-field>
                            <v-date-picker v-model="incoming.receipt_date"
                                           no-title
                                           scrollable>
                                <v-spacer></v-spacer>
                                <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                                <v-btn flat
                                       color="primary"
                                       @click="$refs.receipt_menu.save(incoming.receipt_date)">OK</v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-flex>
                </v-layout>

                <v-layout row wrap>
                    <v-flex sm4 px-2>
                        <v-select required
                                  v-model="incoming.warehouse_id"
                                  :items="sortedWarehouses"
                                  :loading="loading.warehouses"
                                  item-text="name"
                                  item-value="id"
                                  @change="loadWarehouseProducts"
                                  :validate-on-blur="true"
                                  label="Warehouse"
                                  :rules="formRules.required"
                                  name="warehouse_id"></v-select>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <v-select required
                                  v-model="incoming.document_type"
                                  :items="documentTypes"
                                  item-text="name"
                                  item-value="value"
                                  label="Document type"
                                  :rules="formRules.required"
                                  name="document_type_id"></v-select>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <!-- <v-text-field
                      required
                      v-model="incoming.supplier"
                      :validate-on-blur="true"
                      label="Select a Supplier"
                      :rules="formRules.required"
                      name="supplier_name"
                    ></v-text-field> -->
                        <v-select required
                                  v-model="incoming.supplier_id"
                                  :items="suppliers"
                                  item-text="companyName"
                                  item-value="id"
                                  label="Select a Supplier"
                                  :rules="formRules.required"
                                  name="supplier_id"></v-select>
                    </v-flex>
                </v-layout>
                <v-layout row wrap>
                    <v-flex sm4 px-2>
                        <v-select required
                                  v-model="incoming.currency_id"
                                  :items="currencies"
                                  item-text="currency.name"
                                  item-value="currency.id"
                                  label="Currency"
                                  :rules="formRules.required"
                                  name="document_type_id"></v-select>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <v-text-field required
                                      v-model="incoming.amount_paid"
                                      :validate-on-blur="false"
                                      label="Amount Paid (NGN)"
                                      :rules="formRules.required"
                                      name="amount_paid"></v-text-field>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <v-text-field required
                                      v-model="incoming.payment_date"
                                      type="date"
                                      :validate-on-blur="true"
                                      label="Payment Due Date (optional)"
                                      name="payment_due_date"></v-text-field>
                    </v-flex>
                </v-layout>

                <v-layout row wrap>
                    <v-flex sm4 px-2>
                        <v-switch v-model="incoming.delivered"
                                  :value="false"
                                  label="Delivered?"></v-switch>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <v-switch v-model="mass_upload"
                                  :value="false"
                                  @change="toggle"
                                  label="Mass Upload?"></v-switch>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex sm6 md6 lg6>
                <div class="selected-products">
                    <v-data-table item-key="index"
                                  :items="selectedProducts"
                                  :rows-per-page-items="[10]"
                                  :headers="headers.selectedProducts">
                        <template slot="no-data">
                            No products. Add a new product above.
                        </template>
                        <template slot="items" slot-scope="props">
                            <td>{{ props.item.index }}</td>
                            <td>{{ props.item.name }}</td>
                            <td>{{ props.item.quantity }}</td>
                            <!-- <td>{{ props.item.unit }}</td> -->
                            <td>{{ props.item.cost_price }}</td>
                            <td>{{ props.item.selling_price }}</td>
                            <td>{{ props.item.total_cost }}</td>
                            <td v-if="props.item.expiry_date">{{ props.item.expiry_date }}</td>
                            <td style="cursor: pointer;" @click="removeProduct(props.item.id)">
                                <v-icon class="red--text">clear</v-icon>
                            </td>
                        </template>
                    </v-data-table>
                </div>

            </v-flex>
        </v-layout>
        <v-layout row wrap>
            <v-flex xs12 px-2>
                <v-layout>
                    <v-spacer></v-spacer>
                    <v-flex sm4>
                        <v-layout>
                            <v-flex sm6>
                                <v-btn color="info" pre @click="ResolveProducts">
                                    <v-icon>add_circle_outline</v-icon>&nbsp; Add Products
                                </v-btn>
                            </v-flex>
                            <v-flex sm6>
                                <v-text-field v-model="search"
                                              append-icon="search"
                                              label="Search product"
                                              single-line
                                              hide-details
                                              clearable></v-text-field>
                            </v-flex>

                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout row wrap>
            <v-flex sm12 px-2 py-2>
                <v-data-table :headers="headers.headerProducts"
                              item-key="name"
                              :items="sortedProducts"
                              :rows-per-page-items="[10]"
                              ref="selected"
                              :search="search"
                              :loading="loading.receipts">
                    <template v-slot:items="props">
                        <tr :key="`selected-${props.item.id}`">
                            <td>{{ props.item.name }}</td>
                            <td>
                                <v-text-field type="number"
                                                  v-model="selected_product[props.item.id]['quantity']"
                                              name="quantity"></v-text-field>
                            </td>
                            <td>
                                <v-text-field type="number"
                                                  v-model="selected_product[props.item.id]['cost_price']"
                                              name="cost_price"></v-text-field>
                            </td>
                            <td>
                                <v-text-field type="number"
                                                  v-model="selected_product[props.item.id]['selling_price']"
                                              name="selling_price"></v-text-field>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-flex>
        </v-layout>

    </div>
</template>

<script>
import DocTypes from "@/data/documentTypes";
export default {
  computed: {
    sortedProducts() {
      const products = this.products;
      if (products.length < 1) return [];

      return products
        .filter(x => {
          return !this.selectedProducts.find(y => y.id == x.id);
        })
        //.map(x => {
        //  x.name = this.getProductName_(x);
        //  return x;
        //})
        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());
    },
    sortedWarehouses() {
      const warehouses = this.warehouses;
      if (warehouses.length < 1) return [];

      return warehouses.sort(
        (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
      );
    }
  },
  data() {
    return {
      alertDialog: false,
      documentTypes: DocTypes,
      headers: {
        selectedProducts: [
          {
            text: "S/n",
            value: "index"
          },
          {
            text: "Product",
            value: "name"
          },
          {
            text: "Quantity",
            value: "quantity"
          },
          {
            text: "Cost price (per unit)",
            value: "cost_price"
          },
          {
            text: "Selling price (per unit)",
            value: "selling_price"
          },
          {
            text: "Total cost price",
            value: "total_cost"
          },
          {
            text: 'Actions',
            value: ""
          }
          ],
          headerProducts: [
              {
                  text: "Product",
                  value: "name"
              },
              {
                  text: "Quantity",
                  value: "quantity",
                  align:"center"
              },
              {
                  text: "Cost price (per unit)",
                  value: "cost_price",
                  align: "center"
              },
              {
                  text: "Selling price (per unit)",
                  value: "selling_price",
                  align: "center"
              },
          ]
      },
      loading: false,
      posting: false,
      search:"",
      priceChangesData: [],
      product: {
        unit: 1
      },
      productOptions: [],
      selectedItemList:[]
    };
  },
  methods: {
    toggle() {
        this.$emit('toggle');
    },
    ResolveProducts() {
        this.selected_product.forEach((product,index) => {
            //console.log(product);
            if (product.quantity && product.cost_price && product.selling_price) {

                this.addProducts(product);
            }
        });
    },
    removeProduct(id) {
      const selectedProductIndex = this.selectedProducts
        .findIndex(product => product.id === id)

      if (selectedProductIndex > -1) {
        this.selectedProducts.splice(selectedProductIndex, 1);
      }
    },
    addProducts(product) {
      const productDetails = this.products.find(x => x.id == product.id);
      const name = productDetails.name;
      const len = this.selectedProducts.length;
      const { cost_price, selling_price, quantity, expiry_date } = product;
      const total_cost = cost_price * quantity;

      const selectedProduct = {
        id: product.id,
        index: len + 1,
        name: name,
        cost_price,
        selling_price,
        quantity,
        total_cost,
        expiry_date
        };
      //console.log(selectedProduct);
        var product = this.selectedProducts.find(x => x.id == product.id);
        if(!product)
            this.selectedProducts.push(selectedProduct);

      // Reset selected product
      this.product = {};
    },
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
  },
mounted: function () {
    let vm = this;

    vm.$nextTick(function () {
        console.log("NextTICK", vm.currency);
        //this.resolveProducts();
    });
},
  props: {
    currencies: Array,
    products: Array,
    currency: String,
    mass_upload:Boolean,
    warehouses: Array,
    incoming:Object,
    selected_product:Array,
    suppliers:Array,
    selectedProducts:Array
  }
};
</script>

<style scoped>
    .v-toolbar__content{
        height:47px !important;
    }
/* .selected-products {
        max-height: 300px;
        overflow-y: auto;
    } */
</style>
