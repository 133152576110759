import Return from "@/views/return/Return";
import ReturnableReasons from "@/views/return/ReturnableReasons";
import ReturnablePackages from "@/views/return/ReturnablePackages";

export default [

    {
        path: "/return/returnable_products",
        name: "Returnable Packages",
        component: ReturnablePackages,
        icon: "remove_shopping_cart",
        access: "returnable_products",
        showInNav: true
    },
    {
        path: "/return/returnable_reasons",
        name: "Reasons For Return",
        icon: "add_shopping_cart",
        access: "returnable_reasons",
        component: ReturnableReasons,
        showInNav: true
    },
    {
        path: "/return/receipt-product",
        name: "Refund",
        component: Return,
        access:"return_receipt",
        icon: "compare_arrows",
        showInNav: true
    }
];
