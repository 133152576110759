<template>
  <v-card class="dash-card">
    <v-card-title class="teal darken-2 white--text">
      Credit Sales
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="stores"
      class="elevation-1"
      :rows-per-page-items="[5]"
    >
      <template slot="items" slot-scope="props">
        <td>{{ getPartnetById(props.item.partner_id).name }}</td>
        <td class="text-sm-right"><span style="color: #309c76; font-size: 14px; font-weight: bold; cursor: pointer; font-style: italic; text-decoration: underline; "
                                        @click="$router.push(`/reports/invoice/${props.item.id}`)">{{ props.item.number }}</span></td>
        <!--<td class="text-sm-right" v-if="props.item.charged_wallet">
          {{ props.item.balance }}
        </td>
        <td class="text-sm-right" v-if="!props.item.charged_wallet">
          {{ props.item.transaction_amount - props.item.amount_tendered }}
        </td>-->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      stores: [],
      partners: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Receipt No.", value: "transaction_amount", align: "right" }
      ]
    };
  },
  mounted() {
    this.fetchPartners();
    this.getData();
  },
  methods: {
    async fetchPartners() {
      this.loading = false;
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/partners`);
        this.partners = data.data;
        this.loaded = true;
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error fetching products");
      }
    },
    async getData() {
      let url = `${this.apiBaseUrl}/dashboard/pending-receipts`;

      try {
        const res = await this.$http.get(url);
        this.stores = res.data.data;
        //console.log('STORES', this.sto\\res);
        this.loaded = true;
      } catch (error) {
        console.log("ERROR", error);
      }
    },
    getPartnetById(id) {
      return this.partners.find(x => x.id == id) || {};
    }
  }
};
</script>

<style scoped></style>
