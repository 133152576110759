<template>
    <v-dialog
        v-model="show"
        persistent
        max-width="290"
    >
        <v-card>
        <v-card-title class="text-h5">
            {{title}}
        </v-card-title>
        <v-card-text>{{message}}</v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="white darken-1"
            text
            style="color: #43a047;"
            @click="$emit('onCancel')"
            >
            Cancel
            </v-btn>
            <v-btn
            color="white darken-1"
            text
            @click="$emit('onAgree')"
            style="color: #43a047;"
            >
            Yes
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>
 
<script>
  export default {
    props: ['title', 'message', 'show'],
  }
</script>