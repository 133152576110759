<template>
  <layout>
    <v-layout row wrap>
      <v-flex sm8 lg6 xl4 offset-sm4 offset-lg6 offset-xl8>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex sm6 px-2>
            <v-btn block @click="newSupplierInfo" color="info" pre>
              <v-icon>add_circle_outline</v-icon>&nbsp; Add supplier
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <br />
    <!-- <hr> -->
    <v-divider></v-divider>
    <br />
    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-title class="pt-0">
            <v-layout>
              <v-flex sm6 md4 offset-sm6 offset-md8>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search.suppliers"
                  append-icon="search"
                  label="Search supplier"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-data-table
            :items="suppliers"
            :rows-per-page-items="[10]"
            :search="search.suppliers"
            :headers="headers.supplier"
            :loading="loading.suppliers"
          >
            <template slot="no-data">
              No supplier. Add a new supplier above.
            </template>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.companyName }}</td>
              <td>{{ props.item.email || "-" }}</td>
              <td>{{ props.item.phoneNumber }}</td>
              <td>{{ props.item.address || "-" }}</td>
              <td>{{ new Date(props.item.createdAt || "").toLocaleDateString() }}</td>
              <td>
                <v-icon small @click="showSupplierInfo(props.item)">
                  edit
                </v-icon>
                <v-icon
                  style="margin-left: 15px;"
                  small
                  @click="$router.push(`supplier/${props.item.id}`)"
                >
                  info
                </v-icon>
                <v-icon @click="selectedSupplier = props.item; showConfirmModal = true;" small color="red" style="margin-left: 20px;">
                  {{ "remove_circle" }}
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-dialog v-model="newSupplierDialog" max-width="800px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter supplier details
            </h3>
          </v-card-title>
          <transition name="fade">
            <div class="px-3" v-if="alert.single.show">
              <v-alert
                v-model="alert.single.show"
                :type="alert.single.type"
                dismissible
              >
                {{ alert.single.msg }}
              </v-alert>
            </div>
          </transition>
          <v-divider></v-divider>
          <v-card-text>
            <v-form lazy-validation ref="new_supplier_form">
              <v-layout row wrap>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newSupplier.companyName"
                    label="Supplier name"
                    :rules="formRules.required"
                    name="company_name"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newSupplier.email"
                    type="email"
                    validate-on-blur
                    label="Email"
                    :rules="formRules.required"
                    name="email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newSupplier.phoneNumber"
                    label="Phone Number"
                    :rules="formRules.required"
                    name="phone_number"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="newSupplier.address"
                    type="address"
                    validate-on-blur
                    label="Address"
                    :rules="formRules.required"
                    name="address"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeSupplierDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn
                  @click="saveSupplier"
                  flat
                  :disabled="posting"
                  color="primary"
                  >{{ saveText }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
    <confirm-dialog
      @onAgree="deleteSupplier(selectedSupplier.id)"
      @onCancel="showConfirmModal = false"
      title="Are you sure?"
      message="By deleting this supplier, you will not have access to the supplier nor all information previously gathered on this supplier"
      :show="showConfirmModal"
    />
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import ConfirmDialog from "@/components/utils/ConfirmDialog";

export default {
  components: {
    Layout,
    ConfirmDialog
  },
  computed: {
    saveText() {
      const texts = [
        {
          active: "Saving..",
          default: "Save"
        },
        {
          active: "Updating..",
          default: "Update"
        }
      ];

      const index = this.action == "update" ? 1 : 0;
      return this.posting ? texts[index].active : texts[index].default;
    }
  },
  created() {
    this.getSuppliers();
  },
  data() {
    return {
      action: "save",
      headers: {
        supplier: [
          {
            text: "Supplier name",
            align: "left",
            value: "companyName"
          },
          {
            text: "Email",
            value: "email"
          },
          {
            text: "Phone number",
            value: "phoneNumber"
          },
          {
            text: "Address",
            value: "address"
          },
          {
            text: "Created at",
            value: "createdAt"
          },
          {
            text: "Actions",
            value: ""
          }
        ]
      },
      loading: {
        suppliers: false
      },
      newSupplier: {},
      newSupplierDialog: false,
      posting: false,
      supplier: {},
      suppliers: [],
      supplierTypes: ["Climate-controlled", "Others"],
      search: {
        suppliers: ""
      },
      showConfirmModal: false,
      selectedSupplier: {}
    };
  },
  methods: {
    deleteFromSuppliers (id) {
      const selectedSupplier = this.suppliers.findIndex(sup => sup.id === id);
      this.suppliers.splice(selectedSupplier, 1);
    },
    closeSupplierDialog() {
      this.action = "save";
      this.newSupplierDialog = false;
      this.newSupplier = {};
    },
    async getSuppliers() {
      this.loading.suppliers = true;
      let url = `${this.apiBaseUrl}/suppliers`;
      try {
        const res = await this.$http.get(url);
        this.loading.suppliers = false;
        this.handleResponse(res, data => {
          console.log("data", data);
          this.suppliers = data;
        });
      } catch (error) {
        this.loading.suppliers = false;
        this.handleError(error, "We couldn't load your suppliers.");
      }
    },
    async deleteSupplier(supplierId) {
      this.showConfirmModal = false;
      this.posting = true;
      try {
        const requestClient = await this.$http.delete(`${this.apiBaseUrl}/suppliers/${supplierId}`)
        this.posting = false;
        this.handleResponse(requestClient, data => {
          this.selectedSupplier = {};
          this.deleteFromSuppliers(supplierId);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error performing this operation. Please try again later."
        );
      }
    },
    async saveSupplier() {
      if (!this.$refs.new_supplier_form.validate()) return;
      try {
        this.posting = true;
        const newSupplier = Object.assign({}, this.newSupplier, {
          phone_number: this.newSupplier.phoneNumber,
          company: this.newSupplier.companyName
        });

        // If update, make put request instead
        const requestClient = await (
          (this.newSupplier.id)
            ? this.$http.put(
                `${this.apiBaseUrl}/suppliers/${this.newSupplier.id}`,
                newSupplier
              )
            : this.$http.post(
                `${this.apiBaseUrl}/suppliers`,
                newSupplier
              )
        )

        this.posting = false;
        this.handleResponse(requestClient, data => {
          let action = "saved";
          if (this.action == "update") {
            this.updateEntry(data);
            action = "updated";
          } else this.suppliers.push(data);
          this.newSupplier = {};
          this.closeSupplierDialog();
          this.showSnackbar(`Supplier ${action} successfully.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error performing this operation. Please try again later."
        );
      }
    },
    newSupplierInfo() {
      this.newSupplier = {};
      this.action = "create";

      this.newSupplierDialog = true;
    },
    showSupplierInfo(supplier) {
      this.newSupplier = Object.assign({}, supplier);
      this.action = "update";
      console.log(this.newSupplier.store_id);

      this.newSupplierDialog = true;
    },
    updateEntry(data) {
      const index = this.suppliers.findIndex(x => x.id == data.id);
      this.$set(this.suppliers, index, data);
    }
  }
};
</script>
