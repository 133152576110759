<template>
    <layout>
        <v-layout>
            <v-flex sm4 pt3>
                <h4>Click on an item to view details</h4>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex sm4>
                <v-text-field v-model="search"
                              append-icon="search"
                              label="Search receipts"
                              single-line
                              hide-details
                              clearable></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex sm12>
                <v-data-table :headers="headers"
                              :expand="true"
                              item-key="id"
                              :items="missingReceipts"
                              :rows-per-page-items="[25]"
                              :search="search"
                              :loading="loading">
                    <template v-slot:items="props">
                        <tr @click="props.expanded = !props.expanded">
                            <td>{{ props.item.receiptno }}</td>
                            <td>{{ props.item.transaction_date }}</td>
                            <td>{{ props.item.transaction_amount }}</td>
                            <td>{{ props.item.seller }}</td>
                        </tr>
                    </template>
                    <template v-slot:expand="props">
                        <missing-details :data="props" :stores="stores" :paymentmethods="paymentmethods" @refreshList="refreshReceiptList"  />
                    </template>
                </v-data-table>

            </v-flex>
        </v-layout>
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import MissingDetails from "../../components/reports/missingDetails";

    export default {
        components: {
            Layout,
            MissingDetails
        },
        computed: {
            missingReceipts() {
                return this.receipts.map(r => ({
                    id: r.id,
                    receiptno: r.number,
                    transaction_date: this.justDate(r.date_time),
                    transaction_amount: this.moneyFormat(r.transaction_amount),
                    seller: r.seller_name,
                    receipt: r
                }))
            }
        },
        async mounted() {
            await this.getReceipts();
            await this.getStores();
            await this.getPaymentmethods();
        },
        data() {
            return {
                headers: [
                    {
                        text: "Receipt No",
                        value: "receiptno"
                    },
                    {
                        text: "Transaction date",
                        value: "transaction_date"
                    },
                    {
                        text: "TransactionAmount",
                        value: "transaction_amount"
                    },
                    {
                        text: "SellerName",
                        value: "seller"
                    }
                ],
                loading: false,
                newReceiptDialog: false,
                products: [],
                receipts: [],
                stores: [],
                paymentmethods: [],
                search: "",
                warehouses: [],
                currency: null
            };
        },
        methods: {
            async getReceipts() {
                this.loading = true;
                try {
                    const res = await this.$http.get(
                        `${this.apiBaseUrl}/receipts/missing-transactions`
                    );
                    this.loading = false;
                    this.handleResponse(res, data => {
                        this.receipts = data;
                    });
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "We couldn't fetch your receipts.");
                }
            },
            async refreshReceiptList(receipts) {

                const receipt = Object.assign({}, receipts);
                this.posting = true;
                try {
                    const res = await this.$http.post(
                        `${this.apiBaseUrl}/receipts/missing-transactions`,
                        receipt
                    );
                    this.posting = false;
                    if (res.data.status == 0) {
                        return this.showSnackbar("Operation failed. Please try again later.", {
                            type: "error"
                        });
                    } else {
                        this.posting = false;
                        this.showSnackbar("receipt updated successfully.");
                        this.getReceipts();

                    }
                } catch (error) {
                    this.posting = false;
                    this.handleError(error, "Operation failed. Please try again later.");
                }
            },
            async getStores() {
                this.loading = true;
                try {
                    const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
                    this.loading = false;
                    this.handleResponse(res, data => {
                        this.stores = data;
                    });
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "We couldn't fetch your stores");
                }
            },
            async getPaymentmethods() {
                this.loading = true;
                try {
                    const res = await this.$http.get(`${this.apiBaseUrl}/paymentmethods`);
                    this.loading = false;
                    this.handleResponse(res, data => {
                        this.paymentmethods = data;
                    });
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "We couldn't fetch your stores");
                }
            }
        }
    };
</script>
