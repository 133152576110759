<template>
  <layout requires-store>
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex xs6 sm3>
        <!-- <v-btn block @click="showMessageDialog = true" color="info"> -->
        <v-btn block @click="saveMessageDialog('Save Message')" color="info">          
          <v-icon>add_circle_outline</v-icon>&nbsp; New Message
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card flat>
      <v-card-text>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex sm4>
            <v-text-field
              v-model="search.messages"
              append-icon="search"
              label="Search messages"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-flex>
        </v-layout>
        <br />
        <v-data-table
          :items="messages"
          :rows-per-page-items="[10]"
          :search="search.messages"
          :headers="headers.messages"
          :loading="loading"
        >
          <template slot="no-data">
            No messages. Add a new message above.
          </template>
          <template slot="items" slot-scope="props">
            <!-- <tr @click="showFullMessage(props.item.id)" class="clickable"> -->
            <tr>
              <td>{{ props.item.title }}</td>
              <td>{{ previewMessage(props.item.body) }}</td>
              <td>{{ momentFormat(props.item.dateTime) }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, hover }">
                    <v-icon
                      v-bind="hover"
                      v-on="on"
                      @click="openSendMessageDialog(props.item, 'users')"
                      color="info"
                      class="mr-4"
                    >
                      send
                    </v-icon>
                  </template>
                  <span>Send message to Users</span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, hover }">
                    <v-icon
                      v-bind="hover"
                      v-on="on"
                      @click="openSendMessageDialog(props.item, 'partners')"
                      color="info"
                    >
                      send
                    </v-icon>
                  </template>
                  <span>Send message to Customers</span>
                </v-tooltip>
              </td>
              <td> 
                <v-tooltip top>
                  <template v-slot:activator="{ on, hover }">
                    <v-icon 
                    v-bind="hover"
                    v-on="on"
                    @click="editMessageDialog(props.item.id, 'edit')"
                    color="info"
                    class="mr-4"
                    >
                    edit</v-icon>
                  </template>
                  <span>Edit Message</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, hover }">
                    <v-icon 
                    v-bind="hover"
                    v-on="on"
                    @click="deleteMessageDialog(props.item.id, 'delete')"
                    style="color: red"  
                    >
                    delete</v-icon>
                  </template>
                  <span>Delete Message</span>
                </v-tooltip>
                </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showMessageDialog" max-width="500px" persistent>
      <v-form lazy-validation ref="messages_form" @submit.prevent="saveMessage">
        <v-card flat>
          <v-card-title primary-title>
            <h3>
              Enter message
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap>
              <v-flex sm12>
                <v-text-field
                  required
                  :rules="formRules.required"
                  v-model="newMessage.title"
                  label="Title"
                ></v-text-field>
              </v-flex>
              <v-flex sm12>
                <v-textarea
                  box
                  required
                  counter="500"
                  :rules="formRules.required"
                  label="Body"
                  v-model="newMessage.body"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeMessageDialog" flat :disabled="posting"
              >Close</v-btn
            >
            <v-btn @click="saveMessage()" color="primary" flat>
              <!-- {{ saveMessageText }} -->
              {{ buttonText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="showSendMessageDialog"
      max-width="900px"
      height="600px"
      persistent
    >
      <v-card flat>
        <v-card-title>
          Send message to
          {{ capitalizeFirstLetter(selectedMessageType) }} email
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <!-- <v-checkbox v-model="selectAll" @change="toggleSelectAll">Select All</v-checkbox> -->
          <v-layout row wrap>
            <v-spacer></v-spacer>
            <v-flex sm4>
              <v-text-field
                v-model="search[selectedMessageType]"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-data-table
            v-if="selectedMessageType === 'users'"
            :items="filteredUsers"
            :rows-per-page-items="[10]"
            :search="search.users"
            :headers="headers.users"
            :loading="loading"
          >
            <!-- select-all -->
            <template slot="headers" slot-scope="props">
              <tr :style="'text-align: left'">
                <th>
                  <v-checkbox
                    v-model="selectAll"
                    @change="toggleSelectAll('users')"
                    >Select All</v-checkbox
                  >
                </th>
                <th v-for="header in props.headers" :key="header.text">
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template slot="no-data">
              No users.
            </template>
            <template
              slot="items"
              slot-scope="props"
              :active="props.selected"
              @click="props.selected = !props.selected"
            >
              <tr>
                <td>
                  <v-checkbox
                    v-model="props.item.selected"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ props.item.full_name }}</td>
                <td>{{ getStoreName(props.item.store_id) || "N/A" }}</td>
                <td>{{ props.item.role }}</td>
              </tr>
            </template>
          </v-data-table>

          <v-data-table
            v-if="selectedMessageType === 'partners'"
            :items="partners"
            :rows-per-page-items="[10]"
            :search="search.partners"
            :headers="headers.partners"
            :loading="loading"
          >
            <template slot="headers" slot-scope="props">
              <tr :style="'text-align: left'">
                <th>
                  <v-checkbox
                    v-model="selectAll"
                    @change="toggleSelectAll('partners')"
                    >Select All</v-checkbox
                  >
                </th>
                <th v-for="header in props.headers" :key="header.text">
                  {{ header.text }}
                </th>
              </tr>
            </template>
            <template slot="no-data">
              No partners.
            </template>
            <template
              slot="items"
              slot-scope="props"
              :active="props.selected"
              @click="props.selected = !props.selected"
            >
              <tr>
                <td>
                  <v-checkbox
                    v-model="props.item.selected"
                    primary
                    hide-details
                  ></v-checkbox>
                </td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.wallet_balance }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeMessageDialog" flat :disabled="posting"
              >Close</v-btn
            >
            <v-btn
              @click="sendMessage(selectedMessage)"
              color="primary"
              flat
              type="submit"
            >
              {{ saveMessageText }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSelectedMessageDialog" max-width="500px">
      <v-card flat>
        <v-card-title>
          Message Details
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout class="pb-3">
            <v-flex sm3>
              <h4>Title</h4>
            </v-flex>
            <v-flex sm9>
              {{ selectedMessage.title }}
            </v-flex>
          </v-layout>
          <v-layout class="pb-3">
            <v-flex sm3>
              <h4>Body</h4>
            </v-flex>
            <v-flex sm9>
              {{ selectedMessage.body }}
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex sm3>
              <h4>Date</h4>
            </v-flex>
            <v-flex sm9>
              {{ momentFormat(selectedMessage.dateTime) }}
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeMessageDialog" flat :disabled="posting"
              >Close</v-btn
            >
            <v-btn @click="handleAction" color="primary" flat type="submit">
              {{ method }}
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout,
  },
  computed: {
    saveMessageText() {
      return this.posting ? "Sending.." : "Send Message";
    },
    filteredUsers() {
      return this.users.filter(user => {
        const storeName = this.getStoreName(user.store_id);
        return storeName !== "N/A" && storeName === this.selectedStore.name;
      });
    },
  },
  availableMenus() {
    return this.menus.map((x) => {
      return {
        label: this.titleCase(x),
        value: x,
      };
    });
  },
  created() {
    if (this.selectedStore) this.getMessages();
  },
  data() {
    return {
      filters: {
        store: null,
      },
      messages: [],
      headers: {
        messages: [
          { text: "Title", value: "title" },
          { text: "Preview", value: "body" },
          { text: "Date", value: "dateTime" },
          { text: "Users", value: "" },
          { text: "Customers", value: "" },
        ],
        users: [
          { text: "Name", value: "full_name" },
          { text: "Store", value: "store_id" },
          { text: "Role", value: "role" },
        ],
        partners: [
          { text: "Name", value: "name" },
          { text: "Wallet Balance", value: "wallet_balance" },
        ],
      },
      selectAll: false,
      loading: false,
      newMessage: {},
      posting: false,
      search: {
        messages: "",
        users: "",
        partners: "",
      },
      roles: [],
      stores: [],
      selected_store: [],
      business: [],
      users: [],
      partners: [],
      menus: [],
      selectedMessage: {},
      selectedUsers: [],
      fromName: "",
      showMessageDialog: false,
      showSendMessageDialog: false,
      showSelectedMessageDialog: false,
      isUpdate: false,
      method: "",
      selectedMessageType: "",
      businessName: "",
      buttonText: "",
      newMessage: {}
    };
  },
  mounted() {
    this.getRoles();
    this.getStores();
    this.getUsers();
    this.getMenus();
    this.getPartners();
  },

  methods: {
    toggleSelectAll(type) {
      const items = this[type];
      items.forEach((item) => {
        item.selected = this.selectAll;
      });
    },
    resetSelection() {
      this.selectedUsers = [];
      this.selectAll = false;

      this.users.forEach((user) => {
        user.selected = false;
      });

      this.partners.forEach((user) => {
        user.selected = false;
      });
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    openSendMessageDialog(message, messageType) {
      this.selectedMessage = Object.assign({}, message);
      this.selectedMessageType = messageType;
      this.showSendMessageDialog = true;
    },
    closeMessageDialog() {
      this.resetForm();
      this.resetSelection();
      this.showMessageDialog = false;
      this.showSendMessageDialog = false;
      this.showSelectedMessageDialog = false;
    },

    handleUserSelection() {
      this.selectedUsers = this[this.selectedMessageType]
        .filter((user) => user.selected)
    },

    async getMessages() {
      this.loading = true;
      const selectedStore = this.selectedStore;
      let url = `${this.apiBaseUrl}/messages`;
      if (selectedStore) url += `?storeId=${this.selectedStore.id}`;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, (data) => {
          this.messages = data;
        });
      } catch (error) {
        const msg = "We couldn't fetch your messages. Please try again.";
        this.loading = false;
        this.handleError(error, msg);
      }
    },
    previewMessage(msg) {
      const len = msg.length;
      const limit = 30;
      if (len <= limit) return msg;

      return msg.substring(0, limit) + "...";
    },
    resetForm() {
      this.selectedUsers = [];
      this.newMessage = {};
      this.$refs.messages_form.reset();
    },
    async saveMessage() {
      if (!this.$refs.messages_form.validate()) return;
      const newMessage = { ...this.newMessage };
      const selectedStore = this.selectedStore;
      if (selectedStore) newMessage.store_id = this.selectedStore.id;
      
      this.posting = true;
      
      try {
        let res;
        
        if (this.isUpdate) {
          res = await this.$http.put(`${this.apiBaseUrl}/Messages`, newMessage);
        } else {
          res = await this.$http.post(`${this.apiBaseUrl}/messages`, newMessage);
        }

        this.posting = false;
        this.handleResponse(res, (data) => {
          this.messages.push(data);
          this.closeMessageDialog();
          this.getMessages();
          this.showSnackbar("Message " + (this.isUpdate ? "updated" : "added") + " successfully.");
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          `There was an error while ${this.isUpdate ? "updating" : "adding"} this message. Please try again later.`
        );
      } finally {
        this.posting = false;
      }
    },

    async sendMessage(selectedMessage) {
      this.handleUserSelection();

      const businessIds = this.selectedUsers.map(user => user.business_id);
      // const storeIds = this.selectedUsers.map(user => user.store_id);
      
      const business_details = await this.getBusiness(businessIds);

      const stores = await this.getStores();

      const updatedUsers = this.selectedUsers.map((user, index) => ({
        Id: user.id,
        BusinessName: business_details[index].name || '',
        Email: user.email
      }));

      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/Messages/send-messages-to-email/${selectedMessage.id}?type=${this.selectedMessageType}`, 
          updatedUsers,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        this.handleResponse(res, (data) => {
          this.showSnackbar("Message sent successfully.");
        });
      } catch (error) {
        this.handleError(
          error,
          "There was an error while sending this message. Please try again later."
        );
      }
      this.closeMessageDialog();
    },

    getRoleName(id) {
      return this.roles[id] || "-";
    },
    async getMenus() {
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/menus`);
        this.menus = data;
      } catch (error) {
        this.handleError(
          error,
          "Couldn't fetch menus. Please try again later."
        );
      }
    },
    async getRoles() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/roles`);
        this.handleResponse(res, (data) => {
          this.roles = data;
        });
      } catch (error) {
        this.handleError(
          error,
          "Couldn't fetch user roles. Please try again later."
        );
      }
    },
    getStoreName(id) {
      const store = this.stores.find((x) => x.id == id && x.name === this.selectedStore.name);
      return store ? store.name : "N/A";
    },
    async getBusiness(business_ids) {
      const businesses = [];
      
      for (const id of business_ids) {
        try {
          const res = await this.$http.get(`${this.apiBaseUrl}/Business`);
          this.handleResponse(res, (data) => {
            const business = data.find((business) => business.id === id);
            
            if (business) {
              businesses.push(business);
            } else {
              console.error("Business not found for id:", id);
            }
          })
        } catch (error) {
          console.error(`Error fetching business with ID ${id}:`, error);
        }
      }
      
      return businesses; 
    },
    async getStores() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.handleResponse(res, (data) => {
          this.stores = data;
          
        });
      } catch (error) {
        this.handleError(
          error,
          "Couldn't fetch stores. Please try again later."
        );
      }
    },
    async getSelectedStores(store_ids) {
      const selectedStores = [];      
      
      for (const id of store_ids) {
        try {
          const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
          this.handleResponse(res, (data) => {
            const selected_store = data.find((selected_store) => selected_store.id === id);
            
            if (selected_store) {
              selectedStores.push(selected_store);
            } else {
              console.error("Store not found for id:", id);
            }
          })
        } catch (error) {
          console.error(`Error fetching store with ID ${id}:`, error);
        }
      }
      
      return selectedStores;
    },
    async getUsers() {
      this.loading = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/users`);
        this.loading = false;
        this.handleResponse(res, (data) => {
          this.users = data.filter((users) => users.active);
        });
      } catch (error) {
        this.loading = false;
        this.handleError(
          error,
          "We couldn't fetch your users. Please try again later."
        );
      }
    },

    async getPartners() {
      this.loading = true;
      this.partners = [];
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/partners/get-partners`
        );
        this.loading = false;
        this.handleResponse(res, (data) => {
          this.partners = data.filter((users) => users.active);
        });
      } catch (error) {
        this.loading = false;
        this.handleError(
          error,
          "We couldn't fetch your partners. Please try again later."
        );
      }
    },

    showFullMessage(id, method) {
      this.method = method;
      const message = this.messages.find((x) => x.id == id);
      this.selectedMessage = Object.assign({}, message);
      this.showSelectedMessageDialog = true;
    },

    saveMessageDialog(buttonText, message) {
      this.buttonText = buttonText

      this.resetForm();

      if (message) {
        this.isUpdate = true;
        this.newMessage = { ...message };
      } else {
        this.isUpdate = false;
        this.newMessage = {};
      }

      this.showMessageDialog = true;
    },

    updateMessageDialog(buttonText, selectedMessage) {
      this.isUpdate = true;
      this.buttonText = buttonText
      this.newMessage = { ...selectedMessage };
      this.showMessageDialog = true;
    },

    editMessageDialog(id, method) {
      this.showFullMessage(id, method);
    },
    
    deleteMessageDialog(id, method) {
      this.showFullMessage(id, method);
    },

    handleAction() {
      if (this.method === 'edit') {
        this.updateMessageDialog("Update Message", this.selectedMessage);
      } else if (this.method === 'delete') {
        this.deleteSelectedMessage();
      } else {
        console.warn('Unsupported method:', this.method);
      }
    },

    async editSelectedMessage() {
      const updatedUsers = {
        Id: this.selectedMessage.id,
        StoreId: this.selectedMessage.storeId,
        Title: this.selectedMessage.title,
        Body: this.selectedMessage.body
      };

      try {
        const res = await this.$http.put(`${this.apiBaseUrl}/Messages`, 
          updatedUsers
        );
        this.handleResponse(res, (data) => {
          this.showSnackbar(res.data.message);
        });
      } catch (error) {
        this.handleError(
          error,
          "There was an error while sending this message. Please try again later."
        );
      }
      this.closeMessageDialog();
    },

    async deleteSelectedMessage() {
      const selectedMessageId = this.selectedMessage.id
      try {
        const res = await this.$http.delete(`${this.apiBaseUrl}/Messages/${selectedMessageId}`);
        this.handleResponse(res, (data) => {
          this.showSnackbar(res.data.message);
        });
      } catch (error) {
        this.handleError(
          error,
          "There was an error while deleting this message. Please try again later."
        );
      }
      this.closeMessageDialog();
      this.getMessages();
    },

  },
  
  watch: {
    selectedStore: {
      handler() {
        this.loaded = false;
        this.getMessages();
      },
    },
  },
  
};
</script>