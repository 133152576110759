<template>
    <v-card outlined v-if="value">
        <v-card-title class="grey darken-2 white--text">Email Configuration</v-card-title>
    <v-layout row wrap>
      <v-flex xs12  class="pa-3">
        <v-form ref="update_profile" lazy-validation>
          <v-layout row wrap>
            <v-flex sm6 px-2>
              <v-text-field
                required
                v-model="emailDetails.name"
                label="Display Name"
                :rules="formRules.required"
                name="name"
              ></v-text-field>
            </v-flex>
            <!--<v-flex sm6 px-2>
              <v-text-field
                required
                v-model="emailDetails.email"
                type="email"
                validate-on-blur
                label="From Email"
                :rules="formRules.required"
                name="email"
              ></v-text-field>
            </v-flex>-->
          </v-layout>

          <v-layout>
            <v-spacer></v-spacer>
            <v-btn :disabled="!ready" @click="updateDetails">{{
              saveText
            }}</v-btn>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    </v-card>
</template>

<script>
import axios from "axios";
import Layout from "@/components/Layout";

export default {
props: ['data','value'],
  components: {
    Layout
  },
  data() {
    return {
      emailDetails: {},
      saveText: "Update email setting",
      ready: false
    };
  },
  mounted() {
    this.getUser();
  },
  methods: {
    async updateDetails() {
      this.ready = false;
      //this.emailDetails.businessId = data.businessId;
      let data = Object.assign({}, this.emailDetails);
      data.email = "";
      let url = `${this.apiBaseUrl}/account/email-settings`;

      try {
        const res = await this.$http.post(url, data);
          this.handleResponse(res, data => {
              this.emailDetails.email = res.data.data.email;
            //console.log(res, data);
          this.showSnackbar(res.data.message);
        });
      } catch (error) {
        this.handleError(error, "Unable to update user data");
      }

      this.ready = true;
    },
    getUser() {
      let url = `${this.apiBaseUrl}/email-settings`;
      axios
        .get(url)
        .then(response => {
            const userDetails = response.data.data.result.data;
            const details = response.data.data.data;
            if (!details)
                this.emailDetails.businessId = userDetails.business_id;
            else this.emailDetails = details;
          this.ready = true;
        })
        .catch(() => {
          return this.showSnackbar("Unable to get user data", {
            type: "error"
          });
        });
    }
  }
};
</script>
