import Subscription from "../views/Subscription";
import CofirmSubscription from "../views/CofirmSubscription";

export default [
  {
    path: "/subscribe",
    component: Subscription,
    name: "Subscription",
    meta:["admin"]
    },
   {
    path: "/confirm-payment",
    component: CofirmSubscription,
    name: "Cofirm Subscription",
    meta: ["admin"]
   }
];
