import PriceChanges from "@/views/reports/PriceChanges";
import ReturnGoods from "@/views/reports/ReturnGoods";
import NewReturnOfGoods from "@/views/reports/NewReturnOfGoods";
import GoodsDepreciations from "@/views/reports/GoodsDepreciation";
import DailyStatus from "@/views/reports/DailyStatus";
import Receipts from "@/views/reports/Receipts";
import Balances from "@/views/reports/BalanceInHand";
import LowStock from "@/views/reports/LowStock";
import Returnable from "@/views/reports/Returnable";
import DailySales from "@/views/reports/DailySales";
import Orders from "@/views/reports/Orders";
import CashierSales from "@/views/reports/CashierSales";
import StoreInventory from "@/views/reports/StoreInventory";
import Payments from "@/views/reports/Payments";
import Suppliers from "@/views/reports/Suppliers";
import ProfitLoss from "@/views/reports/ProfitLoss";
import WalletLog from "@/views/reports/WalletLog";
import StockReport from "@/views/reports/Stockreport";
import Missingtransactions from "@/views/reports/Missingtransactions";

export default [
  {
    name: "Receipts",
    path: "/reports/receipts",
    component: Receipts,
    access: "reports_receipts",
    icon: "show_chart"
  },
  {
    name: "Sales",
    path: "/reports/daily-sales",
    component: DailySales,
    access: "reports_daily_sale",
    icon: "show_chart"
  },
  {
    name: "Sales by Cashier",
    path: "/reports/cashier-sales",
    component: CashierSales,
    access: "sale_by_cashier",
    icon: "show_chart"
  },
  {
    name: "Profit / Loss",
    path: "/reports/profit-and-loss",
    access: "report_profit_and_loss",
    component: ProfitLoss,
    icon: "show_chart"
    },
    {
        name: "Store Inventory",
        path: "/reports/store-inventory",
        component: StoreInventory,
        access: "report_store_inventory",
        icon: "show_chart"
    },
  {
    name: "Low Stock Products",
    path: "/reports/low-stock",
    component: LowStock,
    access: "report_low_stock",
    icon: "show_chart"
  },
  {
    name: "Missing transactions",
    path: "/reports/missing-transactions",
    component: Missingtransactions,
    access: "report_missing_transactions",
    icon: "show_chart"
  },
  {
    name: "Suppliers",
    path: "/reports/suppliers",
    component: Suppliers,
    access: "report_suppliers",
    icon: "show_chart"
    },
    {
    name: "Orders",
    path: "/reports/customers-order",
    component: Orders,
    access: "report_customer_order",
    icon: "show_chart"
    },
  {
    name: "Returnable Package List",
    path: "/reports/returnable",
    component: Returnable,
    access: "report_returnable",
    icon: "show_chart"
    },
    {
    name: "Wallet Log Report",
    path: "/reports/credit-wallet-log",
    component: WalletLog,
    access: "reports_credit_wallet_log",
    icon: "show_chart"
  },
    {
    name: "Stock Move Report",
    path: "/reports/stock-moved",
    component: StockReport,
    access: "reports_stock_move",
    icon: "show_chart"
  },
  //{
  //  name: "Payments",
  //  path: "/reports/payments",
  //  component: Payments,
  //  icon: "show_chart"
  //},

  //{
  //  name: "Balance in hand",
  //  path: "/reports/balance-in-hand",
  //  component: Balances,
  //  icon: "show_chart"
  //},
    //{
  //  name: "Price Changes",
  //  path: "/reports/price-changes",
  //  component: PriceChanges,
  //  icon: "show_chart"
  //},
  {
    name: "Returns",
    path: "/reports/return-goods",
    component: ReturnGoods,
    icon: "show_chart"
  },
  //{
  //  name: "Return of goods",
  //  path: "/reports/new-return-of-goods",
  //  component: NewReturnOfGoods,
  //  icon: "show_chart"
  //},
  //{
  //  name: "Depreciation of goods",
  //  path: "/reports/goods-depreciations",
  //  component: GoodsDepreciations,
  //  icon: "show_chart"
  //},
  //{
  //  name: "Daily Status",
  //  path: "/reports/daily-status",
  //  component: DailyStatus,
  //  icon: "show_chart"
  //},
];
