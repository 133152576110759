import Dashboard from "@/views/superAdmin/Dashboard";
import AccessControl from "@/views/superAdmin/AccessControl";
import BusinessAnalysis from "@/views/superAdmin/BusinessAnalysis";
import BusinessLogging from "@/views/superAdmin/BusinessLogging";
import BusinessSubscription from "@/views/superAdmin/BusinessSubscription";
import SubscriptionTransactions from "@/views/superAdmin/SubscriptionTransactions";
import Settings from "@/views/superAdmin/Settings";
import SettingsRoutes from "@/router/superadmin-settings";
//import MainAdmin from "@/views/superAdmin/admins/MainAdmin";

export default [
    {
        path: "/",
        name: "Dashboard",
        icon: "apps",
        showInNav: true,
        access: "Administrator",
        component: Dashboard
    },
    {
        path: "/access-control",
        name: "Access Control",
        showInNav: true,
        icon: "contact_mail",
        access: "Administrator",
        component: AccessControl
    },
    {
        path: "/bussiness-analysis",
        name: "Business Analysis",
        showInNav: true,
        icon: "show_chart",
        access: "Administrator",
        component: BusinessAnalysis
    },
    //{
    //    path: "/bussiness-logging",
    //    name: "Business Logging",
    //    showInNav: true,
    //    icon: "event_note",
    //    access: "Administrator",
    //    component: BusinessLogging
    //},
    // {
    //     path: "/bussiness-subscriptions",
    //     name: "Business Subscription",
    //     showInNav: true,
    //     access: "Administrator",
    //     icon: "library_books",
    //     component: BusinessSubscription
    // },
    // {
    //     path: "/subscription-transactions",
    //     name: "Subscription Transactions",
    //     showInNav: true,
    //     access: "Administrator",
    //     icon: "local_atm",
    //     component: SubscriptionTransactions
    // },
    // {
    //     path: "/settings",
    //     name: "Settings",
    //     showInNav: true,
    //     access: "Administrator",
    //     icon: "settings",
    //     component: Settings,
    //     children: [...SettingsRoutes]
    // },
];
