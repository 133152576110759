<template>
  <v-card>
    <v-card-title class="cyan darken-2 white--text">
      Customer Orders
    </v-card-title>
    <v-card-text class="text-sm-right p-8">
        
        <div style="display: flex; justify-content: space-around">
            <h4 style="color:orange">Pending &nbsp;<span style="color:black">- {{orders ? orders.pending : ""}}</span></h4>
            <h4 style="color:green">Approved &nbsp;<span style="color:black">- {{orders ? orders.approved : ""}}</span></h4>
            <h4 style="color:red">Cancelled &nbsp;<span style="color:black">- {{orders ? orders.cancelled : ""}}</span></h4>
        </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CustomerOrder",
  data() {
    return {};
  },
  props: ["orders"]
};
</script>
