var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm3":""}},[_c('v-menu',{ref:"startDayMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.filter.startDate,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "startDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "startDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Start date","prepend-icon":"event","hint":"YYYY/MM/DD format","readonly":""},model:{value:(_vm.filter.startDate),callback:function ($$v) {_vm.$set(_vm.filter, "startDate", $$v)},expression:"filter.startDate"}},on))]}}]),model:{value:(_vm.startDayMenu),callback:function ($$v) {_vm.startDayMenu=$$v},expression:"startDayMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filter.startDate),callback:function ($$v) {_vm.$set(_vm.filter, "startDate", $$v)},expression:"filter.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","color":"primary"},on:{"click":function($event){_vm.startDayMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"flat":"","color":"primary"},on:{"click":function($event){return _vm.$refs.startDayMenu.save(_vm.filter.startDate)}}},[_vm._v("OK")])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm3":""}},[_c('v-menu',{ref:"endDayMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.filter.endDate,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "endDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "endDate", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"End Date","prepend-icon":"event","hint":"YYYY/MM/DD format","readonly":""},model:{value:(_vm.filter.endDate),callback:function ($$v) {_vm.$set(_vm.filter, "endDate", $$v)},expression:"filter.endDate"}},on))]}}]),model:{value:(_vm.endDayMenu),callback:function ($$v) {_vm.endDayMenu=$$v},expression:"endDayMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filter.endDate),callback:function ($$v) {_vm.$set(_vm.filter, "endDate", $$v)},expression:"filter.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","color":"primary"},on:{"click":function($event){_vm.endDayMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"flat":"","color":"primary"},on:{"click":function($event){return _vm.$refs.endDayMenu.save(_vm.filter.endDate)}}},[_vm._v("OK")])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm3":""}},[_c('v-btn',{attrs:{"color":"info"},on:{"click":_vm.fetchWalletLog}},[_vm._v(" Filter ")])],1),_c('v-flex',{attrs:{"xs12":"","sm3":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('div',{staticClass:"text-xs-right"},[(_vm.records.length)?_c('DownloadCSV',{staticClass:"v-btn theme--light success",attrs:{"data":_vm.records,"name":"wallet_log_report.csv"}},[_vm._v("\n                        Export\n                    ")]):_vm._e()],1)])],1)],1),[_c('v-data-table',{attrs:{"expand":true,"item-key":"id","disable-initial-sort":"","items":_vm.histories,"rows-per-page-items":[25],"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',[_c('td',[_vm._v(_vm._s(props.item.transactionId || "No Transaction ID"))]),_c('td',[_vm._v(_vm._s(props.item.partner.name))]),_c('td',[_vm._v(_vm._s(_vm.moneyFormat(props.item.amount)))]),_c('td',[_vm._v(_vm._s(_vm.moneyFormat(props.item.previousBalance)))]),_c('td',[_vm._v("\n                        "+_vm._s(props.item.isCredit
                        ? _vm.moneyFormat(props.item.previousBalance + (props.item.amount))
                        : _vm.moneyFormat((props.item.previousBalance) - (props.item.amount)))+"\n                    ")]),_c('td',[_vm._v(_vm._s(props.item.isCredit ? "Credit" : "Debit"))]),_c('td',[_vm._v(_vm._s(props.item.description))]),_c('td',[_vm._v(_vm._s(_vm.identifyUser(props.item.initiatingUserId)))]),_c('td',[_vm._v("\n                        "+_vm._s(props.item.transactionDate
                                        ? new Date(props.item.transactionDate).toLocaleString()
                                        : "Not recorded")+"\n                    ")]),_c('td',[_vm._v(_vm._s(new Date(props.item.createdAt).toLocaleString()))])])]}}])},[_c('template',{slot:"no-data"},[_vm._v("\n                No histories.\n            ")])],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }