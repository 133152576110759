<template>
  <layout requires-store>
      <template v-if="selectedStore || isLoggedIn.store_id">

          <v-layout row wrap>
              <v-flex xs12 sm3>
                  <v-menu ref="startDayMenu"
                          v-model="startDayMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="filter.startDate"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          min-width="290px">
                      <template v-slot:activator="{ on }">
                          <v-text-field v-model="filter.startDate"
                                        label="Start date"
                                        prepend-icon="event"
                                        hint="YYYY/MM/DD format"
                                        readonly
                                        v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="filter.startDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="startDayMenu = false">Cancel</v-btn>
                          <v-btn flat
                                 color="primary"
                                 @click="$refs.startDayMenu.save(filter.startDate)">OK</v-btn>
                      </v-date-picker>
                  </v-menu>
              </v-flex>

              <v-flex xs12 sm3>
                  <v-menu ref="endDayMenu"
                          v-model="endDayMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="filter.endDate"
                          lazy
                          transition="scale-transition"
                          offset-y
                          full-width
                          min-width="290px">
                      <template v-slot:activator="{ on }">
                          <v-text-field v-model="filter.endDate"
                                        label="End Date"
                                        prepend-icon="event"
                                        hint="YYYY/MM/DD format"
                                        readonly
                                        v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="filter.endDate" no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn flat color="primary" @click="endDayMenu = false">Cancel</v-btn>
                          <v-btn flat
                                 color="primary"
                                 @click="$refs.endDayMenu.save(filter.endDate)">OK</v-btn>
                      </v-date-picker>
                  </v-menu>
              </v-flex>

              <v-flex xs12 sm3>
                  <v-btn color="info" @click="filterData"> Filter </v-btn>
              </v-flex>

              <v-flex xs12 sm3>
                  <div class="text-xs-right">
                      <DownloadCSV class="v-btn theme--light success"
                                   :data="reportDetailsCsv"
                                   name="export-customer-order.csv"
                                   v-if="returnOfGoods.length">
                          Export
                      </DownloadCSV>
                  </div>
              </v-flex>
          </v-layout>

          <v-data-table disable-initial-sort
                        :items="returnOfGoods"
                        :rows-per-page-items="[10]"
                        :search="search"
                        :headers="headers"
                        :loading="loading">
              <template slot="no-data">
                  No return of goods.
              </template>
              <template slot="items" slot-scope="props">
                  <td>{{ fullDateFormat(props.item.return_date_time) }}</td>
                  <td>{{ props.item.receipt_id }}</td>
                  <td>{{ props.item.product_name }}</td>
                  <td>{{ props.item.quantity }}</td>
                  <td>{{ props.item.total_amount }}</td>
                  <td>{{ props.item.refund_method}}</td>
                  <td>{{ props.item.inventory}}</td>
                  <td>{{ props.item.initiator}}</td>
                  <td>{{ props.item.customer_name}}</td>
                  <template>
                      <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                              <td v-on="on">{{ellipses(props.item.reason)}}</td>
                          </template>
                          <span>{{props.item.reason}}</span>
                      </v-tooltip>
                  </template>
              </template>
          </v-data-table>
      </template>
    <template v-else>
      No store selected
    </template>

    <!-- Item dialog -->
    <v-dialog v-model="showItemDialog" max-width="400px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <h3>View Details</h3>
        </v-card-title>
        <v-card-text>
          <strong>Reason:</strong> <br />
          {{ selectedItem.reason }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Item dialog -->
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import JsonCSV from "vue-json-csv";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
        },
        startDayMenu: false,
        endDayMenu: false,
      headers: [
          { text: "Date", value: "return_date_time" },
          { text: "Receipt #", value: "receipt_id" },
          { text: "Product Name", value: "product_name" },
          { text: "Quantity", value: "quantity" },
          { text: "Total Amoount", value: "total_amount" },
          { text: "Refund Method", value: "refund_method" },
          { text: "To inventory?", value: "inventory" },
          { text: "Initiator", value: "initiator" },
          { text: "Customer Name", value: "customer_name" },
          { text: "Reason", value: "reason" }
      ],
      loading: false,
      selectedItem: {},
      returnOfGoods: [],
      search: "",
      showItemDialog: false
    };
  },
  computed:{
      reportDetailsCsv() {
          let list = [];
          this.returnOfGoods.forEach((item) => {
              var temp = {};
              temp.Date = this.fullDateFormat(item.return_date_time);
              temp.ReceiptNumber = item.receipt_id;
              temp.ProductName = item.product_name;
              temp.SellingPrice = item.price;
              temp.Quantity = item.quantity;
              temp.TotalAmount = item.total_amount;
              temp.RefundMethod = item.refund_method;
              temp.ToInventory = item.inventory;
              temp.InitiatedBy = item.initiator;
              temp.CustomerName = item.customer_name;
              temp.Reasons = item.reason;
              list.push(temp); 
          });
          return list;
      },
  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let url = `${this.apiBaseUrl}/storeproducts/get-return-goods?storeId=${storeId}&startDate=${this.filter.startDate}&endDate=${this.filter.endDate}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.returnOfGoods = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    },
    ellipses(str) {
        return (str.length > 15) ? str.slice(0, 15 - 1) + '...' : str;
    },
    filterData() {
         this.getData();
    },
    showInfo(returnOfGood) {
      if (returnOfGood) {
        this.selectedItem = returnOfGood;
      }
      this.showItemDialog = true;
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
