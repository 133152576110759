<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-data-table
        disable-initial-sort
        :items="lowStock"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No data found.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.product.name }}</td>
          <td>{{ props.item.product.code }}</td>
          <td class="text-xs-right">
            {{ numberFormat(props.item.minimumStock) }}
          </td>
          <td class="text-xs-right">{{ numberFormat(props.item.inStock) }}</td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
      },
      headers: [
        {
          text: "Product name",
          value: "product.name"
        },
        {
          text: "Product code",
          value: "product.code"
        },
        {
          text: "Minimum stock",
          value: "minimumStock",
          align: "right"
        },
        {
          text: "Current stock",
          value: "inStock",
          align: "right"
        }
      ],
      loading: false,
      selectedItem: {},
      lowStock: [],
      search: "",
      showItemDialog: false
    };
  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let url = `${this.apiBaseUrl}/storeproducts/low-stock?storeId=${storeId}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.lowStock = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
