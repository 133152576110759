<template>
  <layout>
    <v-layout v-if="stores.length < 1">
      <v-flex xs12>
        <v-alert
          xs12
          v-model="alert"
          dismissible
          type="info"
          transition="fade-transition"
        >
          You have no stores.
          <v-btn to="/stores" color="warning">Add New Store</v-btn>
        </v-alert>
        <br />
      </v-flex>
    </v-layout>

    <v-layout row wrap>
      <v-flex xs6 sm3 px-2>
        <product-count />
      </v-flex>
      <v-flex xs6 sm3 px-2>
        <warehouse-count />
      </v-flex>
      <v-flex xs6 sm3 px-2>
        <sales-count :value="sales" />
      </v-flex>
      <v-flex xs6 sm3 px-2>
        <partners-count />
      </v-flex>
    </v-layout>
    <br />
    <v-layout row wrap>
      <v-flex xs12 sm3 px-2>
        <most-sold-count :mostSoldProduct="mostSoldProduct" />
      </v-flex>
      <v-flex xs12 sm3 px-2>
        <least-sold-count :leastSoldProduct="leastSoldProduct" />
      </v-flex>
      <v-flex xs12 sm6 px-2>
        <customer-order :orders="orders" />
      </v-flex>
    </v-layout>
    <br />
    <v-layout row wrap>
      <v-flex xs12 sm6 px-2>
        <top-products />
      </v-flex>
        
      <v-flex xs12 sm6 px-2>
        <profit-and-loss />
      </v-flex>
    </v-layout>
    <br />
    <v-layout>
      <v-flex xs12 px2>
        <keep-alive>
          <div class="filter-div">
            <v-layout row wrap>
              <v-flex sm3 px-2 pr-3>
                <p class="mb-2">
                  <strong>
                    <small>
                      Start date
                    </small>
                  </strong>
                </p>
                <v-menu
                  ref="startDateMenu"
                  :close-on-content-click="false"
                  :v-model="pickers.start"
                  :nudge-right="40"
                  :return-value.sync="filter.startDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  @blur="
                    filter.startDate = $moment(filter.startdate).format(
                      'YYYY-MM-DD'
                    )
                  "
                >
                  <v-text-field
                    slot="activator"
                    v-model="filter.startDate"
                    label="Start date"
                    solo
                    :hide-details="true"
                    readonly
                    prepend-inner-icon="event"
                  ></v-text-field>
                  <v-date-picker v-model="filter.startDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="pickers.start = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.startDateMenu.save(filter.startDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex sm3 px-2 pr-3>
                <p class="mb-2">
                  <strong>
                    <small>
                      End date
                    </small>
                  </strong>
                </p>
                <v-menu
                  ref="endDateMenu"
                  :close-on-content-click="false"
                  :v-model="pickers.end"
                  :nudge-right="40"
                  :return-value.sync="filter.endDate"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  @blur="
                    filter.endDate = $moment(filter.endDate).format(
                      'YYYY-MM-DD'
                    )
                  "
                >
                  <v-text-field
                    slot="activator"
                    v-model="filter.endDate"
                    label="End date"
                    solo
                    :hide-details="true"
                    readonly
                    prepend-inner-icon="event"
                  ></v-text-field>
                  <v-date-picker v-model="filter.endDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="pickers.end = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.endDateMenu.save(filter.endDate)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex sm2>
                <p class="mb-2">
                  <strong>
                    <small>
                      &nbsp;
                    </small>
                  </strong>
                </p>
                <v-btn large color="primary" @click="getSales">
                  Filter &nbsp;
                  <v-icon>
                    chevron_right
                  </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </div>
        </keep-alive>
      </v-flex>
    </v-layout>
    <br />
    <v-divider></v-divider>
    <br />

    <v-layout row wrap>
      <v-flex md6 px-2>
        <registered-stores />
      </v-flex>

      <v-flex md6 px-2>
        <pending-receipts />
      </v-flex>
    </v-layout>
      
    <v-layout row wrap>
      <v-flex md6 px-2>
        <payment-methods :entries="paymentMethodEntries" />
      </v-flex>
      <v-flex md6 px-2>
        <recent-sale :sales="sales" />
      </v-flex>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import ProductCount from "@/components/dashboard/cards/ProductCount";
import WarehouseCount from "@/components/dashboard/cards/WarehouseCount";
import SalesCount from "@/components/dashboard/cards/SalesCount";
import PartnersCount from "@/components/dashboard/cards/PartnersCount";
import MostSoldCount from "@/components/dashboard/cards/MostSoldCount";
import LeastSoldCount from "@/components/dashboard/cards/LeastSoldCount";
import RecentSale from "@/components/dashboard/RecentSale";
import PendingReceipts from "@/components/dashboard/PendingReceipts";
import RadarChart from "@/components/charts/RadarChart";
import PaymentMethods from "@/components/dashboard/PaymentMethods";
import RegisteredStores from "@/components/dashboard/RegisteredStores";
import Sales from "@/components/dashboard/Sales";
import CustomerRevenue from "@/components/dashboard/CustomerRevenue";
import TopProducts from "@/components/dashboard/TopProducts";
import ProfitAndLoss from "@/components/dashboard/ProfitAndLoss";
import CustomerOrder from "@/components/dashboard/cards/CustomerOrder";
import moment from "moment";

export default {
  name: "dashboard",
  created() {
    //console.log('STORES', this.stores);
    this.getSales();
    this.getSoldProducts();
    this.getOrdersOverview();
  },
  components: {
    RadarChart,
    Sales,
    Layout,
    PaymentMethods,
    RegisteredStores,
    ProductCount,
    WarehouseCount,
    SalesCount,
    PartnersCount,
    RecentSale,
    CustomerRevenue,
    PendingReceipts,
    LeastSoldCount,
    MostSoldCount,
    TopProducts,
    ProfitAndLoss,
    CustomerOrder
  },
  computed: {
    paymentMethodEntries() {
      const bucket = {};
      this.sales.map(x => {
        const { payment_method_name, transaction_amount } = x;
        const name = payment_method_name;
        const entry = bucket[name];
        if (!entry) {
          bucket[name] = { amount: transaction_amount, count: 1 };
        } else {
          bucket[name] = {
            amount: entry.amount + transaction_amount,
            count: entry.count + 1
          };
        }
      });

      return Object.keys(bucket).map(x => {
        return {
          name: x,
          amount: bucket[x].amount,
          count: bucket[x].count
        };
      });
    },
    stores() {
      const business = this.$db.get(this.$appConfig.constants.BUSINESS);
      return business ? business.stores : [];
    }
  },
  data() {
    return {
      filter: {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      },
      pickers: {
        start: false,
        end: false
      },
      sales: [],
      soldProducts: {},
      mostSoldProduct: {},
      orders: {},
      leastSoldProduct: {},
    };
  },
  methods: {
    async getSales() {
      this.showGlobalLoader(true);
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/dashboard/sales?start=${
            this.filter.startDate
          }&end=${this.filter.endDate}`
        );
        await this.handleResponse(res, data => {
          this.sales = data;
        });
      } catch (error) {
        await this.handleError(error, "We couldn't fetch your sales data.");
      }

      this.showGlobalLoader(false);
    },
    async getOrdersOverview() {
      let url = `${this.apiBaseUrl}/customersorder/orders-overview`;
      try {
        const { data } = await this.$http.get(url);
        this.orders = data.data;
      } catch (error) {
          await this.handleError(error, "We couldn't fetch your orders overview data.");}
    },
    async getSoldProducts() {
      let url = `${this.apiBaseUrl}/dashboard/sold-products`;
      try {
        const { data } = await this.$http.get(url);
        const { data: products } = data;
        products.forEach((data) => {
          if (!this.soldProducts[data.productId])
            this.soldProducts[data.productId] = {
              name: data.productName,
              count: data.quantity
            }
          else
            this.soldProducts[data.productId].count += data.quantity;
        })
      } catch (error) {}
      this.calculateProductStatistics(this.soldProducts);
    },
    calculateProductStatistics (soldProducts) {
      const allItemsWithoutTheirKeys =
        Object.keys(soldProducts)
        .map(key => soldProducts[key]);
      const sortItemsByCount =
        allItemsWithoutTheirKeys.sort((a, b) => b.count - a.count)

      // Now lets add the highest and lowest to our cards
      this.mostSoldProduct = sortItemsByCount[0];
      this.leastSoldProduct = sortItemsByCount[sortItemsByCount.length - 1];
    }
  }
};
</script>

<style>
.dash-card {
  margin-bottom: 20px;
}

.v-card__title,
.v-card__text {
  padding: 8px;
}
</style>

<style scoped>
.filter-div {
  padding: 8px;
  background-color: #dce7ec;
}
</style>
