<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="newPartnerGroupDialog = true">
          Add group
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex sm4>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search groups"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <br />
          </v-flex>
          <v-flex xs12>
            <v-data-table
              :items="items"
              :rows-per-page-items="[20]"
              :search="search"
              :headers="headers"
            >
              <template slot="no-data">
                No product groups. Add a new group above.
              </template>
              <template slot="items" slot-scope="props">
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.code || "" }}</td>
                <td>
                  {{ props.item.discount ? props.item.discount.name : "" }}
                </td>
                <td>
                  <v-icon small :color="props.item.active ? 'green' : 'red'">
                    {{ props.item.active ? "check_circle" : "remove_circle" }}
                  </v-icon>
                </td>
                <td>
                  <v-icon small @click="showGroupInfo(props.item)">
                    info
                  </v-icon>
                  &nbsp;&nbsp;
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-dialog v-model="newPartnerGroupDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title primary-title>
          <h3>
            Enter partner group details
          </h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form lazy-validation ref="new_group_form" @submit.prevent="post">
            <v-layout row wrap>
              <v-flex xs12 px-2>
                <v-text-field
                  required
                  v-model="newPartnerGroup.name"
                  autofocus
                  label="Name"
                  :rules="formRules.required"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-2>
                <v-text-field
                  required
                  v-model="newPartnerGroup.code"
                  label="Code"
                  :rules="formRules.required"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-2>
                <v-select
                  v-model="newPartnerGroup.discount_id"
                  :items="discounts"
                  item-text="name"
                  item-value="id"
                  :validate-on-blur="true"
                  label="Discount"
                  placeholder="Select discount"
                  name="discount"
                ></v-select>
              </v-flex>
              <v-flex xs12 px-2>
                <v-checkbox
                  required
                  v-model="newPartnerGroup.active"
                  label="Active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-spacer></v-spacer>
              <v-btn @click="closePartnerGroupDialog" flat :disabled="posting"
                >Close</v-btn
              >
              <v-btn type="submit" flat :disabled="posting" color="primary">{{
                actionText
              }}</v-btn>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.getDiscounts();
  },
  computed: {
    actionText() {
      const texts = [
        {
          active: "Saving",
          default: "Save"
        },
        {
          active: "Updating",
          default: "Update"
        }
      ];

      const index = this.action ? 1 : 0;
      return this.posting ? texts[index].active : texts[index].default;
    }
  },
  data() {
    return {
      action: "",
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Code",
          value: "code"
        },
        {
          text: "Discount",
          value: "discount"
        },
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      newPartnerGroup: {},
      newPartnerGroupDialog: false,
      search: "",
      discounts: []
    };
  },
  methods: {
    async getDiscounts() {
      this.loaded = false;
      let url = `${this.apiBaseUrl}/discounts`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
      const res = await this.$http.get(url);
      this.loaded = true;
      this.handleResponse(res, data => {
        this.discounts = data;
      });
    },
    closePartnerGroupDialog() {
      this.newPartnerGroupDialog = false;
      this.action = "";
      this.$refs.new_group_form.reset();
    },
    showActivationDialog() {},
    showGroupInfo(item) {
      this.newPartnerGroup = Object.assign({}, item);
      this.action = "update";
      this.newPartnerGroupDialog = true;
    },
    async post() {
      if (this.action !== "switchActivation") {
        if (!this.$refs.new_group_form.validate()) return;
      }
      const group = Object.assign({}, this.newPartnerGroup);
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/partners/groups`,
          group
        );
        this.posting = false;
        this.handleResponse(res, () => {
          this.$emit("refreshData");
          this.closePartnerGroupDialog();
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "Operation failed. Please try again later.");
      }
    }
  },
  props: {
    items: Array
  }
};
</script>
