<template>
  <layout>
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex xs6 sm3>
        <v-btn block @click="showMessageDialog = true" color="info">
          <v-icon>add_circle_outline</v-icon>&nbsp; New Reason
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card flat>
      <v-card-text>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex sm4>
            <v-text-field
              v-model="search.messages"
              append-icon="search"
              label="Search reasons"
              single-line
              hide-details
              clearable
            ></v-text-field>
          </v-flex>
        </v-layout>
        <br />
        <v-data-table
          :items="reasons"
          :rows-per-page-items="[10]"
          :search="search.messages"
          :headers="headers.messages"
          :loading="loading"
        >
          <template slot="no-data">
            No reasons. Add a new reason above.
          </template>
          <template slot="items" slot-scope="props">
            <tr>
            <td>{{ previewMessage(props.item.reason) }}</td>
            <td>
                <v-icon small :color="props.item.inventory ? 'green' : 'red'">
                    {{ props.item.inventory ? "check_circle" : "remove_circle" }}
                </v-icon>
            </td>
            <td>
            <v-icon small :color="props.item.active ? 'green' : 'red'">
                {{ props.item.active ? "check_circle" : "remove_circle" }}
            </v-icon>
            </td>
            <td>
                <v-icon small @click="showReasonInfo(props.item)">edit</v-icon>
            </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="showMessageDialog" max-width="500px" persistent>
      <v-form lazy-validation ref="reasons_form" @submit.prevent="saveMessage">
        <v-card flat>
          <v-card-title primary-title>
            <h3>
              Enter reasons
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
              <v-layout row wrap>
                  <v-flex sm12>
                      <v-textarea box
                                  required
                                  counter="500"
                                  :rules="formRules.required"
                                  label="Body"
                                  v-model="newMessage.body"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout row wrap>
                  <v-flex px-2>
                      <v-switch label="Is Active?"
                                v-model="newMessage.active"></v-switch>
                  </v-flex>
                  <v-flex px-2>
                      <v-switch label="Return to inventory?"
                                v-model="newMessage.inventory"></v-switch>
                  </v-flex>
              </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="closeMessageDialog" flat :disabled="posting"
              >Close</v-btn
            >
            <v-btn color="primary" flat type="submit">
              {{ saveMessageText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    saveMessageText() {
      return this.posting ? "Saving..." : "Save";
    }
  },
  created() {
    this.getReasons();
  },
  data() {
    return {
      reasons: [],
      headers: {
        messages: [
          {
            text: "Preview",
            value: "reason"
          },
          {
            text: "Return to inventory",
            value: "inventory"
          },
          {
            text: "Active",
            value: "active"
          },
          {
            text: "Action",
            value: ""
          }
        ]
      },
      loading: false,
      newMessage: {},
      posting: false,
      search: {
        messages: ""
      },
      showMessageDialog: false,
    };
  },
  methods: {
    closeMessageDialog() {
      this.resetForm();
      this.showMessageDialog = false;
    },
      showReasonInfo(msg) {
          this.resetForm();
          this.newMessage.body = msg.reason;
          this.newMessage.id = msg.id;
          this.newMessage.active = msg.active;
          this.newMessage.inventory = msg.inventory;
          this.showMessageDialog = true;
      },
    async getReasons() {
      this.loading = true;
        let url = `${this.apiBaseUrl}/products/get-reasons`;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => {
          this.reasons = data;
        });
      } catch (error) {
        const msg = "We couldn't fetch your reasons. Please try again.";
        this.loading = false;
        this.handleError(error, msg);
      }
    },
    previewMessage(msg) {
      const len = msg.length;
      const limit = 30;
      if (len <= limit) return msg;
      return msg.substring(0, limit) + "...";
    },
    resetForm() {
      this.newMessage = {};
      this.$refs.reasons_form.reset();
    },
    async saveMessage() {
      if (!this.$refs.reasons_form.validate()) return;
      const newMessage = Object.assign({}, this.newMessage);
      this.posting = true;
      try {
        const res = await this.$http.post(
            `${this.apiBaseUrl}/products/add-reason`,
          newMessage
        );
        this.posting = false;
          this.handleResponse(res, data => {
          this.closeMessageDialog();
          this.showSnackbar(res.data.message);
          this.getReasons();
          console.log(data);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error while adding this message. Please try again later."
        );
      }
    },
  },
  watch: {

  }
};
</script>
