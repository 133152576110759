<template>
  <div class="drawer-list">
    <br />
    <v-list dense>
      <div v-for="(route, i) in menus" :key="i">
        <v-list-tile :to="route.path" exact v-if="!route.children">
          <v-list-tile-action>
            <v-icon>{{ route.icon }}</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              {{ route.name }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-group :prepend-icon="route.icon" v-else>
          <v-list-tile slot="activator">
            <v-list-tile-title>{{ route.name }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile
            :to="item.path"
            exact
            v-for="item in route.children"
            :key="item.name"
          >
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-title>
              {{ item.name }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list-group>
      </div>
    </v-list>
  </div>
</template>

<script>
import routes from "../../routes";

export default {
  name: "DrawerMenus",
  data: () => ({
    routes: routes,
    menus: []
  }),
  mounted() {
    let menus = [];
      if (this.isSuperAdmin(this.authUser)) {
          menus = this.routes.filter(
              x => x.access == this.authUser.role
          );
      }
      else if (this.isBusinessAdmin(this.authUser)) {
          menus = this.routes.filter(
              x => x.meta && x.meta.includes("admin")
          );
    } else {
        const userMenus = this.authUser.assigned_menus;
        let temp = [];
        this.routes.forEach(item => {
            if (!item.access || userMenus.includes(item.access)) {
                temp.push(item);
            }
            if (item.children) {
                var list = item.children.filter(x => userMenus.includes(x.access));
                if (list.length > 0) {
                    item.children = list;
                    temp.push(item);
                }
            }
        });
        menus = temp;
      //menus = this.routes.filter(
      //  x => !x.access || userMenus.includes(x.access)
      //);
      }
    this.menus = menus.filter(x => x.showInNav);
  }
};
</script>

<style scoped></style>
