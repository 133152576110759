<template>
    <v-layout row wrap style="margin-top:20px">
        <v-flex sm3 md2 px-4>
            <br />
            Select a store
        </v-flex>
        <v-flex sm6 md4 px-4>
            <v-autocomplete :items="stores"
                            return-object
                            v-model="selectedStore"
                            item-text="name"
                            :loading="loading"
                            autocomplete
                            item-value="id"
                            label="Select Store"
                            @change="change"
                            single-line></v-autocomplete>
        </v-flex>

        <v-flex sm12 v-if="selectedStore">
            <br />
            <template>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-flex sm4>
                                <v-text-field v-model="search"
                                              append-icon="search"
                                              label="Search device"
                                              single-line
                                              hide-details
                                              clearable></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <br />
                <v-data-table :items="details"
                              :rows-per-page-items="[10]"
                              :search="search"
                              :headers="headers"
                              :loading="loading">
                    <template slot="no-data">
                        No devices.
                    </template>
                    <template slot="items" slot-scope="props">
                        <td>
                            <v-icon small>
                                devices
                            </v-icon>
                            &nbsp;
                            {{ props.item.device_name || "" }}
                        </td>
                        <td>
                            {{ props.item.device_code }}
                        </td>
                        <td>{{ props.item.complete_transaction }}</td>
                        <td>{{ props.item.incomplete_transaction }}</td>
                        <td>{{ props.item.store }}</td>
                    </template>
                </v-data-table>
            </template>

        </v-flex>
        <template v-else>
            No store selected
        </template>
    </v-layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    saveText() {
      const texts = [
        {
          active: "Saving",
          default: "Save"
        },
        {
          active: "Updating",
          default: "Update"
        }
      ];

      const index = this.postAction !== "update" ? 0 : 1;
      return this.posting ? texts[index].active : texts[index].default;
    }
  },
  created() {
    this.getStores();
  },
  data() {
    return {
      stores:[],
      selectedStore:{},
      details: [],
      headers: [
        {
          text: "Device name",
          value: "device_name"
        },
        {
          text: "Device code",
          value: "device_code"
          },
          {
              text: "Completed transactions",
              value: "complete_transaction"

          },
          {
              text: "Pending transactions",
              value: "incomplete_transaction"

          },
        {
          text: "Store",
          value: "store"
        }
      ],
      loading: false,
      postAction: "",
      //stores: this.$store.getters.stores,
      search: ""
    };
  },
  methods: {
      change(store) {
         this.getDevices();
    },
      async getStores() {
          this.loading = true;
          try {
              const res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/analysis/stores?businessId=${this.selectedBusiness.id}`);
              this.loading = false;
              this.handleResponse(res, data => {
                  this.stores = data;
              });
          } catch (error) {
              this.loading = false;
              this.handleError(error, "We couldn't fetch your stores");
          }
      },
    async getDevices() {
        let url = `${this.apiBaseUrl}/superAdmin/analysis/cashregisters-usage?storeId=${this.selectedStore.id}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.details = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "We couldn't load your cash registers");
      }
    },
    getStore(id) {
      return this.stores.find(x => x.id == id);
    },
  },
  watch: {
    selectedBusiness: {
      handler() {
         this.getStores();

      },
      deep: true
    }
  }
};
</script>

<style scoped>
.filtersss {
  background-color: #5a5a5a29;
  padding: 10px;
  border-radius: 4px;
}
</style>
