<template>
  <layout>
    <v-layout row wrap>
      <v-flex xs4 px-2 my-2 v-for="(i, key) in plans" :key="i.id">
        <v-card>
          <v-card-title
            class="text-xs-center"
            style="
              font-size: 30px;
              font-weight: bold;
              font-family: unset;
              display: flex;
              justify-content: space-between;
            "
          >
            <div>{{ i.name }}</div>
            <div
              v-if="subscribed.subscriptionPlanId === i.id"
              style="
                font-size: 12px;
                background-color: green;
                padding: 3px;
                color: white;
                border-radius: 5px;
                letter-spacing: 0.7px;
              "
            >
              Active-{{ subscribed.type }}ly <br />
              {{ dateFormat(subscribed.startDate) }} -
              {{ dateFormat(subscribed.endDate) }}
            </div>
          </v-card-title>
          <v-card-text>
            <v-list three-line>
              <v-list-tile>
                <v-list-tile-content>
                  <v-list-tile-sub-title>
                    <v-icon slot="activator" color="green">check</v-icon> &nbsp;
                    User(s) -
                    {{ i.userNumber == 0 ? "Unlimited" : i.userNumber }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <v-icon slot="activator" color="green">check</v-icon> &nbsp;
                    Product(s) -
                    {{ i.productNumber == 0 ? "Unlimited" : i.productNumber }}
                  </v-list-tile-sub-title>
                  <v-list-tile-sub-title>
                    <v-icon slot="activator" color="green">check</v-icon> &nbsp;
                    Store(s) -
                    {{ i.storeNumber == 0 ? "Unlimited" : i.storeNumber }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <v-divider></v-divider>
          </v-card-text>

          <v-card-actions style="justify-content: center" ref="items">
            <v-btn
              style="padding: 0 5px"
              outline
              color="cyan black--text"
              :data-key="i.id + 'month'"
              :loading="loading && indexClicked === i.id && type === 'month'"
              :disabled="
                subscribed.subscriptionPlanId === i.id &&
                subscribed.type == 'month'
              "
              @click="subscribe(i.id, 'month', key)"
              >&#8358;{{ moneyFormat(i.amountMonthly) }}/Month</v-btn
            >

            <v-btn
              style="padding: 0 5px"
              depressed
              color="cyan white--text"
              :data-key="i.id + 'year'"
              :loading="loading && indexClicked === i.id && type === 'year'"
              :disabled="
                subscribed.subscriptionPlanId === i.id &&
                subscribed.type == 'year'
              "
              @click="subscribe(i.id, 'year', key)"
              >&#8358;{{ moneyFormat(i.amountYearly) }}/Year</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <side-panel v-model="showSidePanel" :width="width">
      <downgrade-subscription
        @handleWidth="handleWidth"
        :plan="selectedPlan"
        :type="type"
        @closeSidePanel="closeSidePanel"
      ></downgrade-subscription>
    </side-panel>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import SidePanel from "@/components/shared/SidePanel";
import DowngradeSubscription from "@/components/partials/DowngradeSubscription";

export default {
  components: {
    Layout,
    SidePanel,
    DowngradeSubscription,
  },
  data() {
    return {
      plans: [],
      indexClicked: undefined,
      loading: false,
      showSidePanel: false,
      type: "",
      width: "50%",
    };
  },
  computed: {
    subscribed() {
      if (this.$store.state.subscription.isActive)
        return this.$store.state.subscription.latestSubscription;
      else return {};
    },
    selectedPlan() {
      return this.plans.filter((x) => x.id === this.indexClicked)[0];
    },
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    async getPlans() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/subscriptions/plans`
        );
        this.plans = res.data.data;
      } catch (error) {
        alert("An error occured");
      }
    },
    async subscribe(planId, type, index) {
      this.indexClicked = planId;
      this.type = type;
      //console.log(this.type, this.indexClicked);
      if (this.isDowngrade()) {
        this.showSidePanel = true;
        return;
      }
      this.loading = true;
      this.Buttons();
      //return;
      try {
        var payload = {};
        payload.planId = planId;
        payload.Type = type;
        const res = await this.$http.post(
          `${this.apiBaseUrl}/subscriptions/subscribe`,
          payload
        );
        localStorage.setItem(
          "ecr_reference",
          JSON.stringify({
            reference: res.data.reference,
            type: this.type,
            is_upgrade: true,
          })
        );
        window.location = res.data.data;
      } catch (error) {
        console.log(error);
        if (error.response.data) {
          this.showSnackbar(
            `${error.response.data.message}. Please try again`,
            {
              type: "error",
            }
          );
          window.location.reload(true);
        } else {
          alert("An error occured");
        }
      }
    },
    Buttons() {
      this.$refs["items"].forEach((el) => {
        for (var i = 0; i < el.children.length; i++) {
          var child = el.children[i];
          if (
            child.getAttribute("data-key") !=
            this.indexClicked + "" + this.type
          ) {
            child.setAttribute("disabled", true);
            child.setAttribute("class", "v-btn v-btn--disabled theme--light");
          }
        }
      });
    },
    isDowngrade() {
      var plan = this.plans.filter((x) => x.id === this.indexClicked)[0];

      if (this.$store.state.subscription.isActive) {
        if (this.indexClicked == this.subscribed.subscriptionPlan.id)
          return false;

        if (this.type == "month") {
          if (
            plan.amountMonthly > this.subscribed.subscriptionPlan.amountMonthly
          )
            return false;
          else return true;
        } else {
          if (plan.amountYearly > this.subscribed.subscriptionPlan.amountYearly)
            return false;
          else return true;
        }
      } else return false;
    },
    handleWidth(wide) {
      this.width = wide;
    },
    closeSidePanel() {
      this.width = "50%";
      this.showSidePanel = false;
    },
    //cancel() {
    //    console.log("this is the point");
    //    this.$emit("closed");
    //},
  },
};
</script>
