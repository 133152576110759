<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-data-table
        disable-initial-sort
        :items="goodsDepreciations"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No data.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.productName }}</td>
          <td>{{ props.item.productCode }}</td>
          <td>{{ props.item.quantity }}</td>
          <td>{{ props.item.warehouseName }}</td>
          <td>{{ fullDateFormat(props.item.returnDate) || "" }}</td>
          <td>
            <v-tooltip bottom>
              <v-icon slot="activator" @click="showInfo(props.item)"
                >info</v-icon
              >
              <span>View details</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>

    <!-- Item dialog -->
    <v-dialog v-model="showItemDialog" max-width="400px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <h3>View Details</h3>
        </v-card-title>
        <v-card-text>
          <strong>Reason:</strong> <br />
          {{ selectedItem.reason }}

          <br />

          <strong>Deprecation type:</strong> <br />
          {{ selectedItem.depreciationType || "None" }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Item dialog -->
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
      },
      headers: [
        {
          text: "Product name",
          value: "productName"
        },
        {
          text: "Product Code",
          value: "productCode"
        },
        {
          text: "Quantity",
          value: "quantity"
        },
        {
          text: "Destination",
          value: "destination"
        },
        {
          text: "Date",
          value: "returnDate"
        },
        {
          text: "",
          value: ""
        }
      ],
      loading: false,
      selectedItem: {},
      goodsDepreciations: [],
      search: "",
      showItemDialog: false
    };
  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let url = `${
        this.apiBaseUrl
      }/storeproducts/depreciation?storeId=${storeId}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.goodsDepreciations = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    },
    showInfo(goodsDepreciation) {
      if (goodsDepreciation) {
        this.selectedItem = goodsDepreciation;
      }
      this.showItemDialog = true;
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
