import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import NotFound from "@/views/NotFound.vue";
// import Products from "@/views/stores/Products.vue";
import Partners from "@/views/partners/Index.vue";
import CustomerLedger from "@/views/partners/CustomerLedger";
import WalletLog from "@/views/partners/WalletLog"
import Messages from "@/views/Messages.vue";
import Register from "@/views/Register.vue";
import Warehouse from "@/views/Warehouse.vue";
import Return from "@/views/Return.vue";
import Logging from "@/views/Logging.vue";
import Reports from "@/views/Reports.vue";
import DiscountActions from "@/views/stores/DiscountActions.vue";
import AuthRoutes from "@/router/auth";

import SetupRoutes from "@/router/setup";
import SuperAdmin from "@/router/superAdmin";
import StoreRoutes from "@/router/stores";
import UsersRoutes from "@/router/users";
import SettingsRoutes from "@/router/settings";
import WarehouseRoutes from "@/router/warehouse";
import ReturnRoutes from "@/router/return";
import ReportRoutes from "@/router/reports";
import ProfileRoutes from "@/router/profile";
import SubscriptionRoutes from "@/router/subscription";
import Invoice from "@/views/reports/Invoice";
import OrderInvoice from "@/views/warehouse/customerOrder/OrderInvoice";
import ActivityLog from "@/views/reports/ActivityLog";
import SupplierLedger from "@/views/warehouse/Supplier/Ledger";
import Privacy from "@/views/Privacy.vue";

export default [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
    icon: "dashboard",
    showInNav: true,
    meta:["admin"]
  },
  ...SetupRoutes,
  ...UsersRoutes,
  {
    path: "/partners",
    name: "Customers",
    component: Partners,
    icon: "contact_mail",
    access: "customers",
    showInNav: true,
    meta:["admin"]
  },
  {
    path: "/partners/view/:id",
    name: "Customer Ledger",
    component: CustomerLedger,
    access: "customers",
    showInNav: false,
    meta:["admin"]
  },
  {
    path: "/partners/view-wallet-log/:id/:customerName",
    name: "Wallet Log",
    component: WalletLog,
    access: "wallet_log",
    showInNav: false,
    meta:["admin"]
  },
  {
    path: "/discounts_actions",
    name: "Discounts",
    component: DiscountActions,
    icon: "styles",
    access: "discounts",
    showInNav: true,
    meta:["admin"]
  },
  {
    path: "/about",
    name: "About",
    component: About
  },
  {
    path: "/register",
    name: "Register",
    component: Register
  },
  ...AuthRoutes,
  ...SettingsRoutes,
  ...StoreRoutes,
  {
    path: "/messages",
    name: "Messages",
    component: Messages,
    icon: "message",
    access: "messages",
    showInNav: true,
    meta:["admin"]
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: Warehouse,
    icon: "view_module",
    access: "warehouse",
    showInNav: true,
    children: [...WarehouseRoutes],
    meta:["admin"]
  },
  {
    path: "/returns",
    name: "Return",
    component: Return,
    icon: "device_hub",
    access: "returns",
    showInNav: true,
    children: [...ReturnRoutes],
    meta:["admin"]
  },
  {
    name: "Supplier Ledger",
    path: "/warehouse/supplier/:id",
    component: SupplierLedger,
    showInNav: false,
    meta:["admin"]
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports,
    icon: "pie_chart",
    access: "reports",
    showInNav: true,
    children: [...ReportRoutes],
    meta:["admin"]
  },
  {
    name: "Invoice",
    path: "/reports/invoice/:id",
    component: Invoice,
    showInNav: false,
    meta:["admin"],
    showInNav: false
    },
    {
    name: "Order Invoice",
    path: "/warehouse/customer-order/:order_number",
    component: OrderInvoice,
    showInNav: false
  },
  {
    name: "Activity Logs",
    path: "/reports/activity",
    component: ActivityLog,
    showInNav: false,
    meta:["admin","administrator"]
    },
  {
    name: "Logging",
    path: "/logs",
    component: Logging
  },
   {
    name: "Privacy",
    path: "/privacy",
    component: Privacy,
   },
  ...ProfileRoutes,
  ...SubscriptionRoutes,
  ...SuperAdmin,
    {
        path: "*",
        component:NotFound
    }
];
