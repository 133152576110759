<template>
    <v-card v-if="!loading">
        <v-card-title class="pink darken-2 white--text">
            <h4>
                Wallet Businesses
            </h4>
            <v-radio-group v-model="radios" style="display: flex; justify-content: end; margin-top: 4px; margin-bottom: -15px;"
                           row>
                <v-radio label="Volume" color="white" class="white--text" style="font-weight:bold"
                         value="byVolume"></v-radio>
                <v-radio label="Count" color="white" class="white--text" style="font-weight:bold"
                         value="byCount"></v-radio>
            </v-radio-group>
        </v-card-title>
        <v-card-text class="text-sm-right p-8">
            <div v-if="items.length > 0">
                <h3 v-for="item in items">{{item}}</h3>
            </div>
            <h3 v-else>...</h3>
        </v-card-text>
    </v-card>
    <skeleton-loader :color="'pink darken-2 white--text'" :title="'Wallet Businesses'" :height="'10px'" :count="5" v-else></skeleton-loader>
</template>

<script>
export default {
  name: "HighestBusiness",
  data() {
    return {
      count: "-",
      radios: 'byVolume',
      items:[]

    };
  },
  props: {
      "names": Object,
      'loading': Boolean
  },
    watch: {
        names: {
        handler: function (val) {
            this.items = val[this.radios];
        },
        deep: true
        },
        radios: {
            handler() {
                this.items = this.names[this.radios];
            },
            deep: true
        }
        },
  mounted() {
    //this.getData();
  },
  methods: {
  }
};
</script>

<style scoped></style>
