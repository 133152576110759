<template>
    <layout>
        <v-layout row wrap>
            <v-flex sm3></v-flex>
            <v-flex xs12 sm8>
                <v-layout row wrap>
                    <v-flex sm6 px-1>
                        <v-text-field required
                                      v-model="receiptId"
                                      :validate-on-blur="false"
                                      label="Receipt No"
                                      :loading="loading"
                                      :rules="formRules.required"
                                      name="receipt_no"></v-text-field>
                    </v-flex>
                    <v-flex sm3 py-1>
                        <v-btn block color="primary" @click="getReceipt(receiptId)">
                            <v-icon>search</v-icon>&nbsp; search
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex sm3></v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row wrap v-if="receipts.length > 0">
            <v-flex sm3>
                <v-autocomplete :items="receipts"
                                return-object
                                :value="receipt"
                                item-text="number"
                                :loading="loading"
                                autocomplete
                                item-value="id"
                                label="Select Receipt"
                                single-line
                                @change="changeReceipt"></v-autocomplete>
            </v-flex>
        </v-layout>

        <v-layout xs12 mt-3 v-if="receipt.seller_name">
            <v-flex xs12 sm12 style="display:flex; justify-content:space-evenly; font-size:16px;font-weight:bold">
                <p>Customer Name:&nbsp;<span style="color:red">{{customer.name}}</span></p>
                <p>Seller Name:&nbsp;<span style="color:red">{{receipt.seller_name}}</span></p>
                <p>Payment Method:&nbsp;<span style="color:red">{{receipt.payment_method_name}}</span></p>
                <p>Date:&nbsp; <span style="color:red">{{ justDate(receipt.date_time) || "" }}</span></p>
            </v-flex>
        </v-layout>

        <v-layout>
            <v-flex xs12 sm12 px-1 :class="size" v-if="returnableGoods.length > 0">
                <v-card>
                    <v-card-title primary-title class="cyan darken-3 white--text">
                        <h3>Receipt products</h3>
                    </v-card-title>
                    <template>
                        <v-data-table disable-initial-sort
                                      :items="returnableGoods"
                                      :rows-per-page-items="[25]"
                                      :search="search"
                                      :headers="headers"
                                      :loading="loading">
                            <template slot="no-data">
                                No data.
                            </template>
                            <template slot="items" slot-scope="props">
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.quantity }}</td>
                                <td>{{ props.item.sale_price }}</td>
                                <td v-if="checkreturnableGoods(props.item.product_id)">
                                    <v-tooltip bottom>
                                        <v-icon slot="activator" @click="moveProduct(props.item)">compare_arrows</v-icon>
                                        <span>Return product</span>
                                    </v-tooltip>
                                </td>
                                <td v-else>
                                    <span style="color:red;font-weight:bold">Product is not returnable</span>
                                </td>
                            </template>
                        </v-data-table>
                    </template>
                </v-card>
            </v-flex>
            <v-flex xs12 sm12 :class="size1" v-if="selectedItem.length > 0">
                <v-card>
                    <v-card-title primary-title class="cyan darken-4 white--text">
                        <h3>Products to return</h3>
                    </v-card-title>
                    <template>
                        <v-data-table disable-initial-sort
                                      :items="selectedItem"
                                      :rows-per-page-items="[25]"
                                      :search="search"
                                      :headers="headersR">
                            <template slot="no-data">
                                No data.
                            </template>
                            <template slot="items" slot-scope="props">
                                <tr :key="props.item.product_id">
                                    <td>
                                        <v-tooltip bottom>
                                            <v-icon slot="activator" class="red--text" @click="removeProduct(props.item,2)">clear</v-icon>
                                            <span>Cancel return</span>
                                        </v-tooltip>
                                    </td>
                                    <td>{{ props.item.name }}</td>
                                    <td>
                                        <div style="display:flex;justify-content:space-evenly">
                                            <v-btn fab dark small color="red" @click="removeQuantity(props.item)">
                                                <v-icon dark>remove</v-icon>
                                            </v-btn>
                                            <v-text-field required disabled
                                                          v-model="selectedItemList[props.item.product_id]['quantity']"
                                                          type="number"
                                                          :validate-on-blur="true"
                                                          label="Quantity"
                                                          min="1"
                                                          :max="max(props.item)"
                                                          name="quantity"></v-text-field>
                                            <v-btn fab dark small color="green" @click="addQuantity(props.item)">
                                                <v-icon dark>add</v-icon>
                                            </v-btn>
                                        </div>
                                        <!--@input="change(props.item)"-->
                                    </td>
                                    <td>
                                        <v-select required
                                                  v-model="selectedItemList[props.item.product_id]['reason']"
                                                  :items="ReasonList"
                                                  :loading="loadinglist"
                                                  item-text="reason"
                                                  item-value="reason"
                                                  :validate-on-blur="true"
                                                  label="Reason"
                                                  :rules="formRules.required"
                                                  name="reasons_"></v-select>
                                    </td>

                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                    <v-card-actions>
                        <v-layout row wrap>
                            <v-flex px-2>
                                <p flat color="orange">Total Amount: <span style="font-weight: bold; font-size: 16px;">&#8358; {{total}}</span></p>
                            </v-flex>
                            <v-flex px-2>
                                Select Refund Method
                                <v-radio-group v-model="radioGroup">
                                    <v-radio v-for="n in count"
                                             :key="n"
                                             :label="radioButton(n)"
                                             :value="n"></v-radio>
                                </v-radio-group>
                            </v-flex>
                            <v-flex px-2>
                                <v-btn flat block color="red" class="bold" @click="checkReasons">Refund</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-dialog v-model="alertDialog" max-width="290" persistent>
                <v-card>
                    <v-card-text>
                        This action is irreversible. Do you want to continue the process?
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>

                        <v-btn flat="flat" color="teal" @click="returnProduct" :disabled="loading1">
                            {{saveText}}
                        </v-btn>
                        <v-btn flat="flat" color="red" @click="alertDialog = false" :disabled="loading1">
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-layout>
        <v-divider></v-divider>
        <v-layout v-if="receipt.seller_name" mt-2>
            <v-flex sm10></v-flex>
            <v-flex xs12 sm2>
                <v-btn outline color="red" @click="clearData(true)">
                    Cancel
                </v-btn>
            </v-flex>
        </v-layout>
    </layout>
</template>

<script>
import Layout from "@/components/Layout";
import lodash from "lodash";

export default {
  components: {
    Layout
  },
  mounted() {
      this.getReasons();
      this.getProductOptions();
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Quantity",
          value: "quantity"
        },
        {
          text: "Amount",
          value: "sale_price"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      headersR: [
        {
          text: "",
          value: ""
        },
        {
          text: "Name",
          value: "product.name"
        },
        {
          text: "Quantity",
          value: "product.code"
        },
        {
          text: "Reasons",
          value: "product.code"
        }
      ],
      loading: false,
      loading1: false,
      loadinglist: false,
      alertDialog: false,
      receiptId:"",
      numofGoods:16,
      receipts: [],
      receipt: {},
      size: "md12 lg12",
      size1: "md7 lg7",
      selectedItem: [],
      selectedItemList: [],
      returnableGoods: [],
      returnableGoodsTemp: [],
      returnableGoodsData: [],
      ReasonList: [],
      customer: {},
      search: "",
      type: 0,
      total: 0,
      showItemDialog: false,
      radioGroup: 1,
      action: "save",
    };
  },
  computed:{
    saveText() {
      const texts = [
        {
          active: "Refunding..",
          default: "Refund"
        },
        {
          active: "Updating..",
          default: "Update"
        }
      ];

      const index = this.action == "update" ? 1 : 0;
      return this.loading1 ? texts[index].active : texts[index].default;
    },
    count() {
        if (this.receipt.partner_id) {
            return 2;
        } else return 1;
    }
  },
  methods: {
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
    async getProductOptions() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributeoptions`
        );
        await this.handleResponse(res, data => {
          this.productOptions = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We couldn't fetch your product options."
        );
      }
    },
      removeQuantity(item) {
          let product = this.selectedItemList[item.product_id];
          if (product.quantity == 1 || product.quantity == 0) return;
          if (product.quantity != 1 && product.quantity != 0) {
              //console.log("helo", this.selectedItemList);
              this.selectedItemList[item.product_id]['quantity'] -= 1;
              this.$nextTick(() => {
                  this.$set(this.selectedItemList, item.product_id, this.selectedItemList[item.product_id]);
                  this.totalAmount();
              });
              //console.log("this.selectedItemList", this.selectedItemList);
          }

      },
      addQuantity(item) {

          let temp = localStorage.getItem("returnableGoodsTemp");
          temp = JSON.parse(temp);

          const selectedProductMain = temp
              .find(product => product.product_id === item.product_id);
          let product = this.selectedItemList[item.product_id];
          if (product.quantity == selectedProductMain.quantity) return;
          if (product.quantity < selectedProductMain.quantity) {
              this.selectedItemList[item.product_id]['quantity'] = this.selectedItemList[item.product_id]['quantity'] + 1;
              this.$nextTick(() => {
                  this.$set(this.selectedItemList, item.product_id, this.selectedItemList[item.product_id]);
                  this.totalAmount();
              });
              //console.log("this.selectedItemList", this.selectedItemList);
          }

      },
      checkReasons() {
          var result = this.returnSelectedItems();
          if (result == "reasons") {
              this.showSnackbar("Please select reason(s) for return", {
                  type: "error"
              });
          }
          else if (result == "quantity") {
              this.showSnackbar("Quantities can not be higher than sold quantity", {
                  type: "error"
              });
          }else
              this.alertDialog = true
      },
      change(item) {
          let value = window.event.target.value;
          let product = this.selectedItemList[item.product_id];

          const selectedProductIndex = this.selectedItem
              .findIndex(product => product.product_id === item.product_id);

          //console.log(selectedProductIndex);

          let main = this.selectedItem[selectedProductIndex];

          //console.log(main);
          if (parseFloat(product.quantity) == 0 || product.quantity == "" || value == null || value == "" || value == 0) {
              
              this.showSnackbar(`Quantity can not be lower than one(1)`, {
                  type: "error"
              });
              this.$nextTick(() => {
                 this.selectedItemList[item.product_id]['quantity'] = 1;
                 this.totalAmount();
              })
          }
          else if (parseFloat(value) > parseFloat(main.quantity)) {
              var q = main.quantity;
              //console.log(this.selectedItemList);
              //console.log("Product quantity before-->", main.quantity);
              //console.log("Product quantity after-->",this.selectedItemList, item.product_id);
              this.showSnackbar(`Quantity can not be higher than ${q}`, {
                    type: "error"
              });
              this.$nextTick(() => {
                  //this.$set(this.selectedItemList[item.product_id], "quantity", main.quantity);
                  //this.$set(this.selectedItemList, item.product_id, Object.assign({}, { name: product.name, quantity: product.quantity, reason: product.reason, index: product.index, price: product.price }));
                  //console.log(this.selectedItemList);
                  //this.selectedItemList[item.product_id] = product;
                  this.selectedItemList[item.product_id]['quantity'] = 1;
                  this.totalAmount();
              });
          }
          else if (parseFloat(value) >= 1 && parseFloat(product.quantity) <= parseFloat(main.quantity)) {
                  this.$nextTick(() => {
                      if (parseFloat(product.quantity) > 0)
                          this.totalAmount();
                  })
          }
          else{
              if (parseFloat(value) < 1) {
                  this.showSnackbar(`Quantity can not be lower than one(1)`, {
                      type: "error"
                  });
                  this.$nextTick(() => {
                      this.selectedItemList[item.product_id]['quantity'] = 1;
                      this.totalAmount();
                  });
              }
              else if (parseFloat(value) > parseFloat(main.quantity)) {

                  this.showSnackbar(`Quantity can not be higher than ${main.quantity}`, {
                      type: "error"
                  });
                  this.$nextTick(() => {
                      this.selectedItemList[item.product_id]['quantity'] = main.quantity;
                      this.totalAmount();
                  });
              }
          }

      },
      max(item) {
          const selectedProductIndex = this.selectedItem
              .findIndex(product => product.product_id === item.product_id);
          let main = this.selectedItem[selectedProductIndex];
          return main.quantity;
      },
      totalAmount() {
          this.total = 0;
          if (this.selectedItemList.length > 0) {
              this.selectedItemList.forEach((data, index) => {
                  if (this.selectedItemList[index] !== undefined)
                       this.total += data.price * data.quantity;                      
              });
          }
      },
      radioButton(type) {

          if (type == 2) return "Wallet";
          else return "Cash";
      },
      checkreturnableGoods(productId) {
          var isReturnable = this.returnableGoodsData.find(product => product.productId == productId);
          if (isReturnable) {
              return true;
          }
          return false;
      },
      async changeReceipt(receipt) {
          this.receipt = receipt;
          if (this.receipt != null) {
                this.getreturnableGoodsData(this.receipt.store_id);
                localStorage.setItem("returnableGoodsTemp", JSON.stringify(this.receipt.store_products));
                this.returnableGoods = this.receipt.store_products;
                if (!this.receipt.partner_id) {
                    this.customer.name = "One-Time Customer";
            }else 
                this.getCustomerData(this.receipt.partner_id);
          }

      },
      async getReceipt(ReceiptId) {
          if (this.receiptId == "") {

              this.showSnackbar(`Receipt number can not be empty`, {
                  type: "error"
              });
              return;
          }
          this.clearData(false);
          let url = `${this.apiBaseUrl}/receipts/get-receipt?ReceiptId=${ReceiptId}`;
          this.loading = true;
          try {
              const res = await this.$http.get(url);
              this.loading = false;
              this.handleResponse(res, data => {

                  if (this.receipts != null || this.receipts.length > 0) {
                      this.receipts = data;
                  } else {

                      this.showSnackbar(`Receipt with receipt-${ReceiptId} can not be found`, {
                          type: "error"
                      });
                  }
              });
          } catch (error) {
              this.loading = false;
              this.handleError(error, "Error loading data");
          }
      },
      removeProduct(item, type) {

          if (type == 1) {

              const selectedProductIndex = this.returnableGoods
                  .findIndex(product => product.product_id === item)

              if (selectedProductIndex > -1) {
                  this.returnableGoods.splice(selectedProductIndex, 1);
                  var temp = [];
                  for (var i = 0; i < this.returnableGoods.length; i++) {
                      if (this.returnableGoods[i] !== undefined)
                        temp.push(this.returnableGoods[i]);
                  }
                  this.returnableGoods = [];
                  this.returnableGoods = temp;
              }

              if (this.returnableGoods.length == 0)
                  this.size1 = "md12 lg12";

          } else {
              //console.log(item, this.selectedItem);
              const selectedProductIndex = this.selectedItem
                  .findIndex(product => parseInt(product.product_id) === parseInt(item.product_id));
              //console.log("here");
              if (selectedProductIndex > -1) {                  
                  this.$nextTick(() => {
                      this.$delete(this.selectedItem, selectedProductIndex);
                  });
              }

              const productIndex = this.selectedItemList.indexOf(this.selectedItemList[item.product_id]);

              if (productIndex > -1) {
                  this.selectedItemList.splice(productIndex, 1);
              }

              let t = localStorage.getItem("returnableGoodsTemp");
              t = JSON.parse(t);

              if (this.selectedItem.length === 0) {
                  this.size = "md12 lg12";
                  this.selectedItemList = [];
              }
              //console.log("here3");
              this.returnableGoods.unshift(item);

              if (t.length == this.returnableGoods.length) {
                  this.size = "md12 lg12";
                  this.selectedItemList = [];
              }

          }
          this.totalAmount();

      },
      moveProduct(product) {
          //console.log(product);
          this.size = "md5 lg5";
          this.selectedItemList[parseInt(product.product_id)] = { name: product.name, quantity: product.quantity, reason: "", index: product.product_id, price: product.sale_price };
          //this.selectedItemList[parseInt(product.product_id)] = Object.assign({}, this.selectedItemList[parseInt(product.product_id)], { name: product.name, quantity: product.quantity, reason: "", index: product.product_id, price: product.sale_price })
          this.selectedItem.push(product);
          this.removeProduct(product.product_id, 1);
      },
      returnSelectedItems() {
          let payload = [];
          let payloadProductReason = [];
          let count = 0;
          let quantitycheck = 0;

          this.selectedItem.forEach((item, index) => {

              let selectedProduct = this.selectedItemList[parseInt(item.product_id)];
              let temp = localStorage.getItem("returnableGoodsTemp");
              temp = JSON.parse(temp);
              const selectedProductMain = temp
                  .find(product => product.product_id === item.product_id);
              //console.log(selectedProductMain);

              if (selectedProduct.reason == "") count += 1;
              else if (parseInt(selectedProduct.quantity) > parseInt(selectedProductMain.quantity)) quantitycheck += 1;
              else {

                  payload.push(selectedProduct);
                  var reason = this.ReasonList.find(r => r.reason == selectedProduct.reason);
                  payloadProductReason.push({ productId: selectedProduct.index, isinventory: reason.inventory });
              }

          });
          //console.log("payload", payload, this.$refs);
          if (count > 0)
              return "reasons";
          else if (quantitycheck > 0) return "quantity";
          else return [payload, payloadProductReason];
      },
      async returnProduct() {
          var payload = {};
          var result = this.returnSelectedItems();
          payload.selectedItems = result[0];
          payload.selectedProductReasons = result[1];
          if (payload.selectedItems == "reasons") {
              this.showSnackbar("Please select reason(s) for return", {
                  type: "error"
              });
              return;
          }
          else if (payload.selectedItems == "quantity") {
                  this.showSnackbar("Quantities can not be higher than sold quantity", {
                      type: "error"
                  });
                  return;
              }
          payload.returnType = this.radioGroup == 2 ? "Wallet" : "Cash";

          var businessStores = JSON.parse(window.localStorage.ecr_business).stores;
          var id = businessStores.findIndex(store => store.id === this.receipt.store_id);
          payload.storeName = id > -1 ? businessStores[id].name : "STORE";
          payload.total = this.total;
          payload.receiptId = this.receipt.number;
          payload.customerId = this.customer.id ? this.customer.id : 0;
          payload.customerName = this.customer.name;
          payload.seller = this.receipt.seller_name;
          payload.paymentMethod = this.receipt.payment_method_name;
          payload.storeId = this.receipt.store_id;
          payload.userId = this.$store.getters.userData.id;
          payload.initiator = this.$store.getters.userData.full_name;
          const data = Object.assign({}, payload);
          this.loading1 = true;
          let url = `${this.apiBaseUrl}/receipts/return-products`;
          try {
              const res = await this.$http.post(url, payload);
              this.showSnackbar(res.data.message);
              this.loading1 = false;

              var num = this.receipts.indexOf(this.receipt);
              if (num > -1) {

                  if (this.receipt.store_products.length == 0) {
                      this.receipts.splice(num, 1);
                  }

              }
              this.receipt = Object.assign({}, {});
              
          } catch (error) {
              const msg = "We couldn't complete the return process. Please try again.";
              this.loading1 = false;
              this.handleError(error, msg);
          }
          this.clearData(false);

          //console.log(data);
      },
      clearData(flag = true) {
          this.alertDialog = false;
          this.selectedItems = [];
          this.selectedItem = [];
          this.receipt = {};
          this.selectedItemList = [];
          this.returnableGoods = [];
          this.size = "md12 lg12";
          this.size1 = "md7 lg7";

          if (flag == true)
              this.receiptId = "";
      },
      async getReasons() {
          this.loadinglist = true;
          let url = `${this.apiBaseUrl}/products/get-reasons`;
          try {
              const res = await this.$http.get(url);
              this.loadinglist = false;
              this.handleResponse(res, data => {
                  this.ReasonList = data;
              });
          } catch (error) {
              const msg = "We couldn't fetch your reasons. Please try again.";
              this.loadinglist = false;
              this.handleError(error, msg);
          }
      },
      async getreturnableGoodsData(storeId) {
          let url = `${this.apiBaseUrl
              }/storeproducts/returnable?storeId=${storeId}`;
          this.loading = true;
          try {
              const res = await this.$http.get(url);
              this.loading = false;
              this.handleResponse(res, data => (this.returnableGoodsData = data));
          } catch (error) {
              this.loading = false;
              this.handleError(error, "Error loading data");
          }
      },
      async getCustomerData(customerId) {
          this.loading = true;
          try {
              const { data } = await this.$http.get(`${this.apiBaseUrl}/partners/${customerId}`);
              this.customer = data.data;
          } catch (error) {
              this.handleError(error, "Error fetching cutomer details");
          }
          this.loading = false;
      },
  },
  watch: {
  }
};
</script>
<style scoped>
    .v-btn--floating.v-btn--small{
        height:30px;
        width:30px;
    }
</style>
