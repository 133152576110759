<template>

    <v-layout row wrap style="margin-top:20px">
        <v-flex sm3 md2 px-4>
            <br />
            Select a store
        </v-flex>
        <v-flex sm6 md4 px-4>
            <v-autocomplete :items="stores"
                            return-object
                            v-model="selectedStore"
                            item-text="name"
                            :loading="loading"
                            autocomplete
                            item-value="id"
                            label="Select Store"
                            @change="change"
                            single-line></v-autocomplete>
        </v-flex>
        <v-flex sm12 v-if="selected.length">
            <v-btn flat @click="showConfirmDialog">
                <span>Delete All</span>&nbsp;
                <v-icon class="red--text">delete</v-icon>
            </v-btn>
        </v-flex>
        <v-flex sm12 v-if="selectedStore">
            <template>

                <v-data-table v-model="selected"
                              disable-initial-sort
                              :items="receipts"
                              :rows-per-page-items="[50]"
                              select-all
                              :search="search"
                              item-key="id"
                              :headers="headers"
                              :loading="loading">
                    <template v-slot:headers="props">
                        <tr>
                            <th>
                                <v-checkbox :input-value="props.all"
                                            :indeterminate="props.indeterminate"
                                            primary
                                            select-all
                                            hide-details
                                            @click.stop="toggleAll"></v-checkbox>
                            </th>
                            <th v-for="header in props.headers"
                                :key="header.text">
                                {{ header.text }}
                            </th>
                        </tr>
                    </template>
                    <template slot="no-data">
                        No Transactions.
                    </template>
                    <template slot="items" slot-scope="props">
                        <tr :active="props.selected" @click="props.selected = !props.selected">
                            <td>
                                <v-checkbox :input-value="props.selected"
                                            primary
                                            hide-details></v-checkbox>
                            </td>
                            <td>{{ props.item.number }}</td>
                            <td class="text-xs-right">
                                {{ moneyFormat(props.item.amount_tendered) }}
                            </td>
                            <td class="text-xs-right">
                                {{ moneyFormat(props.item.transaction_amount) }}
                            </td>
                            <td>{{ props.item.seller_name }}</td>
                            <td>{{ justDate(props.item.date_time) || "" }}</td>
                            <td>{{ justTime(props.item.date_time) || "" }}</td>
                            <td class="text-xs-right">
                                {{
                                  props.item["store_products"]
                                    ? props.item.store_products.length
                                    : 0
                                }}
                            </td>
                            <td>
                                <v-tooltip bottom>
                                    <v-icon slot="activator" @click="showInfo(props.item)">info</v-icon>
                                    <span>View details</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>

            <!-- Item dialog -->
            <v-dialog v-model="showItemDialog" max-width="400px" persistent>
                <v-card flat>
                    <v-card-title primary-title>
                        <v-flex xs12 sm6 px-2>
                            <h3>Products</h3>
                        </v-flex>
                        <v-flex xs12 sm6 px-2 text-xs-right v-if="selectedItem.amount_tendered < 1">
                            <v-btn small color="blue" style="color: white" @click="$router.push(`invoice/${selectedItem.id}`)">
                                Generate Invoice
                            </v-btn>
                        </v-flex>
                    </v-card-title>
                    <template>
                        <v-data-table :headers="productHeaders"
                                      :items="selectedItem.store_products"
                                      hide-actions
                                      class="elevation-1">
                            <template slot="items" slot-scope="props">
                                <td>{{ props.item.name }}</td>
                                <td class="text-xs-right">{{ props.item.quantity }}</td>
                                <td class="text-xs-right">{{ moneyFormat(props.item.price) }}</td>
                                <td>
                                    <!-- <v-tooltip bottom>
                              <v-icon slot="activator" v-if="props.item.discounts" @click="showDiscountInfo(props.item)">info</v-icon>
                              <span>View discounts</span>
                            </v-tooltip> -->
                                </td>
                            </template>
                        </v-data-table>
                    </template>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Item dialog -->
            <!-- Discount dialog -->
            <v-dialog v-model="showDiscountDialog" max-width="400px" persistent>
                <v-card flat>
                    <v-card-title primary-title>
                        <h3>Discounts</h3>
                    </v-card-title>

                    <v-data-table :headers="discountHeaders"
                                  :items="selectedProduct.discounts"
                                  hide-actions
                                  class="elevation-1">
                        <template slot="items" slot-scope="props">
                            <td>{{ props.item.isRate }}</td>
                            <td class="text-xs-right">{{ moneyFormat(props.item.amount) }}</td>
                            <td>{{ props.item.transactionNumber }}</td>
                        </template>
                    </v-data-table>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn flat @click.native="showDiscountDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- Discount dialog -->
            <!--Delete dialog-->

                <v-dialog v-model="confirmDialog" max-width="500px" persistent>
                    <v-card>
                        <v-card-title primary-title class="headline grey lighten-2">
                            <h6>Delete Details</h6>
                        </v-card-title>
                        <v-divider></v-divider>

                        <v-card-text>
                            Are you sure you want to delete {{selected.length}} receipt(s) ?
                            <v-layout>
                                <v-spacer></v-spacer>
                                <v-btn @click="closeDialog" flat :disabled="loading">Close</v-btn>
                                <v-btn @click="DeleteReceipts" flat color="primary" :disabled="loading">Okay</v-btn>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            <!--Delete dialog-->
        </v-flex>
        <div v-else>No store selected</div>
    </v-layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    //this.getData();
    this.getStores();
  },
  data() {
      return {
      selected: [],
      payload: {},
      selectedStore:{},
      stores:[],
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
      },
      productHeaders: [
        {
          text: "Name",
          value: "id"
        },
        {
          text: "Quantity",
          value: "quantity",
          align: "right"
        },
        {
          text: "Price",
          value: "price",
          align: "right"
        }
      ],
      discountHeaders: [
        {
          text: "Is Rate?",
          value: "isRate"
        },
        {
          text: "Amount",
          value: "amount",
          align: "right"
        },
        {
          text: "Transaction Number",
          value: "transactionNumber"
        }
      ],
      headers: [
        {
          text: "Receipt number",
          value: "number"
        },
        {
          text: "Amount tendered",
          value: "amount_tendered",
          align: "right"
        },
        {
          text: "Total Amount",
          value: "transaction_amount",
          align: "right"
        },
        {
          text: "Seller",
          value: "seller_name"
        },
        {
          text: "Date",
          value: "date_time"
        },
        {
          text: "Time",
          value: "date_time"
        },
        {
          text: "Products",
          value: "products",
          align: "right"
        },
        {
          text: "",
          value: ""
        }
      ],
      loading: false,
      selectedItem: {},
      selectedProduct: {},
      receipts: [],
      search: "",
      showItemDialog: false,
      confirmDialog: false,
      showDiscountDialog: false
    };
  },
  methods: {
    change(store) {
      this.getData();
      },
      toggleAll() {
          if (this.selected.length) this.selected = []
          else this.selected = this.receipts.slice();
      },
    async getData() {
          let storeId = this.selectedStore.id;
          if (storeId === 0) {
            return;
          }
          let url = `${this.apiBaseUrl}/superAdmin/analysis/get-receipts?storeId=${storeId}`;
          this.loading = true;
          try {
            const res = await this.$http.get(url);
            this.loading = false;
            this.handleResponse(res, data => (this.receipts = data));
          } catch (error) {
            this.loading = false;
            this.handleError(error, "Error loading data");
          }
      },
      async getStores() {
          this.loading = true;
          try {
              const res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/analysis/stores?businessId=${this.selectedBusiness.id}`);
              this.loading = false;
              this.handleResponse(res, data => {
                  this.stores = data;
              });
          } catch (error) {
              this.loading = false;
              this.handleError(error, "We couldn't fetch your stores");
          }
      },
    showInfo(receipt) {
      if (receipt) {
        this.selectedItem = receipt;
      }
      this.showItemDialog = true;
    },
    showDiscountInfo(product) {
      if (product) {
        this.selectedProduct = product;
      }
      this.showDiscountDialog = true;
    },

      closeCustomerDialog() {
          this.confirmDialog = false;
      },
      showConfirmDialog() {
          if (this.selected.length > 0)
              this.confirmDialog = true;
      },
      async DeleteReceipts() {

          let collection = [];
          this.selected.forEach(item => {
              collection.push(item.id);
          });

          this.payload.receipt_list = collection;
          //this.payload.businessId = this.selectedBusiness.id;
          //this.payload.storeId = this.selectedStore.id;

          const payload = Object.assign({}, this.payload);
          //console.log(payload);

          let url = `${this.apiBaseUrl}/superAdmin/analysis/delete-receipts`;
          this.loading = true;
          try {
              await this.$http.post(url, payload);
              this.loading = false;
              this.selected = [];
              this.closeCustomerDialog();
              this.showSnackbar(`Receipts deleted successfully`);
              this.getData();
          } catch (error) {
              this.loading = false;
              this.handleError(error, "Error Occured");
          }
      }
  },
  watch: {
  selectedBusiness: {
      handler() {
          this.getStores();
          this.stores = [];
          this.receipts = [];
      },
      deep: true
    }
  }
};
</script>
