export default [
  {
    name: "Delivery Slip",
    value: "Delivery Slip"
  },
  {
    name: "Purchase Order",
    value: "Purchase Order"
  },
  {
    name: "Invoice",
    value: "Invoice"
  },
  {
    name: "Internal Transfer",
    value: "Internal Transfer"
  }
];
