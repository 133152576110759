<template>
  <v-card outlined>
    <v-card-title class="grey darken-2 white--text">Other Settings</v-card-title>
    <v-flex xs12  class="pa-3">
    <v-card-text>
      <v-checkbox v-model="settings.value" label="Display Balance"></v-checkbox>
    </v-card-text>
    <v-layout>
      <v-spacer></v-spacer>
      <v-btn @click="updateDisplayBalance" :loading="loading">Update Display Settings</v-btn>
    </v-layout>
    </v-flex>
  </v-card>
</template>

<script>
import axios from "axios";
import Layout from "@/components/Layout";

export default {
 props: ['data'],
  components: {
    Layout
  },
  data() {
      return {
      displayBalance: true,
      settings: {Key: 'display_balance', value: false },
      ready: false,
      loading: false,
      value:"false"
    };
  },
  async mounted() {
    this.getUser();
  },
  methods: {
    async updateDisplayBalance() {
        this.loading = true;
        if (this.displayBalance == true) this.value = "true";

        let url = `${this.apiBaseUrl}/config/display-balance?BusinessId=${this.businessId}`;
        try {
            const res = await this.$http.post(url, this.settings);
            this.handleResponse(res, data => {
                this.settings = res.data.data;
                this.showSnackbar("setting updated successfully.");
            });
        } catch (error) {
            this.handleError(error, "Unable to update user settings");
        }
      this.loading = false;
    },
    async getUser() {
      let url = `${this.apiBaseUrl}/me`;
      try {
        const response = await axios.get(url);
        this.businessId = response.data.data.business_id;
          this.getValue();
        this.ready = true;
      } 
      catch (error) {
        this.handleError( error, "Error in getUser");
      }
    },
    async getValue() {
        let url = `${this.apiBaseUrl}/config/display-balance?BusinessId=${this.businessId}`;
          try {
              const response = await axios.get(url);
              this.settings = response.data.data;
            this.ready = true;
          } 
          catch (error) {
            this.handleError( error, "Error in getUser");
          }
    },
  }
};
</script>

