<template>
    <div class="bgimg-1">
        <div class="caption">

            <span class="border">KASHIAR</span><br><br>
            <span style="margin-top:5px"></span>
            <span class="ouro ouro3" v-if="!error">
                <span class="left"><span class="anim"></span></span>
                <span class="right"><span class="anim"></span></span>
            </span>
            <p v-if="!error" style="letter-spacing: 2px; font-size: 14px; font-weight: bold;">Confirming payment,please
                wait...</p>

            <div style="letter-spacing: 2px; font-size: 15px; font-weight: bold; color: #782a2a;margin-top:10px"
                v-if="error">
                <v-icon slot="activator" color="white" x-large>error_outline</v-icon> <br />
                Error,{{ error }}
            </div>
        </div>
    </div>
</template>

<script>
import Layout from "@/components/Layout";

export default {
    components: {
        Layout
    },
    data() {
        return {
            loading: false,
            type: "",
            error: "",
            trxref: "",
            reference: ""
        };
    },
    computed: {
    },
    mounted() {
        this.confirmPayment();
    },
    methods: {
        async confirmPayment() {
            try {
                var data = JSON.parse(localStorage.getItem('ecr_reference'));
                const res = await this.$http.get(
                    `${this.apiBaseUrl}/subscriptions/confirm-payment?invoiceNumber=${data.reference}&type=${data.type}&is_upgrade=${data.is_upgrade}`);
                if (res.data) {
                    this.showSnackbar(`${res.data.message}`);
                    localStorage.removeItem("ecr_reference");
                    window.location = "/";
                }
                else if (res.data.message) this.error = res.data.message;
            } catch (error) {
                this.error = error;
            }
        },
    }
};
</script>
<style scoped>
body,
html {
    height: 100%;
    margin: 0;
    font: 400 15px/1.8 "Lato", sans-serif;
    color: #777;
}

.bgimg-1,
.bgimg-2,
.bgimg-3 {
    position: relative;
    /*opacity: 0.65;*/
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bgimg-1 {
    background-color: #3379e5;
    /*background-image: url("img_parallax.jpg");*/
    height: 50%;
}

.caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
    color: #000;
}

.caption span.border {
    /*background-color: #dcdcdc6b;*/
    background-color: hsl(214deg 100% 67% / 81%);
    color: #fff;
    padding: 18px;
    font-size: 25px;
    letter-spacing: 10px;
}

h3 {
    letter-spacing: 5px;
    text-transform: uppercase;
    font: 20px "Lato", sans-serif;
    color: #111;
}

/*Rolloers*/
.ouro {
    position: relative;
    display: inline-block;
    height: 46px;
    width: 46px;
    /*margin: 1em;*/
    border-radius: 50%;
    background: none repeat scroll 0 0 #DDDDDD;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1) inset, 0 0 25px rgba(0, 0, 255, 0.075);
}

.ouro:after {
    content: "";
    position: absolute;
    top: 9px;
    left: 9px;
    display: block;
    height: 28px;
    width: 28px;
    background: none repeat scroll 0 0 #F2F2F2;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.ouro>span {
    position: absolute;
    height: 100%;
    width: 50%;
    overflow: hidden;
}

.left {
    left: 0
}

.right {
    left: 50%
}

.anim {
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 100%;
    border-radius: 999px;
    /*background: none repeat scroll 0 0 #508EC3;*/
    background: none repeat scroll 0 0 #2967ff;
    opacity: 0.8;
    -webkit-animation: ui-spinner-rotate-left 3s infinite;
    animation: ui-spinner-rotate-left 3s infinite;
    -webkit-transform-origin: 0 50% 0;
    transform-origin: 0 50% 0;
}

.left .anim {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.right .anim {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    left: -100%;
    -webkit-transform-origin: 100% 50% 0;
    transform-origin: 100% 50% 0;
}

/* v2 */
.ouro2 .anim {
    -webkit-animation-delay: 0;
    animation-delay: 0;
}

.ouro2 .right .anim {
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

/* v3 */
.ouro3 .anim {
    -webkit-animation-delay: 0s;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-function: linear;
    animation-delay: 0s;
    animation-duration: 3s;
    animation-timing-function: linear;
}

.ouro3 .right .anim {
    -webkit-animation-name: ui-spinner-rotate-right;
    -webkit-animation-delay: 0;
    -webkit-animation-delay: 1.5s;
    animation-name: ui-spinner-rotate-right;
    animation-delay: 0;
    animation-delay: 1.5s;
}

/* round variation */
.round .ouro:after {
    display: none
}

/* double variation */
.double .ouro:after {
    height: 13px;
    width: 13px;
    left: 7px;
    top: 7px;
    border: 10px solid #ddd;
    background: transparent;
    box-shadow: none;
}

@keyframes ui-spinner-rotate-right {
    0% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(180deg)
    }

    50% {
        transform: rotate(180deg)
    }

    75% {
        transform: rotate(360deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes ui-spinner-rotate-left {
    0% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(180deg)
    }

    75% {
        transform: rotate(180deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

@-webkit-keyframes ui-spinner-rotate-right {
    0% {
        -webkit-transform: rotate(0deg)
    }

    25% {
        -webkit-transform: rotate(180deg)
    }

    50% {
        -webkit-transform: rotate(180deg)
    }

    75% {
        -webkit-transform: rotate(360deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes ui-spinner-rotate-left {
    0% {
        -webkit-transform: rotate(0deg)
    }

    25% {
        -webkit-transform: rotate(0deg)
    }

    50% {
        -webkit-transform: rotate(180deg)
    }

    75% {
        -webkit-transform: rotate(180deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}
</style>
