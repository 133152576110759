<template>
  <layout>
    <v-tabs slider-color="primary" grow>
      <v-tab>
        <h4>Attributes</h4>
      </v-tab>
      <v-tab>
        <h4>Options</h4>
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="showAttributeForm(null)">
              <v-icon>add_circle_outline</v-icon>
              &nbsp; Add Attribute
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <br />
          <attributes
            :attributes="attributes"
            :loading="loading.attributes"
            @show="showAttributeForm"
          ></attributes>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <br />
          <attr-options
            :options="options"
            :loading="loading.options"
            @result="handleOptionResult"
          ></attr-options>
        </v-card>
      </v-tab-item>
    </v-tabs>
    <side-panel v-model="showSidePanel">
      <attribute-form
        @result="handleResult"
        :attribute="attribute"
        @reset="resetAttribute"
      ></attribute-form>
    </side-panel>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import Attributes from "./partial/attributes_options/Attributes";
import AttrOptions from "./partial/attributes_options/Options";
import AttributesToOptionsForm from "./partial/attributes_options/AttributesToOptionsForm";
import SidePanel from "@/components/shared/SidePanel";
export default {
  components: {
    Attributes,
    AttrOptions,
    AttributeForm: AttributesToOptionsForm,
    Layout,
    SidePanel
  },
  created() {
    this.getAttributes();
    this.getOptions();
  },
  data() {
    return {
      attribute: {},
      attributes: [],
      loading: {
        attributes: true,
        options: true
      },
      options: [],
      showSidePanel: false,
      showOptionsModal: false
    };
  },
  methods: {
    addAttribute(attr) {
      this.attribute = Object.assign({}, attr);
      this.attributes.push(attr);
    },
    addOption(option) {
      this.options.push(option);
    },
    async getAttributes() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributes`
        );
        await this.handleResponse(res, data => {
          this.attributes = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We could not fetch your attributes. Please try again later."
        );
      }

      this.loading.attributes = false;
    },
    async getOptions() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributeoptions`
        );
        await this.handleResponse(res, data => {
          this.options = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We could not fetch your options. Please try again later."
        );
      }

      this.loading.options = false;
    },
    handleOptionResult(option) {
      const index = this.options.findIndex(x => x.id == option.id);
      if (index > -1) {
        this.$set(this.options, index, option);
      }
    },
    handleResult(result) {
      const { action, type, data } = result;
      action == "update"
        ? type == "attribute"
          ? this.updateAttribute(data)
          : this.updateOption(data)
        : type == "attribute"
        ? this.addAttribute(data)
        : this.addOption(data);
    },
    resetAttribute() {
      this.attribute = {};
    },
    showAttributeForm(attr) {
      if (attr) this.attribute = Object.assign({}, attr);
      this.showSidePanel = true;
    },
    showOptionsForm() {
      this.showOptionsModal = true;
    },
    updateAttribute(attr) {
      const index = this.attributes.findIndex(x => x.id == attr.id);
      if (index > -1) {
        this.attribute = Object.assign({}, attr);
        this.$set(this.attributes, index, attr);
      }
    },
    updateOption(option) {
      const index = this.options.findIndex(x => x.id == option.id);
      if (index > -1) {
        this.$set(this.options, index, option);
      }
    }
  }
};
</script>
