<template>
  <div>
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex xs6 sm4 offset-xs6 offset-sm8>
        <v-btn block @click="manageUser({})" color="info">
          <v-icon>add_circle_outline</v-icon>&nbsp; Add user
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <h3>All users</h3>
        <v-card flat>
          <v-card-title class="pt-0">
            <v-layout>
              <v-flex sm6 md4 offset-sm6 offset-md8>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search.users"
                  append-icon="search"
                  label="Search users"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-data-table
            :items="users"
            :rows-per-page-items="[20]"
            search
            :headers="headers.users"
            :loading="loading"
          >
            <template slot="no-data">
              No users.
            </template>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.full_name }}</td>
              <td>{{ props.item.phone_number }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ getStoreName(props.item.store_id) || "N/A" }}</td>
              <td>{{ props.item.role }}</td>
              <!-- <td>
                  <v-icon small :color="props.item.is_subscribed ? 'green' : 'red'">
                      {{ props.item.is_subscribed ? "check_circle" : "remove_circle" }}
                  </v-icon>
              </td> -->
              <td>
                <v-icon small :color="props.item.active ? 'green' : 'red'">
                  {{ props.item.active ? "check_circle" : "remove_circle" }}
                </v-icon>
              </td>
              <td>
                <v-tooltip bottom v-if="loggedinUser.id != props.item.id">
                  <v-icon slot="activator" @click="manageUser(props.item)"
                    >edit</v-icon
                  >
                  <span>Edit user</span>
                </v-tooltip>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-dialog v-model="userDialog" max-width="900px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter user's details
            </h3>
          </v-card-title>
          <transition name="fade">
            <div class="px-3" v-if="alert.single.show">
              <v-alert
                v-model="alert.single.show"
                :type="alert.single.type"
                dismissible
              >
                {{ alert.single.msg }}
              </v-alert>
            </div>
          </transition>
          <v-divider></v-divider>
          <v-card-text>
            <v-form lazy-validation ref="user_form" @submit.prevent="saveUser">
              <v-layout row wrap>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="selectedUser.first_name"
                    :validate-on-blur="true"
                    label="First name"
                    placeholder="Enter first name"
                    :rules="formRules.required"
                    name="name"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="selectedUser.last_name"
                    :validate-on-blur="true"
                    label="Last name"
                    placeholder="Enter last name"
                    :rules="formRules.required"
                    name="name"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="selectedUser.phone_number"
                    @keydown="checkPhoneNumber"
                    :validate-on-blur="true"
                    label="Phone number"
                    placeholder="Enter phone number"
                    :rules="formRules.required"
                    name="description"
                  ></v-text-field>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    type="email"
                    v-model="selectedUser.email"
                    :validate-on-blur="true"
                    label="Email address"
                    placeholder="Enter email address"
                    :rules="formRules.required"
                    name="email"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm6 px-2>
                  <v-select
                    required
                    :loading="roles.length < 1"
                    v-model="selectedUser.role"
                    :items="roles"
                    item-text="name"
                    item-value="name"
                    :validate-on-blur="true"
                    label="Role"
                    placeholder="Select role"
                    :rules="formRules.required"
                  ></v-select>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-text-field
                    required
                    v-model="selectedUser.contact_address"
                    :validate-on-blur="true"
                    label="Contact address"
                    placeholder="Enter contact address"
                    :rules="formRules.required"
                    name="contact_address"
                    type="address"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex sm6 px-2>
                  <v-select
                    required
                    :loading="stores.length < 1"
                    v-model="selectedUser.store_id"
                    :items="stores"
                    item-text="name"
                    item-value="id"
                    :validate-on-blur="true"
                    label="Store"
                    placeholder="Select store"
                  ></v-select>
                </v-flex>
                <v-flex sm6 px-2>
                  <v-flex xs12>
                    <v-select
                      v-model="selectedUser.assigned_menus"
                      :items="availableMenus"
                      item-text="label"
                      item-value="value"
                      :menu-props="{ top: true, offsetY: true }"
                      attach
                      chips
                      label="Assigned Menus"
                      multiple
                      :disabled="isBusinessAdmin(selectedUser)"
                    ></v-select>
                  </v-flex>
                </v-flex>
              </v-layout>
              <v-layout row>
                <v-flex sm6 px-2>
                  <v-checkbox
                    required
                    v-model="selectedUser.active"
                    label="Active"
                  />
                </v-flex>
              </v-layout>
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeUserDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn
                  type="submit"
                  flat
                  :disabled="posting || roles.length < 1"
                  color="primary"
                  >{{ saveText }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </div>
</template>

<script>
export default {
  computed: {
    saveText() {
      return this.posting ? "Saving.." : "Add User";
    },
    availableMenus() {
      return this.menus.map(x => {
        return {
          label: this.titleCase(x),
          value: x
        };
      });
    }
  },
  data() {
    return {
      filters: {
        store: null
      },
      headers: {
        users: [
          {
            text: "Name",
            value: "full_name"
          },
          {
            text: "Phone",
            value: "phone_number"
          },
          {
            text: "Email",
            value: "email"
          },
          {
            text: "Store",
            value: "store_id"
          },
          {
            text: "Role",
            value: "role"
          },
          // {
          //   text: "Subscribed",
          //   value: "is_subscribed"
          // },
          {
            text: "Status",
            value: "active"
          },
          {
            text: "Actions",
            value: ""
          }
        ]
      },
      loading: false,
      selectedUser: {},
      userDialog: false,
      search: {
        user: ""
      },
      roles: [],
      stores: [],
      users: [],
      menus: [],
      loggedinUser: this.$store.getters.userData
    };
  },
  mounted() {
    this.getRoles();
    this.getStores();
    this.getUsers();
    this.getMenus();
  },
  methods: {
    manageUser(user) {
      this.selectedUser = user;
      this.userDialog = true;
    },
    async saveUser() {
      if (!this.$refs.user_form.validate()) return;
      const selectedUser = Object.assign({}, this.selectedUser);
      this.posting = true;
      try {
        const url = `${this.apiBaseUrl}/users`;
        const isUpdate = this.selectedUser.id;
        const res = isUpdate
          ? await this.$http.patch(url, selectedUser)
          : await this.$http.post(url, selectedUser);

        this.posting = false;
        this.handleResponse(res, data => {
          isUpdate ? this.updateUserInfo : this.users.push(data);
          const action = isUpdate ? "updated" : "added";
          this.closeUserDialog();
          this.showSnackbar(`User ${action} successfully.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "Operation failed. Please try again later.");
      }
    },
    checkPhoneNumber(e) {
      const value = e.key;
      if (!value.match(/[0-9]+/) && value.toLowerCase() != "backspace") {
        e.preventDefault();
      }
    },
    closeUserDialog() {
      this.selectedUser = Object.assign({}, {});
      this.userDialog = false;
      this.$refs.user_form.reset();
    },
    getRoleName(id) {
      return this.roles[id] || "-";
    },
    async getMenus() {
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/menus`);
          this.menus = data;
          //console.log(this.menus);
      } catch (error) {
        this.handleError(
          error,
          "Couldn't fetch menus. Please try again later."
        );
      }
    },
    async getRoles() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/roles`);
        this.handleResponse(res, data => {
          this.roles = data;
        });
      } catch (error) {
        this.handleError(
          error,
          "Couldn't fetch user roles. Please try again later."
        );
      }
    },
    getStoreName(id) {
      const store = this.stores.find(x => x.id == id);
      return store ? store.name : "";
    },
    async getStores() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.handleResponse(res, data => {
          this.stores = data;
        });
      } catch (error) {
        this.handleError(
          error,
          "Couldn't fetch stores. Please try again later."
        );
      }
    },
    async getUsers() {
      this.loading = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/users`);
        this.loading = false;
        this.handleResponse(res, data => {
          this.users = data;
        });
      } catch (error) {
        this.loading = false;
        this.handleError(
          error,
          "We couldn't fetch your users. Please try again later."
        );
      }
    },
    updateUserInfo(user) {
      const index = this.users.findIndex(x => x.id == user.id);
      this.$set(this.users, index, user);
    },
    titleCase(value) {
      let sentence = value.toLowerCase().split(" ");
      for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
      }
        //console.log(sentence);
      return sentence.join(" ");
    }
  }
};
</script>
