<template>
  <layout>
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex class="text-xs-right">
        <v-btn @click="createNewMeasurement" color="primary">
          <v-icon>add_circle_outline</v-icon>&nbsp; Add measurement
        </v-btn>
      </v-flex>
    </v-layout>
    <br />
    <!-- <hr> -->
    <v-divider></v-divider>
    <br />

    <v-layout row wrap>
      <v-flex xs12>
        <v-card flat>
          <v-card-title class="pt-0">
            <v-layout>
              <v-flex sm6 md4 offset-sm6 offset-md8>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search.measurements"
                  append-icon="search"
                  label="Search measurement"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-data-table
            :items="measurements"
            :rows-per-page-items="[10]"
            :search="search.measurements"
            :headers="headers.measurement"
            :loading="loading.measurements"
          >
            <template slot="no-data">
              No measurement. Add a new measurement above.
            </template>
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.code }}</td>
              <td class="text-xs-center">
                <v-icon small :color="props.item.active ? 'green' : 'red'">
                  brightness_1
                </v-icon>
              </td>
              <td>
                <v-icon
                  v-show="props.item.business_id"
                  small
                  @click="editItem(props.item)"
                >
                  edit
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-dialog v-model="newMeasurementDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter measurement details
            </h3>
          </v-card-title>
          <transition name="fade">
            <div class="px-3" v-if="alert.single.show">
              <v-alert
                v-model="alert.single.show"
                :type="alert.single.type"
                dismissible
              >
                {{ alert.single.msg }}
              </v-alert>
            </div>
          </transition>
          <v-divider></v-divider>
          <v-card-text>
            <v-form lazy-validation ref="new_measurement_form">
              <v-layout row wrap>
                <v-flex xs12 px-2>
                  <v-text-field
                    required
                    v-model="newMeasurement.name"
                    :validate-on-blur="true"
                    label="Measurement name"
                    :rules="formRules.required"
                    name="name"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex xs6 px-2>
                  <v-text-field
                    required
                    v-model="newMeasurement.code"
                    :validate-on-blur="true"
                    label="Measurement code"
                    :rules="formRules.required"
                    name="code"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 px-2>
                  <v-switch
                    label="Is Active?"
                    v-model="newMeasurement.active"
                  ></v-switch>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeMeasurementDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn
                  @click="saveMeasurement"
                  flat
                  :disabled="posting"
                  color="primary"
                  >{{ saveText }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Edit measurement dialog -->
      <v-dialog v-model="itemDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Edit measurement details
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <p>Are you sure you want to remove this item?</p>

            <v-layout>
              <v-spacer></v-spacer>
              <v-btn @click="closeItemDialog()" flat :disabled="posting"
                >Close</v-btn
              >
              <v-btn
                @click="removeMeasurement"
                flat
                :disabled="posting"
                color="primary"
                >Remove</v-btn
              >
            </v-layout>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    saveText() {
      return this.posting ? "Saving.." : "Save";
    },
    activeMeasurements() {
      return this.measurements
        .filter(x => x.active == true)
        .sort((a, b) => a.name > b.name);
    },
    inactiveMeasurements() {
      return this.measurements
        .filter(x => x.active == false)
        .sort((a, b) => a.name > b.name);
    }
  },
  created() {
    this.getMeasurements();
  },
  data() {
    return {
      headers: {
        measurement: [
          {
            text: "Name",
            value: "name"
          },
          {
            text: "Code",
            value: "code"
          },
          {
            text: "Status",
            value: "active",
            align: "center"
          },
          {
            text: "Actions",
            value: ""
          }
        ]
      },
      item: {},
      itemDialog: false,
      loading: {
        measurements: false
      },
      measurements: [],
      newMeasurement: {},
      newMeasurementDialog: false,
      posting: false,
      search: {
        measurements: ""
      }
    };
  },
  methods: {
    createNewMeasurement() {
      this.newMeasurement = { active: true };
      this.newMeasurementDialog = true;
    },
    closeItemDialog() {
      this.itemDialog = false;
    },
    closeMeasurementDialog() {
      this.newMeasurementDialog = false;
    },
    deleteItem() {},
    unsubscribe(item) {
      this.item = Object.assign({}, item);
      this.itemDialog = true;
    },
    editItem(item) {
      this.newMeasurement = Object.assign({}, item);
      this.newMeasurementDialog = true;
    },
    getMeasurements() {
      this.loading.measurements = true;
      this.$http
        .get(`${this.apiBaseUrl}/measurements`)
        .then(res =>
          this.handleResponse(res, data => {
            this.loading.measurements = false;
            this.measurements = data;
          })
        )
        .catch(error => {
          this.handleError(
            error,
            "We couldn't fetch your measurements.",
            () => {
              this.loading.measurements = false;
            }
          );
        });
    },
    saveMeasurement() {
      this.posting = true;
      this.$http
        .post(`${this.apiBaseUrl}/measurements`, this.newMeasurement)
        .then(res => {
          this.handleResponse(res, () => {
            this.closeMeasurementDialog();
            this.getMeasurements();
            this.showSnackbar(res.data.message, {
              type: "success"
            });
          });
        })
        .catch(error =>
          this.handleError(
            error,
            "An error occurred. Please try again later.",
            false
          )
        );

      this.posting = false;
    },
    updateItem() {
      //
    },
    updateUnits() {
      let units = this.measurements;
      this.posting = true;
      let payload = {};
      let items = [];
      items = units.map(x => ({ item_id: x.id, active: x.active }));
      payload.items = items;

      this.$http
        .patch(`${this.apiBaseUrl}/measurements/subscribe`, payload)
        .then(res =>
          this.handleResponse(res, () => {
            this.getMeasurements();
            this.closeMeasurementDialog();
            this.showAlert(res.data.message);
          })
        )
        .catch(error =>
          this.handleError(
            error,
            "An error occurred. Please try again later.",
            false
          )
        );

      this.posting = false;
    },
    removeMeasurement() {
      this.posting = true;
      const item = Object.assign({}, this.item);
      this.$http
        .delete(`${this.apiBaseUrl}/measurements/${item.id}`)
        .then(res =>
          this.handleResponse(res, () => {
            this.posting = false;
            this.measurements.pop(this.item);
            this.closeItemDialog();
            this.getMeasurements();
          })
        )
        .catch(error =>
          this.handleError(error, false, () => {
            console.log("Error:", error);

            this.posting = false;
          })
        );
    }
  }
};
</script>
