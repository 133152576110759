<template>
  <layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-tabs slider-color="primary" grow>
          <v-tab>
            <h4>Tax Groups</h4>
          </v-tab>
          <v-tab>
            <h4>Payment Methods</h4>
          </v-tab>
          <v-tab-item>
            <v-card flat>
              <v-card-title class="pt-0">
                <v-container fluid class="pa-0">
                  <v-layout>
                    <v-flex sm4 md3 offset-sm8 offset-md9> </v-flex>
                  </v-layout>
                </v-container>
              </v-card-title>
              <v-data-table
                :items="taxGroups"
                :rows-per-page-items="[10]"
                search
                :headers="headers.taxGroups"
                :loading="loading.taxGroups"
              >
                <template slot="no-data">
                  No tax groups. Add a new group above.
                </template>
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.value }}</td>
                  <td>
                    <v-icon small :color="props.item.active ? 'green' : 'red'">
                      {{ props.item.active ? "check_circle" : "remove_circle" }}
                    </v-icon>
                  </td>
                  <td>
                    <!-- <v-icon small @click="showTaxGroupInfo(props.item)">
                      edit
                    </v-icon> -->
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-title class="pt-0">
                <v-container fluid class="pa-0">
                  <v-layout>
                    <!-- <v-spacer></v-spacer> -->
                    <v-flex sm4 md3 offset-sm8 offset-md9>
                      <v-btn
                        right
                        @click="newPaymentMethodDialog = true"
                        color="info"
                        block
                      >
                        <v-icon>add_circle_outline</v-icon>&nbsp; Add payment
                        method
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-title>
              <v-data-table
                :items="paymentMethods"
                :rows-per-page-items="[10]"
                search
                :headers="headers.paymentMethods"
                :loading="loading.paymentMethods"
              >
                <template slot="no-data">
                  No payment method. Add a new method above.
                </template>
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.name }}</td>
                  <td>
                    <v-icon small :color="props.item.active ? 'green' : 'red'">
                      {{ props.item.active ? "check_circle" : "remove_circle" }}
                    </v-icon>
                  </td>
                  <td>
                    <v-icon small @click="showPaymentMethodInfo(props.item)">
                      edit
                    </v-icon>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-dialog v-model="newTaxGroupDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter tax group details
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form lazy-validation ref="tax_group_form">
              <v-layout row wrap>
                <v-flex xs12>
                  <v-text-field
                    required
                    v-model="newTaxGroup.name"
                    :validate-on-blur="true"
                    label="Tax group name"
                    placeholder="Enter tax group name"
                    :rules="formRules.required"
                    name="name"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    required
                    v-model="newTaxGroup.value"
                    :validate-on-blur="true"
                    label="Value"
                    placeholder="Enter value (%)"
                    :rules="formRules.required"
                    name="value"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex px-2>
                  <v-switch
                    label="Is Active?"
                    v-model="newTaxGroup.active"
                  ></v-switch>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeTaxGroupDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn
                  @click="saveTaxGroup"
                  flat
                  :disabled="posting"
                  color="primary"
                  >{{ saveText }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="newPaymentMethodDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter payment method details
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form
              lazy-validation
              ref="payment_method_form"
              @submit.prevent="savePaymentMethod"
            >
              <v-layout row wrap>
                <v-flex px-2>
                  <v-text-field
                    required
                    v-model="newPaymentMethod.name"
                    :validate-on-blur="true"
                    label="Method name"
                    placeholder="Enter method name"
                    :rules="formRules.required"
                    name="name"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex md6 px-2>
                  <v-checkbox
                    required
                    v-model="newPaymentMethod.active"
                    label="Active"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn
                  @click="closePaymentMethodDialog"
                  flat
                  :disabled="posting"
                  >Close</v-btn
                >
                <v-btn type="submit" flat :disabled="posting" color="primary">{{
                  saveText
                }}</v-btn>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    taxGroups_() {
      if (this.taxGroups.length < 1) this.getTaxGroups();

      return this.taxGroups;
    },
    saveText() {
      const state = this.action === "update" ? "update" : "new";
      const texts = {
        active: {
          new: "Saving...",
          update: "Updating..."
        },
        inactive: {
          new: "Save",
          update: "Update"
        }
      };
      return this.posting ? texts.active[state] : texts.inactive[state];
    }
    // saveText() {
    //     return this.posting ? "Saving.." : "Save";
    // }
  },
  created() {
    this.getPaymentMethods();
    this.getTaxGroups();
  },
  data() {
    return {
      action: "",
      loading: {
        paymentMethods: false,
        taxGroups: false
      },
      headers: {
        paymentMethods: [
          {
            text: "Method",
            align: "left",
            value: "name"
          },
          {
            text: "Status",
            align: "left",
            value: "active"
          },
          {
            text: "Actions",
            value: ""
          }
        ],
        taxGroups: [
          {
            text: "Name",
            align: "left",
            value: "name"
          },
          {
            text: "Value",
            align: "left",
            value: "value"
          },
          {
            text: "Status",
            align: "left",
            value: "active"
          },
          {
            text: "Actions",
            value: ""
          }
        ]
      },
      newPaymentMethod: {
        active: true
      },
      newTaxGroup: {
        active: true
      },
      newPaymentMethodDialog: false,
      newTaxGroupDialog: false,
      posting: false,
      paymentMethods: [],
      taxGroups: [],
      search: {
        paymentMethods: "",
        taxGroups: ""
      }
    };
  },
  methods: {
    addPaymentMethod(paymentMethod) {
      this.paymentMethods.push(paymentMethod);
    },
    addTaxGroup(taxGroup) {
      this.taxGroups.push(taxGroup);
    },
    closePaymentMethodDialog() {
      this.action = "";
      this.newPaymentMethod = {};
      this.newPaymentMethodDialog = false;
      this.resetPaymentMethodForm();
    },
    closeTaxGroupDialog() {
      this.action = "";
      this.newTaxGroup = {};
      this.newTaxGroupDialog = false;
      this.$refs.tax_group_form.reset();
    },
    async getPaymentMethods() {
      this.loading.paymentMethods = true;
      this.$http
        .get(`${this.apiBaseUrl}/paymentMethods`)
        .then(res => {
          this.loading.paymentMethods = false;
          const response = res.data;
          if (!response.status)
            return this.showAlert(response.message, "error");

          this.paymentMethods = response.data;
        })
        .catch(error => {
          this.loading.paymentMethods = false;
          const response = error.response;
          if (!(response && response.data && response.data.message))
            return this.showAlert(
              "We couldn't fetch your payment methods. Please try again.",
              "error"
            );

          const data = error.response.data;
          this.showAlert(data.message, "error");
        });
    },
    async getTaxGroups() {
      this.loading.taxGroups = true;
      this.$http
        .get(`${this.apiBaseUrl}/taxGroups`)
        .then(res => {
          this.loading.taxGroups = false;
          const response = res.data;
          if (!response.status)
            return this.showAlert(response.message, "error");

          this.taxGroups = response.data;
        })
        .catch(error => {
          this.loading.taxGroups = false;
          const response = error.response;
          if (!(response && response.data && response.data.message))
            return this.showAlert(
              "We couldn't fetch your tax groups. Please try again.",
              "error"
            );

          const data = error.response.data;
          this.showAlert(data.message, "error");
        });
    },
    resetPaymentMethodForm() {
      this.$refs.payment_method_form.reset();
    },
    async savePaymentMethod() {
      if (!this.$refs.payment_method_form.validate()) return;
      const paymentMethod = Object.assign({}, this.newPaymentMethod);
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/paymentMethods`,
          paymentMethod
        );
        this.posting = false;
        await this.handleResponse(res, data => {
          if (this.action == "update") {
            this.updatePaymentMethod(data);
          } else {
            this.paymentMethods.push(data);
          }

          this.closePaymentMethodDialog();
          this.showSnackbar("Payment method added successfully.");
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error while adding this payment method. Please try again later."
        );
      }
    },
    async saveTaxGroup() {
      if (!this.$refs.tax_group_form.validate()) return;
      const taxGroup = Object.assign({}, this.newTaxGroup);
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/taxGroups`,
          taxGroup
        );
        this.posting = false;
        const action = this.action === "update" ? "updated" : "added";
        await this.handleResponse(res, data => {
          if (action == "updated") {
            this.updateTaxGroup(data);
          } else {
            this.addTaxGroup(data);
          }
        });
        this.closeTaxGroupDialog();
        this.showSnackbar(`Tax group ${action} successfully.`);
      } catch (error) {
        this.posting = false;
        await this.handleError(
          error,
          "We couldn't add this tax group. Please try again later."
        );
      }
    },
    showPaymentMethodInfo(method) {
      this.action = "update";
      this.newPaymentMethod = Object.assign({}, method);
      this.newPaymentMethodDialog = true;
    },
    showTaxGroupInfo(group) {
      this.newTaxGroup = Object.assign({}, group);
      this.action = "update";
      this.newTaxGroupDialog = true;
    },
    updatePaymentMethod(paymentMethod) {
      const index = this.paymentMethods.findIndex(
        x => x.id == paymentMethod.id
      );
      if (index < 0) return;

      this.$set(this.paymentMethods, index, paymentMethod);
    },
    updateTaxGroup(taxGroup) {
      const index = this.taxGroups.findIndex(x => x.id == taxGroup.id);
      if (index < 0) return;

      this.$set(this.taxGroups, index, taxGroup);
    }
  }
};
</script>
