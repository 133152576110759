<template>
    <div class="side-panel" :class="{ show: visible }">
        <div class="s-content" :class="{ show: showContent }" :style="{ maxWidth:width}">
            <v-layout row justify-end>
                <v-spacer></v-spacer>
                <v-btn flat @click="closePanel">
                    Close &nbsp;
                    <v-icon>close</v-icon>
                </v-btn>
            </v-layout>
            <v-divider></v-divider>
            <br />
            <v-layout row wrap>
                <v-flex sm-12>
                    <slot></slot>
                </v-flex>
            </v-layout>
        </div>
    </div>
</template>
<!--@click.self="closePanel"-->
<script>
    export default {
        data() {
            return {
                showContent: false,
                visible: false,
            };
        },
        methods: {
            closePanel() {
                this.width = "50%";
                this.$emit("input", false);
                //this.$emit("cancel");
            }
        },
        props: ["value", "width"],
        watch: {
            value: {
                immediate: true,
                handler(value) {
                    if (this.visible != value) {
                        if (value) {
                            this.visible = value;
                            setTimeout(() => {
                                this.showContent = value;
                            }, 0);
                        } else {
                            this.showContent = value;
                            setTimeout(() => {
                                this.visible = value;
                            }, 200);
                        }
                    }
                    this.$emit("input", value);
                }
            }
        }
    };
</script>

<style>
    @keyframes s_animate_side_panel_hide {
        80% {
            opacity: 0;
            visibility: hidden;
        }

        100% {
            opacity: 0;
            visibility: hidden;
            display: none;
        }
    }

    @keyframes s_animate_side_panel_show {
        0% {
            opacity: 0;
            display: block;
            visibility: visible;
        }

        100% {
            opacity: 1;
        }
    }
</style>

<style scoped>
    .side-panel {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 350;
        background-color: #00000030;
        display: none;
        animation: s_animate_side_panel_hide 0.3s;
    }

        .side-panel.show {
            display: block;
            animation: s_animate_side_panel_show 0.5s;
        }

    .s-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        /*max-width: 50%;*/
        /*max-width: 800px;*/
        background-color: #fff;
        box-shadow: -7px 0px 14px 0px #00000038;
        transition: all 0.3s ease-in-out;
        transform: translateX(1500px);
        overflow: auto;
    }

        .s-content.show {
            transform: translateX(0px);
        }
</style>
