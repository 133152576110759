<template>
  <v-card flat>
    <v-card-title primary-title>
      <v-flex xs12 sm12>
        <div class="text-xs-left">
          <strong>Purchased Products</strong>
        </div>
      </v-flex>
    </v-card-title>

    <v-data-table :headers="headers" :items="products" class="elevation-1">
      <template v-slot:items="props">
        <td>{{ props.item.productName }}</td>
        <!-- <td>{{ moneyFormat(props.item.price) }}</td> -->
        <td>{{ moneyFormat(props.item.price * props.item.quantity) }}</td>
        <td>{{ moneyFormat(props.item.salePrice) }}</td>
        <td>{{ props.item.quantity }}</td>
        <td>{{ props.item.totalDiscount }}</td>
        <td>{{ props.item.isService }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

export default {
  props: ["data"],
  data() {
    return {
      products: this.data.item.products,
      headers: [
        { text: "Name", value: "productName" },
        // { text: "Cost (NGN)", value: "price" },
        { text: "Full Cost (NGN)", value: "total" },
        { text: "Sale At (NGN)", value: "salePrice" },
        { text: "Quantity", value: "quantity" },
        { text: "Total Discount", value: "totalDiscount" },
        { text: "Is Service", value: "isService" }
      ]
    };
  },
  mounted() {
    console.log('products', this.products)
  }
};
</script>
