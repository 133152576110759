<template>
    <v-dialog v-model="showUpdateWalletBalance" max-width="800px" persistent>
      <v-card>
        <v-card-title primary-title>
          <h3>
            Credit Wallet Balance
          </h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form lazy-validation ref="set_wallet_balance" @submit.prevent="showConfirmModal = true">
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-text-field
                  required
                  v-model="amount"
                  :value="amount"
                  label="Credit Amount (NGN)"
                  autofocus
                  :rules="formRules.required"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-spacer></v-spacer>
              <v-btn @click="$emit('onClose')" flat
                >Close</v-btn
              >
              <v-btn
                type="submit"
                flat
                :disabled="amount < 0"
                color="primary"
                >Credit Wallet</v-btn
              >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
      <confirm-dialog
        @onAgree="showConfirmModal = false; $emit('updateWalletBalance', {
            modalRef: $refs.set_wallet_balance,
            newValue: amount
        })"
        @onCancel="showConfirmModal = false"
        title="Are you sure?"
        :message="`By clicking Yes, this customer's wallet would be updated with ${moneyFormat(amount)}`"
        :show="showConfirmModal"
      />
    </v-dialog>
</template>

<script>
import ConfirmDialog from "@/components/utils/ConfirmDialog";

export default {
    components: {
        ConfirmDialog
    },
    props: ['showUpdateWalletBalance'],
    data() {
        return {
            amount: '',
            showConfirmModal: false,
        }
    },
    computed: {
        displayText() {
            return "By clicking Yes, this customer's wallet would be updated with " + this.moneyFormat(this.amount);
        }
    }
}
</script>