<template>
    <v-card flat>
        <v-card-title primary-title>
            <v-flex xs12 sm12>
                <div class="text-xs-left">
                    <strong>Log Details</strong>
                </div>
            </v-flex>
        </v-card-title>

        <v-data-table :headers="headers" :items="filterLogs" class="elevation-1"
                      disable-initial-sort>
            <template v-slot:items="props">
                <td>{{ props.item.process_name }}</td>
                <td>{{ props.item.method }}</td>
                <td>{{ props.item.path }}</td>
                <td v-if="props.item.status && props.item.apiUrl != ''"><v-icon color="green white--text">check_circle_outline</v-icon></td>
                <td v-else-if="props.item.apiUrl != ''"></td>
                <td v-else><v-icon color="red white--text">highlight_off</v-icon></td>

                <td v-if="props.item.process_name == 'SYNC STARTED' || props.item.process_name == 'SYNC ENDED'"></td>
                <td v-else-if="props.item.result != 'null'"><v-icon color="green white--text">check_circle_outline</v-icon></td>
                <td v-else><v-icon color="red white--text">highlight_off</v-icon></td>

                <td v-if="props.item.process_name == 'SYNC STARTED' || props.item.process_name == 'SYNC ENDED'"></td>
                <td v-else>
                    <v-tooltip bottom>
                        <v-icon slot="activator" @click="showInfo(props.item)">info</v-icon>
                        <span>View details</span>
                    </v-tooltip>
                </td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>

export default {
  props: ["data"],
  data() {
    return {
      details: this.data.item.report,
      headers: [
        { text: "Process Name", value: "process_name" },
        { text: "Method", value: "method" },
        { text: "ApiUrl", value: "path" },
        { text: "Http status", value: "status" },
        { text: "Process result", value: "result" },
        { text: "Response", value: "response" },
      ],
    };
  },
    computed: {
        filterLogs() {
            return this.details.map((item) => ({
                process_name: item.process_name,
                method: item.http_name,
                path: item.apiUrl,
                response: item.body_response,
                result: item.result,
                status: parseInt(item.status_code) == 200 ? true : false
            }));
        },
    },
    methods: {
        showInfo(data) {
            this.$emit("showResponse", data.response);
        },
    }
};
</script>
