<template>
    <v-card>
      <v-card-title :class="color">
        {{title}}
      </v-card-title>
      <v-card-text class="text-sm-right p-8" v-for="index in count" :key="index">
          <span class="skeleton-loader"  :style="'--p-height: '+ height"></span>
      </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
            };
        },
        props: ['count', 'title', 'height', 'color'],
    };
</script>

<style scoped>
    .skeleton-loader:empty {
        width: 100%;
        height: var(--p-height);
        /*height: 30px;*/
        /*height: 15px;*/
        display: block;
        background: linear-gradient( to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80% ), #ebebeb;
        background-repeat: repeat-y;
        background-size: 50px 500px;
        background-position: 0 0;
        animation: shine 2s infinite;
    }

    @keyframes shine {
        to {
            background-position: 100% 0;
        }
    }
</style>
