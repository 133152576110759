<template>
    <v-card class="dash-card" v-if="!loading">
        <v-card-title class="green darken-2 white--text">
            Business Type
        </v-card-title>

        <bar-chart text-xs-center
                   :data="chart.data"
                   :options="chart.options"
                   :height="200"></bar-chart>
    </v-card>
    <skeleton-loader :color="'green darken-2 white--text'" :title="'Business Type'" :height="'10px'" :count="8" v-else></skeleton-loader>
</template>

<script>
    import BarChart from "@/components/charts/BarChart";
    export default {
        components: {
            BarChart
        },
        data() {
            return {
                loaded: false,
            };
        },
        props: {
            chart: Array,
            'loading': Boolean
        }
    };
</script>
