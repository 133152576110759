<template>
    <v-card v-if="!loading">
        <v-card-title class="pink darken-2 white--text">
            Total Businesses
        </v-card-title>
        <v-card-text class="text-sm-right p-8">
            <h2>{{count}}</h2>
        </v-card-text>
    </v-card>
    <skeleton-loader :color="'pink darken-2 white--text'" :title="'Total Businesses'" :height="'30px'" :count="1" v-else></skeleton-loader>
</template>

<script>
   import SkeletonLoader from "@/components/shared/SkeletonLoader";
export default {
components: {
    SkeletonLoader
},
  data() {
    return {
      count: "X"
    };
  },
  props: ['count', 'loading'],
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped></style>
