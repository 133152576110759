<template>
  <layout>
    <template>
      <v-layout row wrap style="margin-bottom: 40px;">
        <v-flex xs12 sm6 px-2>
          <v-btn large color="white" @click="$router.back()">
            Back
          </v-btn>
        </v-flex>
        <v-flex xs12 sm6 px-2 text-xs-right>
          <DownloadCSV
            class="v-btn theme--light success"
            :data="filterRecordsForExport"
            :name="'supplier-ledger_for_' + selectedSupplier.name + '.csv'"
            v-if="filterRecordsForExport.length"
          >
              Export
          </DownloadCSV>
        </v-flex>
      </v-layout>
      <v-layout row wrap style="margin-bottom: 40px;">
        <v-flex xs6 sm3 px-2>
          <customer :value="selectedSupplier" />
        </v-flex>
        <v-flex xs6 sm3 px-2>
          <paid :value="moneyFormat(totalPaid)" />
        </v-flex>
        <v-flex xs6 sm3 px-2>
          <total-cost :value="moneyFormat(totalAmountTransacted)" />
        </v-flex>
        <v-flex xs6 sm3 px-2>
          <balance
            :value="toBalance"
          />
        </v-flex>
      </v-layout>

      <v-data-table
        :headers="headers"
        :expand="true"
        item-key="receiptId"
        :items="filterRecords"
        :rows-per-page-items="[25]"
        :search="search"
        :loading="loading"
      >
        <template slot="no-data"> No history. </template>
        <template slot="items" slot-scope="props">
          <tr @click="props.expanded = !props.expanded">
            <td>{{ props.item.receiptName }}</td>
            <td>{{ props.item.name }}</td>
            <td>
              {{ props.item.code }}
            </td>
            <td>
              {{ props.item.store }}
            </td>
            <td>
              {{ props.item.documentType }}
            </td>
            <td>
              {{ props.item.delivered }}
            </td>
            <td>
              {{ moneyFormat(props.item.amountPaid) }}
            </td>
            <td>
              {{ props.item.total }}
            </td>
            <td>{{ props.item.date }}</td>
          </tr>
        </template>
        <template v-slot:expand="props">
          <batch-details :data="props" />
        </template>
      </v-data-table>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import Paid from "@/components/supplier/Paid";
import TotalCost from "@/components/supplier/TotalCost";
import Balance from "@/components/supplier/Balance";
import Customer from "@/components/supplier/Customer";
import BatchDetails from "./BatchDetails";
import JsonCSV from "vue-json-csv";

export default {
  components: {
    Layout,
    TotalCost,
    Balance,
    Paid,
    Customer,
    BatchDetails,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "Receipt", value: "receiptName" },
        { text: "Product Name", value: "name" },
        { text: "Product Code", value: "code" },
        { text: "Store", value: "store" },
        { text: "Document Type", value: "documentType" },
        { text: "Delivered", value: "delivered" },
        { text: "Amount Paid", value: "amountPaid" },
        { text: "Cost Price (NGN)", value: "total" },
        { text: "Date", value: "date" },
      ],
      loading: false,
      search: "",
      history: [],
      stores: [],
      selectedSupplier: {},
    };
  },
  mounted() {
    this.fetchStores();
    this.fetchSupplierHistory();
  },
  computed: {
    filterRecords () {
      return this.history.map((item) => ({
          receiptId: item.incomingReceiptData.id,
          receiptName: item.incomingReceiptData.name,
          delivered: item.incomingReceiptData.delivered,
          documentType: item.incomingReceiptData.documentType,
          amountPaid: this.moneyFormat(
            this.getTotalPaidPerProduct(item.incomingReceiptData.id, item.product)
          ),
          name: item.product.name,
          store: this.getStore(item.incomingReceiptData.storeId).name,
          code: item.product.code,
          batches: this.getBatchesPerProduct(item.incomingReceiptData.id, item.product),
          total: this.moneyFormat(
            this.getTotalPricePerProduct(item.incomingReceiptData.id, item.product)
          ),
          date: this.dateFormat(item.incomingReceiptData.transactionDate),
      }));
    },
    filterRecordsForExport () {
      return this.history.map((item) => ({
          'Receipt ID': item.incomingReceiptData.id,
          'Receipt Name': item.incomingReceiptData.name,
          'Name': item.product.name,
          'Code': item.product.code,
          'Store': this.getStore(item.incomingReceiptData.storeId).name,
          'Document Type': item.incomingReceiptData.documentType,
          'Delivered': item.incomingReceiptData.delivered,
          'Amount Paid': this.moneyFormat(
            this.getTotalPaidPerProduct(item.incomingReceiptData.id, item.product)
          ),
          'Cost Price (NGN)': this.moneyFormat(
            this.getTotalPricePerProduct(item.incomingReceiptData.id, item.product)
          ),
          'Date': this.dateFormat(item.incomingReceiptData.transactionDate),
      }));
    },
    totalPaid () {
      let totalPaid = 0;
      this.history.map(record => {
        totalPaid += record.incomingReceiptData.amountPaid;
      })
      return totalPaid;
    },
    totalAmountTransacted () {
      let total = 0;
      this.history.map(record => {
        total +=
          this.getTotalPricePerProduct(record.incomingReceiptData.id, record.product)
      })
      return total;
    },
    totalOwed () {
      const owed = (this.totalAmountTransacted - this.totalPaid);
      return owed > 0 ? owed : 0;
    },
    toBalance () {
      const balance = this.totalAmountTransacted - this.totalPaid
      return balance > 0
        ? this.moneyFormat(balance)
        : 0
        // this.moneyFormat(-1 * balance)
    }
  },
  methods: {
    async fetchStores() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.stores = data.data;
        // console.log('this.stores', this.stores);
      } catch (error) {
        // console.log(error);
        this.handleError(error, "Error fetching stores");
      }
      this.loading = false;
    },
    async fetchSupplierHistory() {
      const id = this.$route.params.id;
      this.loading = true;
      try {
        const { data } =
          await this.$http.get(`${this.apiBaseUrl}/suppliers/history/${id}`);

        // console.log(data.data);
        this.history = data.data;
        if (data.data.length > 0) this.selectedSupplier = data.data[0].supplierData
      } catch (error) {
        this.handleError(error, "Error fetching supplier history");
      }
      this.loading = false;
    },
    getTotalPricePerProduct(transactionId, product) {
      /**
      Transaction ID is received because a product contains batches even if they were seperate
      incoming receipt, so we need to identify batches by incoming receipt they belong to.
       */
      let totalPrice = 0;
      product.productBatches.map(batch => {
        if (batch.incomingReceiptId === transactionId)
          totalPrice += (batch.costPrice * batch.quantity)
      })
      return totalPrice;
    },
    getTotalPaidPerProduct(transactionId, product) {
      /**
      Transaction ID is received because a product contains batches even if they were seperate
      incoming receipt, so we need to identify batches by incoming receipt they belong to.
       */
      let totalPrice = 0;
      product.productBatches.map(batch => {
        if (batch.incomingReceiptId === transactionId)
          totalPrice += batch.amountPaid
      })
      return totalPrice;
    },
    getBatchesPerProduct(transactionId, product) {
      /**
      Transaction ID is received because a product contains batches even if they were seperate
      incoming receipt, so we need to identify batches by incoming receipt they belong to.
       */
      const batches = [];
      product.productBatches.map(batch => {
        if (batch.incomingReceiptId === transactionId)
          batches.push({...batch, index: batches.length + 1})
      })
      return batches;
    },
    getStore(storeId) {
      return this.stores.find(x => x.id == storeId) || {name: 'Admin'};
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.customer-name {
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
</style>