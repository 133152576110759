<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-menu
            ref="startDayMenu"
            v-model="startDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.startDate"
                label="Start date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="startDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.startDayMenu.save(filter.startDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-menu
            ref="endDayMenu"
            v-model="endDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.endDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.endDate"
                label="End Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="endDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.endDayMenu.save(filter.endDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-btn color="info" @click="filterData"> Filter </v-btn>
        </v-flex>

        <v-flex xs12 sm3>
          <div class="text-xs-right">
            <DownloadCSV
              class="v-btn theme--light success"
              :data="filterPaymentRecords"
              name="export-payments-report.csv"
              v-if="filterPaymentRecords.length"
            >
              Export
            </DownloadCSV>
          </div>
        </v-flex>
      </v-layout>

      <v-data-table
        disable-initial-sort
        :items="filterPaymentRecords"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data"> No payments. </template>

        <template slot="items" slot-scope="props">
          <td>{{ props.item.transactionId }}</td>
          <td>{{ props.item.amountReceived }}</td>
          <td>{{ props.item.date }}</td>
          <td>{{ props.item.store }}</td>
          <td>{{ props.item.customer }}</td>
          <td>{{ props.item.paymentMethod }}</td>
          <td>{{ props.item.receiver }}</td>
        </template>
      </v-data-table>
    </template>
    
    <template v-else> No store selected </template>
  </layout>
</template>

<script>
import JsonCSV from "vue-json-csv";
import Layout from "@/components/Layout";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "Receipt ID", value: "reference" },
        { text: "Amount", value: "amountReceived" },
        { text: "Date", value: "created_at" },
        { text: "Store", value: "store" },
        { text: "Customer", value: "customer" },
        { text: "Payment Method", value: "paymentMethod" },
        { text: "Confirmed By", value: "receiver" },
      ],
      loading: false,
      payments: [],
      search: "",
      startDayMenu: false,
      endDayMenu: false,
      paymentMethods: [],
      partners: []
    };
  },
  computed: {
    filterPaymentRecords () {
      return this.payments.map((item) => ({
          transactionId: item.transaction_id,
          date: this.fullDateFormat(item.created_at),
          amountReceived: item.amount_received,
          customer: this.getPartner(item.customer_id).name,
          paymentMethod: this.getPaymentMethod(item.payment_method_id).name,
          receiver: item.receiver,
          store: this.selectedStore.name,
          deviceCode: item.device_code,
      }));
    }
  },
  mounted() {
    console.log('STORE', this.selectedStore);
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      try {
        const [paymentMethods, partners] = await Promise.all([
          await this.$http.get(`${this.apiBaseUrl}/paymentmethods`),
          await this.$http.get(`${this.apiBaseUrl}/partners`)
        ]);

        this.paymentMethods = paymentMethods.data.data;
        this.partners = partners.data.data;
      } catch (error) {
        this.handleError(error, "Error loading data");
      }
      this.loading = false;
    },
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      const params = {
        storeId,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      };
      let url = `${this.apiBaseUrl}/payments`;
      this.loading = true;
      try {
        const { data } = await this.$http.get(url, { params });
        this.payments = data.data;
      } catch (error) {
        this.handleError(error, "Error loading data");
      }
      this.loading = false;
    },
    filterData() {
      this.getData();
    },
    totalQty(items) {
      return items.reduce(function(a, b) {
        return a + b["quantity"];
      }, 0);
    },
    getPartner(id) {
      const item = this.partners.find(x => x.id == id);
      return item || {};
    },
    getPaymentMethod(id) {
      const item = this.paymentMethods.find(x => x.id == id);
      return item || {};
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
