<template>
  <v-card flat>
    <v-card-title primary-title>
      <v-flex xs12 sm12>
        <div class="text-xs-left">
          <strong>Product Batches</strong>
        </div>
      </v-flex>
    </v-card-title>

    <v-data-table :headers="headers" :items="batches" class="elevation-1">
      <template v-slot:items="props">
        <td>{{ props.item.index }}</td>
        <td>{{ props.item.costPrice }}</td>
        <td>{{ props.item.quantity }}</td>
        <td>{{ moneyFormat(props.item.costPrice * props.item.quantity) }}</td>
        <!-- <td>{{ dateFormat(props.item.createdAt) }}</td> -->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

export default {
  props: ["data"],
  data() {
    return {
      batches: this.data.item.batches,
      headers: [
        { text: "#", value: "index" },
        { text: "Cost price", value: "costPrice" },
        { text: "Quantity", value: "quantity" },
        { text: "Total (NGN)", value: "total" },
        // { text: "Created", value: "createdAt" }
      ]
    };
  }
};
</script>
