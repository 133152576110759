<template>
  <layout>
    <v-layout row wrap>
      <v-flex xs12>
        <div v-if="!loaded">
          <v-progress-linear height="3" indeterminate></v-progress-linear>
        </div>
        <div v-else>
          <v-tabs slider-color="primary" grow>
            <v-tab>
              <h4>Customers</h4>
            </v-tab>
            <v-tab>
              <h4>Customer groups</h4>
            </v-tab>
            <v-tab-item>
              <partners
                :items="partners"
                :partner-groups="partnerGroups"
                @refreshData="refreshData"
              ></partners>
            </v-tab-item>
            <v-tab-item>
              <partner-groups
                :items="partnerGroups"
                @refreshData="refreshData"
              ></partner-groups>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-flex>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import Partners from "./Partners.vue";
import PartnerGroups from "./PartnerGroups.vue";
export default {
  components: {
    Layout,
    Partners,
    PartnerGroups,
  },
  computed: {
    loaded() {
      return !(this.loader.partners || this.loader.partnerGroups);
    }
  },
  created() {
    this.refreshData();
  },
  data() {
    return {
      loader: {
        partners: false,
        partnerGroups: false
      },
      partners: [],
      partnerGroups: []
    };
  },
  methods: {
    async getPartners() {
      this.loader.partners = true;
      this.partners = [];
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/partners/get-partners`);
        this.loader.partners = false;
          this.handleResponse(res, data => {
          //console.log(res, data);
          this.partners = data;
        });
      } catch (error) {
        this.loader.partners = false;
        this.handleError(
          error,
          "We couldn't fetch your partners. Please try again later."
        );
      }
    },
    async getPartnerGroups() {
      this.loader.partnerGroups = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/partners/groups`);
        this.loader.partnerGroups = false;
        this.handleResponse(res, data => {
          this.partnerGroups = data;
        });
      } catch (error) {
        this.loader.partnerGroups = false;
        this.handleError(
          error,
          "We couldn't fetch your partner groups. Please try again later."
        );
      }
    },
      refreshData() {
      this.getPartners();
      this.getPartnerGroups();
    }
  },
  //watch: {
  //  selectedStore: {
  //    handler() {
  //      this.refreshData();
  //    },
  //    deep: true
  //  }
  //}
};
</script>
