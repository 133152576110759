import Table_OtherSettings from "@/views/stores/Table_OtherSettings";

export default [
  {
    path: "/settings/table_other_settings",
    name: "Table/Other Settings",
    component: Table_OtherSettings,
    meta:["admin"]
  }
];
