<template>
  <v-card flat>
      <v-card-text>
          <div v-if="!proceed">
              <v-timeline :reverse="reverse" style="padding-top:0"
                          dense>
                  <v-timeline-item color="red" v-if="subscribed.subscriptionPlan">
                      <v-card class="elevation-2">
                          <v-card-title style="display: flex; justify-content: space-between; font-weight: bold">
                              <span style="font-size:16px">{{subscribed.subscriptionPlan.name }} (Current)</span>
                              <span>{{dateFormat(subscribed.startDate)}} - {{dateFormat(subscribed.endDate)}}</span>
                          </v-card-title>
                          <v-card-text>
                              <span>
                                  <v-icon slot="activator" color="green">check</v-icon> &nbsp; 
                                User(s) - {{subscribed.subscriptionPlan.userNumber == 0 ? 'Unlimited' : subscribed.subscriptionPlan.userNumber }}
                              </span><br />
                              <span>
                                  <v-icon slot="activator" color="green">check</v-icon> &nbsp; 
                                Product(s) {{subscribed.subscriptionPlan.productNumber == 0 ? 'Unlimited' : subscribed.subscriptionPlan.productNumber }}
                              </span><br />
                              <span>
                                  <v-icon slot="activator" color="green">check</v-icon> &nbsp; 
                                    Store(s) {{subscribed.subscriptionPlan.storeNumber == 0 ? 'Unlimited' : subscribed.subscriptionPlan.storeNumber }}
                              </span>
                          </v-card-text>
                      </v-card>
                  </v-timeline-item>
                  <v-timeline-item color="green" v-if="plan">
                      <v-card class="elevation-2">
                          <v-card-title style="display:flex;justify-content:space-between;font-weight:bold">
                              <span style="font-size:16px">{{plan.name }} (Selected)</span>
                          </v-card-title>
                          <v-card-text>
                              <span>
                                  <v-icon slot="activator" color="green">check</v-icon> &nbsp; User(s) - {{plan.userNumber == 0 ? 'Unlimited' : plan.userNumber }}
                              </span><br />
                              <span>
                                  <v-icon slot="activator" color="green">check</v-icon> &nbsp; Product(s) - {{plan.productNumber == 0 ? 'Unlimited' : plan.productNumber }}
                              </span><br />
                              <span>
                                  <v-icon slot="activator" color="green">check</v-icon> &nbsp; Store(s) - {{plan.storeNumber == 0 ? 'Unlimited' : plan.storeNumber }}
                              </span>
                          </v-card-text>
                      </v-card>
                  </v-timeline-item>
              </v-timeline>
              <br />
              <v-divider></v-divider><br /><br />
              <div style="display: flex; justify-content: flex-end; align-items: baseline;">
                  <p>The selected plan is a downgrade from the current subscription, do you want to proceed?</p>
                  <v-btn style="padding: 0 5px;" depressed color="red white--text" @click="cancel">Cancel</v-btn>
                  <v-btn style="padding: 0 5px;" depressed color="cyan white--text" @click="next">Proceed</v-btn>
              </div>

          </div>
            <div v-else>
                <h2>Select options</h2>
                <v-stepper v-model="e6" vertical>
                    <!--User Panel-->
                    <v-stepper-step :complete="e6 > 1" step="1" color="cyan">
                        Select user's list
                        <small>List of users that can access your business</small>
                    </v-stepper-step>

                    <v-stepper-content step="1">

                        <v-data-table v-model="selectedUsers"
                                        :headers="headers.users"
                                        :items="users"
                                        :pagination.sync="pagination"
                                        select-all
                                        item-key="id"
                                        class="elevation-1">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="toggleAll('users')"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                                        @click="changeSort(header.value)">
                                        <v-icon small>arrow_upward</v-icon>
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr :active="props.selected" @click="props.selected = !props.selected">
                                    <td>
                                        <v-checkbox :input-value="props.selected"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td class="text-xs-right">{{ props.item.full_name }}</td>
                                    <td class="text-xs-right">{{ props.item.phone_number }}</td>
                                    <td class="text-xs-right">{{ props.item.email }}</td>
                                    <td class="text-xs-right">{{ props.item.role }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                        <v-btn color="primary" @click="Pagination('users')" :disabled="selectedUsers.length < 1">Next</v-btn>
                        <v-btn flat color="red" @click="cancel">Cancel</v-btn>
                    </v-stepper-content>

                    <!--Store Panel-->
                    <v-stepper-step :complete="e6 > 2" step="2" color="cyan">
                        Select store.
                        <small>List of stores users can access(including it's product)</small>
                    </v-stepper-step>

                    <v-stepper-content step="2">

                        <v-data-table v-model="selectedStores"
                                      :headers="headers.stores"
                                      :items="stores"
                                      :pagination.sync="pagination"
                                      select-all
                                      item-key="id"
                                      class="elevation-1">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="toggleAll('stores')"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        @click="changeSort(header.value)">
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr :active="props.selected" @click="props.selected = !props.selected">
                                    <td>
                                        <v-checkbox :input-value="props.selected"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td class="text-xs-right">{{ props.item.name }}</td>
                                    <td class="text-xs-right">{{ props.item.code ? props.item.code : "NIL" }}</td>
                                    <td class="text-xs-right">{{ props.item.address }}</td>
                                    <td class="text-xs-right">{{ props.item.phone_number }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                        <v-btn color="primary" @click="Pagination('stores')"  :disabled="selectedStores.length < 1">Next</v-btn>
                        <v-btn flat color="red">Cancel</v-btn>
                    </v-stepper-content>


                    <!--Product Panel-->
                    <v-stepper-step :complete="e6 > 3" step="3" color="cyan">
                        Select product from the above listed store's products
                        <small>List of products available for business.</small>
                    </v-stepper-step>

                    <v-stepper-content step="3">

                        <v-data-table v-model="selectedProducts"
                                      :headers="headers.products"
                                      :items="products"
                                      :loading="loadingProducts"
                                      :pagination.sync="pagination"
                                      select-all
                                      item-key="id"
                                      class="elevation-1">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="toggleAll('products')"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        @click="changeSort(header.value)">
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr :active="props.selected" @click="props.selected = !props.selected">
                                    <td>
                                        <v-checkbox :input-value="props.selected"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td>{{ props.item.product.name }}</td>
                                    <td class="text-xs-right">{{ props.item.in_stock }}</td>
                                    <td class="text-xs-right">{{ moneyFormat(props.item.cost_price) }}</td>
                                    <td class="text-xs-right">{{ moneyFormat(props.item.selling_price) }}</td>
                                </tr>
                            </template>
                        </v-data-table>

                        <v-btn color="primary" @click="Pagination('products')"  :loading="loading_" :disabled="selectedProducts.length < 1">Continue</v-btn>
                        <!--<v-btn flat color="red" :disabled="!loading_">Cancel</v-btn>-->
                    </v-stepper-content>
                </v-stepper>

            </div>
      </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
  },
  data() {
    return {
      price: "",
        options: [],
        pagination: {
            sortBy: 'full_name'
        },
        selectedUsers: [],
        selectedStores: [],
        selectedProducts: [],
        headers: {
            users: [
                {
                    text: "Name",
                    value: "full_name"
                },
                {
                    text: "Phone",
                    value: "phone_number"
                },
                {
                    text: "Email",
                    value: "email"
                },
                {
                    text: "Role",
                    value: "role"
                },
            ],
            stores: [
                {
                    text: "Store name",
                    align: "left",
                    value: "name"
                },
                {
                    text: "Store code",
                    value: "code"
                },
                {
                    text: "Address",
                    value: "address"
                },
                {
                    text: "Phone Number",
                    value: "phone_number"
                },
            ],
            products: [
                {
                    text: "Product name",
                    align: "left",
                    value: "name"
                },
                {
                    text: "In stock",
                    value: "in_stock"
                },
                {
                    text: "Cost price",
                    value: "cost_price"
                },
                {
                    text: "Selling price",
                    value: "selling_price"
                },
            ]
        },
      e6: 1,
      valid: true,
      users: [],
      proceed: false,
      search: {
        users: ""
      },
      loading: {
          users: false,
          products:false
        },
      loadingProducts: false,
      loading_:false,
      roles: [],
      stores: [],
      products: [],
      reverse: true,
      variants_: [],
      payload: {
          users: [],
          stores: [],
          products: []
       }
    };
  },
  mounted() {
    this.getStores();
    this.getUsers();
  },
  computed: {
      subscribed() {
          return this.$store.state.subscription.latestSubscription || {};
      },
  },
  methods: {
      next() {
          this.proceed = true;
        this.$emit("handleWidth","70%");
    },
      cancel() {
          this.proceed = false;
          this.$emit("closeSidePanel")
      },
      closed() {
          this.proceed = false;
      },
      async getStores() {
          try {
              const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
              this.handleResponse(res, data => {
                  this.stores = data;
              });
          } catch (error) {
              this.handleError(
                  error,
                  "Couldn't fetch stores. Please try again later."
              );
          }
      },
      async getUsers() {
          this.loading = true;
          try {
              const res = await this.$http.get(`${this.apiBaseUrl}/users`);
              this.loading = false;
              this.handleResponse(res, data => {
                  this.users = data;
              });
          } catch (error) {
              this.loading = false;
              this.handleError(
                  error,
                  "We couldn't fetch your users. Please try again later."
              );
          }
      },
      changeSort(column) {
          if (this.pagination.sortBy === column) {
              this.pagination.descending = !this.pagination.descending
          } else {
              this.pagination.sortBy = column
              this.pagination.descending = false
          }
      },
      toggleAll(type) {
          if (type == 'users') {

              if (this.selectedUsers.length) this.selectedUsers = []
              else this.selectedUsers = this.users.slice();

          } else if (type == 'stores') {

              if (this.selectedStores.length) this.selectedStores = []
              else this.selectedStores = this.stores.slice();
          }
          else {

              if (this.selectedProducts.length) this.selectedProducts = []
              else this.selectedProducts = this.products.slice();
          }
      },
      Pagination(type) {
          switch (type) {
              case "users":

                  this.e6 = 2;
                  this.selectedUsers.forEach(x => {
                      this.payload.users.push(x.id);
                  });

                  break;
              case "stores":

                  this.e6 = 3;
                  this.selectedStores.forEach(x => {
                      this.payload.stores.push(x.id);
                  });
                  this.getStoreProducts();
                  break;

              default:
                  //this.e6 = 4
                  this.selectedProducts.forEach(x => {
                      this.payload.products.push(x.product_id);
                  });
                  this.storeSelectedItems();
          }
      },
      async getStoreProducts() {
          try {
              this.loadingProducts = true;
              var temp = {
                  storeIds: this.payload.stores
              };
              const payload = Object.assign({}, temp);
              const res = await this.$http.post(`${this.apiBaseUrl}/storeproducts/get-storeproducts`, payload);
              this.products = res.data.data;
          } catch (error) {
              //console.log(error);
          }
          this.loadingProducts = false;
      },
      async storeSelectedItems() {
          try {
              this.loading_ = true;
              var temp = {
                  storeIds: this.payload.stores,
                  productsIds: this.payload.products,
                  usersIds: this.payload.users,
                  planId: this.plan.id,
                  type:this.type
              };
              const payload = Object.assign({}, temp);
              const res = await this.$http.post(`${this.apiBaseUrl}/subscriptions/downgrade-plan`, payload);

              localStorage.setItem('ecr_reference', JSON.stringify({ 'reference': res.data.reference, 'type': this.type, 'is_upgrade': false }));
              window.location = res.data.data;
          } catch (error) {
              if (error.response.data) {
                  this.showSnackbar(`${error.response.data.message}. Please try again`, {
                      type: "error"
                  });
                  window.location.reload(true);
              } else {
                  alert("An error occured");
              }
          }
          this.loading_ = false;
      },
  },

  props: ["plan","type"],
  watch: {
      selectedUsers: {
          handler() {
              if (this.plan.userNumber != 0)
                  if (this.selectedUsers.length > this.plan.userNumber) {
                      this.selectedUsers = this.selectedUsers.slice(-1);
                      this.showSnackbar(`You can only select ${this.plan.userNumber} user(s)`, {
                          type: "error"
                      });

                  }
        },
        deep: true
    },
      selectedStores: {
          handler() {
              if (this.plan.storeNumber != 0)
                  if (this.selectedStores.length > this.plan.storeNumber) {
                      this.selectedStores = this.selectedStores.slice(-1);
                      this.showSnackbar(`You can only select ${this.plan.storeNumber} store(s)`, {
                          type: "error"
                      });

                  }
        },
        deep: true
    },
      selectedProducts: {
          handler() {
              if (this.plan.productNumber != 0)
                  if (this.selectedProducts.length > this.plan.productNumber) {
                      this.selectedProducts = this.selectedProducts.slice(-1);
                      this.showSnackbar(`You can only select ${this.plan.productNumber} product(s)`, {
                          type: "error"
                      });
                  }
        },
        deep: true
    },
  }
};
</script>

<style scoped>
.preview-area {
  padding: 10px;
  border-radius: 5px;
  background-color: #0000000f;
}

.preview-area p {
  font-size: 0.8em;
}
</style>
