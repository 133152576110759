<template>
  <v-card flat>
    <v-card-title>
      <h2>Attribute details</h2>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form
        lazy-validation
        ref="attr_form"
        @submit.prevent="saveOrUpdateAttribute"
      >
        <v-layout row wrap>
          <v-flex xs12 px-2>
            <v-text-field
              label="Name"
              v-model="attribute.name"
              :rules="formRules.required"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex md6 px-2>
            <v-checkbox
              required
              v-model="attribute.active"
              label="Active"
            ></v-checkbox>
          </v-flex>
          <v-flex md6 px-2>
            <v-layout justify-end>
              <v-btn
                class="default"
                :disabled="posting"
                @click="resetAttribute"
              >
                Reset
              </v-btn>
              &nbsp;
              <v-btn class="primary" type="submit" :disabled="posting">
                {{ saveText }}
              </v-btn>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
      <br />
      <br />
      <br />
      <div v-show="attribute.id">
        <v-form
          lazy-validation
          ref="opt_form"
          @submit.prevent="saveOrUpdateOption"
          class="op-form"
        >
          <h3>Add option to attribute</h3>
          <v-layout row wrap>
            <v-flex xs12 px-2>
              <v-text-field
                label="Name"
                v-model="option.name"
                :rules="formRules.required"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex md6 px-2>
              <v-checkbox
                required
                v-model="option.active"
                label="Active"
              ></v-checkbox>
            </v-flex>
            <v-flex md6 px-2>
              <v-layout justify-end>
                <v-btn class="default" :disabled="posting" @click="resetOption">
                  Reset
                </v-btn>
                <v-btn class="primary" :disabled="posting" type="submit">
                  {{ saveOptionText }}
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
        <v-layout row wrap>
          <v-flex xs12>
            <v-data-table
              :items="options"
              :rows-per-page-items="[20]"
              :loading="loading"
            >
              <template slot="no-data">
                No options for this attribute. Add a new option above.
              </template>
              <template slot="items" slot-scope="props">
                <td>{{ props.item.name }}</td>
                <td>
                  <v-icon
                    :class="`${props.item.active ? 'primary' : 'red'}--text`"
                  >
                    {{ props.item.active ? "check" : "clear" }}
                  </v-icon>
                </td>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  computed: {
    saveOptionText() {
      return this.option.id
        ? this.posting
          ? "Updating"
          : "Update"
        : this.posting
        ? "Saving"
        : "Save";
    },
    saveText() {
      return this.attribute.id
        ? this.posting
          ? "Updating"
          : "Update"
        : this.posting
        ? "Saving"
        : "Save";
    }
  },
  data() {
    return {
      // attribute: {},
      loading: false,
        options: [],
        header: [
            {
                text: "Name",
                value: "name"
            },
            {
                text: "Status",
                value: "active"
            }
        ],
      option: {},
      posting: false
    };
  },
  methods: {
    resetAttribute() {
      // this.attribute = {};
      this.$emit("reset");
      this.options = [];
      this.$refs.attr_form.reset();
    },
    resetOption() {
      this.option = {};
      this.$refs.opt_form.reset();
    },
    async saveAttribute(attribute) {
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/productattributes`,
          attribute
        );
        await this.handleResponse(res, data => {
          // this.attribute = data;
          this.showSnackbar("Attribute added successfully.");
          this.$emit("result", {
            action: "save",
            data,
            type: "attribute"
          });
        });
      } catch (error) {
        console.log(error);
        await this.handleError(
          error,
          "Operation failed. Please try again later."
        );
      }

      this.posting = false;
    },
    async saveOption(option) {
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/productattributeoptions`,
          option
        );
        await this.handleResponse(res, data => {
          this.option = data;
          this.showSnackbar("Option added successfully.");
          this.$emit("result", {
            action: "save",
            data,
            type: "option"
          });
          this.options.push(data);
          this.resetOption();
        });
      } catch (error) {
        console.log(error);
        await this.handleError(
          error,
          "Operation failed. Please try again later."
        );
      }

      this.posting = false;
    },
    saveOrUpdateAttribute() {
      if (!this.$refs.attr_form.validate()) return;
      const attr = Object.assign({}, this.attribute);
      attr.id ? this.updateAttribute(attr) : this.saveAttribute(attr);
    },
    async saveOrUpdateOption() {
      if (!this.$refs.opt_form.validate()) return;
      const option = Object.assign({}, this.option);
      if (!option.id) {
        // This is a new option
        option.attribute_id = this.attribute.id;
        this.saveOption(option);
      } else {
        // Option already has an attribute ID
        // Just update it
        this.updateOption(option);
      }
    },
    async updateAttribute(attr) {
      this.posting = true;
      try {
        const res = await this.$http.patch(
          `${this.apiBaseUrl}/productattributes`,
          attr
        );
        await this.handleResponse(res, data => {
          // this.attribute = data;
          this.showSnackbar("Attribute updated successfully.");
          this.$emit("result", {
            action: "update",
            data,
            type: "attribute"
          });
        });
      } catch (error) {
        console.log(error);
        await this.handleError(
          error,
          "Operation failed. Please try again later."
        );
      }

      this.posting = false;
    },
    async updateOption(option) {
      //

      console.log(option);
    }
  },
  props: {
    attribute: Object
  },
  watch: {
    attribute: {
      immediate: true,
      handler(attr) {
        if (attr) {
          this.options = attr.options;
        }
      }
    }
  }
};
</script>

<style scoped>
.op-form {
  padding: 10px;
  background-color: #0000000f;
}
</style>
