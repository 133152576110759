<template>
  <div>
    <v-layout justify-end pt-3>
      <v-btn color="primary" @click="showEntryDetails()" :disabled="!warehouse">
        New &nbsp;
        <v-icon small>
          add_circle_outline
        </v-icon>
      </v-btn>
    </v-layout>
    <v-layout>
      <v-flex sm6 md4 offset-sm6 offset-md8>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search entries"
          single-line
          hide-details
          clearable
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex sm12>
        <v-data-table
          :items="entries"
          :rows-per-page-items="[20]"
          :search="search"
          :headers="entryHeaders"
          :loading="loading.entries"
          item-key="id"
        >
          <template slot="no-data">
            No entries.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.warehouse.name }}</td>
            <td>{{ props.item.description || "" }}</td>
            <td>{{ props.item.created_by.full_name || "" }}</td>
            <!-- <td>{{ props.item.warehouse.type || 'Virtual' }}</td> -->
            <td>{{ momentFormat(props.item.created_at) || "" }}</td>
            <td>
              <v-tooltip bottom>
                <v-icon
                  slot="activator"
                  @click="showEntryDetails(props.item.id)"
                  >info</v-icon
                >
                <span>Details</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialogs.productsList"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <products-list
        lv-if="dialogs.productsList"
        :items="productsList"
        :loading="loading.products"
        @result="handleProductListResult"
        :allowSave="allowEditAndSave"
      ></products-list>
    </v-dialog>
    <v-dialog v-model="dialogs.entry" max-width="500px" persistent>
      <v-card>
        <v-card-title class="primary-title">
          <h3>Entry details</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-progress-linear
            :active="posting"
            indeterminate
            :height="3"
            class="top-progress"
          ></v-progress-linear>
          <v-form lazy-validation ref="entry_form" @submit.prevent="addEntry">
            <v-layout row wrap>
              <v-flex sm12>
                <v-textarea
                  v-model="entry.description"
                  :rules="formRules.required"
                  :validate-on-blur="true"
                  label="Description"
                  rows="4"
                  placeholder="Enter entry description"
                  name="description"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-spacer></v-spacer>
              <v-btn
                type="button"
                :disabled="posting"
                flat
                @click.native="dialogs.entry = false"
                >Cancel</v-btn
              >
              <v-btn type="submit" :disabled="posting" flat color="primary"
                >Add</v-btn
              >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductsList from "./ProductsList";
export default {
  components: {
    ProductsList
  },
  data() {
    return {
      allowEditAndSave: true,
      dialogs: {
        entry: false,
        productsList: false
      },
      entry: {},
      entryHeaders: [
        {
          text: "Warehouse",
          align: "left",
          value: "warehouse.name"
        },
        {
          text: "Description",
          value: "description"
        },
        {
          text: "Added by",
          value: "created_by.full_name"
        },
        // {
        //   text: "Warehouse type",
        //   value: "warehouse.type"
        // },
        {
          text: "Date",
          value: "created_at"
        },
        {
          text: "Actions",
          value: null
        }
      ],
      entries: [],
      loading: {
        entries: false,
        products: false
      },
      posting: false,
      productsList: [],
      search: ""
    };
  },
  methods: {
    async addEntry() {
      const entryForm = this.$refs.entry_form;
      if (!entryForm.validate()) return;
      const entry = Object.assign({}, this.entry);

      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/inventory/entries`,
          entry
        );
        // Close dialogs if successful.
        this.closeProductListDialog();
        this.dialogs.entry = false;
        await this.handleResponse(res, data => {
          this.entries.push(data);
          entryForm.reset();
          this.showSnackbar("Entry added successfully.");
          console.log(data);
        });
      } catch (error) {
        console.log(error);
        await this.handleError(error);
      }

        this.posting = false;
      },
      closeProductListDialog() {
        this.allowEditAndSave = true;
        this.dialogs.productsList = false;
      },
      convertToProductsList(list) {
        return list.map(x => {
          const {
            product,
            current_situation,
            actual_situation,
            surplus,
            deficit
          } = x;

          const { unit_of_measurement, product_group } = product;

          const entry = {
            id: product.id,
            name: product.name,
            code: product.code,
            product_group: product_group ? product_group.name : '',
            unit_of_measurement:unit_of_measurement ? unit_of_measurement.name : '',
            current_situation,
            actual_situation,
            surplus,
            deficit
          };

        return entry;
      });
    },
    editEntry(entry) {
      console.log(entry);
    },
    formatStorageStatus(list) {
      const formatted = list.map(x => {
        return {
          product: Object.assign({}, x.product),
          current_situation: x.in_stock,
          actual_situation: x.in_stock,
          surplus: 0,
          deficit: 0
        };
      });

      return this.convertToProductsList(formatted);
    },
    async getEntries(filters) {
      const { startDate, endDate } = filters;
      let url = `${
        this.apiBaseUrl
      }/inventory/entries?startDate=${startDate}&endDate=${endDate}`;
      if (!this.warehouse) return;

      url += `&warehouseId=${this.warehouse.id}`;

      // Show loader
      this.loading.entries = true;
      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.entries = data;
        });
      } catch (error) {
        await this.handleError(error, "We couldn't get your inventory entries");
      }

      // Hide loader
      this.loading.entries = false;
    },
    async getStorageStatus() {
      let url = `${this.apiBaseUrl}/inventory/storage-status`;
      if (!this.warehouse) return;

      url += `?warehouseId=${this.warehouse.id}`;

      // Show loader
      this.loading.products = true;
      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.productsList = this.formatStorageStatus(data);
        });
      } catch (error) {
        await this.handleError(error, "We couldn't get your products list");
      }

      // Hide loader
      this.loading.products = false;
    },
    async getInventoryDetails(id) {
      let url = `${this.apiBaseUrl}/inventory/entries/${id}/items`;

      // Show loader
      this.loading.products = true;
      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.productsList = this.convertToProductsList(data);
        });
      } catch (error) {
        await this.handleError(error, "We couldn't get your entry items.");
      }

      // Hide loader
      this.loading.products = false;
    },
    handleProductListResult(result) {
      if (!result) {
        this.closeProductListDialog();
        return;
      }
      this.dialogs.entry = true;
      this.entry.items = result.map(x => {
        return {
          product_id: x.id,
          current_situation: x.current_situation,
          actual_situation: x.actual_situation
        };
      });
      this.entry.warehouse_id = this.warehouse.id;
      this.entry.store_id = this.warehouse.store_id;
    },
    showEntryDetails(id) {
      this.dialogs.productsList = true;
      if (id) {
        this.allowEditAndSave = false;
        return this.getInventoryDetails(id);
      }
      return this.getStorageStatus();
    }
  },
  props: {
    filters: Object,
    warehouse: Object
  },
  watch: {
    filters: {
      handler(filter) {
        this.getEntries(filter);
      },
      deep: true
    },
    warehouse: {
      handler() {
        this.getEntries(this.filters);
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped>
.top-progress {
  margin: 0 0 2px;
}
</style>
