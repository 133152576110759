<template>
  <div>
    <br />
    <v-divider></v-divider>
    <br />
    <v-data-table
      :items="products"
      :rows-per-page-items="[20]"
      search
      :headers="headers.products"
    >
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.quantity }}</td>
        <td>
          <!-- <v-btn flat icon color="red" small>
                    <v-icon>delete</v-icon>
                  </v-btn> -->
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: {
        products: [
          {
            text: "Product name",
            align: "left",
            value: "name"
          },
          {
            text: "Quantity",
            value: "quantity"
          },
          {
            text: "",
            value: ""
          }
          //   {
          //     text: "Single unit",
          //     value: "single_unit"
          //   },
          //   {
          //     text: "Compound unit",
          //     value: "compound_unit"
          //   },
          //   {
          //     text: "Product group",
          //     value: "product_group"
          //   },
          //   {
          //     text: "Actions",
          //     value: ""
          //   }
        ]
      }
    };
  },
  props: {
    products: Array
  }
};
</script>
