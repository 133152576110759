<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-menu
            ref="startDayMenu"
            v-model="startDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.startDate"
                label="Start date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="startDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.startDayMenu.save(filter.startDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-menu
            ref="endDayMenu"
            v-model="endDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.endDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.endDate"
                label="End Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="endDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.endDayMenu.save(filter.endDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-btn color="info" @click="getData"> Filter </v-btn>
        </v-flex>

        <v-flex xs12 sm3>
          <div>
            <DownloadCSV
              class="v-btn theme--light success"
              :data="filterReceipts"
              :name="filename"
              v-if="filterReceipts.length"
            >
              Export
            </DownloadCSV>
          </div>
        </v-flex>
      </v-layout>

      <v-data-table
        disable-initial-sort
        :items="receipts"
        :rows-per-page-items="[50]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No data found.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.number }}</td>
          <td class="text-xs-right">
            {{ moneyFormat(props.item.amount_tendered) }}
          </td>
          <td class="text-xs-right">
            {{ moneyFormat(props.item.transaction_amount) }}
          </td>
          <!-- <td class="text-xs-right">
                      {{ moneyFormat(props.item.transaction_discounts.discount_value) }}
                  </td> -->
          <td class="text-xs-right">
            {{
              props.item.transaction_discounts.length > 0
              ? moneyFormat( 
                props.item.transaction_discounts[0].discount_value
              )
              : "0.00"
            }}
          </td>
          <td>
            {{
              getPartner(props.item.partner_id).name
                ? getPartner(props.item.partner_id).name
                : "One-Time Customer"
            }}
          </td>
          <td>{{ props.item.seller_name }}</td>
          <td>{{ justDate(props.item.date_time) || "" }}</td>
          <td>{{ justTime(props.item.date_time) || "" }}</td>
          <td class="text-xs-right">
            {{
              props.item["store_products"]
                ? props.item.store_products.length
                : 0
            }}
          </td>
          <td>
            <v-tooltip bottom>
              <v-icon slot="activator" @click="showInfo(props.item)"
                >info</v-icon
              >
              <span>View details</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>

    <!-- Item dialog -->
    <v-dialog v-model="showItemDialog" max-width="400px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <v-flex xs12 sm6 px-2>
            <h3>Products</h3>
          </v-flex>
          <v-flex
            xs12
            sm6
            px-2
            text-xs-right
            v-if="selectedItem.amount_tendered < 1"
          >
            <v-btn
              small
              color="blue"
              style="color: white"
              @click="$router.push(`invoice/${selectedItem.id}`)"
            >
              Generate Invoice
            </v-btn>
          </v-flex>
        </v-card-title>
        <template>
          <v-data-table
            :headers="productHeaders"
            :items="selectedItem.store_products"
            hide-actions
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td>{{ props.item.name }}</td>
              <td class="text-xs-right">{{ props.item.quantity }}</td>
              <td class="text-xs-right">{{ moneyFormat(props.item.price) }}</td>
              <td>
                <!-- <v-tooltip bottom>
                        <v-icon slot="activator" v-if="selectedItem.transaction_discounts" @click="showDiscountInfo(selectedItem.transaction_discounts)">info</v-icon>
                        <span>View discounts</span>
                      </v-tooltip> -->
              </td>
            </template>
          </v-data-table>

          <div class="pa-1">
            <v-layout row wrap align-right justify-end>
              <v-flex xs10 sm6>
                Total Amount:
                {{ calculateTotalAmount(selectedItem.store_products) }}
                <v-tooltip bottom>
                  <v-icon
                    slot="activator"
                    v-if="selectedItem.transaction_discounts"
                    @click="
                      showDiscountInfo(selectedItem.transaction_discounts)
                    "
                    >info</v-icon
                  >
                  <span>View discounts</span>
                </v-tooltip>
              </v-flex>
            </v-layout>
          </div>
        </template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Item dialog -->

    <!-- Discount dialog -->
    <v-dialog v-model="showDiscountDialog" max-width="400px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <h3>Discounts</h3>
        </v-card-title>

        <v-data-table
          :headers="discountHeaders"
          :items="selectedItem.transaction_discounts"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.discount_rate }}</td>
            <td class="text-xs-right">
              {{ moneyFormat(props.item.discount_value) }}
            </td>
            <td>{{ props.item.discount_name }}</td>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showDiscountDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Discount dialog -->
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import JsonCSV from "vue-json-csv";
import moment from "moment";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV,
  },
  mounted() {
    this.getData();
    this.fetchPartners();
  },
  computed: {
    filterReceipts() {
      return this.receipts.map((item) => ({
        ReceiptNumber: item.number,
        AmountTendered: item.amount_tendered,
        TransactionAmount: item.transaction_amount,
        Discount: item.transaction_discounts && item.transaction_discounts.length > 0
        ? item.transaction_discounts[0].discount_value
        : "0.00",
        CustomerName: this.getPartner(item.partner_id).name,
        SellerName: item.seller_name,
        Date: this.justDate(item.date_time),
        Time: this.$moment(item.date_time).format("HH:mm") || "",
        //Time: this.justTime(item.date_time)
      }));
    },
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10),
        paymentMethod: "",
      },
      productHeaders: [
        {
          text: "Name",
          value: "id",
        },
        {
          text: "Quantity",
          value: "quantity",
          align: "right",
        },
        {
          text: "Price",
          value: "price",
          align: "right",
        },
      ],
      discountHeaders: [
        {
          text: "Discount rate",
          value: "discount_rate",
        },
        {
          text: "Amount",
          value: "discount_value",
          align: "right",
        },
        {
          text: "Discount name",
          value: "discount_name",
        },
      ],
      headers: [
        {
          text: "Receipt number",
          value: "number",
        },
        {
          text: "Amount tendered",
          value: "amount_tendered",
          align: "right",
        },
        {
          text: "Total Amount",
          value: "transaction_amount",
          align: "right",
        },
        {
          text: "Discount",
          value: "discount_value",
          align: "center",
        },
        {
          text: "Customer Name",
          value: "partner_id",
        },
        {
          text: "Seller",
          value: "seller_name",
        },
        {
          text: "Date",
          value: "date_time",
        },
        {
          text: "Time",
          value: "date_time",
        },
        {
          text: "Products",
          value: "products",
          align: "right",
        },
        {
          text: "",
          value: "",
        },
      ],
      loading: false,
      selectedItem: {},
      selectedProduct: {},
      partners: [],
      receipts: [],
      search: "",
      filename: "",
      showItemDialog: false,
      showDiscountDialog: false,
    };
  },
  methods: {
    calculateTotalAmount(storeProducts) {
      if (storeProducts && storeProducts.length > 0) {
        const totalAmount = storeProducts.reduce(
          (total, product) => total + product.price,
          0
        );
        return this.moneyFormat(totalAmount);
      } else {
        return this.moneyFormat(0);
      }
    },
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      if (storeId === 0) {
        return;
      }
      this.filename =
        this.filter.startDate +
        "--" +
        this.filter.endDate +
        "--export-receipts.csv";
      let url = `${this.apiBaseUrl}/receipts/get-receipts?storeId=${storeId}&startDate=${this.filter.startDate}&endDate=${this.filter.endDate}`;
      this.loading = true;
      const params = {
        storeId: storeId,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
      };
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, (data) => (this.receipts = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    },
    async fetchPartners() {
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/partners`);
        this.partners = data.data;
      } catch (error) {
        console.log(error);
        this.handleError(error, "Error fetching partners");
      }
      this.loading = false;
    },
    showInfo(receipt) {
      if (receipt) {
        this.selectedItem = receipt;
      }
      this.showItemDialog = true;
    },
    getPartner(id) {
      const partner = this.partners.find((x) => x.id == id);

      return partner || {};
    },
    showDiscountInfo(product) {
      if (product) {
        this.selectedProduct = product;
      }
      this.showDiscountDialog = true;
    },
  },
  watch: {
    selectedStore: {
      handler() {
        //this.getData();
      },
      deep: true,
    },
  },
};
</script>
