<template>
  <v-card flat>
    <v-card-title class="pt-0">
      <v-layout row wrap>
        <v-flex xs12 text-sm-right>
          <v-btn @click="showVarianceUploadDialog" color="warning" pre>
            <v-icon>file_upload</v-icon>&nbsp; Upload product variances
          </v-btn>
          <v-btn @click="showUploadDialog" color="primary" pre>
            <v-icon>file_upload</v-icon>&nbsp; Upload products
          </v-btn>
          <v-btn @click="showProductDialog" color="info" pre>
            <v-icon>add_circle_outline</v-icon>&nbsp; Add product
          </v-btn>
          <v-divider></v-divider>
          <br />
        </v-flex>
        <v-flex xs12>
          <v-layout>
            <v-spacer></v-spacer>
            <v-flex sm6>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search product"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-data-table
      :items="products"
      :rows-per-page-items="[10]"
      :search="search"
      :headers="productHeaders"
      :loading="loading.products"
    >
      <template slot="no-data">No products. Add a new product above.</template>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.product_group.name || "" }}</td>
        <td class="text-sm-right">{{ moneyFormat(props.item.price) }}</td>
        <!-- <td>
            <v-checkbox readonly v-model="props.item.is_subscribed"></v-checkbox>
        </td> -->
        <td>
          <v-checkbox readonly v-model="props.item.is_for_sale"></v-checkbox>
        </td>
        <!-- <td>
          <v-checkbox readonly v-model="props.item.is_returnable"></v-checkbox>
        </td>-->
        <!--<td>
          <v-checkbox readonly v-model="props.item.is_complex"></v-checkbox>
        </td>-->
        <td>
          <v-checkbox readonly v-model="props.item.is_service"></v-checkbox>
        </td>
        <td>
          <v-icon small @click="showProductInfo(props.item)">edit</v-icon>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="uploadVarianceFormDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title primary-title class="headline grey lighten-2">
          <h5>Upload Product Variance</h5>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-btn @click="downloadVarianceFile" flat :disabled="posting"
            >Click here to download sample file here</v-btn
          >

          <v-form lazy-validation ref="upload_form">
              <upload-btn :fileChangedCallback="fileChanged" :disabled="posting">
                  <template slot="icon-left">
                      <v-icon color="white">file_upload</v-icon>
                  </template>
              </upload-btn>
              {{ fileupload.name ? fileupload.name : "No file selected" }}
              <v-layout>
                  <v-spacer></v-spacer>
                  <v-btn @click="closeUploadVarianceFormDialog" flat :disabled="posting">Close</v-btn>
                  <v-btn @click="uploadProductVarianceFile" flat color="primary" :disabled="posting" :loading="posting">Upload file</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadFormDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title primary-title class="headline grey lighten-2">
          <h3>Upload Products</h3>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-btn @click="downloadFile" flat :disabled="posting"
            >Click here to download sample file here</v-btn
          >

          <v-form lazy-validation ref="upload_form">
            <upload-btn :fileChangedCallback="fileChanged" :disabled="posting">
              <template slot="icon-left">
                <v-icon color="white">file_upload</v-icon>
              </template>
            </upload-btn>
            {{ fileupload.name ? fileupload.name : "No file selected" }}
          <v-layout>
              <v-spacer></v-spacer>
              <v-btn @click="closeUploadFormDialog" flat :disabled="posting">Close</v-btn>
              <v-btn @click="uploadProductFile" flat color="primary" :disabled="posting" :loading="posting">Upload file</v-btn>
          </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="productDialog" hide-overlay fullscreen persistent>
      <v-toolbar dark color="primary">
        <v-btn @click.native="closeProductDialog" flat :disabled="posting">
          <v-icon>close</v-icon>&nbsp;Close
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-card-title primary-title>
          <h3>Enter product details</h3>
          <v-spacer></v-spacer>
            <v-btn
            @click="attributesDialog = true"
            :disabled="posting"
            color="grey white--text"
            v-if="product.has_variance"
            >Attribute</v-btn
          >
          <v-btn
            @click="showProductVariantForm"
            :disabled="posting"
            color="teal white--text"
            v-if="product.has_variance"
            >Add Variants</v-btn
          >
          <v-btn
            @click="saveProduct"
            :disabled="posting || productGroups.length < 1"
            color="primary"
            >{{ saveText }}</v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap>
            <v-flex lg10 offset-lg1>
              <v-form lazy-validation ref="new_product_form">
                <v-layout row wrap>
                  <!--<v-flex sm2 px-2>
                    <div>
                      <div class="product-img" @click="showFilePicker">
                        <img :src="productImg" alt />
                        <span>Click image to update it.</span>
                        <div class="inverse"></div>
                        <div class="img-loader">
                          <span class="spinner s bdblue"></span>
                        </div>
                      </div>
                      <br />
                      <input
                        type="file"
                        name="img-picker"
                        accept="image/*"
                        id="img-picker"
                        @change="photoPreview"
                      />
                    </div>
                  </v-flex>-->
                  <!--<v-spacer></v-spacer>-->
                  <v-flex sm10 offset-sm-2>
                    <v-layout row wrap>
                      <v-flex sm6 px-2>
                        <v-text-field
                          required
                          v-model="product.name"
                          label="Product name"
                          :rules="formRules.required"
                        ></v-text-field>
                      </v-flex>
                      <v-flex sm6 px-2>
                        <v-text-field
                          required
                          v-model="product.code"
                          label="Product code"
                          :rules="formRules.required"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex sm6 px-2>
                        <v-text-field
                          required
                          v-model="product.precision"
                          type="number"
                          label="Product precision"
                          :rules="formRules.amount"
                        ></v-text-field>
                      </v-flex>
                      <v-flex sm6 px-2>
                        <v-autocomplete
                          :loading="loading.unitsOfMeasurement"
                          required
                          v-model="product.unit_of_measurement_id"
                          :items="activeUnits"
                          item-text="name"
                          item-value="id"
                          label="Unit of measurement"
                          :rules="formRules.required"
                        ></v-autocomplete>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex sm6 px-2>
                        <v-autocomplete
                          :loading="loading.productGroups"
                          required
                          v-model="product.product_group_id"
                          :items="activeGroups"
                          item-text="name"
                          item-value="id"
                          label="Product group"
                          :rules="formRules.required"
                        ></v-autocomplete>
                      </v-flex>
                      <v-flex sm6 px-2>
                        <!-- <v-text-field
                          v-model="product.expiry_date"
                          :value="formatDate(product.expiry_date)"
                          type="date"
                          label="Product Expiry Date"
                        ></v-text-field> -->
                        <v-layout row wrap>
                          <v-flex sm6 px-2>
                            <v-checkbox
                              v-model="product.monitor_stock"
                              label="Monitor stock"
                              @change="setMonitorStockValue"
                            ></v-checkbox>
                          </v-flex>
                          <v-flex sm6 px-2>
                            <v-checkbox
                              v-model="product.is_returnable"
                              label="Returnable?"
                            ></v-checkbox>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex sm6 px-2>
                        <v-text-field
                          required
                          v-model="product.price"
                          type="number"
                          label="Default price"
                          :rules="formRules.amount"
                        ></v-text-field>
                      </v-flex>
                      <v-flex sm6 px-2>
                        <v-layout row wrap>
                          <v-flex sm6 px-2>
                            <v-switch
                              v-model="product.is_for_sale"
                              :value="false"
                              label="For sale?"
                            ></v-switch>
                          </v-flex>
                          <v-flex sm6 px-2>
                            <v-switch
                              v-model="product.is_service"
                              label="Is a service?"
                            ></v-switch>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                      <v-flex sm6 px-2>
                        <v-text-field
                          v-model="product.minimum_stock"
                          type="number"
                          label="Minimum stock"
                          :disabled="!product.monitor_stock"
                          :rules="
                            formRules.dynamic(
                              product.monitor_stock,
                              'amount'
                            )
                          "
                        ></v-text-field>
                      </v-flex>
                      <v-flex sm6 px-2>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <br />
                <v-divider></v-divider>
                <br />
                <v-layout row wrap>
                  <!-- <v-flex sm3 px-2>
                    <v-switch
                      v-model="product.is_complex"
                      @change="handleComplexProduct"
                      label="Complex product?"
                    ></v-switch>
                  </v-flex>-->
                    <v-flex sm3 px-2>
                    <v-switch
                      v-model="product.has_variance"
                      label="Product variance"
                    ></v-switch>
                  </v-flex>
                  <v-flex sm3 px-2>
                    <v-switch
                      v-model="product.is_volatile"
                      @change="volatileChanged"
                      label="Volatile price?"
                    ></v-switch>
                  </v-flex>
                  <v-flex sm3 px-2>
                    <v-switch
                      v-model="product.has_tax"
                      :value="false"
                      :disabled="product.is_volatile === true"
                      label="Taxable?"
                    ></v-switch>
                  </v-flex>
                  <v-flex sm3 px-2>
                    <v-select
                      :loading="loading.taxGroups"
                      :disabled="product.has_tax !== true"
                      :required="product.has_tax == true"
                      v-model="product.tax_group"
                      :items="activeTaxGroups"
                      item-text="name"
                      item-value="id"
                      label="Tax group"
                      :rules="rules.taxGroup"
                    ></v-select>
                  </v-flex>
                </v-layout>
                <br />
                <v-divider></v-divider>
                <br />
                <template v-if="selectedSimpleProducts.length > 0">
                  <selected-simple-products
                    :products="selectedSimpleProducts"
                  ></selected-simple-products>
                </template>
                <template v-if="product.has_variance">
                  <selected-product-variants
                    :products="productVariants"
                  ></selected-product-variants>
                </template>
                <!--<complex-dialog
                  :show-dialog="complexProductDialog"
                  @closeComplexDialog="closeComplexProductDialog"
                  @simpleProductAdded="addSimpleProduct"
                  :products="simpleProducts"
                ></complex-dialog>-->
              </v-form>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="attributesDialog" persistent max-width="900px">
      <v-card flat>
        <v-card-title primary-title>
          <v-layout justify-space-between fill-height align-center>
            <h3>Add attributes</h3>
            <v-btn flat @click="closeAttributeDialog">
              Close &nbsp;
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap fill-height>
            <v-flex sm6 px-2>
              <v-layout row wrap>
                <v-spacer></v-spacer>
                <v-flex sm12>
                  <v-text-field
                    v-model="attributeSearch"
                    append-icon="search"
                    label="Search attributes"
                    single-line
                    hide-details
                    clearable
                  ></v-text-field>
                  <br />
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex sm12>
                  <v-data-table
                    :items="attributes"
                    :rows-per-page-items="[20]"
                    :search="attributeSearch"
                    :headers="attributeHeaders"
                    :loading="loading.attributes"
                  >
                    <template slot="no-data">No attributes.</template>
                    <template slot="items" slot-scope="props">
                      <td>{{ props.item.name }}</td>
                      <td>
                        <v-layout justify-end>
                          <v-icon
                            ripple
                            color="teal darken-2"
                            @click="showAttributeOptions(props.item)"
                            >add_box</v-icon
                          >
                        </v-layout>
                      </td>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex sm6 pa-2>
              <v-layout row class="pa-2 opt-tab ml-3" fill-height>
                <v-flex xs12 v-if="selectedAttribute">
                  <v-layout>
                    <v-flex xs12>
                      <v-checkbox
                        :input-value="attributeIsOptional"
                        label="Optional?"
                        @change="handleAttributeRequiredSelection($event)"
                      ></v-checkbox>
                    </v-flex>
                  </v-layout>
                  <v-divider></v-divider>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-list>
                        <v-list-tile
                          v-for="item in selectedAttributeOptions"
                          :key="item.id"
                        >
                          <v-list-tile-content>
                            <v-list-tile-title
                              v-text="item.name"
                            ></v-list-tile-title>
                          </v-list-tile-content>
                          <v-list-tile-action>
                            <v-checkbox
                              :input-value="optionIsSelected(item.id)"
                              @change="handleOptionSelection(item, $event)"
                            ></v-checkbox>
                          </v-list-tile-action>
                        </v-list-tile>
                      </v-list>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 v-else>
                  <v-layout row>Select an attribute.</v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <br />
          <v-divider></v-divider>
          <v-layout row justify-end>
            <!-- <v-btn flat @click="closeAttributeDialog">
              Cancel
            </v-btn>-->
            <v-btn flat color="primary" @click="closeAttributeDialog"
              >Done</v-btn
            >
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <side-panel v-model="showVariantForm">
      <product-variant-form
        @result="handleResults"
        :variantsData="selectedProductOptions"
        :variants="productVariants"
      ></product-variant-form>
    </side-panel>
  </v-card>
</template>

<script>
import ComplexDialog from "./../partial/ComplexDialog.vue";
import SelectedSimpleProducts from "./../partial/SelectedSimpleProducts.vue";
import SelectedProductVariants from "./../partial/SelectedProductVariants.vue";
import ProductVariantForm from "./../partial/ProductVariantForm.vue";
import SidePanel from "@/components/shared/SidePanel";
import UploadButton from "vuetify-upload-button";
import axios from "axios";
import moment from 'moment';

export default {
  components: {
    ComplexDialog,
    ProductVariantForm,
    SelectedSimpleProducts,
    SelectedProductVariants,
    SidePanel,
    "upload-btn": UploadButton
  },
  computed: {
    attributeIsOptional() {
      const attributeId = this.selectedAttribute.id;
      const found = this.optionalAttributes.find(x => x == attributeId);
      //console.log("Found", found);
      return found;
      // const index = this.selectedProductOptions.findIndex(
      //   x => x.attribute.id == this.selectedAttribute.id
      // );
      // console.log(index);
      // const attribute = Object.assign({}, this.selectedAttribute);
      // if (index < 0) return false;
      // return this.selectedProductOptions[index].isOptional || false;
    },
    availableAttributes() {
      const attributes = {};
      this.attributes.forEach(x => {
        attributes[x.id] = x;
      });

      return attributes;
    },
    productImg() {
      return this.productImg_ || "/static/img/product-default.png";
    },
    saveText() {
      return this.posting ? "Saving.." : "Save";
    },
    selectedSimpleProducts() {
      if (!this.product.sub_products || this.product.sub_products.length < 1)
        return [];

      const subProducts = this.product.sub_products;
      return subProducts.map(x => {
        const product = this.products.find(y => y.id == x.id);
        product.quantity = x.quantity;
        return Object.assign({}, product);
      });
    },
    simpleProducts() {
      return this.products
        .filter(x => x.id !== this.product.id)
        .sort((a, b) => a.name > b.name);
    },
    activeUnits() {
      return this.unitsOfMeasurement
        .filter(x => x.active === true)
        .sort((a, b) => a.name > b.name);
    },
    activeGroups() {
      return this.productGroups
        .filter(x => x.active == true)
        .sort((a, b) => a.name > b.name);
    },
    activeTaxGroups() {
      return this.taxGroups
        .filter(x => x.active == true)
        .sort((a, b) => a.name > b.name);
    }
  },
  created() {
    this.getTaxGroups();
    this.getUnitsOfMeasurement();
    this.getProductOptions();
    this.getAttributes();
  },
  data() {
    return {
      attributes: [],
      attributesDialog: false,
      attributeHeaders: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Action",
          align: "right",
          value: ""
        }
      ],
      attributeSearch: "",
      complexProductDialog: false,
      loading: {
        attributes: false,
        product: false,
        taxGroups: false,
        unitsOfMeasurement: false
      },
      postAction: "",
      product: {},
      productDialog: false,
      productOptions: [],
      productVariants: [],
      selectedAttribute: null,
      selectedAttributeOptions: [],
      selectedProductOptions: [],
      uploadFormDialog: false,
      uploadVarianceFormDialog: false,
      fileupload: {},
      filevarianceupload: {},
      optionalAttributes: [],
      productHeaders: [
        {
          text: "Name",
          align: "left",
          value: "name"
        },
        {
          text: "Group",
          value: "product_group.name"
        },
        {
          text: "Default Price",
          value: "price",
          align: "right"
        },
        // {
        //   text: "Subscribed",
        //   value: "is_subscribed"
        // },
        {
          text: "For sale",
          value: "is_for_sale"
        },
        // {
        //   text: "Complex",
        //   value: "is_complex"
        // },
        {
          text: "Service",
          value: "is_service"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      productImg_: "",
      rules: {
        taxGroup: [() => this.enableTaxGroup() || "This is a required field."]
      },
      search: "",
      showVariantForm: false,
      taxGroups: [],
      unitsOfMeasurement: [],
      discounts: [],
      posting: false
    };
  },
  methods: {
    downloadFile() {
      window.open(`${this.apiBaseUrl}/misc/product-sample-file`, "_blank");
    },
    downloadVarianceFile() {
      window.open(`${this.apiBaseUrl}/misc/product-variance-sample-file`, "_blank");
    },
    addSimpleProduct(product) {
      if (!this.product.sub_products)
        this.$set(this.product, "sub_products", []);
      this.product.sub_products.push(product);
    },
    closeAttributeDialog() {
      // Match optional attributes
      this.product.applicable_attributes_and_options = this.selectedProductOptions.map(
        x => {
          return {
            attribute_id: x.attribute.id,
            is_optional:
              this.optionalAttributes.find(y => y == x.attribute.id) > -1,
            // Select option IDs only
            selected_options: x.options.map(y => y.id)
          };
        }
      );
      //console.log(
      //  "Applicable shit:",
      //  this.product.applicable_attributes_and_options
      //);
      this.attributesDialog = false;
    },
    closeComplexProductDialog() {
      if (this.selectedSimpleProducts.length < 1)
        this.product.is_complex = false;
      this.complexProductDialog = false;
    },
    closeProductDialog() {
      this.productDialog = false;
      this.productImg_ = "";
      this.postAction = "";
      this.$refs.new_product_form.reset();
      this.product = {};
    },
    closeUploadVarianceFormDialog() {
      this.uploadVarianceFormDialog = false;
      this.fileupload = {};
    },
    closeUploadFormDialog() {
      this.uploadFormDialog = false;
      this.fileupload = {};
    },
    enableTaxGroup() {
      if (this.product.has_tax === true) {
        return this.product.tax_group ? true : false;
      }

      return true;
    },
    fileChanged(file) {
      this.fileupload = file;
    },
    async getAttributes() {
      this.loading.attributes = true;
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributes`
        );
        await this.handleResponse(res, data => {
          this.attributes = data.filter(x => x.active);
        });
      } catch (error) {
        await this.handleError(
          error,
          "We could not fetch your attributes. Please try again later."
        );
      }

      this.loading.attributes = false;
    },
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
    async getProductOptions() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributeoptions`
        );
        await this.handleResponse(res, data => {
          this.productOptions = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We couldn't fetch your product options."
        );
      }
    },
    async getTaxGroups() {
      this.loading.unitsOfMeasurement = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/taxGroups`);
        this.handleResponse(res, data => {
          this.loading.taxGroups = false;
          this.taxGroups = data;
        });
      } catch (error) {
        this.handleError(error, "We couldn't fetch your tax groups.", () => {
          this.loading.taxGroups = false;
        });
      }
    },
    async getUnitsOfMeasurement() {
      this.loading.unitsOfMeasurement = true;
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/measurements?status=1`
        );
        this.handleResponse(res, data => {
          //console.log('data', data);
          this.loading.unitsOfMeasurement = false;
          this.unitsOfMeasurement = data;
        });
      } catch (error) {
        this.handleError(
          error,
          "We couldn't fetch your units of measurement.",
          () => {
            this.loading.unitsOfMeasurement = false;
          }
        );
      }
    },
    handleComplexProduct(isComplex) {
      if (isComplex) this.complexProductDialog = true;
    },
    handleAttributeRequiredSelection(selected) {
      const attributeId = this.selectedAttribute.id;
      if (!selected) {
        this.optionalAttributes = this.optionalAttributes.filter(
          x => x.id == attributeId
        );
      } else this.optionalAttributes.push(attributeId);

      return;
      /*const index = this.selectedProductOptions.findIndex(
        x => x.attribute.id == this.selectedAttribute.id
      );
      */
      // const attribute = Object.assign({}, this.selectedAttribute);
      /*if (index < 0) return;

      this.selectedProductOptions[index].isOptional = selected;
      */
    },
    handleOptionSelection(item, selected) {
      // console.log(this.selectedProductOptions);
      const index = this.selectedProductOptions.findIndex(
        x => x.attribute.id == this.selectedAttribute.id
      );
      const attribute = Object.assign({}, this.selectedAttribute);
      if (selected) {
        if (index > -1) {
          this.selectedProductOptions[index].options.push(item);
        } else {
          this.selectedProductOptions.push({ attribute, options: [item] });
        }
      } else {
        if (index > -1) {
          this.selectedProductOptions[
            index
          ].options = this.selectedProductOptions[index].options.filter(
            x => x.id != item.id
          );
        }
      }

      //console.log(this.selectedProductOptions);
    },
    handleResults(variants) {
      this.productVariants.push(...variants);
    },
    handleVariableOptionChange(isVariance) {
      if (isVariance) {
        // Show attributes dialog
        this.attributesDialog = true;
        // If attributes haven't been fetched previously, get them
        if (!this.attributes.length) {
          this.getAttributes();
        }
      } else {
        // Reset product attributes
        this.product.attributes = null;
      }
    },
    optionIsSelected(id) {
      return (
        this.selectedProductOptions.findIndex(x => {
          const options = x.options;
          return options.findIndex(y => y.id == id) > -1;
        }) > -1
      );
    },
    photoPreview(e) {
      const self = this;
      var file = e.target.files[0];
      if (!file) return;

      if (file.size < 4096000) {
        if (file.type.match("image.*")) {
          var reader = new FileReader();
          reader.onload = (() => {
            return function(evt) {
              // Set image source
              self.productImg_ = evt.target.result;
            };
          })(file);

          reader.readAsDataURL(file);
        } else {
          alert("The file you selected is not a valid image file");
        }
      } else {
        alert(
          "The file you selected is too large. Images shouldn't be bigger than 4MB."
        );
      }
      },
    clear() {
        this.selectedSimpleProducts = [];
        this.productOptions = [];
        this.productVariants = [];
        this.selectedAttributeOptions = [];
        this.selectedProductOptions = [];
    },
    async saveProduct() {
      const form = this.$refs.new_product_form;
      if (!form.validate()) return;
      const product = Object.assign({}, this.product);
      if (product.is_complex) {
        product.sub_products = this.selectedSimpleProducts;
      }
      // Validate variable products
      if (product.has_variance) {
        if (this.productVariants.length < 1) {
          return this.showSnackbar("Please add the product variants.", {
            type: "error"
          });
        }
        if (this.selectedProductOptions.length < 1) {
          return this.showSnackbar("Please add the variance attributes.", {
            type: "error"
          });
        }

        product.variances = this.productVariants.map(x => {
          const variant = { price: x.price };
          variant.option_ids = x.options.map(y => y.id);
          return variant;
        });
      }

      // console.log("Product:", product);
       //return;
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/products`,
          product
        );
        this.posting = false;
        this.handleResponse(res, data => {
          const productGroup = this.productGroups.find(
            x => x.id == product.product_group_id
          );
          product.product_group = Object.assign({}, productGroup);
          const action = "added";
          if (this.postAction == "update") {
            this.$emit("productUpdated", product);
          } else {
            product.id = data.id; // Set id from created product
            this.products.push(product);
              this.showSnackbar(`Product ${action} successfully.`);
              this.clear();
            this.$emit("refreshProducts");
          }
          this.$refs.new_product_form.reset();
          this.productDialog = false;
        });
      } catch (error) {
        this.posting = false;
        console.log(error);
        this.handleError(error, "An error occurred.");
      }
    },
    setMonitorStockValue(enabled) {
      if (!enabled) {
        this.product.minimum_stock = null;
      }
    },
    showAttributeOptions(item) {
      this.selectedAttribute = item;
      this.selectedAttributeOptions = item.options;
    },
    showFilePicker() {
      const filePicker = document.getElementById("img-picker");
      filePicker.click();
    },
    showProductDialog() {
      this.productDialog = true;
      this.product.code = this.$moment(new Date()).format("hhDDMMYYYYssmm");
    },
    showProductInfo(product) {
      this.postAction = "update";
      // if (product.expiry_date != null) product.expiry_date = this.formatDate(product.expiry_date)
      // console.log("product.expiry_date ", product.expiry_date )
      product.product_group_id = product.product_group.id;
      this.product = Object.assign({}, product);
      this.productDialog = true;
    },
    formatDate(dateString) {
      return moment(new Date(dateString)).format("YYYY-MM-DD");
    },
    showProductVariantForm() {
      this.showVariantForm = true;
    },
    showUploadDialog() {
      this.uploadFormDialog = true;
    },
    showVarianceUploadDialog() {
      this.uploadVarianceFormDialog = true;
    },
    uploadProductVarianceFile() {
      let formData = new FormData();
      formData.append("file", this.fileupload);
      const headers = {
        headers: {
          "Content-Type": "miltipart/form-data"
        }
      };
        this.posting = true;
      axios.post(`${this.apiBaseUrl}/products/upload-product-variance`, formData, headers)
        .then(response => {
          const { message, data } = response.data;
          const errors = data.errors;
          //this.$emit("refreshProducts");
          if (errors.length) {
            this.closeUploadVarianceFormDialog();
            this.posting = false;
            return this.showSnackbar(errors.join("<br />"), {
              type: "error",
              timeout: 8000
            });
          }
          this.showSnackbar(message);
          this.closeUploadVarianceFormDialog();
          this.posting = false;
          this.$emit("syncedVariance");
        })
        .catch(error => {
          console.log("error", error);
            this.posting = false;
        });
    },
    uploadProductFile() {
      let formData = new FormData();
      formData.append("file", this.fileupload);
      const headers = {
        headers: {
          "Content-Type": "miltipart/form-data"
        }
      };
        this.posting = true;
      axios
        .post(`${this.apiBaseUrl}/products/upload`, formData, headers)
        .then(response => {
          const { message, data } = response.data;
          const errors = data.errors;
          this.$emit("refreshProducts");
          if (errors.length) {
            return this.showSnackbar(errors.join("<br />"), {
              type: "error",
              timeout: 8000
            });
          }
          this.showSnackbar(message);
            this.closeUploadFormDialog();
            this.posting = false;
        })
        .catch(error => {
          console.log("error", error);
            this.posting = false;
        });
    },
    volatileChanged(bool) {
      if (bool) {
        if (this.product.has_tax) this.product.has_tax = false;
      }
    }
  },
  props: {
    products: Array,
    productGroups: Array
  }
};
</script>

<style scoped>
.product-img {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
}

.opt-tab {
  border: 1px solid;
}

#img-picker {
  height: 0;
  visibility: hidden;
}

.product-img:hover {
  cursor: pointer;
}

.product-img > span {
  font-style: italic;
  font-weight: bold;
  display: block;
}

.product-img > img {
  width: auto;
  max-width: 100%;
  max-height: 200px;
  text-align: center;
  margin: 0 auto;
  text-align: center;
}

.product-img:hover .inverse {
  opacity: 0.2;
}

.inverse,
.img-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  transition: all ease-in-out 0.4s;
}

.img-loader {
  background-color: #fff;
  opacity: 0.85;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
}

.upload-btn {
  display: inline-flex;
}
</style>
