<template>
    <layout>
        <v-layout row wrap>
            <v-flex xs12 sm3>
                <v-menu ref="startDayMenu"
                        v-model="startDayMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="filter.startDate"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="filter.startDate"
                                      label="Start date"
                                      prepend-icon="event"
                                      hint="YYYY/MM/DD format"
                                      readonly
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.startDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="startDayMenu = false">Cancel</v-btn>
                        <v-btn flat
                               color="primary"
                               @click="$refs.startDayMenu.save(filter.startDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>

            <v-flex xs12 sm3>
                <v-menu ref="endDayMenu"
                        v-model="endDayMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="filter.endDate"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="filter.endDate"
                                      label="End Date"
                                      prepend-icon="event"
                                      hint="YYYY/MM/DD format"
                                      readonly
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.endDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="endDayMenu = false">Cancel</v-btn>
                        <v-btn flat
                               color="primary"
                               @click="$refs.endDayMenu.save(filter.endDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>

            <v-flex xs12 sm3>
                <v-btn color="info" @click="fetchStockLog"> Filter </v-btn>
            </v-flex>


            <v-flex xs12 sm3>
                <v-layout row wrap>
                    <div class="text-xs-right">
                        <DownloadCSV class="v-btn theme--light success"
                                     :data="records"
                                     name="stock-move-report-logs.csv"
                                     v-if="records.length">
                            Export
                        </DownloadCSV>
                    </div>
                </v-layout>
            </v-flex>
        </v-layout>
        <template>
            <v-data-table :expand="true"
                          item-key="id"
                          disable-initial-sort
                          :items="histories"
                          :rows-per-page-items="[25]"
                          :headers="headers"
                          :loading="loading">
                <template slot="no-data">
                    No histories.
                </template>
                <template slot="items" slot-scope="props">
                    <tr>
                        <td>{{ props.item.serialNo || "No ID" }}</td>
                        <td>{{ props.item.productName }}</td>
                        <td>{{ props.item.quantity }}</td>
                        <td>{{ props.item.fWarehouse }}</td>
                        <td>{{ props.item.tWarehouse }}</td>
                        <td>{{ props.item.createdBy }}</td>
                        <td>{{ new Date(props.item.createdAt).toLocaleString() }}</td>
                    </tr>
                </template>
            </v-data-table>
        </template>
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import JsonCSV from "vue-json-csv";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  mounted() {
    //this.fetchWalletLog();
  },
  data() {
    return {
      customerName: "",    
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "#ID", value:"serialNo" },
        { text: "Product", value:"productName" },
        { text: "Quantity",value:"quantity" },
        { text: "From", value:"fWarehouse" },
        { text: "To", value:"tWarehouse" },
        { text: "Initiator", value:"createdBy"},
        { text: "Date And Time", value:"createdAt"},
      ],
      loading: false,
      histories: [],
      users: []
    };
  },
  computed: {
      records() {
      return this.histories.map((x) => {
        return {
            SerialNo: x.serialNo,
            ProductName: x.productName,
            Quantity: x.quantity,
            FromWarehouse: x.fWarehouse,
            ToWarehouse: x.tWarehouse,
            CreatedBy: x.createdBy,
            EffectiveDateAndTime: new Date(x.createdAt).toLocaleString()
        };
      });
    }
  },
  methods: {
    async fetchStockLog() {
          this.loading = true;
          const params = {
              startDate: this.filter.startDate,
              endDate: this.filter.endDate
          };
          try {
              const { data } = await this.$http.get(`${this.apiBaseUrl}/transactions/stock-transfer-report`, { params });
        this.histories = data.data;
        // console.log('this.stores', this.stores);
      } catch (error) {
        // console.log(error);
        this.handleError(error, "Error fetching wallet log");
      }
      this.loading = false;
    },
  },
  watch: {
    //selectedStore: {
    //  handler() {
    //    this.fetchWalletLog();
    //    this.fetchUsers();
    //  },
    //  deep: true
    //}
  }
};
</script>
