<template>
  <v-card flat>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="newActionDialog = true">
        <v-icon>add_circle</v-icon>&nbsp; Add action
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <br />
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex sm4>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search actions"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <br />
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :items="actions"
          :rows-per-page-items="[20]"
          :search="search"
          :loading="loading"
          :headers="headers"
        >
          <template slot="no-data">
            No actions. Add a new action above.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.action_type || "" }}</td>
            <td>{{ props.item.category_type || "" }}</td>
            <td>
              <v-icon small :color="props.item.active ? 'green' : 'red'">
                {{ props.item.active ? "check_circle" : "remove_circle" }}
              </v-icon>
            </td>
            <td>
              <v-icon small @click="showActionInfo(props.item)">
                info
              </v-icon>
              &nbsp;&nbsp;
              <!-- <v-icon small @click="showDeactivateDialog(props.item)">
                {{ props.item.active ? 'remove_circle' : 'check_circle' }}
              </v-icon> -->
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog max-width="900px" persistent v-model="newActionDialog">
      <v-form ref="action_form" @submit.prevent="post" lazy-validation>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter action details
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-text-field
                  label="Action name"
                  v-model="action.name"
                  required
                  :rules="formRules.required"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 px-2>
                <v-radio-group
                  v-model="action.action_type"
                  :rules="formRules.required"
                  row
                  mandatory
                  required
                >
                  <v-radio label="Use discount" value="discount"></v-radio>
                  <v-radio
                    label="Use free products"
                    value="free_products"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-select
                  label="Discount"
                  :items="discounts"
                  :disabled="action.action_type !== 'discount'"
                  item-text="name"
                  item-value="id"
                  :readonly="action.action_type !== 'discount'"
                  v-model="action.discount_id"
                  required
                  :rules="formRules.dynamic(action.action_type === 'discount')"
                ></v-select>
              </v-flex>
              <v-flex sm3 px-2>
                <v-text-field
                  label="Amount to pay"
                  v-model="action.pay_amount"
                  :disabled="action.action_type !== 'free_products'"
                  type="number"
                  min="0"
                  required
                  :rules="
                    formRules.dynamic(
                      action.action_type === 'free_products',
                      'amount'
                    )
                  "
                ></v-text-field>
              </v-flex>
              <v-flex sm3 px-2>
                <v-text-field
                  label="Products to get free"
                  v-model="action.get_amount"
                  :disabled="action.action_type !== 'free_products'"
                  type="number"
                  min="0"
                  required
                  :rules="
                    formRules.dynamic(
                      action.action_type === 'free_products',
                      'amount'
                    )
                  "
                ></v-text-field>
              </v-flex>
            </v-layout>
            <!-- <br> -->
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-radio-group
                  v-model="action.category_type"
                  :rules="formRules.required"
                  row
                  mandatory
                  required
                >
                  <v-radio label="Use product" value="product"></v-radio>
                  <v-radio
                    label="Use product group"
                    value="product_group"
                  ></v-radio>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-select
                  label="Product"
                  :items="products"
                  :disabled="action.category_type !== 'product'"
                  item-text="name"
                  item-value="id"
                  :readonly="action.category_type !== 'product'"
                  v-model="action.product_id"
                  required
                  :rules="formRules.dynamic(action.category_type === 'product')"
                ></v-select>
              </v-flex>
              <v-flex sm6 px-2>
                <v-select
                  label="Product group"
                  :items="productGroups"
                  :disabled="action.category_type !== 'product_group'"
                  item-text="seeded_group.name"
                  item-value="id"
                  :readonly="action.category_type !== 'product_group'"
                  v-model="action.product_group_id"
                  required
                  :rules="
                    formRules.dynamic(action.category_type === 'product_group')
                  "
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-select
                  label="Partner/Customer"
                  :items="partners"
                  item-text="name"
                  item-value="id"
                  v-model="action.partner_id"
                ></v-select>
              </v-flex>
              <v-flex sm6 px-2>
                <v-select
                  label="Payment method"
                  :items="paymentMethods"
                  item-text="payment_method.name"
                  item-value="id"
                  v-model="action.payment_method_id"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md6 px-2>
                <v-menu
                  ref="startDateMenu"
                  :close-on-content-click="false"
                  :v-model="true"
                  :nudge-right="40"
                  :return-value.sync="action.start_date"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  @blur="action.start_date = formatDate(action.start_date)"
                >
                  <v-text-field
                    slot="activator"
                    v-model="action.start_date"
                    :rules="formRules.required"
                    label="Start date"
                    readonly
                    prepend-icon="event"
                  ></v-text-field>
                  <v-date-picker
                    v-model="action.start_date"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="menu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.startDateMenu.save(action.start_date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex md6 px-2>
                <v-menu
                  ref="startTimeMenu"
                  :close-on-content-click="false"
                  :v-model="true"
                  :nudge-right="40"
                  :return-value.sync="action.start_time"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="action.start_time"
                    label="Start time"
                    prepend-icon="access_time"
                    readonly
                  ></v-text-field>
                  <v-time-picker
                    format="24hr"
                    v-model="action.start_time"
                    @change="$refs.startTimeMenu.save(action.start_time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex md6 px-2>
                <v-menu
                  ref="endDateMenu"
                  :close-on-content-click="false"
                  :v-model="true"
                  :nudge-right="40"
                  :return-value.sync="action.end_date"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  @blur="action.end_date = formatDate(action.end_date)"
                >
                  <v-text-field
                    slot="activator"
                    v-model="action.end_date"
                    label="End date"
                    readonly
                    prepend-icon="event"
                  ></v-text-field>
                  <v-date-picker v-model="action.end_date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="menu = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.endDateMenu.save(action.end_date)"
                      >OK</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex md6 px-2>
                <v-menu
                  ref="endTimeMenu"
                  :close-on-content-click="false"
                  :v-model="true"
                  :nudge-right="40"
                  :return-value.sync="action.end_time"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="action.end_time"
                    label="End time"
                    prepend-icon="access_time"
                    readonly
                  ></v-text-field>
                  <v-time-picker
                    format="24hr"
                    v-model="action.end_time"
                    @change="$refs.endTimeMenu.save(action.end_time)"
                  ></v-time-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-checkbox
                  label="Is active"
                  v-model="action.active"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat :disabled="posting" @click.native="closeDialog"
              >Cancel</v-btn
            >
            <v-btn color="primary" :disabled="posting" flat type="submit">{{
              actionText
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="activationDialog" max-width="400px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <h3>
            {{ this.action.is_active ? "Deactivate" : "Activate" }} action
          </h3>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            v-if="activationLoader"
            indeterminate
            color="secondary"
            height="2"
            value="15"
          ></v-progress-linear>
          <div v-if="!activationLoader">
            Do you want to
            {{ this.action.is_active ? "deactivate" : "activate" }} this action?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            :disabled="activationLoader"
            @click.native="closeActivationDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :disabled="activationLoader"
            flat
            @click.native="updateActivation"
            >{{ this.action.is_active ? "Deactivate" : "Activate" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  computed: {
    actionText() {
      const texts = [
        {
          active: "Saving",
          default: "Save"
        },
        {
          active: "Updating",
          default: "Update"
        }
      ];

      const index = this.postAction ? 1 : 0;
      return this.posting ? texts[index].active : texts[index].default;
    }
  },
  // created() {
  //   this.getActions();
  // },
  data() {
    return {
      activationDialog: false,
      activationLoader: false,
      action: {},
      headers: [
        {
          text: "Action name",
          value: "name"
        },
        {
          text: "Action type",
          value: "action_type"
        },
        {
          text: "Category type",
          value: "category_type"
        },
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      loading: false,
      newActionDialog: false,
      postAction: "",
      search: "",
      types: [
        {
          id: 0,
          name: "Flat"
        },
        {
          id: 1,
          name: "Percent (%)"
        }
      ]
    };
  },
  methods: {
    closeActivationDialog() {
      this.postAction = "";
      this.activationDialog = false;
    },
    closeDialog() {
      this.postAction = "";
      this.newActionDialog = false;
      this.$refs.action_form.reset();
      this.action = {};
      // this.action = Object.assign({}, this.defaultAction)
    },
    getActionType(index) {
      return this.types[index].name;
    },
    async post() {
      if (this.postAction !== "updateActivation") {
        if (!this.$refs.action_form.validate()) return;
      }
      const action = Object.assign({}, this.action);
      const selectedStore = this.selectedStore;
      if (selectedStore) action.store_id = selectedStore.id;
      this.posting = true;
      try {
        const res = await this.$http.post(`${this.apiBaseUrl}/actions`, action);
        this.posting = false;
        const actionMsg = this.postAction ? "updated" : "added";
        this.handleResponse(res, data => {
          this.postAction
            ? this.$emit("actionUpdated", action)
            : this.$emit("actionAdded", data);
          this.closeDialog();
          this.showSnackbar(`Action ${actionMsg} successfully.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error while performing this operation."
        );
      }
    },
    showDeactivateDialog(item) {
      this.postAction = "update";
      this.action = Object.assign({}, item);
      this.activationDialog = true;
    },
    showActionInfo(item) {
      this.postAction = "update";
      this.action = Object.assign({}, item);
      this.newActionDialog = true;
    },
    updateActivation() {
      const active = this.action.is_active;
      this.action.is_active = !active;
      this.post();
    }
  },
  props: {
    actions: Array,
    discounts: Array,
    partners: Array,
    paymentMethods: Array,
    products: Array,
    productGroups: Array
  }
};
</script>
