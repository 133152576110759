let appUrl = "";
let apiUrl = "";
let env = process.env.NODE_ENV;

if (env === "development") {
  appUrl = "http://localhost:50829";
  apiUrl = "http://localhost:50829/api";
} else if (env === "local") {
  appUrl = "http://iecr.softalliance.com";
  apiUrl = "http://iecr.softalliance.com/api";
} else {
  appUrl = "";
  apiUrl = "/api";
}

let config = {
  appBaseUrl: appUrl,
  apiBaseUrl: apiUrl,
  constants: {
    BUSINESS: "ecr_business",
    SELECTED_STORE: "ecr_selected_store",
    SELECTED_BUSINESS: "ecr_selected_business",
    SELECTED_WAREHOUSE: "ecr_selected_warehouse",
    BUSINESSES: "ecr_businesses",
    STORE_KEY: "ecr_stores",
    USER: "ecr_user",
    REFERENCE:"ecr_reference"
  },
  dbConfig: {
    name: "ecrApp",
    storeName: "ecrStore"
  }
};

export default config;
