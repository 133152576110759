<template>
  <v-card @click="$emit('updateWalletBalance')">
    <v-card-title class="cyan darken-2 white--text">
      Wallet Balance <small><b>&nbsp;(tap to add)</b></small>
    </v-card-title>
    <v-card-text class="text-sm-right p-8">
      <h2>
        {{moneyFormat(value.walletBalance || 0)}}
      </h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Customer",
  data() {
    return {
      count: "-"
    };
  },
  props: ['value'],
};
</script>

<style scoped></style>
