<template>
  <v-card>
    <v-card-title class="pink darken-2 white--text">
      Owing (NGN)
    </v-card-title>
    <v-card-text class="text-sm-right p-8">
      <h2>
          {{value}}
      </h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Owing",
  data() {
    return {
      count: "-"
    };
  },
  props: ['value'],
};
</script>

<style scoped></style>
