<template>
    <layout>
        <template>
            <v-layout row wrap>
                <v-flex xs12 sm3>
                    <v-menu ref="startDayMenu"
                            v-model="startDayMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="filter.startDate"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="filter.startDate"
                                          label="Start date"
                                          prepend-icon="event"
                                          hint="YYYY/MM/DD format"
                                          readonly
                                          v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="filter.startDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn flat color="error" @click="startDayMenu = false">Cancel</v-btn>
                            <v-btn flat
                                   color="green"
                                   @click="$refs.startDayMenu.save(filter.startDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex xs12 sm3>
                    <v-menu ref="endDayMenu"
                            v-model="endDayMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="filter.endDate"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="filter.endDate"
                                          label="End Date"
                                          prepend-icon="event"
                                          hint="YYYY/MM/DD format"
                                          readonly
                                          v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="filter.endDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn flat color="error" @click="endDayMenu = false">Cancel</v-btn>
                            <v-btn flat
                                   color="green"
                                   @click="$refs.endDayMenu.save(filter.endDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex xs12 sm3>
                    <v-btn color="green white--text" @click="filterData"> Filter </v-btn>
                </v-flex>
            </v-layout>
            <v-data-table disable-initial-sort
                          :items="transactions"
                          :rows-per-page-items="[10]"
                          :search="search"
                          :headers="headers"
                          :loading="loading">
                <template slot="no-data">
                    No subscription transactions
                </template>
                <template slot="items" slot-scope="props">
                    <td>{{ fullDateFormat(props.item.transaction_date) || "" }}</td>
                    <td>{{ props.item.invoice_number }}</td>
                    <td>{{ props.item.reference }}</td>
                    <td>{{ props.item.plan }}</td>
                    <td>{{ props.item.business }}</td>
                    <td>{{ props.item.customer_name }}</td>
                    <td>
                        {{ moneyFormat(props.item.amount_paid) }}
                    </td>
                    <td>{{ props.item.status }}</td>
                </template>
            </v-data-table>
        </template>
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";

    export default {
        components: {
            Layout,
        },
        data() {
            return {
                filter: {
                    endDate: new Date().toISOString().substr(0, 10),
                    startDate: new Date().toISOString().substr(0, 10)
                },
                headers: [
                    { text: "Transaction Date", value: "transaction_date" },
                    { text: "Invoice Id", value: "invoice_number" },
                    { text: "Reference", value: "reference" },
                    { text: "Plan", value: "plan" },
                    { text: "Business", value: "business" },
                    { text: "Customer Name", value: "customer_name" },
                    { text: "Amount", value: "amount_paid" },
                    { text: "status", value: "status" },
                ],
                loading: false,
                search: "",
                transactions: [],
            };
        },
        mounted() {
            this.fetchTransactions();
        },
        computed: {
        },
        methods: {
            async fetchTransactions() {
                this.loading = true;
                try {
                    const { data } = await this.$http.get(`${this.apiBaseUrl}/superAdmin/subscribe/transactions`);
                    this.transactions = data.data;
                } catch (error) {
                    // console.log(error);
                    this.handleError(error, "Error fetching stores");
                }
                this.loading = false;
            },
            async filterData() {
                const params = {
                    startDate: this.filter.startDate,
                    endDate: this.filter.endDate
                };
                this.loading = true;
                try {
                    const { data } = await this.$http.get(`${this.apiBaseUrl}/superAdmin/subscribe/transactions`, { params });
                    this.transactions = data.data;
                } catch (error) {
                    // console.log(error);
                    this.handleError(error, "Error fetching stores");
                }
                this.loading = false;
            }
        },
        watch: {
        }
    };
</script>
<style scoped>
    .customer-name {
        font-size: 15px;
        margin-bottom: 15px;
        padding: 10px;
    }
</style>