<template>
    <v-card class="dash-card profitloss">
        <transition name="fade">
            <div class="_loader" v-if="loader">
                <div class="_ct">
                    <div>
                        <v-progress-circular indeterminate color="#00796b" size="40" width="3"></v-progress-circular>
                    </div>
                </div>
            </div>
        </transition>
        <v-card-title class="blue-grey darken-2 white--text">
            <span class="text--white mr-1">Profit And Loss Statistics</span>

            <v-menu ref="startDateMenu"
                    :close-on-content-click="false"
                    :v-model="startDateMenu"
                    :nudge-right="40"
                    :return-value.sync="filter.startDate"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                    @blur="
                    filter.startDate = $moment(filter.startdate).format(
                      'YYYY-MM-DD'
                    )
                  ">
                <v-text-field slot="activator"
                              v-model="filter.startDate"
                              label="Start date"
                              solo
                              :hide-details="true"
                              readonly
                              prepend-inner-icon="event"></v-text-field>
                <v-date-picker v-model="filter.startDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <!--<v-btn flat color="error" @click="pickers.start = false">Cancel</v-btn>-->
                    <v-btn flat color="error" @click="startDateMenu = false">Cancel</v-btn>
                    <v-btn flat
                           color="green"
                           @click="$refs.startDateMenu.save(filter.startDate)">OK</v-btn>
                </v-date-picker>
            </v-menu>

            <v-menu ref="endDateMenu"
                    :close-on-content-click="false"
                    :v-model="endDateMenu"
                    :nudge-right="40"
                    :return-value.sync="filter.endDate"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    min-width="290px"
                    @blur="
                    filter.endDate = $moment(filter.endDate).format(
                      'YYYY-MM-DD'
                    )
                  ">
                <v-text-field slot="activator"
                              v-model="filter.endDate"
                              label="End date"
                              solo
                              :hide-details="true"
                              readonly
                              prepend-inner-icon="event"></v-text-field>
                <v-date-picker v-model="filter.endDate" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn flat color="error" @click="endDateMenu = false">Cancel</v-btn>
                    <v-btn flat
                           color="green"
                           @click="$refs.endDateMenu.save(filter.endDate)">OK</v-btn>
                </v-date-picker>
            </v-menu>
            <v-btn outlined color="blue-grey" class="white--text" style="margin: 0; padding: 0 6px" @click="Filter">
                Search &nbsp;
                <v-icon dark>
                    search
                </v-icon>
            </v-btn>
        </v-card-title>

        <v-alert v-model="alert.all.show"
                 :type="alert.all.type"
                 transition="fade-transition"
                 dismissible>
            {{ alert.all.msg }}
        </v-alert>

        <bar-chart v-if="loaded"
                   text-xs-center
                   :data="chart.data"
                   :options="chart.options"
                   :height="150"></bar-chart>
    </v-card>
</template>

<script>
import BarChart from "@/components/charts/BarChart";
import moment from "moment";

export default {
  name: "ProfitAndLoss",
  components: {
    BarChart
   },
  data() {
    return {
      filter: {
        startDate: "",
        endDate: ""
      },
      pickers: {
        start: false,
        end: false
      },
      endDateMenu: false,
      startDateMenu: false,
      loaded: false,
      loader:false,
      chart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec"
          ],
          datasets: [
            {
              label: "Profit Agg",
              backgroundColor: "darkgreen",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
            {
              label: "Loss Agg",
              backgroundColor: "darkred",
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            }
          ]
        },
        options: {
          height: 50,
        }
      }
    };
  },
  mounted() {
      this.getReceiptStatistics();
  },
    watch: {
        selectedBusiness: {
            handler() {
                this.getReceiptStatistics();
            },
            deep: true
        }
    },
  methods: {
    async getReceiptStatistics() {
          let url = `${this.apiBaseUrl}/superAdmin/analysis/profit-and-loss?businessId=${this.selectedBusiness.id}`;
          this.getData(url,false);
      },
      async Filter() {
          this.loader = true;
          let url = `${this.apiBaseUrl}/superAdmin/analysis/profit-and-loss-timing?businessId=${this.selectedBusiness.id}&filterYear=false&start=${this.filter.startDate}&end=${this.filter.endDate}`;
          this.getData(url,true);
          //this.loader = false;
      },
      async reset() {
          this.chart.data.datasets[0].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.chart.data.datasets[1].data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      },
      async getData(url,update) {
          try {
              this.loaded = false;
              const { data } = await this.$http.get(url);
              const { data: statistics } = data;
              this.reset();
              if (statistics.details) {
                  if (update) this.reset();
                  for (const stats in statistics.details) {
                      let index = parseInt(stats - 1);
                      if (statistics.details[stats] > 0)
                          this.chart.data.datasets[0].data[index] = statistics.details[stats];
                      else this.chart.data.datasets[1].data[index] = statistics.details[stats]
                  }
              }
              else this.reset();
              this.loader = false;
              this.loaded = true;
          } catch (error) {
              this.loader = false;
              if (error.response) {
                  return this.showAlert(
                      error.response.data.message,
                      "error"
                  );
              }
          }
      },
  }
};
</script>

<style>
    .profitloss .v-input__control {
        min-height: 30px !important;
        padding: 0 !important;
    }
    .profitloss .v-card__title {
        flex-wrap: nowrap !important;
    }
    .profitloss .v-menu__activator {
        -webkit-box-align: center !important;
        -ms-flex-align: center !important;
        align-items: center !important;
        cursor: pointer !important;
        display: -webkit-box !important;
        display: -ms-flexbox !important;
        display: flex !important;
        width: 80% !important;
    }
    .profitloss > ._loader {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        background-color: #ffffffbd !important;
        z-index: 1 !important;
    }

    .profitloss > ._loader > ._ct {
        display: table !important;
        width: 100% !important;
        height: 100% !important;
    }

    .profitloss > ._loader > ._ct > div {
        display: table-cell !important;
        vertical-align: middle !important;
        text-align: center !important;
    }
</style>
