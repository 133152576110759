<template>
  <layout>
    <v-layout row wrap>
      <v-flex xs12>
        <template>
          <div v-if="!loaded">
            <v-progress-linear height="3" indeterminate></v-progress-linear>
          </div>
          <div v-else>
            <v-tabs slider-color="primary" grow>
              <v-tab>
                <h4>Discounts</h4>
              </v-tab>
              <!-- <v-tab>
                <h4>Actions</h4>
              </v-tab> -->
              <v-tab-item>
                <discounts
                  :items="discounts"
                  @discountAdded="addDiscount"
                  @discountUpdated="updateDiscount"
                ></discounts>
              </v-tab-item>
              <!-- <v-tab-item>
                <actions :discounts="discounts" :actions="actions" :products="products" :productGroups="productGroups"
                  :paymentMethods="paymentMethods" :partners="partners" @actionAdded="addAction" @actionUpdated="updateAction"></actions>
              </v-tab-item> -->
            </v-tabs>
          </div>
        </template>
      </v-flex>
    </v-layout>
  </layout>
</template>

<script>
import Actions from "./discounts_actions/Actions";
import Discounts from "./discounts_actions/Discounts";
import Layout from "@/components/Layout";
export default {
  components: {
    Actions,
    Discounts,
    Layout
  },
  data() {
    return {
      actions: [],
      discounts: [],
      partners: [],
      paymentMethods: [],
      products: [],
      productGroups: [],
      loaded: true
    };
  },
  methods: {
    addAction(action) {
      this.actions.push(action);
    },
    addDiscount(discount) {
      this.discounts.push(discount);
    },
    async getActions() {
      let url = `${this.apiBaseUrl}/actions`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.actions = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "We couldn't load your actions");
      }
    },
    async getDiscounts() {
      this.loaded = false;
      let url = `${this.apiBaseUrl}/discounts`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
      const res = await this.$http.get(url);
      this.loaded = true;
      this.handleResponse(res, data => {
        this.discounts = data;
      });
    },
    async getPartners() {
      let url = `${this.apiBaseUrl}/partners`;
      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.partners = data;
        });
      } catch (error) {
        this.handleError(
          error,
          "We couldn't fetch your partners. Please try again."
        );
      }
    },
    async getPaymentMethods() {
      let url = `${this.apiBaseUrl}/paymentMethods`;
      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.paymentMethods = data;
        });
      } catch (error) {
        this.handleError(error, "We couldn't fetch your payment methods.");
      }
    },
    async getProducts() {
      let url = `${this.apiBaseUrl}/products/get-products`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.products = data;
        });
      } catch (error) {
        this.handleError(
          error,
          "We couldn't fetch your products. Please try again."
        );
      }
    },
    async getProductGroups() {
      let url = `${this.apiBaseUrl}/productsgroup`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
      try {
        const res = await this.$http.get(url);
        this.handleResponse(res, data => {
          this.productGroups = data;
        });
      } catch (error) {
        this.handleError(error, "We couldn't fetch your product groups.");
      }
    },
    updateAction(action) {
      const index = this.actions.findIndex(x => x.id == action.id);
      this.$set(this.actions, index, action);
    },
    updateDiscount(discount) {
      const index = this.discounts.findIndex(x => x.id == discount.id);
      this.$set(this.discounts, index, discount);
    },
    updatePageInfo() {
      this.getActions();
      this.getDiscounts();
      this.getPartners();
      this.getPaymentMethods();
      this.getProducts();
      this.getProductGroups();
    }
  },
  mounted() {
    this.updatePageInfo();
  },
  watch: {
    selectedStore: {
      handler() {
        this.updatePageInfo();
      },
      deep: true
    }
  }
};
</script>
