<template>
  <layout requires-business>
      <template v-if="selectedBusiness">
          <v-layout row wrap>
              <v-spacer></v-spacer>
              <v-flex xs12>
                  <v-card flat>
                      <v-card-title class="pt-0">
                          <v-layout>
                              <v-flex xs6 sm4>
                                  <v-btn @click="alertDialog = true" :color="btnConfig.color" class="white--text">
                                      <v-icon>{{btnConfig.icon}}</v-icon>&nbsp; {{btnConfig.text}}
                                  </v-btn>
                                  <v-btn @click="manageUser({})" :disabled="!selectedBusiness.active" color="teal" class="white--text">
                                      <v-icon>add_circle_outline</v-icon>&nbsp; Add user
                                  </v-btn>
                              </v-flex>
                              <v-flex xs6 sm4></v-flex>
                              <v-flex xs6 sm4 offset-sm-1>
                                  <v-spacer></v-spacer>
                                  <v-text-field v-model="search"
                                                append-icon="search"
                                                label="Search users"
                                                single-line
                                                hide-details
                                                clearable></v-text-field>
                              </v-flex>
                          </v-layout>
                      </v-card-title>
                      <v-data-table :items="users"
                                    :rows-per-page-items="[20]"
                                    :search="search"
                                    :headers="headers"
                                    :loading="loading">
                          <template slot="no-data">
                              No users.
                          </template>
                          <template slot="items" slot-scope="props">
                              <td>{{ props.item.full_name }}</td>
                              <td>{{ props.item.phone_number }}</td>
                              <td>{{ props.item.email }}</td>
                              <td>{{ getStoreName(props.item.store_id) || "N/A" }}</td>
                              <td>{{ props.item.role }}</td>
                              <td>
                                  <v-icon small :color="props.item.active ? 'green' : 'red'">
                                      {{ props.item.active ? "check_circle" : "remove_circle" }}
                                  </v-icon>
                              </td>
                              <td>
                                  <v-tooltip bottom v-if="props.item.store_id != 0">
                                      <v-icon slot="activator" @click="manageUser(props.item)" :disabled="!selectedBusiness.active">edit</v-icon>
                                      <span>Edit user</span>
                                  </v-tooltip>
                              </td>
                          </template>
                      </v-data-table>
                  </v-card>
              </v-flex>
          </v-layout>
          <user-details :userDialog="userDialog" :selectedUser="selectedUser" @closeUserDialog="closeUserDialog"
                        @updateUserInfo="updateUserInfo" :roles="roles" @refreshData="refreshData"></user-details>

          <v-dialog v-model="alertDialog" max-width="290">
              <v-card>
                  <v-card-text text-center style="text-align:center">
                      Are you sure you want to continue.
                  </v-card-text>

                  <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn flat="flat" @click="alertDialog = false">
                          Cancel
                      </v-btn>
                      <v-btn flat="flat" @click="confirm">
                          Ok
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>

      </template>
      <template v-else> No Business Selected</template>
  </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import UserDetails from "@/components/superadmin/accessControl/userDetail.vue";
import business from "../../router/setup/business";

export default {
  components: {
        Layout,
        UserDetails
  },
  data() {
      return {
      headers: [
                  {
                      text: "Name",
                      value: "full_name"
                  },
                  {
                      text: "Phone",
                      value: "phone_number"
                  },
                  {
                      text: "Email",
                      value: "email"
                  },
                  {
                      text: "Store",
                      value: "store"
                  },
                  {
                      text: "Role",
                      value: "role"
                  },
                  {
                      text: "Status",
                      value: "active"
                  },
                  {
                      text: "Actions",
                      value: ""
                  }],
      loading: false,
      search: "",
      userDialog:false,
      alertDialog:false,
      selectedUser: {},
      btnconfig:{},
      users: [],
      roles: [],
      stores: [],
    };
  },
  mounted() {
    this.getUsers();
    this.getRoles();
  },
  computed: {
      btnConfig() {
          return this.selectedBusiness.active ?
              this.btnconfig = { color: "red", text: "Disable", icon: "cancel" } :
              this.btnconfig = { color: "green", text: "Enable", icon: "check" };
      },
  },
  methods: {
    manageUser(user) {
      this.userDialog = true;
      this.selectedUser = user;
    },
      async confirm() {
          this.loading = true; 
          this.alertDialog = false
        try {
            const res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/accesscontrol/toggle-business?BusinessId=` + this.selectedBusiness.id);
            this.handleResponse(res, data => {
                let msg = "Activated";
                if (this.selectedBusiness.active) msg = "Deactivated";
                this.showSnackbar(`Bussiness ${msg} successfully.`);
                this.selectedBusiness.active = !this.selectedBusiness.active;
                this.$store.commit("selectBusiness", this.selectedBusiness);
                this.$store.commit("updateBusinesses", data);
            });
        } catch (error) {
            this.handleError(
                error,
                "Couldn't fetch businesses. Please try again later."
            );
        }
          this.loading = false;
    },
    getStoreName(id) {
      const store = this.stores.find(x => x.id == id);
      return store ? store.name : "";
    },
    async getRoles() {
        try {
            const res = await this.$http.get(`${this.apiBaseUrl}/roles`);
            this.handleResponse(res, data => {
                this.roles = data;
            });
        } catch (error) {
            this.handleError(
                error,
                "Couldn't fetch user roles. Please try again later."
            );
        }
      },
      async fetchStores() {
          if (!this.selectedBusiness) return;
          this.loading = true;
          try {
              const { data } = await this.$http.get(`${this.apiBaseUrl}/stores/get-stores?BusinessId=` + this.selectedBusiness.id);
              this.stores = data.data;
              // console.log('this.stores', this.stores);
          } catch (error) {
              // console.log(error);
              this.handleError(error, "Error fetching stores");
          }
          this.loading = false;
      },
      async getUsers() {
          if (!this.selectedBusiness) return;
          this.fetchStores();
          this.loading = true;
          try {
              const res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/accesscontrol?BusinessId=` + this.selectedBusiness.id);
              this.loading = false;
              this.handleResponse(res, data => {
                  this.users = data;
              });
          } catch (error) {
              this.loading = false;
              this.handleError(
                  error,
                  "We couldn't fetch users. Please try again later."
              );
          }
      },
      closeUserDialog() {
          this.selectedUser = Object.assign({}, {});
          this.userDialog = false;
      },
      refreshData(user) {
          //this.users.push(user);
          this.userDialog = false;
          this.getUsers();
      },
      updateUserInfo(user) {
          this.selectedUser = Object.assign({}, {});
          const index = this.users.findIndex(x => x.id == user.id);
          this.$set(this.users, index, user);
          this.userDialog = false;
      },
  },
  watch: {
    selectedBusiness: {
      handler() {
        this.getUsers();
      },
      deep: true
    }
  },
};
</script>
<style scoped>
.customer-name {
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
</style>