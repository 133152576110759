<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-data-table
        disable-initial-sort
        :items="balances"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No return of goods.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ fullDateFormat(props.item.start_time) }}</td>
          <td class="text-xs-right">
            {{ moneyFormat(props.item.start_amount) }}
          </td>
          <td>{{ fullDateFormat(props.item.end_time) }}</td>
          <td class="text-xs-right">
            {{ moneyFormat(props.item.end_amount) }}
          </td>
          <td class="text-xs-right">
            {{ numberFormat(props.item.actions.length) }}
          </td>
          <td>
            <v-tooltip bottom>
              <v-icon slot="activator" @click="showInfo(props.item)"
                >info</v-icon
              >
              <span>View details</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>

    <!-- Item dialog -->
    <v-dialog v-model="showItemDialog" max-width="550px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <h3>View Details</h3>
        </v-card-title>

        <v-data-table
          :headers="actionHeaders"
          :items="selectedItem.actions"
          hide-actions
          class="elevation-1"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.user_name }}</td>
            <td class="text-xs-right">{{ moneyFormat(props.item.amount) }}</td>
            <td>{{ props.item.action ? "Deposit" : "Withdrawal" }}</td>
            <td>
              {{ props.item.is_change ? "Change given" : "No change given" }}
            </td>
          </template>
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Item dialog -->
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
      },
      headers: [
        {
          text: "Start time",
          value: "start_time"
        },
        {
          text: "Start Amount",
          value: "start_amount",
          align: "right"
        },
        {
          text: "End time",
          value: "end_time"
        },
        {
          text: "End amount",
          value: "end_amount",
          align: "right"
        },
        {
          text: "Total actions",
          value: "actions.length",
          align: "right"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      actionHeaders: [
        {
          text: "Seller name",
          value: "user_name"
        },
        {
          text: "Amount",
          value: "amount",
          align: "right"
        },
        {
          text: "Action",
          value: "action"
        },
        {
          text: "Change status",
          value: "is_change"
        }
      ],
      loading: false,
      selectedItem: {},
      balances: [],
      search: "",
      showItemDialog: false
    };
  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let url = `${this.apiBaseUrl}/balances?storeId=${storeId}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.balances = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    },
    showInfo(balance) {
      if (balance) {
        this.selectedItem = balance;
      }
      this.showItemDialog = true;
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
