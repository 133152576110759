const functions = {
  generateErrorMessages: errors => {
    const keys = Object.keys(errors);
    const length = keys.length;
    const is = length > 1 ? "are" : "is";
    const suffix = length > 1 ? "errors" : "error";
    let message = `There ${is} ${length} ${suffix}.<br />`;
    let body = keys
      .map(x => {
        return errors[x][0]; // Pick first error
      })
      .join("<br />");

    message += body;
    return message;
  }
};

export default functions;
