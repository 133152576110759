import Index from "@/views/warehouse/Index";
import Receipts from "@/views/warehouse/receipts/Index";
import Inventory from "@/views/warehouse/Inventory";
import StorageStatus from "@/views/warehouse/StorageStatus";
// import ChangeQuantity from "@/views/warehouse/ChangeQuantity";
import MoveStock from "@/views/warehouse/MoveStock";
import Supplier from "@/views/warehouse/Supplier/Index";
import CustomerOrder from "@/views/warehouse/customerOrder/Index";
//import Return from "@/views/warehouse/returnReceipt/Return";

export default [
  {
    path: "/warehouse",
    name: "Warehouses",
    component: Index,
    access: "manage_warehouse",
    icon: "dns",
    showInNav: true
  },
  {
    path: "/warehouse/storage_status",
    name: "Storage Status",
    component: StorageStatus,
    access:"storage_status",
    icon: "storage",
    showInNav: true
  },
  {
    path: "/warehouse/inventory",
    name: "Inventory Adjustment",
    component: Inventory,
    access:"warehouse_inventory",
    icon: "add_shopping_cart",
    showInNav: true
  },
  {
    path: "/warehouse/receipts",
    name: "Incoming Receipts",
    component: Receipts,
    access:"incoming_receipts",
    icon: "add_circle_outline",
    showInNav: true
  },
  {
    path: "/warehouse/customer_order",
    name: "Customer Order",
    component: CustomerOrder,
    access: "customer_order",
    icon: "people_outline",
    showInNav: true
  },
  {
    path: "/warehouse/supplier",
    name: "Supplier",
    component: Supplier,
    icon: "add_shopping_cart",
    access:"warehouse_supplier",
    showInNav: true
  },
  // {
  //   path: "/warehouse/change_quantity",
  //   name: "Change Quantity",
  //   component: ChangeQuantity
  // },
  {
    path: "/warehouse/move_stock",
    name: "Move Stock",
    component: MoveStock,
    access:"move_stock",
    icon: "compare_arrows",
    showInNav: true
  },
  //{
  //  path: "/warehouse/return",
  //  name: "Reversal",
  //  component: Return,
  //  access:"return",
  //  icon: "remove_shopping_cart",
  //  showInNav: true
  //}
];
