<template>
  <layout>
    <v-layout row wrap>
      <v-flex sm8 py-2>
        <h3>All Stores</h3>
      </v-flex>
      <v-flex sm4 px-2 text-sm-right>
        <v-btn @click="showDialog" color="primary">
          <v-icon>add_circle_outline</v-icon>&nbsp; Add store
        </v-btn>
      </v-flex>
    </v-layout>
    <br />
    <v-divider></v-divider>
    <br />
    <v-layout row wrap>
      <v-flex xs12>
        <v-data-table
          :items="items"
          :rows-per-page-items="[10]"
          :headers="stores.headers"
          :loading="loading.stores"
        >
          <template slot="no-data">
            No stores. Add a new store above.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.code ? props.item.code : "NIL" }}</td>
            <td>{{ props.item.address }}</td>
            <td>{{ props.item.phone_number }}</td>
            <!-- <td>
              <v-icon small :color="props.item.is_subscribed ? 'green' : 'red'">
                {{ props.item.is_subscribed ? "check_circle" : "remove_circle" }}
              </v-icon>
            </td> -->
            <td>
              <v-icon small :color="props.item.is_active ? 'green' : 'red'">
                {{ props.item.is_active ? "check_circle" : "remove_circle" }}
              </v-icon>
            </td>
            <td>
              <v-tooltip bottom>
                <v-icon
                  slot="activator"
                  small
                  @click="showStoreInfo(props.item)"
                  >edit</v-icon
                >
                <span>Edit store</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="storeDialog" max-width="800px" persistent>
      <v-form ref="store_form" lazy-validation @submit.prevent="saveStore">
        <v-card>
          <v-card-title primary-title>
            <h3>
              Enter store details
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field
                  :validate-on-blur="true"
                  v-model="storeInfo.name"
                  :rules="[
                    v => (!!v && v.length > 3) || 'Store name is required.'
                  ]"
                  label="Store name"
                  required
                  prepend-icon="bookmark"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :validate-on-blur="true"
                  v-model="storeInfo.address"
                  label="Address"
                  :rules="[v => (!!v && v.length > 6) || 'Enter an address']"
                  required
                  prepend-icon="place"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-text-field
                  :validate-on-blur="true"
                  v-model="storeInfo.phone_number"
                  :rules="formRules.phone"
                  label="Contact Phone no."
                  required
                  prepend-icon="phone"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  :validate-on-blur="true"
                  v-model="storeInfo.email"
                  label="Contact Email"
                  prepend-icon="email"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-select
                  :validate-on-blur="true"
                  :items="storeTypes"
                  v-model="storeInfo.store_type"
                  item-text="name"
                  item-value="id"
                  label="Select Store Type"
                  single-line
                  prepend-icon="folder_open"
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                  <v-layout>
                      <v-flex md5 xs12>
                          <v-checkbox required
                                      v-model="storeInfo.is_active"
                                      label="Active"></v-checkbox>
                      </v-flex>
                      <v-flex md7 xs12>
                          <v-checkbox required
                                      v-model="storeInfo.use_store_price"
                                      label="Use store price"></v-checkbox>
                      </v-flex>
                  </v-layout>
              </v-flex>
            </v-layout>
            <v-subheader>
              Working hours
            </v-subheader>
            <v-layout row wrap>
              <v-flex xs12 md6>
                <v-menu
                  ref="openingMenu"
                  :close-on-content-click="false"
                  v-model="pickers.opening"
                  :nudge-right="40"
                  :return-value.sync="storeInfo.openingTime"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="storeInfo.openingTime"
                    label="Opening time"
                    prepend-icon="access_time"
                    readonly
                  ></v-text-field>
                  <v-time-picker format="24hr" v-model="storeInfo.openingTime">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="pickers.opening = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.openingMenu.save(storeInfo.openingTime)"
                      >OK</v-btn
                    >
                  </v-time-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 md6>
                <v-menu
                  ref="closingMenu"
                  :close-on-content-click="false"
                  v-model="pickers.closing"
                  :nudge-right="40"
                  :return-value.sync="storeInfo.closingTime"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  max-width="290px"
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    v-model="storeInfo.closingTime"
                    label="Closing time"
                    prepend-icon="access_time"
                    readonly
                  ></v-text-field>
                  <v-time-picker format="24hr" v-model="storeInfo.closingTime">
                    <v-spacer></v-spacer>
                    <v-btn flat color="primary" @click="pickers.closing = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      flat
                      color="primary"
                      @click="$refs.closingMenu.save(storeInfo.closingTime)"
                      >OK</v-btn
                    >
                  </v-time-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="warning"
              @click.native="closeStoreDialog"
              :disabled="posting"
            >
              Cancel
            </v-btn>
            <v-btn color="success" type="submit" :disabled="posting">
              {{ buttons.store.text }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import storeData from "@/services/data/storesData";

export default {
  components: {
    Layout
  },
  computed: {
    buttons() {
      const texts = storeData.buttonTexts;
      const text = this.operation.length ? texts[1] : texts[0];
      return {
        store: {
          text: this.posting ? text.active : text.default
        }
      };
    }
  },
  created() {
    this.getStores();
    this.getStoreTypes();
  },
  data() {
    return {
      buttonTexts: storeData.buttonTexts,
      items: this.$store.getters.stores,
      loading: storeData.loading,
      operation: "",
      pickers: storeData.pickers,
      posting: false,
      removeItemDialog: false,
      store: {},
      storeDialog: false,
      storeInfo: {},
      stores: {
        headers: storeData.headers,
        items: []
      },
      storeTypes: [],
      time: null
    };
  },
  methods: {
    closeStoreDialog() {
      this.storeDialog = false;
      this.operation = "";
      this.$refs.store_form.reset();
      this.storeInfo = {};
    },
    async getStores() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.loading.stores = false;
        this.handleResponse(res, data => {
          this.items = data;
          this.$store.commit("updateStores", data);
        });
      } catch (error) {
        this.loading.stores = false;
        this.handleError(
          error,
          "We couldn't fetch your stores. Please try again."
        );
      }
    },
    async getStoreTypes() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/storetypes`);
        this.loading.storeTypes = false;
        this.handleResponse(res, data => {
          this.storeTypes = data;
        });
      } catch (error) {
        this.loading.storeTypes = false;
        this.handleError(error, "Unable to get store types");
      }
    },
    parseStoreInfo(store) {
      const info = Object.assign({}, store);
      const hours = JSON.parse(store.working_hours);
      if (hours) {
        info.openingTime = hours.openingTime || "";
        info.closingTime = hours.closingTime || "";
      }

      return info;
    },
    async saveStore() {
      if (
        typeof this.storeInfo.openingTime === "undefined" ||
        typeof this.storeInfo.closingTime === "undefined"
      ) {
        this.showSnackbar("Opening and closing times must be set", {
          type: "error"
        });
        return;
      }

      if (!this.$refs.store_form.validate()) return;
      const storeInfo = Object.assign({}, this.storeInfo);
      storeInfo.working_hours = JSON.stringify({
        openingTime: storeInfo.openingTime,
        closingTime: storeInfo.closingTime
      });
      const storeTypeId = storeInfo.store_type.id;
      if (storeTypeId) storeInfo.store_type = storeTypeId;

      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/stores`,
          storeInfo
        );
        this.posting = false;
        this.handleResponse(res, () => {
          if (!this.operation) {
            let newStore = res.data.data;
            this.items.push(newStore);
            this.$store.commit("addStore", this.storeInfo);
          } else {
            this.updateStore(storeInfo);
          }
          const action = this.operation ? "updated" : "added";
          this.closeStoreDialog();
          this.showSnackbar(`Store ${action} successfully. Kindly synchronize the mobile application for this changes.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "An error occurred. Please try again later.");
      }
    },
    showDialog() {
      this.storeDialog = true;
    },
    showStoreInfo(store) {
      if (store) {
        this.storeInfo = this.parseStoreInfo(store);
      }
      this.operation = "update";
      this.showDialog();
    },
    updateStore(item) {
      const index = this.items.findIndex(x => x.id == item.id);
      this.$set(this.items, index, item);
      this.$store.commit("updateStores", this.items);
    }
  }
};
</script>
