<template>
    <layout requires-store>
        <template v-if="selectedStore || isLoggedIn.store_id">
            <v-layout row wrap>
                <v-flex xs12 sm3>
                    <v-menu ref="startDayMenu"
                            v-model="startDayMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="filter.startDate"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="filter.startDate"
                                          label="Start date"
                                          prepend-icon="event"
                                          hint="YYYY/MM/DD format"
                                          readonly
                                          v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="filter.startDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn flat color="primary" @click="startDayMenu = false">Cancel</v-btn>
                            <v-btn flat
                                   color="primary"
                                   @click="$refs.startDayMenu.save(filter.startDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex xs12 sm3>
                    <v-menu ref="endDayMenu"
                            v-model="endDayMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="filter.endDate"
                            lazy
                            transition="scale-transition"
                            offset-y
                            full-width
                            min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="filter.endDate"
                                          label="End Date"
                                          prepend-icon="event"
                                          hint="YYYY/MM/DD format"
                                          readonly
                                          v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="filter.endDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn flat color="primary" @click="endDayMenu = false">Cancel</v-btn>
                            <v-btn flat
                                   color="primary"
                                   @click="$refs.endDayMenu.save(filter.endDate)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-flex>

                <v-flex xs12 sm3>
                    <v-btn color="info" @click="filterData"> Filter </v-btn>
                </v-flex>

                <v-flex xs12 sm3>
                    <div class="text-xs-right">
                        <DownloadCSV class="v-btn theme--light success"
                                     :data="productDetailsCsv"
                                     name="export-customer-order.csv"
                                     v-if="filterOrders.length">
                            Export
                        </DownloadCSV>
                    </div>
                </v-flex>
            </v-layout>

            <v-data-table disable-initial-sort
                          :items="filterOrders"
                          :rows-per-page-items="[10]"
                          :search="search"
                          :headers="headers"
                          :loading="loading">
                <template slot="no-data">
                    No Orders.
                </template>
                <template slot="items" slot-scope="props">
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.orderId }}</td>
                    <td>{{ props.item.customerName }}</td>
                    <td>{{ props.item.author }}</td>
                    <td>
                        <v-chip class="ma-2"
                                small
                                :color="colors[props.item.status]"
                                label
                                outlined>
                            {{flags[props.item.status]}}
                        </v-chip>
                    </td>
                    <td>
                        <v-tooltip bottom>
                            <v-icon slot="activator" @click="showInfo(props.item)">info</v-icon>
                            <span>View details</span>
                        </v-tooltip>
                    </td>
                    <td>{{moneyFormat(props.item.totalCost)}}</td>
                    <td>{{moneyFormat(props.item.totalAmount)}}</td>
                    <td v-if="props.item.status == 0">{{flags[props.item.status]}}</td>
                    <td v-else>{{flags[props.item.status]}} by {{props.item.assignee}}</td>
                </template>
            </v-data-table>
        </template>
        <template v-else>
            No store selected
        </template>

        <!-- Item dialog -->
        <v-dialog v-model="showItemDialog" max-width="500px" persistent>
            <v-card flat>
                <v-card-title primary-title>
                    <v-flex xs12 sm6 px-2>
                        <h3>Products</h3>
                    </v-flex>
                </v-card-title>
                <template>
                    <v-data-table :headers="productHeaders"
                                  :items="selectedItem.products"
                                  hide-actions
                                  class="elevation-1">
                        <template slot="items" slot-scope="props">
                            <td>{{ props.item.product }}</td>
                            <td class="text-xs-right">{{ props.item.quantity }}</td>
                            <td class="text-xs-right">{{ moneyFormat(props.item.costPrice) }}</td>
                            <td class="text-xs-right">{{ moneyFormat(props.item.sellingPrice) }}</td>
                        </template>
                    </v-data-table>
                </template>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Item dialog -->
    </layout>
</template>

<script>
import JsonCSV from "vue-json-csv";
import Layout from "@/components/Layout";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      flags: [ "Pending", "Cancelled", "Approved"],
      colors: [ "orange", "red", "green"],
      headers: [
        { text: "Date", value: "date" },
        { text: "OrderId", value: "orderId" },
        { text: "Customer Name", value: "customerName" },
        { text: "Initiated by", value: "author" },
        { text: "Status", value: "status"},
        { text: "Products", value: "products" },    
        { text: "Total Cost price", value: "totalCost" },
        { text: "Total Selling price", value: "totalAmount" },
        { text: "Action", value: "assignee"},
      ],
        productHeaders: [
            {
                text: "Name",
                value: "id"
            },
            {
                text: "Quantity",
                value: "quantity",
                align: "right"
            },
            {
                text: "Cost Price",
                value: "price",
                align: "right"
            },
            {
                text: "Selling Price",
                value: "price",
                align: "right"
            }
        ],
      loading: false,
      orders: [],
      showItemDialog: false,
      selectedItem: {},
      search: "",
      startDayMenu: false,
      endDayMenu: false,
    };
  },
  mounted() {
  },
  computed: {
    filterOrders() {
      return this.orders.map((item) => ({
          date: this.fullDateFormat(item.created_at),
          orderId: item.order_number,
          customerName: item.customer_name,
          author: item.author,
          status: item.status,
          products: item.products,
          totalCost: this.getTotalAmount(item,"cost"),
          totalAmount: this.getTotalAmount(item,"sale"),
          assignee: item.assingee
      }));
    },
      productDetailsCsv() {
          let list = [];
          this.orders.forEach((item) => {
              item.products.forEach(product => {
                  var temp = {};
                  temp.Date = this.fullDateFormat(item.created_at);
                  temp.OrderId = item.order_number;
                  temp.CustomerName = item.customer_name;
                  temp.InitiatedBy = item.author;
                  temp.Product = product.product;
                  temp.CostPrice = product.costPrice;
                  temp.SellingPrice = product.sellingPrice;
                  temp.Quantity = product.quantity;
                  temp.TotalAmount = product.sellingPrice * product.quantity;
                  temp.Status = this.flags[item.status];
                  temp.ApprovedBy = item.assingee;
                  list.push(temp);
                });
          });
          return list;
      },
  },
  methods: {
      getTotalAmount(items, type) {
          var amount = 0;
          items.products.forEach(product => {
              if (type == "cost")
                  amount += product.costPrice * product.quantity;
              else amount += product.sellingPrice * product.quantity;
          });
          return amount;
      },
      showInfo(product) {
          if (product) {
              this.selectedItem = product;
          }
          this.showItemDialog = true;
      },
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      const params = {
        storeId,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      };

      this.loading = true;
      try {
        const [orders] = await Promise.all([
          this.$http.get(`${this.apiBaseUrl}/customersorder/report`, {
            params
          })
        ]);
        this.orders = orders.data.data;
      } catch (error) {
        this.handleError(error, "Error loading data");
      }
      this.loading = false;
    },
    filterData() {
      this.getData();
    },
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
