<template>

    <v-layout>
        <v-dialog v-model="userDialog" max-width="900px" persistent>
            <v-card>
                <v-card-title primary-title>
                    <h3>
                        Enter user's details
                    </h3>
                </v-card-title>
                <transition name="fade">
                    <div class="px-3" v-if="alert.single.show">
                        <v-alert v-model="alert.single.show"
                                 :type="alert.single.type"
                                 dismissible>
                            {{ alert.single.msg }}
                        </v-alert>
                    </div>
                </transition>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form lazy-validation ref="users_form" @submit.prevent="saveUsers">
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="selectedUser.first_name"
                                              :validate-on-blur="true"
                                              label="First name"
                                              placeholder="Enter first name"
                                              :rules="formRules.required"
                                              name="first_name"></v-text-field>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="selectedUser.last_name"
                                              :validate-on-blur="true"
                                              label="Last name"
                                              placeholder="Enter last name"
                                              :rules="formRules.required"
                                              name="last_name"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="selectedUser.phone_number"
                                              @keydown="checkPhoneNumber"
                                              :validate-on-blur="true"
                                              label="Phone number"
                                              placeholder="Enter phone number"
                                              :rules="formRules.required"
                                              name="phone_number"></v-text-field>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              type="email"
                                              v-model="selectedUser.email"
                                              :validate-on-blur="true"
                                              label="Email address"
                                              placeholder="Enter email address"
                                              :rules="formRules.required"
                                              name="email"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-select required
                                          :loading="roles.length < 1"
                                          v-model="selectedUser.role"
                                          :items="roles"
                                          item-text="name"
                                          item-value="name"
                                          :validate-on-blur="true"
                                          label="Role"
                                          placeholder="Select role"
                                          :rules="formRules.required"></v-select>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="selectedUser.contact_address"
                                              :validate-on-blur="true"
                                              label="Contact address"
                                              placeholder="Enter contact address"
                                              :rules="formRules.required"
                                              name="contact_address"
                                              type="address"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex sm6 px-2>
                                <v-select required
                                          v-model="selectedUser.store_id"
                                          :items="stores"
                                          item-text="name"
                                          item-value="id"
                                          :validate-on-blur="true"
                                          label="Store"
                                          placeholder="Select store"></v-select>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-flex xs12>
                                    <v-select v-model="selectedUser.assigned_menus"
                                              :items="availableMenus"
                                              item-text="label"
                                              item-value="value"
                                              attach
                                              chips
                                              label="Assigned Menus"
                                              multiple></v-select>
                                </v-flex>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex sm6 px-2>
                                <v-checkbox required
                                            v-model="selectedUser.active"
                                            label="Active" />
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-btn @click="$emit('closeUserDialog')" type="button" flat :disabled="posting">Close</v-btn>
                            <v-btn type="submit"
                                   flat
                                   :disabled="posting || roles.length < 1"
                                   color="primary">{{ saveText }}</v-btn>
                        </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
export default {
    props:{
        "userDialog": Boolean,
        "selectedUser": {},
        "roles": [],
    },
  data() {
    return {
      loaded: false,
      stores: [],
      menus: [],
      partners: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Amount", value: "transaction_amount", align: "right" }
      ]
    };
  },
  computed: {
    saveText() {
      return this.posting ? "Saving.." : "Add User";
    },
    availableMenus() {
      return this.menus.map(x => {
        return {
          label: this.titleCase(x),
          value: x
        };
      });
    }
  },
  created() {
    //this.fetchPartners();
      this.fetchStores();
      this.getMenus();
  },
  watch: {
    selectedBusiness: {
      handler() {
        this.fetchStores();
      },
      deep: true
    }
  },
  methods: {
      async fetchStores() {
          this.loading = true;
          try {
              const { data } = await this.$http.get(`${this.apiBaseUrl}/stores/get-stores?BusinessId=` + this.selectedBusiness.id);
              this.stores = data.data;
              // console.log('this.stores', this.stores);
          } catch (error) {
              // console.log(error);
              this.handleError(error, "Error fetching stores");
          }
          this.loading = false;
      },
    closeUserDialog() {
        //this.$refs.users_form.reset();
        //this.$emit("closeDialog");
        //this.userDialog = false;
      },
      async getMenus() {
          try {
              const { data } = await this.$http.get(`${this.apiBaseUrl}/menus`);
              this.menus = data;
          } catch (error) {
              this.handleError(
                  error,
                  "Couldn't fetch menus. Please try again later."
              );
          }
      },
    getPartnetById(id) {
      return this.partners.find(x => x.id == id) || {};
      },
    titleCase(value) {
        let sentence = value.toLowerCase().split(" ");
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }

        return sentence.join(" ");
    },
    getStore(storeId) {
      return this.stores.find(x => x.id == storeId) || {name: 'Admin'};
      },

      async saveUsers() {
          if (!this.$refs.users_form.validate()) return;
          this.selectedUser.BusinessId = this.selectedBusiness.id;
          const selectedUser = Object.assign({}, this.selectedUser);
          this.posting = true;
          try {
              const url = `${this.apiBaseUrl}/superAdmin/accesscontrol`;
              const isUpdate = this.selectedUser.id;
              const res = isUpdate
                  ? await this.$http.patch(url, selectedUser)
                  : await this.$http.post(url, selectedUser);
              this.posting = false;
              this.handleResponse(res, data => {
                  isUpdate ? this.$emit("updateUserInfo",data) : this.$emit("refreshData",data);
                  const action = isUpdate ? "updated" : "added";
                  this.closeUserDialog();
                  this.showSnackbar(`User ${action} successfully.`);
              });
          } catch (error) {
              this.posting = false;
              this.handleError(error, "Operation failed. Please try again later.");
          }
      },

      checkPhoneNumber(e) {
          const value = e.key;
          if (!value.match(/[0-9]+/) && value.toLowerCase() != "backspace") {
              e.preventDefault();
          }
      },
  }
};
</script>