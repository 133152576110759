<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-menu
            ref="startDayMenu"
            v-model="startDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.startDate"
                label="Start Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="startDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.startDayMenu.save(filter.startDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-menu
            ref="endDayMenu"
            v-model="endDayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="filter.endDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="filter.endDate"
                label="End Date"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="endDayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.endDayMenu.save(filter.endDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-btn color="info" @click="filterData">
            Filter
          </v-btn>
        </v-flex>

        <v-flex xs12 sm3>
          <div class="text-xs-right">
            <DownloadCSV
              class="v-btn theme--light success"
              :data="records"
              name="export.csv"
              v-if="records.length"
            >
              Export
            </DownloadCSV>
          </div>
        </v-flex>
      </v-layout>

      <v-data-table
        disable-initial-sort
        :items="records"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No sales.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.receiptName }}</td>
          <td class="text-xs-right">
            {{ props.item.date }}
          </td>
          <td>{{ props.item.productCode }}</td>
          <td>{{ props.item.productName }}</td>
          <td class="text-xs-right">
            {{ props.item.quantity }}
          </td>
          <td class="text-xs-right">
            {{ props.item.sellingPrice }}
          </td>
          <td class="text-xs-right">
            {{ props.item.costPrice }}
          </td>
          <td>{{ props.item.supplier }}</td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import JsonCSV from "vue-json-csv";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: ""
      },
      headers: [
        { text: "Receipt #", value: "receiptId" },
        { text: "Date", value: "date", align: "right" },
        { text: "Product Code", value: "productCode" },
        { text: "Product Name", value: "productName" },
        { text: "Quantity", value: "totalQuantity", align: "right" },
        { text: "Sell Price", value: "sellingPrice", align: "right" },
        { text: "Cost Price", value: "costPrice", align: "right" },
        { text: "Supplier", value: "supplier" }
      ],
      loading: false,
      search: "",
      products: [],
      suppliers: [],
      // selectedDay: new Date().toISOString().substr(0, 10),
      startDayMenu: false,
      endDayMenu: false
    };
  },
  computed: {
    records() {
      return this.products.map(x => {
        return {
          receiptName: x.receiptName,
          date: this.fullDateFormat(x.date),
          productCode: x.productCode,
          productName: x.productName,
          quantity: this.numberFormat(x.quantity),
          costPrice: this.moneyFormat(x.costPrice),
          sellingPrice: this.moneyFormat(x.sellingPrice),
          supplier: this.getSupplier(x.incomingReceipt.productSupplierId).companyName || ""
        };
      });
    }
  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;

      const params = {
        storeId,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      };
      let url = `${this.apiBaseUrl}/incoming-receipts/product-report`;
      this.loading = true;
      try {
        // Load all Suppliers
        this.getSuppliers();
        const { data } = await this.$http.get(url, { params });
        this.products = data.data;
      } catch (error) {
        this.handleError(error, "Error loading data");
      }
      this.loading = false;
    },
    async getSuppliers() {
      this.loading = true;
      let url = `${this.apiBaseUrl}/suppliers`;
      try {
        const { data } = await this.$http.get(url);
        console.log("getSupplier", data);
        this.suppliers = data.data;
      } catch (error) {
        this.handleError(error, "We couldn't load your suppliers.");
      }
      this.loading = false;
    },
    filterData() {
      this.getData();
    },
    totalQty(items) {
      return items.reduce(function(a, b) {
        return a + b["quantity"];
      }, 0);
    },
    getSupplier(id) {
      const supplier = this.suppliers.find(x => x.id == id);

      return supplier || {};
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
