<template>
    <v-card class="dash-card">
        <v-card-title class="orange darken-2 white--text">
            Most Used Payment Methods per Business
        </v-card-title>
        <div class="pt-0">
            <v-layout>
                <v-flex sm6 md4 offset-sm6 offset-md8>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search"
                                  label="Search"
                                  single-line
                                  hide-details
                                  clearable></v-text-field>
                </v-flex>
            </v-layout>
        </div>
        <v-data-table :headers="headers"
                      :items="list"
                      :loading="loading"
                      :search="search"
                      class="elevation-1"
                      :rows-per-page-items="[5]">
            <template slot="items" slot-scope="props">
                <td>{{ props.item.businessName }}</td>
                <td class="text-sm-right">{{ props.item.paymentMethod }}</td>
                <td class="text-sm-right">{{ props.item.count }}</td>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Name", value: "businessName" },
        { text: "Payment Method", value: "paymentMethod", align: "right" },
        { text: "Count", value: "count", align: "right" }
      ],
        search:""
    };
  },
  props: {
    list: Array,
    'loading': Boolean
  }
};
</script>
