<template>
  <v-card class="dash-card">
    <v-card-title class="purple darken-2 white--text">
      Recent Sales
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="sales"
      class="elevation-1"
      :rows-per-page-items="[5]"
    >
      <template slot="items" slot-scope="props">
        <td>{{ props.item.store_name }}</td>
        <td>{{ props.item.transaction_amount }}</td>
        <td class="text-sm-right">{{ props.item.store_products.length }}</td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      startDate: false,
      endDate: false,
      headers: [
        { text: "Store", value: "name" },
        { text: "Amount", value: "transaction_amount" },
        { text: "No of products", value: "sales", align: "right" }
      ],
      data: []
    };
  },
  props: {
    sales: Array
  }
};
</script>

<style scoped></style>
