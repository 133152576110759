import Vue from "vue";
import Vuex from "vuex";
import config from "./config";
import db from "./db";
import envConfig from "./config/env";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appName: {},
    alert: {
      all: {
        show: false,
        msg: "",
        type: "success"
      },
      single: {
        show: false,
        msg: "",
        type: "success"
      }
    },
    drawer: undefined,
    isLoggedIn: false,
    loadingPage: false,
    selectedStore: db.get(config.constants.SELECTED_STORE),
    selectedBusiness: db.get(config.constants.SELECTED_BUSINESS),
    selectedWarehouse: db.get(config.constants.SELECTED_WAREHOUSE),
    businesses: db.get(config.constants.BUSINESSES),
    reference: db.get(config.constants.REFERENCE),

    // selectedStore: null,
    showGlobalLoader: false,
    snackbar: {
      content: "",
      show: false,
      mode: "",
      align: "",
      position: "top",
      timeout: 4000
    },
    stores: [],
    subscription: {}
  },
  getters: {
    subscriptionStatus(state) {
      return state.subscription.isActive;
    },
    appName(state) {
      return state.appName;
    },
    bgColor() {
      let config =
        process.env.APP_ENV === "airtill"
          ? envConfig.airtill
          : envConfig.default;
      return config.theme.background;
    },
    accessToken() {
      const user = db.get(config.constants.USER);
      return user ? user.token : null;
    },
    alert(state) {
      return state.alert;
    },
    businessInfo() {
      return db.get(config.constants.BUSINESS);
    },
    referenceInfo() {
      return db.get(config.constants.REFERENCE) || "--";
    },
    drawer(state) {
      return state.drawer;
    },
    globalLoader(state) {
      return state.showGlobalLoader;
    },
    loadingPage(state) {
      return state.loadingPage;
    },
    selectedStore: state => () => {
      return state.selectedStore;
    },
    reference: state => () => {
        //console.log(db.get(config.constants.REFERENCE))
        //return state.reference;
       return state.reference;
    },
    selectedBusiness: state => () => {
      return state.selectedBusiness;
    },
    selectedWarehouse: state => () => {
      return state.selectedWarehouse;
    },
    snackbar(state) {
      return state.snackbar;
    },
    stores() {
      const business = db.get(config.constants.BUSINESS);
      return business ? business.stores.sort((a, b) => a.name > b.name) : [];
    },
    userData() {
      return db.get(config.constants.USER);
    },
    businesses: state => () => {
        return state.businesses;
    }
  },
  mutations: {
    setAppName(state, config) {
      state.appName = config;
    },
    addStore(state, store) {
      const businessInfo = db.get(config.constants.BUSINESS) || {};
      const stores = businessInfo.stores || [];
      stores.push(store);
      businessInfo.stores = stores;
      db.save(config.constants.BUSINESS, businessInfo);
    },
    burn() {
      localStorage.clear();
      return (window.location = "/");
    },
    selectStore(state, storeData) {
      db.save(config.constants.SELECTED_STORE, storeData);
      state.selectedStore = storeData;
    },
    selectBusiness(state, businessData) {
      db.save(config.constants.SELECTED_BUSINESS, businessData);
      state.selectedBusiness = businessData;
    },
    selectWarehouse(state, warehouse) {
      db.save(config.constants.SELECTED_WAREHOUSE, warehouse);
      state.selectedWarehouse = warehouse;
    },
    showAlert(state, alertObj) {
      const type = alertObj.type || "success";
      const msg = alertObj.msg || "An error occurred.";
      const global = alertObj.global == false ? false : true;

      let obj = {};
      obj.msg = msg;
      obj.type = type;
      obj.show = true;

      const key = global ? "all" : "single";
      state.alert[key] = obj;

      setTimeout(() => {
        state.alert[key].show = false;
      }, 2500);
    },
    showGlobalLoader(state, bool) {
      const timeout = bool ? 0 : 500;
      setTimeout(() => {
        state.showGlobalLoader = bool;
      }, timeout);
    },
    showSnackbar(state, snackObj) {
      const obj = {};
      obj.content = snackObj.msg || "An error occurred.";
      let color = snackObj.type;
      if (color && color == "error") {
        color = "red darken-3";
      }
      obj.color = color;
      obj.show = true;
      obj.mode = snackObj.mode || false;
      obj.align = snackObj.align || false;
      obj.align = snackObj.align || "right";
      obj.position = snackObj.position || "bottom";
      // obj.position = snackObj.position || "top";
      obj.timeout = snackObj.timeout || 4000;
      state.snackbar = obj;
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    updateAccessToken(state, token) {
      const userData = db.get(config.constants.USER) || {};
      userData.token = token;
      db.save(config.constants.USER, userData);
    },
    updateDrawer(state, bool) {
      state.drawer = bool;
    },
    updateSubscription(state, data) {
      state.subscription = data;
    },
    updateUserData(state, data) {
      const userData = db.get(config.constants.USER) || {};
      const updatedUser = Object.assign(userData, data);
      // const updatedUser = {
      //   ...userData,
      //   ...data
      // };

      db.save(config.constants.USER, updatedUser);
    },
    saveReference(state,data) {
        db.save(config.constants.REFERENCE, data);
        state.reference = data;
    },
    updateBusinessInfo(state, data) {
      const businessInfo = db.get(config.constants.BUSINESS) || {};
      const updatedBusiness = Object.assign(businessInfo, data);

      db.save(config.constants.BUSINESS, updatedBusiness);
    },
    updateStores(state, stores) {
      const businessInfo = db.get(config.constants.BUSINESS) || {};
      businessInfo.stores = stores;
      db.save(config.constants.BUSINESS, businessInfo);
    },
    updateBusinesses(state, business) {
      db.save(config.constants.BUSINESSES, business);
    }
  },
  actions: {}
});
