<template>


    <v-card flat style="margin-top:20px">
        <v-card-text>
            <v-layout row wrap>
                <v-flex>
                    <v-toolbar dense>

                        <v-text-field v-model="search"
                                      label="Search customer"
                                      single-line
                                      hide-details
                                      clearable></v-text-field>

                        <v-divider class="mr-2"
                                   vertical></v-divider>

                        <v-btn flat @click="showConfirmDialog('Delete')">
                            <span>Delete</span>&nbsp;
                            <v-icon class="red--text">delete</v-icon>
                        </v-btn>
                        <v-btn flat value="disabled" @click="showConfirmDialog('Disactive')">
                            <span>Deactive</span>&nbsp;
                            <v-icon class="light-blue--text">sync_disabled</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-flex>
                <!--<v-flex sm4>
                <v-text-field v-model="search"
                              append-icon="search"
                              label="Search customer"
                              single-line
                              hide-details
                              clearable></v-text-field>
                <br />
            </v-flex>-->
                <v-flex xs12 mt3>
                    <template>
                        <v-data-table v-model="selected"
                                      :rows-per-page-items="[20]"
                                      :headers="headers"
                                      :items="customers"
                                      select-all
                                      :search="search"
                                      item-key="id"
                                      class="elevation-1">

                            <!--select-all-->
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    select-all
                                                    hide-details
                                                    @click.stop="toggleAll"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text">
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>

                            <template slot="no-data">
                                No partners.
                            </template>
                            <!--:active="props.selected" @click="props.selected = !props.selected"-->
                            <template slot="items" slot-scope="props">
                                <tr :active="props.selected" @click="props.selected = !props.selected">
                                    <td>
                                        <v-checkbox :input-value="props.selected"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td>
                                        {{ props.item.name }} <br />
                                        <small>{{ props.item.address || "" }}</small>
                                    </td>
                                    <td>{{ props.item.phone_number }}</td>
                                    <td>{{ props.item.email }}</td>
                                    <td>
                                        <v-icon small :color="props.item.active ? 'green' : 'red'">
                                            {{ props.item.active ? "check_circle" : "remove_circle" }}
                                        </v-icon>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </v-flex>
            </v-layout>
        </v-card-text>

        <v-dialog v-model="confirmCustomerDialog" max-width="500px" persistent>
            <v-card>
                <v-card-title primary-title class="headline grey lighten-2">
                    <h6>{{type}} Customers Details</h6>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text>
                    Are you sure you want to {{type}} {{selected.length}} customer(s) ?
                    <v-layout>
                        <v-spacer></v-spacer>
                        <v-btn @click="closeCustomerDialog" flat :disabled="loading">Close</v-btn>
                        <v-btn @click="AltCustomers" flat color="primary" :disabled="loading">{{type}}</v-btn>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>

</template>

<script>
    export default {
        mounted() {
            this.getCustomers();
        },
        data() {
            return {
                selected: [],
                payload: {},
                type:"",
                filter: {
                    endDate: "",
                    startDate: "",
                    paymentMethod: ""
                },
                headers: [
                    {
                        text: "Name",
                        value: "name"
                    },
                    {
                        text: "Phone number",
                        value: "phone_number"
                    },
                    {
                        text: "Email",
                        value: "email"
                    },
                    {
                        text: "Status",
                        value: "active"
                    }
                ],
                loading: false,
                confirmCustomerDialog: false,
                customers: [],
                search: "",
            };
        },
        methods: {
            toggleAll() {
                if (this.selected.length) this.selected = []
                else this.selected = this.customers.slice();
            },
            async getCustomers() {
                let url = `${this.apiBaseUrl}/superAdmin/analysis/get-customers?businessId=${this.selectedBusiness.id}`;
                this.loading = true;
                try {
                    const res = await this.$http.get(url);
                    this.loading = false;
                    this.handleResponse(res, data => (this.customers = data));
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "Error loading data");
                }
            },
            closeCustomerDialog() {
                this.confirmCustomerDialog = false;
            },
            showConfirmDialog(type) {
                if (this.selected.length > 0) 
                    this.confirmCustomerDialog = true;
                this.type = type;
            },
            async AltCustomers() {

                let collection = [];
                this.selected.forEach(item => {
                    collection.push(item.id);
                });

                this.payload.partner_list = collection;
                this.payload.businessId = this.selectedBusiness.id;
                this.payload.type = this.type;

                const payload = Object.assign({}, this.payload);
                //console.log(payload);

                let url = `${this.apiBaseUrl}/superAdmin/analysis/alt-customers`;
                this.loading = true;
                try {
                    await this.$http.post(url, payload);
                    this.loading = false;
                    this.selected = [];
                    this.closeCustomerDialog();
                    this.showSnackbar(`Partners ${this.type}d Successfully`);
                    this.getCustomers();
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "Error Occured");
                }
            }
        },
        watch: {
            selectedBusiness: {
                handler() {
                    this.customers = [];
                    this.getCustomers();
                },
                deep: true
            }
        }
    };
</script>
