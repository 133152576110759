import Products_Groups from "@/views/setup/business/Products_Groups";
import AttrsOptions from "@/views/setup/business/AttrsOptions";
import Measurements from "@/views/setup/business/Measurements";
import TaxPayments from "@/views/setup/business/TaxPayments";

export default [
    {
        path: "/setup/business/products",
        name: "Products",
        component: Products_Groups,
        access: "setup_add_product",
        icon: "shopping_cart"
    },
   {
    path: "/setup/business/attribute",
    name: "Attribute",
    component: AttrsOptions,
    access: "setup_attribute",
    icon: "bookmarks"
  },
  {
    path: "/setup/business/measurements",
    name: "Units of Measurement",
    component: Measurements,
    access: "setup_measurement",
    icon: "line_weight"
  },
  {
    path: "/setup/business/tax_payments",
    name: "Tax/Payment Methods",
    component: TaxPayments,
    access: "setup_taxpayment",
    icon: "credit_card"
    }
];
