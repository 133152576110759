<template>
  <layout>
    <v-layout row wrap>
      <v-flex xs12>
          <v-tabs slider-color="primary">
              <v-tab>
                  <h4>Products</h4>
              </v-tab>
              <v-tab>
                  <h4>Product groups</h4>
              </v-tab>
              <v-tab>
                  <h4>Product variance</h4>
              </v-tab>
              <v-tab-item>
                  <template v-if="!loading.product">
                      <products :products="products"
                                :productGroups="productGroups"
                                @productUpdated="updateProduct"
                                @syncedVariance="syncedVariance"
                                @refreshProducts="refreshProducts"></products>
                  </template>
                  <template v-else>
                      <v-progress-linear height="2" indeterminate></v-progress-linear>
                  </template>
              </v-tab-item>
              <v-tab-item>
                  <template v-if="!loading.productGroups">
                      <groups :groups="productGroups"
                              @groupUpdated="updateProductGroup"
                              @groupAdded="addGroup"
                              @syncedGroups="syncedGroups"></groups>
                  </template>
                  <template v-else>
                      <v-progress-linear height="2" indeterminate></v-progress-linear>
                  </template>
              </v-tab-item>
              <v-tab-item>
                  <template v-if="!loading.product_variance">
                      <variance :variances="productVariance"
                                :products="products"
                                @syncedProducts="syncedProducts"
                                @syncedVariance="syncedVariance"></variance>
                  </template>
                  <template v-else>
                      <v-progress-linear height="2" indeterminate></v-progress-linear>
                  </template>
              </v-tab-item>
          </v-tabs>
      </v-flex>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import Products from "./products/Products.vue";
import Groups from "./products/Groups.vue";
import Variance from "./products/Variance.vue";

export default {
  components: {
    Groups,
    Layout,
    Products,
    Variance
  },
  created() {
    this.getProducts();
    this.getProductGroups();
    this.getProductsVariance();
  },
  data() {
    return {
      loading: {
        products: false,
        product_variance: false,
        productGroups: false,
        taxGroups: false,
        unitsOfMeasurement: false
      },
      products: [],
      productGroups: [],
      productVariance:[]
    };
  },
  methods: {
    addGroup(group) {
      this.productGroups.push(Object.assign({}, group));
      this.showSnackbar(`Product group added successfully.`);
    },
    async getProducts() {
      this.loading.products = true;
      try {
          const res = await this.$http.get(`${this.apiBaseUrl}/products/get-products`);
        this.loading.products = false;
        this.handleResponse(res, data => {
          this.products = data;
        });
      } catch (error) {
        this.loading.products = false;
        this.handleError(
          error,
          "We couldn't fetch your products. Please try again."
        );
      }
    },
    async getProductsVariance() {
      this.loading.product_variance = true;
      try {
          const res = await this.$http.get(`${this.apiBaseUrl}/products/get-products-variance`);
          this.loading.product_variance = false;
        this.handleResponse(res, data => {
           this.productVariance = data;
        });
      } catch (error) {
          this.loading.product_variance = false;
        this.handleError(
          error,
          "We couldn't fetch your product variances. Please try again."
        );
      }
    },
    async getProductGroups() {
      this.loading.productGroups = true;
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/productsgroup`);
        this.loading.productGroups = false;
        this.handleResponse(res, data => (this.productGroups = data));
      } catch (error) {
        this.handleError(
          error,
          "We couldn't fetch your products. Please try again."
        );
      }
    },
    updateProduct(product) {
      const index = this.products.findIndex(x => x.id == product.id);
      this.$set(this.products, index, product);
      this.showSnackbar(`Product updated successfully.`);
    },
    refreshProducts() {
      this.getProducts();
      this.showSnackbar(`Products refreshed successfully.`);
    },
    updateProductGroup(group) {
      const index = this.productGroups.findIndex(x => x.id == group.id);
      this.$set(this.productGroups, index, group);
      this.showSnackbar(`Product group updated successfully.`);
    },
    syncedGroups() {
      this.getProductGroups();
    },
    syncedProducts() {
      this.getProducts();
    },
    syncedVariance() {
        this.getProductsVariance();
    }
  }
};
</script>
