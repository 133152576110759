<template>
    <v-card v-if="!loading">
        <v-card-title class="blue-grey darken-2 white--text">
            Active Subscriptions
        </v-card-title>
        <v-card-text class="text-sm-right p-8">
            <h2>{{count}}</h2>
        </v-card-text>
    </v-card>
    <skeleton-loader :color="'blue-grey darken-2 white--text'" :title="'Active Subscriptions'" :height="'30px'" :count="1" v-else></skeleton-loader>
</template>

<script>
export default {
  name: "SubscriptionCount",
  data() {
    return {
      count: "-"
    };
  },
  props: ['count', 'loading'],
  mounted() {
    //this.getData();
  },
  methods: {
  }
};
</script>

<style scoped></style>
