<template>
  <v-dialog max-width="1000px" width="90%" persistent v-model="showDialog">
    <v-toolbar dark color="teal darken-2 white--text">
      <v-btn @click.native="closeDialog" flat :disabled="posting">
        <v-icon>close</v-icon>&nbsp;Close
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn dark flat @click="saveOrder" :disabled="posting">
          {{saveText}}
      </v-btn>
    </v-toolbar>
    <v-card flat class="pa-2">
      <v-form :lazy-validation="true" ref="new_order_form">
        <v-layout row wrap>
          <v-flex sm6 px-2>
            <v-select
              required
              v-model="order.store_id"
              :items="sortedstores"
              :loading="loading.store"
              item-text="name"
              item-value="id"
              @change="loadStoreProducts"
              :validate-on-blur="true"
              label="Stores"
              :rules="formRules.required"
              name="store_id"
            ></v-select>
          </v-flex>
          <v-flex sm6 px-2>
            <!--<v-select
              required
              v-model="order.partner_id"
              :items="sortedpartners"
              item-text="name"
              item-value="id"
              label="Customer Name"
              :rules="formRules.required"
              name="customer_name"
            ></v-select>-->
              
            <v-autocomplete :items="sortedpartners"
                            v-model="order.partner_id"
                            item-text="name"
                            :rules="formRules.required"
                            autocomplete
                            item-value="id"
                            label="Customer Name"
                            single-line></v-autocomplete>
          </v-flex>
        </v-layout>
        <br />
      </v-form>
      <v-form @submit.prevent="validateProduct" ref="add_product_form">
        <v-subheader>
          <h4>Add products</h4>
        </v-subheader>
        <!-- <br> -->
        <v-layout row wrap>
          <v-flex sm11 px-2>
            <v-layout row wrap>
              <v-flex sm5 px-2>
                <v-autocomplete
                  :loading="loading.products"
                  v-model="product.id"
                  :disabled="!order.store_id"
                  :items="sortedProducts"
                  item-text="name"
                  item-value="product_id"
                  :validate-on-blur="true"
                  placeholder="Select product"
                  @change="selectProductChange"
                ></v-autocomplete>
              </v-flex>
              <v-flex sm2 px-2>
                <v-text-field
                  required
                  v-model="product.quantity"
                  type="number"
                  :validate-on-blur="true"
                  label="Quantity"
                  :rules="formRules.amount"
                  name="quantity"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 px-2>
                <v-text-field
                  required
                  v-model="product.cost_price"
                  type="number"
                  disabled="true"
                  :validate-on-blur="true"
                  label="Cost price (per unit)"
                  name="cost_price"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 px-2>
                <v-text-field
                  required
                  v-model="product.selling_price"
                  type="number"
                  :validate-on-blur="true"
                  label="Selling price (per unit)"
                  :rules="formRules.amount"
                  name="selling_price"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm1 px-0>
            <div class="text-xs-center">
              <v-btn color="info" fab small type="submit">
                <v-icon dark>add</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-form>
      <v-dialog v-model="alertDialog" max-width="290">
        <v-card>
          <v-card-text>
            Please select at least one product.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn flat="flat" @click="alertDialog = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-subheader>
        Selected products
      </v-subheader>
      <v-divider></v-divider>
      <div class="selected-products">
        <v-data-table
          item-key="index"
          :items="selectedProducts"
          :rows-per-page-items="[10]"
          :headers="headers.selectedProducts"
        >
          <template slot="no-data">
            No products. Add a new product above.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.index }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.quantity }}</td>
            <!-- <td>{{ props.item.unit }}</td> -->
            <td>{{ props.item.cost_price }}</td>
            <td>{{ props.item.selling_price }}</td>
            <td>{{ props.item.total_cost }}</td>
            <td style="cursor: pointer;" @click="removeProduct(props.item.id)">
              <v-icon class="red--text">clear</v-icon>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import DocTypes from "@/data/documentTypes";
export default {
  computed: {
    saveText() {
      const texts = [
        {
          active: "Saving..",
          default: "Save Order"
        },
        {
          active: "Updating..",
          default: "Update"
        }
      ];

      const index = this.action == "update" ? 1 : 0;
      return this.posting ? texts[index].active : texts[index].default;
    },
    qtyUnits() {
      if (!this.product.id) return []; // Product object is empty

      const product = this.products.find(x => x.id == this.product.id);
      const units = [];
      units.push({
        id: 1,
        name: product.single_unit
      });

      if (product.compound_unit) {
        units.push({
          id: 2,
          name: product.compound_unit
        });
      }

      return units;
    },
    sortedProducts() {
        const products = this.productsList;
      //const products = this.products;
      if (products.length < 1) return [];

      return products
        .filter(x => {
          return !this.selectedProducts.find(y => y.id == x.product_id);
        })
        //.map(x => {
        //  x.name = this.getProductName_(x);
        //  return x;
        //})
          .map(x => {
              x.name = x.product.name; return x;
          })
        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());
        //.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());
    },
    sortedstores() {
      const stores = this.stores;
      if (stores.length < 1) return [];

      return stores.sort(
        (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
      );
    },
    sortedpartners() {
      const partners = this.partners;
      if (partners.length < 1) return [];

      return partners.sort(
        (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
      );
    }
  },
  created() {
  },
  data() {
    return {
      alertDialog: false,
      action: "save order",
      documentTypes: DocTypes,
      headers: {
        selectedProducts: [
          {
            text: "S/n",
            value: "index"
          },
          {
            text: "Product",
            value: "name"
          },
          {
            text: "Quantity",
            value: "quantity"
          },
          {
            text: "Cost price (per unit)",
            value: "cost_price"
          },
          {
            text: "Selling price (per unit)",
            value: "selling_price"
          },
          {
            text: "Total cost price",
            value: "total_cost"
          }
        ]
      },
      order: {},
      loading: false,
      posting: false,
      priceChangesData: [],
      product: {
        unit: 1,
      },
      suppliers: [],
      productOptions: [],
      productsList: [],
      selectedProducts: [],
      storeProducts: {}
    };
  },
  methods: {
    removeProduct(id) {
      const selectedProductIndex = this.selectedProducts
        .findIndex(product => product.id === id)

      if (selectedProductIndex > -1) {
        this.selectedProducts.splice(selectedProductIndex, 1);
      }
    },
    getSupplierById(id) {
      return this.suppliers.find(sup => sup.id ===  id);
    },
    addProduct(product) {
      const productDetails = this.productsList.find(x => x.product_id == product.id);
      const name = productDetails.product.name;
      const len = this.selectedProducts.length;
      const { cost_price, selling_price, quantity } = product;
      const total_cost = cost_price * quantity;

      const selectedProduct = {
        id: product.id,
        index: len + 1,
        name: name,
        cost_price,
        selling_price,
        quantity,
        total_cost
      };

      this.selectedProducts.push(selectedProduct);

      // Reset selected product
      this.product = {};
      this.$refs.add_product_form.reset();
    },
    closeDialog() {
      this.resetForm();
      this.$emit("closeDialog");
    },
    async loadStoreProducts() {
      this.selectedProducts = [];
      const storeId = this.order.store_id;
      if (this.storeProducts[storeId]) return;
      this.showGlobalLoader(true);

      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/storeproducts?storeId=${storeId}`
        );
        await this.handleResponse(res, data => {
          this.storeProducts[storeId] = data;
            this.productsList = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "There was an error performing this operation. Please try again later."
        );
      }
      this.showGlobalLoader(false);
    },
    findStoreProduct(id) {
      const activeStore = this.order.store_id;
      const storeProducts = this.storeProducts[activeStore];

      if (!storeProducts) return null;
      return storeProducts.find(x => x.product_id == id);
    },
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
    resetForm() {
      this.$refs.new_order_form.reset();
      this.selectedProducts = [];
      this.product = {};
    },
    async saveOrder() {
      if (!this.validateForm()) return;
      this.posting = true;
      const incomingOrder = Object.assign({}, this.order);
      incomingOrder.products = this.selectedProducts.map(x => {
        const product = Object.assign({}, x);
        product.product_id = product.id;
        return product;
      });

      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/customersorder/incoming-orders`,
            incomingOrder
        );
        this.handleResponse(res, data => {
          this.posting = false;
          this.$emit("orderAdded", data);
          this.closeDialog();
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error performing this operation. Please try again later."
        );
      }
    },
    selectProductChange() {
      // Check if this product already exists for this store
        const existingProduct = this.findStoreProduct(this.product.id);
      //console.log("exisiting_product", existingProduct);
      let costPrice = 0;
      let sellingPrice = 0;
        if (existingProduct) {
            costPrice = existingProduct.cost_price;
            sellingPrice = existingProduct.selling_price;
            this.product.cost_price = costPrice;
            this.product.selling_price = sellingPrice;
        }
      //else {
      //  // Find global product and get its default selling price
      //  const globalProduct = this.products.find(x => x.id == this.product.id);
      //  sellingPrice = globalProduct.price;
      //}

      //this.product.cost_price = costPrice;
      //this.product.selling_price = sellingPrice;
    },
    validateProduct() {
      if (!this.$refs.add_product_form.validate()) return;
      const product = this.product;
      //console.log(product);
      this.addProduct(product);
    },
    validateForm() {
      if (!this.$refs.new_order_form.validate()) return false;

      if (this.selectedProducts.length < 1) {
        this.alertDialog = true;
        return false;
      }
      return true;
    }
  },
  props: {
    showDialog: Boolean,
    stores: Array,
    partners:Array
  }
};
</script>

<style scoped>
</style>
