<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.$store.commit("burn");
  }
};
</script>
