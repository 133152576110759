<template>
    <layout v-if="Object.keys(receipt).length && Object.keys(customer).length">
        <template>
            <div id="printableArea">
                <div slot="pdf-content">
                    <v-layout row wrap style="margin-top: 20px;">
                        <v-flex xs24 sm12 px-2 text-xs-center style="margin-bottom: 30px;">
                            <h2>INVOICE
                                <span class="light">(#{{receipt.number}})</span>
                            </h2>
                            <h3 class="contact-info bold">
                                <span class="light">({{justDate(receipt.date_time)}})</span>
                            </h3>
                        </v-flex>

                        <v-flex xs12 sm6 px-2>
                            <h3 class="text-blue capitalize">
                                {{business.name}} 
                                ({{getStore(receipt.store_id).name}})
                            </h3>
                            <h5 class="contact-info">{{getStore(receipt.store_id).address}}</h5>
                            <h5 class="contact-info">{{business.phone_number}}</h5>
                            <h5 class="contact-info">{{business.email}}</h5>
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap style="margin-top: 20px;">
                        <v-flex xs24 sm12 px-2 text-xs-right>
                            <h3 class="text-blue">BILL TO</h3>
                            <h5 class="contact-info">
                                <b class="text-bold">Name: </b>
                                <span class="light">{{customer.name}}</span>
                            </h5>
                            <h5 class="contact-info">
                                <b class="text-bold">Address: </b>
                                <span class="light">{{customer.address}}</span>
                            </h5>
                            <h5 class="contact-info">
                                <b class="text-bold">Email: </b>
                                <span class="light">{{customer.email}}</span>
                            </h5>
                            <h5 class="contact-info">
                                <b class="text-bold">Phone Number: </b>
                                <span class="light">{{customer.phoneNumber}}</span>
                            </h5>
                        </v-flex>
                    </v-layout>

                    <div style="margin-top: 30px;">
                        <v-data-table
                            disable-initial-sort
                            hide-actions
                            :items="filterReceiptProducts"
                            :headers="headers"
                            :loading="loading"
                            class="bordered"
                        >
                            <template slot="no-data">
                            No data found.
                            </template>
                            <template slot="items" slot-scope="props">
                                <td>{{ props.item.name }}</td>
                                <td>
                                    {{ props.item.quantity }}
                                </td>
                                <td>
                                    {{ props.item.price }}
                                </td>
                                <!-- <td>
                                    {{moneyFormat(props.item.discount)}}
                                </td> -->
                                <td>
                                    {{ props.item.total }}
                                </td>
                            </template>
                        </v-data-table>
                    </div>
                    <div style="margin-top: 20px;">
                        <div class="calculation-wrapper">
                            <div>
                            </div>
                            <div text-xs-right>
                                <div class="data-wrapper padded-content">
                                    <b class="flex-child-element light">SUBTOTAL (NGN): </b>
                                    <span class="flex-child-element text-bold">
                                        {{moneyFormat(subTotal)}}
                                    </span>
                                </div>

                                <div class="data-wrapper padded-content">
                                    <b class="flex-child-element light">TENDERED (NGN): </b>
                                    <span class="flex-child-element text-bold">
                                        {{moneyFormat(totalTendered)}}
                                    </span>
                                </div>

                                <div class="data-wrapper padded-content" v-if="receipt.charged_wallet">
                                    <b class="flex-child-element light">WALLET (NGN): </b>
                                    <span class="flex-child-element text-bold">
                                        {{moneyFormat(wallet_deducted)}}
                                    </span>
                                </div>
                                <div class="data-wrapper padded-content">
                                    <b class="flex-child-element light">DISCOUNT (NGN): </b>
                                    <span class="flex-child-element text-bold">
                                        {{moneyFormat(transaction_discounts)}}
                                    </span>
                                </div>
                                <div class="data-wrapper padded-content">
                                    <h4 class="flex-child-element text-blue">BALANCE DUE (NGN): </h4>
                                    <span class="flex-child-element text-blue text-bold">
                                        {{moneyFormat(outstanding)}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="'invoice_by_' + business.name + '_to_' + customer.name"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                ref="html2Pdf"
                @hasDownloaded="processPDF"
            >
                <div slot="pdf-content" id="pdf-holder"> </div>
            </vue-html2pdf>
            <div style="margin-top: 30px;">
                <v-flex xs24 sm12 px-2 text-xs-center>
                    <v-btn large color="white" @click="$router.back()">Back</v-btn>
                    <v-btn large color="black" @click="printPDF" style="color: white;">PRINT</v-btn>
                    <v-btn large color="green" @click="sendEmail" style="color: white;">SEND AS EMAIL</v-btn>
                </v-flex>
            </div>
        </template>
  </layout>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import Layout from "@/components/Layout";
import Paid from "@/components/partner/Paid";
import Owing from "@/components/partner/Owing";
import LimitBalance from "@/components/partner/LimitBalance";
import Customer from "@/components/partner/Customer";

export default {
    components: {
        VueHtml2pdf,
        Layout,
        Owing,
        LimitBalance,
        Paid,
        Customer
    },
    data() {
        return {
            actionType: '',
            filter: {
                endDate: new Date().toISOString().substr(0, 10),
                startDate: new Date().toISOString().substr(0, 10)
            },
            headers: [
                { text: "Product", value: "name"},
                { text: "Quantity", value: "quantity" },
                { text: "Price", value: "price" },
                // { text: "Discount", value: "discount" },
                { text: "Total", value: "total" },
            ],
            loading: false,
            search: "",
            receipt: {},
            products: [],
            customer: {},
            business: {},
            outstanding: 0,
            wallet_deducted: 0,
            transaction_discounts: 0
        };
    },
    mounted() {
        this.business = JSON.parse(window.localStorage.ecr_business);
        //console.log('business', this.business);
        this.fetchReceiptHistory().then(_ => {
            this.calcReceipt();
        });
    },
    computed: {
        filterReceiptProducts() {   
            const totalDiscount = this.receipt.transaction_discounts.length
            ? this.calcTotalDiscount(this.receipt.transaction_discounts)
            : 0;
            return this.products.map((item) => {

                    const total = item.quantity * item.sale_price;
                    const discount = totalDiscount / this.products.length; // Distributing the total discount evenly
                

                return {
                    name: item.name,
                    quantity: this.numberFormat(item.quantity),
                    price: this.moneyFormat(item.sale_price),
                    discount,
                    total: this.moneyFormat(total),
                    subTotal: this.moneyFormat(total - discount)
                }; 
            });
        },  
        subTotal() {
            let subTotal = 0;
            this.filterReceiptProducts.forEach(receipt =>{
                
                subTotal += parseInt(receipt.subTotal.replaceAll(",", ""))
            });
            
            return Math.max(subTotal, 0);
            // return subTotal;
        },
        totalTendered() {
            return this.receipt.cash;
        },
        // totalDiscount() {
        //     let totalDiscount = 0;
        //     this.filterReceiptProducts.map(receipt => {
        //         totalDiscount += receipt.discount
        //     })
        //     return totalDiscount;
        // },

        total() {
            const total = this.subTotal - this.totalTendered;
            return this.moneyFormat(total > -1 ? total : 0);
        },
        walletAmount() {
            return this.moneyFormat(this.receipt.previous_wallet_balance);
        }
    },
    methods: {
        async getCustomerData(customerId) {
            this.loading = true;
            try {
                const { data } = await this.$http.get(`${this.apiBaseUrl}/partners/${customerId}`);
                this.customer = data.data;
            } catch (error) {
                this.handleError(error, "Error fetching cutomer details");
            }
            this.loading = false;
        },
        async fetchReceiptHistory() {
            const id = this.$route.params.id;
            this.loading = true;
            try {
                const { data } =
                await this.$http.get(`${this.apiBaseUrl}/receipts/${id}`);

                this.receipt = data.data;
                this.products = this.receipt.store_products;
                this.getCustomerData(this.receipt.partner_id);
            } catch (error) {
                this.handleError(error, "Error fetching receipt data");
            }
            this.loading = false;
        },
        calcTotalDiscount(transaction_discounts) {
            let total = 0;
            transaction_discounts.forEach(discount => total += discount.discount_value);
            return total;
        },
        getStore(id) {
            if (Object.keys(this.business).length > 0 && this.business.stores)
                return this.business.stores.find(x => x.id == id);
            return {}
        },
        calcReceipt() {
            let isComplete = this.receipt.is_complete;
            let isAmountTendered = this.receipt.cash > 0;
            let chargedWallet = this.receipt.charged_wallet;
            let wallet_deducted = 0;
            let transaction_discounts =0;
            let outstanding = 0;

            //Credit only Payment
            if (!isComplete && !isAmountTendered && !chargedWallet) {
                wallet_deducted = 0;
                outstanding = this.receipt.transaction_amount;
            }
            //Cash only payment
            else if (isAmountTendered && !chargedWallet && isComplete) {
                wallet_deducted = 0;
                outstanding = 0;
            }
            //Wallet Only payment
            else if (!isAmountTendered && chargedWallet && isComplete) {
                wallet_deducted = this.receipt.transaction_amount;
                outstanding = 0;
            }
            //Credit and cash payment
            else if (isAmountTendered && !chargedWallet && !isComplete) {
                wallet_deducted = 0;
                outstanding = this.receipt.transaction_amount - this.receipt.cash;
            }
            //Wallet and cash payment
            else if (isAmountTendered && chargedWallet && isComplete) {
                wallet_deducted = this.receipt.transaction_amount - this.receipt.cash;
                outstanding = 0;
            }
            //wallet and credit payment
            else if (!isAmountTendered && chargedWallet && !isComplete) {
                wallet_deducted = this.receipt.previous_wallet_balance;
                outstanding = this.receipt.transaction_amount - wallet_deducted;
            }
            //Credit, wallet and cash payment
            else if (isAmountTendered && chargedWallet && !isComplete) {
                wallet_deducted = this.receipt.previous_wallet_balance;
                outstanding = this.receipt.transaction_amount - (wallet_deducted + this.receipt.cash);
            }
            //discount
            if (this.receipt.transaction_discounts && this.receipt.transaction_discounts.length > 0) {
                transaction_discounts = this.calcTotalDiscount(this.receipt.transaction_discounts);
            }

            // Assign the total discount to the variable
            this.transaction_discounts = transaction_discounts;
            this.wallet_deducted = wallet_deducted < 0 ? 0 : wallet_deducted;
            this.outstanding = outstanding;
        },
        async processPDF (pdfBlob) {
            if (this.actionType === 'email') {
                this.$refs.html2Pdf.closePreview();
                var pdfFile = new File([pdfBlob],`invoice_by_${this.business.name}_to_${this.customer.name}`);
                this.loading = true;

                const formData = new FormData();
                formData.append("attachment", pdfFile)
                formData.append("recipient", this.customer.email)
                formData.append(
                    "subject",
                    `${this.business.name} (${this.getStore(this.receipt.store_id).name}) Sent an Invoice`
                )
                formData.append("body", "Find Invoice in attached files.")

                try {
                    await this.$http
                    .post(
                        `${this.apiBaseUrl}/notifications/sendmail_with_attachment`,
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        }
                    );
                    this.loading = false;

                    this.showSnackbar("Invoice has been successfully sent to the customer by email", {
                        type: "success"
                    });
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "Operation failed. Please try again later.");
                }
                console.log("pdfContent", pdfFile);
            }
        },
        printPDF() {
            this.actionType = 'print';
            this.generatePDF();
        },
        generatePDF() {
            document.getElementById('pdf-holder').innerHTML =
                document.getElementById('printableArea').innerHTML;
            this.$refs.html2Pdf.generatePdf();
        },
        sendEmail() {
            this.actionType = 'email';
            this.generatePDF();
        }
    },
    watch: {
        selectedStore: {
        handler() {
            this.fetchReceiptHistory();
        },
        deep: true
        }
    }
};
</script>
<style scoped>
.customer-name {
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
.bold {
    font-weight: 700;
    color: black;
}
.light {
    font-weight: 500;
    color: #111;
}
.text-blue {
    color: blue;
}
.capitalize {
    text-transform: capitalize;
}
.padded-content {
    padding: 5px;
}
.receipt-calc-info {
    flex-direction: column;
    align-items: stretch;
}
.data-wrapper {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-gap: 20px; 
    border: 1px solid #dde; 
    margin-top: 5px;
}
.calculation-wrapper {
    display: grid; 
    grid-template-columns: 1.1fr 0.9fr; 
    grid-gap: 20px;
}
.contact-info {
    padding: 2px;
    font-size: 15px;
}
.bordered {
    border: 1px solid #ddd; 
}
.contact-info b {
    padding-left: 20pc;
}
.flex-child-element {
    text-align: center;
}
#printableArea {
    padding: 20px;
}
</style>