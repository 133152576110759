<template>
    <layout @FilterData="FilterData">
        <v-layout row wrap>
            <v-flex xs6 sm3 px-2>
                <business-count :count="details.businessCount" :loading="loading.active_business" />
            </v-flex>
            <v-flex xs6 sm3 px-2>
                <!--<subscription-count :count="count" />-->
                <!-- <subscription-count :count="details.subscriptionCount" :loading="loading.subscription_count" /> -->
                <subscription-count :count="'XX'" :loading="loading.subscription_count" />
            </v-flex>
            <v-flex xs6 sm3 px-2>
                <total-amount-paid :amount="details.amountPaid" :loading="loading.amount_paid" />
            </v-flex>
            <v-flex xs6 sm3 px-2>
                <total-customer :numbers="details.totalCustomers" :loading="loading.customers" />
            </v-flex>
        </v-layout>
        <br />
        <v-layout row wrap>
            <v-flex xs6 sm6 px-2>
                <active-business :names="details.activeBusinessIn7" :loading="loading.active_business7" />
            </v-flex>
            <v-flex xs6 sm6 px-2>
                <subscription-used :names="details.mostUsedSubscription" :loading="loading.subscription_business" />
            </v-flex>
        </v-layout>
        <br />
        <v-layout row wrap>
            <v-flex xs6 sm6 px-2>
                <wallet-business :names="details.walletBuinsess" :loading="loading.wallet_businesses" />
            </v-flex>
            <v-flex xs6 sm6 px-2>
                <highest-business :names="details.highestBuinsess" :loading="loading.transacting_businesses" />
            </v-flex>
        </v-layout>
        <br />
        <v-layout row wrap>
            <v-flex sm6 px-2>
                <business-type :chart="chart" :loading="loading.business_type"/>
            </v-flex>

            <v-flex sm6 px-2>
                <payment-method-per-business :list="details.businessPaymentMethods" :loading="loading.payment_methods" />
            </v-flex>
        </v-layout>
        <br />
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import BusinessCount from "@/components/superadmin/dashboard/cards/BusinessCount";
    import TotalCustomer from "@/components/superadmin/dashboard/cards/TotalCustomer";
    import TotalAmountPaid from "@/components/superadmin/dashboard/cards/TotalAmountPaid";
    import SubscriptionCount from "@/components/superadmin/dashboard/cards/SubscriptionCount";
    import SubscriptionUsed from "@/components/superadmin/dashboard/cards/SubscriptionUsed";
    import HighestBusiness from "@/components/superadmin/dashboard/cards/HighestBusiness";
    import BusinessType from "@/components/superadmin/dashboard/BusinessType";
    import PaymentMethodPerBusiness from "@/components/superadmin/dashboard/PaymentMethodPerBusiness";
    import ActiveBusiness from "@/components/superadmin/dashboard/cards/ActiveBusiness";
    import WalletBusiness from "@/components/superadmin/dashboard/cards/WalletBusiness";

    export default {
        components: {
            Layout,
            BusinessCount,
            TotalCustomer,
            TotalAmountPaid,
            SubscriptionCount,
            SubscriptionUsed,
            HighestBusiness,
            BusinessType,
            PaymentMethodPerBusiness,
            ActiveBusiness,
            WalletBusiness
        },
        data() {
            return {
                loading: {
                    active_business: false,
                    subscription_count: false,
                    amount_paid: false,
                    customers: false,
                    transacting_businesses: false,
                    subscription_business: false,
                    business_type: false,
                    payment_methods: false,
                    business_type: false,
                    active_business7: false,
                    wallet_businesses: false
                },
                search: "",
                details: {},
                count: 0,
                temp: [],
                chart: {
                    data: {
                        labels: [
                        ],
                        datasets: [
                            {
                                label: "Types",
                                borderColor: '#ff9f40',
                                data: []
                            }
                        ]
                    },
                    options: {
                        height: 50,
                    }
                },
                attrs: {
                    class: 'mb-6',
                    boilerplate: true,
                    elevation: 2,
                },
            };
        },
        mounted() {
            this.getOverview();
        },
        watch: {
        },
        methods: {
            async getOverview(params) {
                this.getActiveBusiness(params);
                this.getSubscriptionCount(params);
                this.getTransactions(params);
                this.getCustomers(params);
                this.getHighestBusiness(params);
                this.GetSubscriptionCount(params);
                this.ActiveBusiness7Days(params);
                this.getWalletBusiness(params);
                //this.getTemp();
                this.GetPaymentMethodPerBusiness(params);
                this.GetBusinessType(params);
            },
            async getActiveBusiness(params = null) {
                try {
                    this.loading.active_business = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/active-business`, { params });
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/active-business`);
                    }

                    await this.handleResponse(res, data => {
                        this.details.businessCount = data;
                        this.loading.active_business = false;
                    });

                } catch (error) {
                    await this.handleError(error, "We couldn't fetch active business.");
                }
            },
            async getSubscriptionCount(params = null) {
                try {
                    this.loading.subscription_count = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/active-subscriptions`, {params});
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/active-subscriptions`);
                    }

                    await this.handleResponse(res, data => { 
                        this.details.subscriptionCount = data;
                        this.loading.subscription_count = false;
                    });

                } catch (error) {
                    await this.handleError(error, "We couldn't fetch subscription count.");
                }
            },
            async getTransactions(params = null) {
                try {
                    this.loading.amount_paid = true;
                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/transactions`, {params});
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/transactions`);
                    }

                    await this.handleResponse(res, data => {
                        this.details.amountPaid = data;
                        this.loading.amount_paid = false;
                    });

                } catch (error) {
                    await this.handleError(error, "We couldn't fetch total amount.");
                }
            },
            async getCustomers(params = null) {
                try {
                    this.loading.customers = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/customers`, {params});
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/customers`);
                    }

                    await this.handleResponse(res, data => {
                        this.details.totalCustomers = data;
                        this.loading.customers = false;
                    });
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch customers data.");
                }
            },
            async getHighestBusiness(params = null) {
                try {
                    this.loading.transacting_businesses = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/transacting-businesses`, {params});
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/transacting-businesses`);
                    }

                    await this.handleResponse(res, data => {
                        this.details.highestBuinsess = data;
                        this.loading.transacting_businesses = false;
                    });
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch transacting businesses.");
                }
            },
            async getWalletBusiness(params = null) {
                try {
                    this.loading.wallet_businesses = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/wallet-businesses`, {params});
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/wallet-businesses`);
                    }

                    await this.handleResponse(res, data => {
                        this.details.walletBuinsess = data;
                        this.loading.wallet_businesses = false;
                    });
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch transacting businesses.");
                }
            },
            async GetSubscriptionCount(params = null) {
                try {
                    this.loading.subscription_business = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/business-subscription-count`, {params});
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/business-subscription-count`);
                    }

                    await this.handleResponse(res, data => {
                        if (data[0] == null) this.details.mostUsedSubscription = null;
                        else
                            this.details.mostUsedSubscription = data;
                        this.loading.subscription_business = false;
                    });
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch business subscription.");
                }
            },
            async GetPaymentMethodPerBusiness(params = null) {
                try {
                    this.loading.payment_methods = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/business-payment-method`, { params });
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/business-payment-method`);
                    }

                    await this.handleResponse(res, data => {
                        this.details.businessPaymentMethods = data;
                        this.loading.payment_methods = false;
                    });
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch business payment method.");
                }
            },
            async GetBusinessType(params = null) {
                try {
                    this.loading.business_type = true;

                    let res = null;
                    var labels = [];
                    var datasets = [];

                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/business-type`, { params });
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/business-type`);
                    }

                    await this.handleResponse(res, data => {
                        for (const key in data) {
                            labels.push(key)
                            datasets.push(data[key]);
                        }
                        this.chart.data.labels = labels;
                        this.chart.data.datasets[0].data = datasets;
                        this.loading.business_type = false;
                    });
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch business type.");
                }
            },
            async ActiveBusiness7Days(params = null) {
                try {
                    this.loading.active_business7 = true;

                    let res = null;
                    if (params) {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/active-businessIn7`, { params });
                    } else {
                        res = await this.$http.get(`${this.apiBaseUrl}/superAdmin/dashboard/active-businessIn7`);
                    }

                    await this.handleResponse(res, data => {
                        this.details.activeBusinessIn7 = data;
                        //console.log(this.details.activeBusinessIn7);
                        this.loading.active_business7 = false;
                    });

                } catch (error) {
                    await this.handleError(error, "We couldn't fetch active business on last 7 days.");
                }

            },
            async FilterData(data) {
                //console.log("This is the Dashboard.vue", data);
                if (data.endDate == "" && data.startDate == "")
                    await this.handleError({}, "No specified dates.");
                else if (data.endDate != "" && data.startDate == "")
                    await this.handleError({}, "Start date is not specified");
                else if (data.startDate != "" && data.endDate == "")
                    await this.handleError({}, "End date is not specified");
                else {
                        const params = {
                            startDate: data.startDate,
                            endDate: data.endDate
                        };
                        this.getOverview(params);
                }
            },
            async getTemp() {
                try {
                    const { data } = await this.$http.get(`${this.apiBaseUrl}/menus/temp-subscrpt-data`);
                    var labels = [];
                    var datasets = [];
                    for (const key in data.item2) {
                        this.temp.push(key + " (" + data.item2[key] + ")");
                        this.count += data.item2[key];
                    }
                    for (const key in data.item1) {
                        labels.push(key)
                        datasets.push(data.item1[key]);
                    }
                    this.chart.data.labels = labels;
                    this.chart.data.datasets[0].data = datasets;
                } catch (error) {
                    await this.handleError(error, "We couldn't fetch business type data.");
                }
            }
           
        }
    };
</script>
<style scoped>

</style>