<template>
    <layout>

        <v-layout row wrap>
            <v-flex xs12 sm3>
                <v-menu ref="startDayMenu"
                        v-model="startDayMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="filter.startDate"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="filter.startDate"
                                      label="Start date"
                                      prepend-icon="event"
                                      hint="YYYY/MM/DD format"
                                      readonly
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.startDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="startDayMenu = false">Cancel</v-btn>
                        <v-btn flat
                               color="primary"
                               @click="$refs.startDayMenu.save(filter.startDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>

            <v-flex xs12 sm3>
                <v-menu ref="endDayMenu"
                        v-model="endDayMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="filter.endDate"
                        lazy
                        transition="scale-transition"
                        offset-y
                        full-width
                        min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field v-model="filter.endDate"
                                      label="End Date"
                                      prepend-icon="event"
                                      hint="YYYY/MM/DD format"
                                      readonly
                                      v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.endDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="endDayMenu = false">Cancel</v-btn>
                        <v-btn flat
                               color="primary"
                               @click="$refs.endDayMenu.save(filter.endDate)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex>

            <v-flex xs12 sm3>
                <v-btn color="info" @click="fetchWalletLog"> Filter </v-btn>
            </v-flex>

            <v-flex xs12 sm3>
                <v-layout row wrap>
                    <div class="text-xs-right">
                        <DownloadCSV class="v-btn theme--light success"
                                     :data="records"
                                     name="wallet_log_report.csv"
                                     v-if="records.length">
                            Export
                        </DownloadCSV>
                    </div>
                </v-layout>
            </v-flex>
        </v-layout>
        <template>
            <v-data-table :expand="true"
                          item-key="id"
                          disable-initial-sort
                          :items="histories"
                          :rows-per-page-items="[25]"
                          :headers="headers"
                          :loading="loading">
                <template slot="no-data">
                    No histories.
                </template>
                <template slot="items" slot-scope="props">
                    <tr>
                        <td>{{ props.item.transactionId || "No Transaction ID" }}</td>
                        <td>{{ props.item.partner.name }}</td>
                        <td>{{ moneyFormat(props.item.amount) }}</td>
                        <td>{{ moneyFormat(props.item.previousBalance) }}</td>
                        <td>
                            {{
                            props.item.isCredit
                            ? moneyFormat(props.item.previousBalance + (props.item.amount))
                            : moneyFormat((props.item.previousBalance) - (props.item.amount))
                            }}
                        </td>
                        <td>{{ props.item.isCredit ? "Credit" : "Debit" }}</td>
                        <td>{{ props.item.description }}</td>
                        <td>{{ identifyUser(props.item.initiatingUserId) }}</td>
                        <td>
                            {{
                             props.item.transactionDate
                                            ? new Date(props.item.transactionDate).toLocaleString()
                                            : "Not recorded"
                            }}
                        </td>
                        <td>{{ new Date(props.item.createdAt).toLocaleString() }}</td>
                    </tr>
                </template>
            </v-data-table>
        </template>
        <!-- <template v-else>
            No store selected
        </template> -->
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import JsonCSV from "vue-json-csv";

export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  mounted() {
    //this.fetchWalletLog();
    this.fetchUsers();
  },
  data() {
    return {
      customerName: "",    
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "Transaction ID", value: "transactionId" },
        { text: "Customer Name", value:"partner.name" },
        { text: "Amount (NGN)", value: "amount" },
        { text: "Opening Balance" },
        { text: "Closing Balance" },
        { text: "Transaction Type", value: "isCredit" },
        { text: "Description", value: "description" },
        { text: "Initiator", value: "initiatingUserId" },
        { text: "Transaction Date", value: "transactionDate" },
        { text: "Effective Date", value: "createdAt" },
      ],
      loading: false,
      histories: [],
      users: []
    };
  },
  computed: {
      records() {
      return this.histories.map((x) => {
        return {
          TransactionID: x.transactionId ? x.transactionId : "No TransactionID",
          CustomerName: x.partner.name,
          Amount: x.amount,
          Opening_Balance: x.previousBalance,
          Closing_Balance:  x.isCredit
                            ? this.moneyFormat(x.previousBalance + (x.amount))
                            : this.moneyFormat((x.previousBalance) - (x.amount)),
          Transaction_Type: x.isCredit ? "Credit" : "Debit",
          Description: x.description,
          Initiator: this.identifyUser(x.initiatingUserId),
          TransactionDate: x.transactionDate
                                            ? new Date(x.transactionDate).toLocaleString()
                                            : "Not recorded",
          EffectiveDate: new Date(x.createdAt).toLocaleString()
        };
      });
    }
  },
  methods: {
    async fetchWalletLog() {
      //let storeId = this.selectedStore
      //  ? this.selectedStore.id
      //  : this.isLoggedIn.store_id;
      //if (storeId === 0) {
      //  return;
      //}
      //const id = this.$route.params.id;
      //this.customerName = this.$route.params.customerName;
       this.loading = true;
       const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate
      };
      try {
          const { data } = await this.$http.get(`${this.apiBaseUrl}/partners/wallet_log_report`, {
              params
          });
        this.histories = data.data;
        // console.log('this.stores', this.stores);
      } catch (error) {
        // console.log(error);
        this.handleError(error, "Error fetching wallet log");
      }
      this.loading = false;
    },
    async fetchUsers() {
      this.loading = true;
      try {
        const { data } =
          await this.$http.get(`${this.apiBaseUrl}/users`);
        this.users = data.data;
      } catch (error) {
        //console.log(error);
        this.handleError(error, "Error fetching cashiers");
      }
      this.loading = false;
    },
    identifyUser(id) {
      const user = this.users.find(x => x.id == id)
      return user ? user.full_name : "User not found";
    }
  },
  watch: {
    //selectedStore: {
    //  handler() {
    //    this.fetchWalletLog();
    //    this.fetchUsers();
    //  },
    //  deep: true
    //}
  }
};
</script>
