<template>
  <v-card flat>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="newDiscountDialog = true">
        <v-icon>add_circle</v-icon>&nbsp; Add discount
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <br />
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex sm4>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search discounts"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <br />
      </v-flex>
      <v-flex xs12>
        <v-data-table
          :items="items"
          :rows-per-page-items="[20]"
          :search="search"
          :headers="headers"
        >
          <template slot="no-data"
            >No discounts. Add a new discount above.</template
          >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.amount || "" }}</td>
            <td>{{ getDiscountType(props.item.type) || "" }}</td>
            <td>{{ getBaseType(props.item.base) }}</td>
            <td>
              <v-icon small :color="props.item.active ? 'green' : 'red'">
                {{ props.item.active ? "check_circle" : "remove_circle" }}
              </v-icon>
            </td>
            <td>
              <v-icon small @click="showDiscountInfo(props.item)">info</v-icon
              >&nbsp;&nbsp;
              <!-- <v-icon small @click="showDeactivateDialog(props.item)">
                                {{ props.item.is_active ? 'remove_circle' : 'check_circle' }}
              </v-icon>-->
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog max-width="750px" persistent v-model="newDiscountDialog">
      <v-form ref="discount_form" @submit.prevent="postDiscount">
        <v-card>
          <v-card-title primary-title>
            <h3>Enter discount details</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-text-field
                  label="Discount name"
                  v-model="discount.name"
                  required
                  :rules="formRules.required"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 px-2>
                <v-select
                  :items="types"
                  label="Type"
                  v-model="discount.type"
                  item-text="name"
                  item-value="id"
                  required
                  :rules="formRules.required"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-text-field
                  label="Amount"
                  v-model="discount.amount"
                  type="number"
                  min="0"
                  required
                  :rules="formRules.amount"
                ></v-text-field>
              </v-flex>
              <v-flex sm6 px-2>
                <v-select
                  :items="discountBaseTypes"
                  label="Discount base"
                  v-model="discount.base"
                  item-text="text"
                  item-value="value"
                  required
                  :rules="formRules.required"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-flex sm6 px-2>
              <v-switch label="Is Active?" v-model="discount.active"></v-switch>
            </v-flex>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat :disabled="posting" @click.native="closeDialog"
              >Cancel</v-btn
            >
            <v-btn color="primary" :disabled="posting" flat type="submit">
              {{ actionText }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="activationDialog" max-width="400px" persistent>
      <v-card flat>
        <v-card-title primary-title>
          <h3>
            {{ this.discount.is_active ? "Deactivate" : "Activate" }} discount
          </h3>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            v-if="activationLoader"
            indeterminate
            color="secondary"
            height="2"
            value="15"
          ></v-progress-linear>
          <div v-if="!activationLoader">
            Do you want to
            {{ this.discount.is_active ? "deactivate" : "activate" }} this
            discount?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            :disabled="activationLoader"
            @click.native="closeActivationDialog"
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            :disabled="activationLoader"
            flat
            @click.native="updateActivation"
            >{{ this.discount.is_active ? "Deactivate" : "Activate" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  computed: {
    actionText() {
      const texts = [
        {
          active: "Saving",
          default: "Save"
        },
        {
          active: "Updating",
          default: "Update"
        }
      ];

      const index = !this.action ? 0 : 1;
      return this.posting ? texts[index].active : texts[index].default;
    }
  },
  data() {
    return {
      action: "",
      activationDialog: false,
      activationLoader: false,
      discount: {},
      headers: [
        {
          text: "Discount name",
          value: "name"
        },
        {
          text: "Amount",
          value: "amount"
        },
        {
          text: "Type",
          value: "type"
        },
        {
          text: "Discount base",
          value: "base"
        },
        {
          text: "Status",
          value: "active"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      newDiscountDialog: false,
      search: "",
      types: [
        {
          id: 0,
          name: "Flat"
        },
        {
          id: 1,
          name: "Percent (%)"
        }
      ],
      discountBaseTypes: [
        {
          value: 0,
          text: "Before tax"
        },
        {
          value: 1,
          text: "After tax"
        }
      ]
    };
  },
  methods: {
    closeActivationDialog() {
      this.action = "";
      this.activationDialog = false;
    },
    closeDialog() {
      this.action = "";
      this.newDiscountDialog = false;
      this.activationDialog = false;
      this.$refs.discount_form.reset();
      this.discount = {};
    },
    getDiscountType(index) {
      return this.types[index].name;
    },
    async postDiscount() {
      if (this.action !== "updateActivation") {
        if (!this.$refs.discount_form.validate()) return;
      }
      const discount = Object.assign({}, this.discount);
      const selectedStore = this.selectedStore;
      if (selectedStore) discount.store_id = selectedStore.id;
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/discounts`,
          discount
        );
        this.posting = false;
        const action = !this.action ? "added" : "updated";
        this.handleResponse(res, data => {
          this.action
            ? this.$emit("discountUpdated", discount)
            : this.$emit("discountAdded", data);
          this.closeDialog();
          this.showSnackbar(`Discount ${action} successfully.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error while performing this operation."
        );
      }
    },
    showDeactivateDialog(item) {
      this.action = "updateActivation";
      this.discount = Object.assign({}, item);
      this.activationDialog = true;
    },
    showDiscountInfo(item) {
      this.action = "update";
      this.discount = Object.assign({}, item);
      this.newDiscountDialog = true;
    },
    updateActivation() {
      const active = this.discount.is_active;
      this.discount.is_active = !active;
      this.postDiscount();
    }
  },
  props: {
    items: Array
  }
};
</script>
