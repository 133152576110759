<template>
  <layout requires-warehouse>
    <template v-if="!selectedWarehouse">
      No warehouse selected
    </template>
    <template v-else>
      <v-layout row wrap>
        <v-flex sm3 pr-3>
          <p class="mb-2">
            <strong>
              <small>
                Start date
              </small>
            </strong>
          </p>
          <v-menu
            ref="startDateMenu"
            :close-on-content-click="false"
            :v-model="pickers.start"
            :nudge-right="40"
            :return-value.sync="filter.startDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
            @blur="filter.startDate = formatDate(filter.startDate)"
          >
            <v-text-field
              slot="activator"
              v-model="filter.startDate"
              label="Start date"
              solo
              :hide-details="true"
              readonly
              prepend-inner-icon="event"
            ></v-text-field>
            <v-date-picker v-model="filter.startDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="pickers.start = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.startDateMenu.save(filter.startDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>
        <v-flex sm3 pr-3>
          <p class="mb-2">
            <strong>
              <small>
                End date
              </small>
            </strong>
          </p>
          <v-menu
            ref="endDatemenu"
            :close-on-content-click="false"
            :v-model="true"
            :nudge-right="40"
            :return-value.sync="filter.endDate"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
            @blur="filter.endDate = formatDate(filter.endDate)"
          >
            <v-text-field
              slot="activator"
              v-model="filter.endDate"
              label="End date"
              solo
              :hide-details="true"
              readonly
              prepend-inner-icon="event"
            ></v-text-field>
            <v-date-picker v-model="filter.endDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn
                flat
                color="primary"
                @click="$refs.endDatemenu.save(filter.endDate)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>
        <!-- <v-flex sm3>
          <p class="mb-2">
            <strong>
              <small>
                Warehouse type
              </small>
            </strong>
          </p>
          <v-select :items="warehouseTypes" solo label="Warehouse type" v-model="filter.warehouseType"></v-select>
        </v-flex> -->
        <v-flex sm2>
          <p class="mb-2">
            <strong>
              <small>
                &nbsp;
              </small>
            </strong>
          </p>
          <v-btn large color="primary" @click="setEntriesFilter">
            Search &nbsp;
            <v-icon>
              search
            </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
    </template>
    <entries :filters="entriesFilter" :warehouse="selectedWarehouse"></entries>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import Entries from "@/components/inventory/Entries";

export default {
  components: {
    Entries,
    Layout
    // PrintButton
  },
  created() {
    const today = this.$moment().format("YYYY-MM-DD");
    this.filter = {
      startDate: today,
      endDate: today,
      warehouseType: this.warehouseTypes[0]
    };

    this.setEntriesFilter();
  },
  data() {
    return {
      entriesFilter: {},
      filter: {},
      pickers: {
        start: false,
        end: false
      },
      posting: false,
      warehouseTypes: ["Climate-controlled", "Others", "Virtual"]
    };
  },
  methods: {
    setEntriesFilter() {
      this.entriesFilter = Object.assign({}, this.filter);
    },
    updateRowNum() {
      this.rowValue = [-1];
      const warehouse = this.products[5];
      warehouse.product.id = 5000;
      this.$set(this.products, 5, Object.assign({}, warehouse));
    }
  }
  // watch: {
  //   selectedWarehouse: {
  //     handler() {
  //       this.getInventory();
  //     },
  //     immediate: true
  //   }
  // }
};
</script>
