<template>
  <div>
    <top-header></top-header>
    <div id="registration">
      <div class="intro">
        <v-container>
          <v-layout row>
            <v-flex xs12 sm8 md8 xl6 offset-sm2 offset-md2 offset-xl3>
              <h1 class="text-xs-center">
                Reset your PIN
              </h1>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
      <v-container>
        <v-dialog max-width="500" v-model="dialog" persistent>
          <v-card>
            <v-card-text>
              <v-progress-linear :indeterminate="true"></v-progress-linear>
              Please wait while we setup your account.
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-layout row wrap>
          <v-flex lg8 offset-lg2>
            <v-card>
              <v-card-title primary-title>
                <v-layout>
                  <v-flex xs12>
                    <h2 class="text-xs-center">
                      Enter your phone number
                    </h2>
                  </v-flex>
                </v-layout>
              </v-card-title>
              <v-layout row wrap>
                <v-flex xs12 sm6 offset-sm3 px-3>
                  <v-alert
                    v-model="alert.all.show"
                    :type="alert.all.type"
                    transition="fade-transition"
                    dismissible
                  >
                    {{ alert.all.msg }}
                  </v-alert>

                  <v-form
                    ref="password_reset_form"
                    lazy-validation
                    @submit.prevent="requestPasswordReset"
                    class="register-form"
                  >
                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-text-field
                          v-model="phone_number"
                          :rules="formRules.required"
                          label="Phone number"
                          required
                          prepend-icon="phone"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-layout row>
                      <v-flex xs12 class="text-xs-right">
                        <v-btn color="warning" flat to="/">
                          Cancel
                        </v-btn>

                        <v-btn type="submit" color="info" :disabled="posting">
                          Reset PIN
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/shared/TopHeader";

export default {
  components: {
    TopHeader
  },
  data() {
    return {
      dialog: false,
      posting: false,
      phone: ""
    };
  },
  methods: {
    clearValidation() {
      this.$refs.password_reset_form.reset();
    },
    requestPasswordReset() {
      if (!this.$refs.password_reset_form.validate()) return;
      this.posting = true;
      let payload = {};
      payload.phone_number = this.phone_number;

      this.$http
        .post(`${this.baseUrl}/api/account/forgot-pin`, payload)
        .then(res => {
          this.posting = false;
          const data = res.data;
          if (!data.status) return this.showAlert(data.message, "error");

          this.showAlert(`${data.message}. Check your email to continue`);
          window.location = "/";
        })
        .catch(error => {
          this.posting = false;
          const data = error.response.data;
          const msg = data.message;
          return this.showAlert(msg, "error");
        });
    }
  }
};
</script>

<style scoped>
#registration {
  margin-top: 60px;
}

.intro {
  background-color: #066ec1;
  color: #fff;
  padding: 90px 0;
}

.sub {
  max-width: 450px;
  margin: 0 auto;
}

@media screen and (max-width: 450px) {
  .intro {
    padding: 40px 0;
  }
}
</style>
