import Logout from "@/views/Logout.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";

export default [
  {
    path: "/logout",
    name: "Log out",
    component: Logout,
    meta:["admin","administrator"]
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: ForgotPassword,
    meta:["admin","administrator"]
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: ResetPassword,
    meta:["admin","administrator"]
  }
];
