<template>
  <div class="home">
    <div v-if="!isLoggedIn">
      <frontpage></frontpage>
    </div>
      <div v-else-if="isLoggedIn.role.toLowerCase() == 'administrator' ">
          <SDashboard></SDashboard>
      </div>
    <div v-else>
      <dashboard></dashboard>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Frontpage from "@/views/Frontpage.vue";
import Dashboard from "@/views/Dashboard.vue";
import SDashboard from "@/views/superAdmin/Dashboard.vue";

export default {
  name: "home",
  components: {
    Frontpage,
    Dashboard,
    SDashboard
  }
};
</script>
