<template>
  <router-view></router-view>
</template>

<script>
export default {
  // beforeRouteUpdate(to, from, next){
  //       console.log('updated.');
  //       next()
  //   },
};
</script>

<style></style>
