<template>
    <layout v-if="Object.keys(order).length && Object.keys(customer).length">
        <template>
            <div id="printableArea">
                <div slot="pdf-content">
                    <v-layout row wrap style="margin-top: 20px;">
                        <v-flex xs24 sm12 px-2 text-xs-center style="margin-bottom: 30px;color:black">
                            <h2>Invoice Number: &nbsp;
                                <span>{{order.order_number}}</span>
                            </h2>
                            <h3 class="contact-info">
                                Date of Issue: &nbsp;
                                <span>{{dateFormat(order.created_at)}}</span>
                            </h3>
                        </v-flex>

                        <v-flex xs12 sm6 px-2>
                            <h3 class="text-blue capitalize">
                                {{business.name}}
                                ({{getStore(order.storeId).name}})
                            </h3>
                            <h5 class="contact-info">{{getStore(order.storeId).address}}</h5>
                            <h5 class="contact-info">{{business.phone_number}}</h5>
                            <h5 class="contact-info">{{business.email}}</h5>
                        </v-flex>
                    </v-layout>

                    <v-layout row wrap style="margin-top: 20px;">
                        <v-flex xs24 sm12 px-2 text-xs-right>
                            <h3 class="text-blue">BILL TO</h3>
                            <h5 class="contact-info">
                                <b class="text-bold">Name: </b>
                                <span class="light">{{customer.name}}</span>
                            </h5>
                            <h5 class="contact-info">
                                <b class="text-bold">Address: </b>
                                <span class="light">{{customer.address}}</span>
                            </h5>
                            <h5 class="contact-info">
                                <b class="text-bold">Email: </b>
                                <span class="light">{{customer.email}}</span>
                            </h5>
                            <h5 class="contact-info">
                                <b class="text-bold">Phone Number: </b>
                                <span class="light">{{customer.phoneNumber}}</span>
                            </h5>
                        </v-flex>
                    </v-layout>

                    <div style="margin-top: 30px;">
                        <v-data-table
                            disable-initial-sort
                            hide-actions
                            :items="filterOrderProducts"
                            :headers="headers"
                            :loading="loading"
                            class="bordered"
                        >
                            <template slot="no-data">
                            No data found.
                            </template>
                            <template slot="items" slot-scope="props">
                                <td>{{ props.item.name }}</td>
                                <td>
                                    {{ props.item.quantity }}
                                </td>
                                <td>
                                    {{ props.item.price }}
                                </td>
                                <td>
                                    {{ props.item.total }}
                                </td>
                            </template>
                        </v-data-table>
                    </div>
                    <div style="margin-top: 20px;">
                        <div class="calculation-wrapper">
                            <div>
                            </div>
                            <div text-xs-right>
                                <div class="data-wrapper padded-content">
                                    <b class="flex-child-element light">TOTAL (NGN): </b>
                                    <span class="flex-child-element text-bold">
                                        {{moneyFormat(subTotal)}}
                                    </span>
                                </div>
                                <br />
                                <h3 style="text-align:center;font-size:14px">PAYMENT STATUS: &nbsp; &nbsp; <span :style="{color: colors[order.status],fontSize: '20px'}">{{flags[order.status]}}</span></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="true"
                :paginate-elements-by-height="1400"
                :filename="'invoice_by_' + business.name + '_to_' + customer.name"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="portrait"
                pdf-content-width="800px"
                ref="html2Pdf"
                @hasDownloaded="processPDF"
            >
                <div slot="pdf-content" id="pdf-holder"> </div>
            </vue-html2pdf>
            <div style="margin-top: 30px;">
                <v-flex xs24 sm12 px-2 text-xs-center>
                    <v-btn large color="white" @click="$router.back()">Back</v-btn>
                    <v-btn large color="black" @click="printPDF" style="color: white;">PRINT</v-btn>
                    <v-btn large color="green" @click="sendEmail" style="color: white;">SEND AS EMAIL</v-btn>
                </v-flex>
            </div>
        </template>
  </layout>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import Layout from "@/components/Layout";

export default {
    components: {
        VueHtml2pdf,
        Layout,
    },
    data() {
        return {
            actionType: '',
            flags: ["PENDING", "CANCELLED", "COMPLETED"],
            colors: ["orange", "red", "green"],
            headers: [
                { text: "Product", value: "name"},
                { text: "Quantity", value: "quantity" },
                { text: "Price", value: "price" },
                { text: "Total", value: "total" },
            ],
            loading: false,
            search: "",
            order: {},
            products: [],
            customer: {},
            business: {},
        };
    },
    mounted() {
        this.business = JSON.parse(window.localStorage.ecr_business);
        //console.log('business', this.business);
        this.fetchOrderHistory();
    },
    computed: {
        filterOrderProducts() {
            return this.products.map((item) => {
                return {
                    name: item.product,
                    quantity: this.numberFormat(item.quantity),
                    price: this.moneyFormat(item.sellingPrice),
                    total: this.moneyFormat((item.quantity * item.sellingPrice))
                }
            });
        },
        subTotal() {
            let subTotal = 0;
            this.filterOrderProducts.forEach(order =>
                subTotal += parseInt(order.total.replaceAll(",", ""))
            );
            return subTotal;
        },
    },
    methods: {
        async getCustomerData(customerId) {
            this.loading = true;
            try {
                const { data } = await this.$http.get(`${this.apiBaseUrl}/partners/${customerId}`);
                this.customer = data.data;
            } catch (error) {
                this.handleError(error, "Error fetching cutomer details");
            }
            this.loading = false;
        },
        async fetchOrderHistory() {
            const orderId = this.$route.params.order_number;
            this.loading = true;
            try {
                const { data } =
                    await this.$http.get(`${this.apiBaseUrl}/customersorder/customer-order?orderId=${orderId}&businessId=${this.business.id}`);

                this.order = data.data;
                this.products = this.order.products;
                //console.log(this.products);
                this.getCustomerData(this.order.customer_id);
            } catch (error) {
                this.handleError(error, "Error fetching receipt data");
            }
            this.loading = false;
        },
        getStore(id) {
            if (Object.keys(this.business).length > 0 && this.business.stores)
                return this.business.stores.find(x => x.id == id);
            return {}
        },
        async processPDF (pdfBlob) {
            if (this.actionType === 'email') {
                this.$refs.html2Pdf.closePreview();
                var pdfFile = new File([pdfBlob],`invoice_by_${this.business.name}_to_${this.customer.name}`);
                this.loading = true;

                const formData = new FormData();
                formData.append("attachment", pdfFile)
                formData.append("recipient", this.customer.email)
                formData.append(
                    "subject",
                    `${this.business.name} (${this.getStore(this.order.storeId).name}) Sent an Invoice`
                )
                formData.append("body", "Find Invoice in attached files.")

                try {
                    await this.$http
                    .post(
                        `${this.apiBaseUrl}/notifications/sendmail_with_attachment`,
                        formData,
                        {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        }
                    );
                    this.loading = false;

                    this.showSnackbar("Invoice has been successfully sent to the customer by email", {
                        type: "success"
                    });
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "Operation failed. Please try again later.");
                }
                //console.log("pdfContent", pdfFile);
            }
        },
        printPDF() {
            this.actionType = 'print';
            this.generatePDF();
        },
        generatePDF() {
            document.getElementById('pdf-holder').innerHTML =
                document.getElementById('printableArea').innerHTML;
            this.$refs.html2Pdf.generatePdf();
        },
        sendEmail() {
            this.actionType = 'email';
            this.generatePDF();
        }
    },
    watch: {
        selectedStore: {
        handler() {
            this.fetchOrderHistory();
        },
        deep: true
        }
    }
};
</script>
<style scoped>
.customer-name {
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
.bold {
    font-weight: 700;
    color: black;
}
.light {
    font-weight: 500;
    color: #111;
}
.text-blue {
    color: blue;
}
.capitalize {
    text-transform: capitalize;
}
.padded-content {
    padding: 5px;
}
.receipt-calc-info {
    flex-direction: column;
    align-items: stretch;
}
.data-wrapper {
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-gap: 20px; 
    border: 1px solid #dde; 
    margin-top: 5px;
}
.calculation-wrapper {
    display: grid; 
    grid-template-columns: 1.1fr 0.9fr; 
    grid-gap: 20px;
}
.contact-info {
    padding: 2px;
    font-size: 15px;
}
.bordered {
    border: 1px solid #ddd; 
}
.contact-info b {
    padding-left: 20pc;
}
.flex-child-element {
    text-align: center;
}
#printableArea {
    padding: 20px;
}
</style>