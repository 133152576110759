var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"base-layout"}},[_c('vue-headful',{attrs:{"title":((this.$store.state.appName) + " - " + (this.$router.currentRoute.name))}}),_c('v-navigation-drawer',{attrs:{"persistent":"","fixed":"","app":"","clipped":true,"value":_vm.drawer}},[_c('drawer-menus')],1),_c('main-header'),_c('v-content',[_c('div',{staticClass:"page-content"},[_c('v-card',[_c('v-card-title',{class:_vm.colorplate,staticStyle:{"display":"flex","justify-content":"space-between"},attrs:{"primary-title":""}},[(_vm.isLoggedIn.role.toLowerCase() == 'business admin')?_c('h3',[_vm._v(_vm._s(_vm.$router.currentRoute.name || ""))]):(_vm.isLoggedIn.role.toLowerCase() == 'administrator' 
                  && _vm.$router.currentRoute.name != 'Dashboard')?_c('h3',[_vm._v(_vm._s(_vm.$router.currentRoute.name || ""))]):_vm._e(),(this.isLoggedIn.role.toLowerCase() == 'administrator' && _vm.$router.currentRoute.name == 'Dashboard')?_c('span',{staticClass:"text--white mr-1"},[_vm._v(_vm._s(_vm.$router.currentRoute.name || ""))]):_vm._e(),(this.isLoggedIn.role.toLowerCase() == 'administrator' && _vm.$router.currentRoute.name == 'Dashboard')?_c('div',{staticClass:"dashboard",staticStyle:{"display":"flex","justify-content":"end"}},[_c('v-menu',{ref:"startDateMenu",staticStyle:{"margin-right":"4px"},attrs:{"close-on-content-click":false,"v-model":_vm.startDateMenu,"nudge-right":40,"return-value":_vm.filter.startDate,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "startDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "startDate", $event)},"blur":function($event){_vm.filter.startDate = _vm.$moment(_vm.filter.startdate).format(
                  'YYYY-MM-DD'
              )}}},[_c('v-text-field',{attrs:{"slot":"activator","label":"Start date","solo":"","hide-details":true,"readonly":"","prepend-inner-icon":"event"},slot:"activator",model:{value:(_vm.filter.startDate),callback:function ($$v) {_vm.$set(_vm.filter, "startDate", $$v)},expression:"filter.startDate"}}),_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filter.startDate),callback:function ($$v) {_vm.$set(_vm.filter, "startDate", $$v)},expression:"filter.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","color":"error"},on:{"click":function($event){_vm.startDateMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"flat":"","color":"green"},on:{"click":function($event){return _vm.$refs.startDateMenu.save(_vm.filter.startDate)}}},[_vm._v("OK")])],1)],1),_c('v-menu',{ref:"endDateMenu",staticStyle:{"margin-right":"4px"},attrs:{"close-on-content-click":false,"v-model":_vm.endDateMenu,"nudge-right":40,"return-value":_vm.filter.endDate,"lazy":"","transition":"scale-transition","offset-y":"","full-width":"","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.filter, "endDate", $event)},"update:return-value":function($event){return _vm.$set(_vm.filter, "endDate", $event)},"blur":function($event){_vm.filter.endDate = _vm.$moment(_vm.filter.endDate).format(
                  'YYYY-MM-DD'
              )}}},[_c('v-text-field',{attrs:{"slot":"activator","label":"End date","solo":"","hide-details":true,"readonly":"","prepend-inner-icon":"event"},slot:"activator",model:{value:(_vm.filter.endDate),callback:function ($$v) {_vm.$set(_vm.filter, "endDate", $$v)},expression:"filter.endDate"}}),_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filter.endDate),callback:function ($$v) {_vm.$set(_vm.filter, "endDate", $$v)},expression:"filter.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"flat":"","color":"error"},on:{"click":function($event){_vm.endDateMenu = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"flat":"","color":"green"},on:{"click":function($event){return _vm.$refs.endDateMenu.save(_vm.filter.endDate)}}},[_vm._v("OK")])],1)],1),_c('v-btn',{staticClass:"black--text",staticStyle:{"margin":"0","padding":"0 6px"},attrs:{"outlined":"","color":"white"},on:{"click":_vm.Filter}},[_vm._v("\n                  Filter  \n                  "),_c('v-icon',{attrs:{"dark":""}},[_vm._v("\n                      filter_list\n                  ")])],1)],1):_vm._e()]),(
            _vm.requiresStore &&
              this.isLoggedIn.role.toLowerCase() == 'business admin'
          )?_c('store-picker'):_vm._e(),(
            _vm.requiresBusiness &&
              this.isLoggedIn.role.toLowerCase() == 'administrator'
          )?_c('business-picker'):_vm._e(),(_vm.requiresWarehouse)?_c('warehouse-picker',{on:{"warehouseSelected":_vm.warehouseSelected}}):_vm._e(),_c('v-divider'),_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":"","pa-2":"","mb-3":""}},[_c('transition',{attrs:{"name":"fade"}},[(_vm.alert.all.show)?_c('v-layout',[_c('v-flex',{attrs:{"xs12":""}},[_c('v-alert',{attrs:{"type":_vm.alert.all.type,"transition":"fade-transition","dismissible":""},model:{value:(_vm.alert.all.show),callback:function ($$v) {_vm.$set(_vm.alert.all, "show", $$v)},expression:"alert.all.show"}},[_vm._v(_vm._s(_vm.alert.all.msg))]),_c('br')],1)],1):_vm._e()],1),_vm._t("default")],2)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }