<template>
    <layout>
        <v-card outlined>
            <v-card-title class="grey darken-2 white--text">Profile Update:- {{full_name}}</v-card-title>
            <v-layout row wrap>
                <v-flex xs12 class="pa-3">
                    <v-form ref="update_profile" lazy-validation>
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="userDetails.first_name"
                                              label="First name"
                                              :rules="formRules.required"
                                              name="first_name"></v-text-field>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="userDetails.last_name"
                                              validate-on-blur
                                              label="Last name"
                                              :rules="formRules.required"
                                              name="last_name"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="userDetails.email"
                                              type="email"
                                              validate-on-blur
                                              label="Email address"
                                              :rules="formRules.required"
                                              name="email"></v-text-field>
                            </v-flex>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="userDetails.phone_number"
                                              label="Phone number"
                                              :rules="formRules.required"
                                              name="phone_number"></v-text-field>
                            </v-flex>
                        </v-layout>

                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field v-model="userDetails.contact_address"
                                              type="address"
                                              validate-on-blur
                                              label="Contact Address"
                                              name="contact_address"></v-text-field>
                            </v-flex>
                        </v-layout>

                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="!ready" @click="updateDetails">
                                {{
              saveText
                                }}
                            </v-btn>
                        </v-layout>
                    </v-form>
                </v-flex>
            </v-layout>
        </v-card>
        <v-divider style="margin:20px"></v-divider>
        <PasswordChange></PasswordChange>
        <v-divider style="margin:20px"></v-divider>
        <EmailSettings :value="isBusinessAdmin(authUser)"></EmailSettings>
        <!--<EmailSettings></EmailSettings>-->
        <v-divider style="margin:20px"></v-divider>
        <DisplayBalance></DisplayBalance>
    </layout>
</template>

<script>
import axios from "axios";
import Layout from "@/components/Layout";
import PasswordChange from "@/views/profile/PasswordChange";
import EmailSettings from "@/views/profile/EmailSettings";
import DisplayBalance from "@/views/profile/DisplayBalance";

export default {
  components: {
    Layout,
    PasswordChange,
    EmailSettings,
    DisplayBalance
  },
  data() {
    return {
      userDetails: {},
      saveText: "Update details",
      ready: false,
    };
  },
  mounted() {
    this.getUser();
  },
  computed:{
      full_name() {
          return this.userDetails.last_name + " " + this.userDetails.first_name;
      }
  },
  methods: {
    async updateDetails() {
      this.ready = false;
      let data = Object.assign({}, this.userDetails);
      let url = `${this.apiBaseUrl}/account/update-profile`;

      try {
        const res = await this.$http.put(url, data);
        this.handleResponse(res, data => {
          let newDetails = data;
          data.role = this.userDetails.role;
          this.$store.commit("updateUserData", newDetails);
          this.showSnackbar("User details updated successfully");
        });
      } catch (error) {
        this.handleError(error, "Unable to update user data");
      }

      this.ready = true;
    },
    getUser() {
      let url = `${this.apiBaseUrl}/me`;
      axios
        .get(url)
        .then(response => {
            this.userDetails = response.data.data;
          this.ready = true;
        })
        .catch(() => {
          return this.showSnackbar("Unable to get user data", {
            type: "error"
          });
        });
    },
  }
};
</script>
