<template>
    <v-card v-if="!loading">
        <v-card-title class="green darken-2 white--text">
            Total Amount Of Transactions
        </v-card-title>
        <v-card-text class="text-sm-right p-8">
            <h2>{{moneyFormat(amount)}}</h2>
        </v-card-text>
    </v-card>
    <skeleton-loader :color="'green darken-2 white--text'" :title="'Total Amount Of Transactions'" :height="'30px'" :count="1" v-else></skeleton-loader>
</template>

<script>
export default {
  name: "TotalAmountPaid",
  data() {
    return {
      amount: "-"
    };
  },
  props: ['amount', 'loading'],
  mounted() {
    // this.getData();
  },
  methods: {
  }
};
</script>

<style scoped></style>
