<template>
  <v-card class="dash-card">
    <v-card-title class="pink darken-2 white--text">
      Available Stores
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="stores"
      class="elevation-1"
      :rows-per-page-items="[5]"
    >
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name }}</td>
        <td class="text-sm-right">
          {{ props.item.cash_registers ? props.item.cash_registers.filter(x => x.active).length : 0 }}
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
      stores: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "# of devices", value: "cash_registers.length", align: "right" }
      ]
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let url = `${this.apiBaseUrl}/dashboard/available-stores`;

      try {
        const res = await this.$http.get(url);
        this.stores = res.data.data;
        this.loaded = true;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped></style>
