<template>
    <layout>
        <template>
            <div>
                <br />
            </div>
        </template>
        </layout>
    </template>

<script>
    import Layout from "@/components/Layout";
    export default {
        components: {
            Layout
        },
        computed: {
            saveText() {
                const texts = [
                    {
                        active: "Saving",
                        default: "Save"
                    },
                    {
                        active: "Updating",
                        default: "Update"
                    }
                ];

                const index = this.postAction !== "update" ? 0 : 1;
                return this.posting ? texts[index].active : texts[index].default;
            }
        },
        created() {
            if (this.selectedStore || this.isLoggedIn.store_id) this.getDevices();
            if (this.isLoggedIn.store_id) this.getStores();
        },
        data() {
            return {
                device: {},
                subscription: {},
                devices: [],
                devices_: [],
                deviceDialog: false,
                subscriptionDialog: false,
                headers: [
                    {
                        text: "Name",
                        value: "name"
                    },
                    {
                        text: "Amount",
                        value: "amount"

                    },
                    {
                        text: "Descriptions",
                        value: "pending_transact"

                    },
                    {
                        text: "Active",
                        value: "active"
                    },
                    {
                        text: "Actions",
                        align: "left",
                        value: ""
                    }
                ],
                loading: false,
                postAction: "",
                stores: this.$store.getters.stores,
                search: ""
            };
        },
        methods: {
            manageSubscription(subscription) {
                this.selectedSubscription = subscription;
                this.subscriptionDialog = true;
            },
            closeSubscriptionDialog() {
                this.postAction = "";
                this.subscriptionDialog = false;
                this.device = {};
                this.$refs.device_form.reset();
            },
            async getDevices() {
                let url = `${this.apiBaseUrl}/cashregisters`;
                if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
                this.loading = true;
                try {
                    const res = await this.$http.get(url);
                    this.loading = false;
                    this.handleResponse(res, data => (this.devices = data));
                } catch (error) {
                    this.loading = false;
                    this.handleError(error, "We couldn't load your devices");
                }
            },
            getStore(id) {
                return this.stores.find(x => x.id == id);
            },
            async getStores() {
                try {
                    const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
                    this.handleResponse(res, data => {
                        this.stores = data;
                    });
                } catch (error) {
                    this.handleError(
                        error,
                        "We couldn't fetch your stores. Please try again."
                    );
                }
            },
            async post() {
                if (!this.$refs.device_form.validate()) return;
                const device = Object.assign({}, this.device);
                this.posting = true;
                try {
                    let url = `${this.apiBaseUrl}/cashregisters`;
                    const res = await this.$http.post(url, device);
                    this.posting = false;
                    this.handleResponse(res, data => {
                        let action = "added";
                        if (this.postAction === "update") {
                            action = "updated";
                            this.updateDevice(device);
                        } else {
                            this.devices.push(data);
                        }
                        this.closeDeviceDialog();
                        this.showSnackbar(`Device ${action} successfully.`);
                    });
                } catch (error) {
                    this.posting = false;
                    this.handleError(error, "An error occurred. Please try again.");
                }
                //
            },
            showDeviceDialog() {
                this.deviceDialog = true;
            },
            showDeviceInfo(device) {
                this.postAction = "update";
                this.device = Object.assign({}, device);
                this.deviceDialog = true;
            },
            updateDevice(device) {
                const index = this.devices.findIndex(x => x.id == device.id);
                this.$set(this.devices, index, device);
            }
        },
        watch: {
            selectedStore: {
                handler() {
                    this.getDevices();
                },
                deep: true
            }
        }
    };
</script>

<style scoped>
    .filtersss {
        background-color: #5a5a5a29;
        padding: 10px;
        border-radius: 4px;
    }
</style>
