import Stores from "@/views/stores/Stores";
import StoreProducts from "@/views/stores/Products";
import CashRegisters from "@/views/stores/CashRegisters";

export default [
  {
    path: "/stores",
    name: "Stores",
    component: Stores,
    icon: "store",
    access: "stores",
    showInNav: true,
    meta:["admin"]
  },
  {
    path: "/store-products",
    name: "Store Products",
    component: StoreProducts,
    icon: "shopping_cart",
    access: "store_products",
    showInNav: true,
    meta:["admin"]
  },
  {
    path: "/cash-registers",
    name: "Cash Registers",
    component: CashRegisters,
    icon: "devices",
    access: "cash_registers",
    showInNav: true,
    meta:["admin"]
  }
];
