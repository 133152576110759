<template>
  <layout>
    <v-layout row wrap>
      <v-flex xl10>
        <v-form ref="transfer_form">
          <v-layout row wrap>
            <v-flex md6 px-2>
              <v-select
                label="From"
                :loading="loading.warehouses"
                item-value="id"
                item-text="name"
                v-model="stockData.from_id"
                :items="warehouses"
                @change="selectFromWarehouse"
                :rules="formRules.required"
              ></v-select>
            </v-flex>
            <v-flex md6 px-2>
              <v-select
                label="To"
                :loading="loading.warehouses"
                item-value="id"
                item-text="name"
                v-model="stockData.to_id"
                :items="toWarehouses"
                :rules="formRules.required"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
        <v-form @submit.prevent="validateProduct" ref="add_product_form">
          <v-subheader>
            <h3>Add product</h3>
          </v-subheader>
          <!-- <br> -->
          <v-layout row wrap>
            <v-flex md6 px-2>
              <v-autocomplete
                :loading="loading.products"
                v-model="product.product_id"
                :items="products"
                item-text="product.name"
                item-value="product.id"
                label="Select product"
              ></v-autocomplete>
            </v-flex>
            <v-flex sm2 px-2>
              <v-text-field
                readonly
                :value="remainder"
                label="In stock"
                :rules="[v => v >= 0 || 'negative stock is not allowed.']"
              ></v-text-field>
            </v-flex>
            <v-flex sm2 px-2>
              <v-text-field
                required
                v-model="product.quantity"
                type="number"
                min="0"
                :validate-on-blur="true"
                label="Quantity"
                :rules="formRules.amount"
              ></v-text-field>
            </v-flex>
            <v-flex sm2 px-2>
              <v-btn
                color="info"
                type="submit"
                :disabled="product.quantity < 1"
              >
                <v-icon dark>add</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-form>
        <v-layout row wrap>
          <v-spacer></v-spacer>
          <v-flex sm3>
            <br />
            <v-btn @click="moveStock" color="primary" block :disabled="posting">
              Move stock
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <br />
        <v-subheader>
          Selected products
        </v-subheader>
        <v-divider></v-divider>
        <br />
        <v-data-table
          item-key="index"
          :items="selectedProducts"
          :rows-per-page-items="[10]"
          :headers="headers.selectedProducts"
        >
          <template slot="no-data">
            No products. Add a new product above.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.index }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.quantity }}</td>
            <td style="cursor: pointer;" @click="removeProduct(props.item.id)">
              <v-icon class="red--text">clear</v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    remainder() {
      const id = this.product.product_id;
      if (!id) return 0;
      const qty = this.product.quantity || 0;
      const product = this.products.find(x => x.product.id == id);
      const remainder = product ? product.in_stock - qty : 0;
      // Fix for in stock input field showing empty value when its value is 0.
      return remainder.toString();
    }
  },
  created() {
    this.getWarehouses();
    this.getProducts();
    this.getProductOptions();
  },
  data() {
    return {
      headers: {
        selectedProducts: [
          {
            text: "S/n",
            value: "index"
          },
          {
            text: "Product",
            value: "name"
          },
          {
            text: "Quantity",
            value: "quantity"
          }
        ]
      },
      product: {},
      products: [],
      productOptions: [],
      selectedProducts: [],
      stockData: {},
      loading: {
        products: false,
        warehouses: true
      },
      warehouses: [],
      toWarehouses: []
    };
  },
  methods: {
    removeProduct(id) {
      const selectedProductIndex = this.selectedProducts
        .findIndex(product => product.id === id)

      if (selectedProductIndex > -1) {
        this.selectedProducts.splice(selectedProductIndex, 1);
      }
    },
    async selectFromWarehouse() {
      this.toWarehouses = this.warehouses.filter(value => {
        return value.id != this.stockData.from_id;
      });

      await this.getProducts();
    },
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
    async getProductOptions() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributeoptions`
        );
        await this.handleResponse(res, data => {
          this.productOptions = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We couldn't fetch your product options."
        );
      }
    },
    async getProducts() {
      // Reset selected products
      this.selectedProducts = [];
      if (!this.stockData.from_id) return;

      this.loading.products = true;

      // fetch products for selected warehouse
      try {
        let url = `${this.apiBaseUrl}/inventory/storage-status`;
        url += `?warehouseId=${this.stockData.from_id}`;
        const res = await this.$http.get(url);
        this.loading.products = false;
        this.handleResponse(res, data => {
            this.products = data;
            //map(x => {
            //    x.product.name = this.getProductName_(x.product);
            //    return x;
            //})
        //.map(x => {
        //        x.name = this.getProductName_(x);
        //        return x;
        //    }).sort((a, b) => a.name > b.name);
        });
      } catch (error) {
        this.loading.products = false;
        this.handleError(
          error,
          "We couldn't fetch your products. Please try again."
        );
      }
    },
    async getWarehouses() {
      const user = this.isLoggedIn;
      const storeId = user.store_id;
      let url = `${this.apiBaseUrl}/warehouses`;
      if (storeId) url += `?store_id=${storeId}`;
      try {
        const res = await this.$http.get(url);
        this.loading.warehouses = false;
        this.handleResponse(res, data => {
          this.warehouses = data;
        });
      } catch (error) {
        this.loading.warehouses = false;
        this.handleError(error, "We couldn't load your warehouses.");
      }
    },
    async moveStock() {
      if (!this.$refs.transfer_form.validate()) return;
      const stockData = Object.assign({}, this.stockData);
      stockData.products = this.selectedProducts.map(x => Object.assign({}, x));
      this.posting = true;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/transactions/movestock`,
          stockData
        );
        this.posting = false;
        this.handleResponse(res, () => {
          this.showSnackbar("Items moved successfully.");
          this.$refs.transfer_form.reset();
          this.selectedProducts = [];
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "An error occurred. Please try again later.");
      }
    },
    validateProduct() {
      if (!this.$refs.add_product_form.validate()) return;

      const product = Object.assign({}, this.product);
      const entry = this.products.find(x => x.product.id == product.product_id);
      product.name = entry.product.name;
      product.index = this.selectedProducts.length + 1;

      // check if product is already selected and update quantity if it is
      let selected = this.selectedProducts.find(x => x.product_id === product.product_id);
      if (selected) {
        const index = this.selectedProducts.findIndex(x => x.product_id === product.product_id);
        selected.quantity = parseInt(selected.quantity) + parseInt(product.quantity);
        this.selectedProducts[index] = selected;
      } else {
        this.selectedProducts.push(product);
      }
      // Update original product's quantity
      const productQty = product.quantity;
      entry.in_stock -= productQty;

      this.$refs.add_product_form.reset();
    }
  }
};
</script>
