<template>
  <v-dialog max-width="1000px" width="90%" persistent v-model="showDialog" hide-overlay :fullscreen="incoming.mass_upload">
    <v-toolbar dark color="teal darken-2 white--text" style="height:50px">
        Incoming receipt
      <!--<v-btn @click.native="closeDialog" flat :disabled="posting">
        <v-icon>close</v-icon>&nbsp;Close
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn dark flat @click="saveReceipt" :disabled="posting">
        Save receipt
      </v-btn>-->
    </v-toolbar>
    <v-card flat class="pa-2">
      <v-form :lazy-validation="true" ref="new_product_form">
          <div class="" v-if="!incoming.mass_upload">
              <incoming-input 
                  :incoming="incoming"
                  :warehouses="sortedWarehouses"
                  :suppliers="suppliers"
                  :loading="loading"
                  :documents="documentTypes"
                  @changeType="changeType"
                  @loadWarehouseProducts="triggerloadWarehouseProducts"
                  :currencies="currencies"></incoming-input>

          </div>
         
          <div v-else>
              <br />
              <mass-incoming-receipt 
                  :currencies="currencies"
                  :warehouses="warehouses"
                  :mass_upload="incoming.mass_upload"
                  :currency="currency"
                  :selected_product="selectedItemList"
                  :selectedProducts="selectedProducts"
                  :incoming="incoming"
                  :suppliers="suppliers"
                  @toggle="changeToggle"
                  @selection="AddselectedProducts"
                  :products="products"></mass-incoming-receipt>
          </div>

        <br />
      </v-form>
      <v-form @submit.prevent="validateProduct" ref="add_product_form"  v-if="!incoming.mass_upload">
        <v-subheader>
          <h4>Add products</h4>
          <v-flex v-if="totalCostPrice != 0" style="text-align: right; font-size: larger; color: black;" sm10>
            Total cost price (NGN) : {{ totalCostPrice }}
          </v-flex>
        </v-subheader>
        <!-- <br> -->
        <v-layout row wrap>
          <v-flex sm11 px-2>
            <v-layout row wrap>
              <v-flex sm4 px-2>
                <v-autocomplete
                  :loading="loading.products"
                  v-model="product.id"
                  :disabled="!incoming.warehouse_id"
                  :items="sortedProducts"
                  item-text="name"
                  item-value="id"
                  :validate-on-blur="true"
                  placeholder="Select product"
                  @change="selectProductChange"
                ></v-autocomplete>
              </v-flex>
              <v-flex sm2 px-2>
                <v-text-field
                  required
                  v-model="product.quantity"
                  type="number"
                  :validate-on-blur="true"
                  label="Quantity"
                  :rules="formRules.amount"
                  name="quantity"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 px-2>
                <v-text-field
                  required
                  v-model="product.cost_price"
                  type="number"
                  :validate-on-blur="true"
                  label="Cost price (per unit)"
                  :rules="formRules.amount"
                  name="cost_price"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 px-2>
                <v-text-field
                  required
                  v-model="product.selling_price"
                  type="number"
                  :validate-on-blur="true"
                  label="Selling price (per unit)"
                  :rules="formRules.amount"
                  name="selling_price"
                ></v-text-field>
              </v-flex>
              <v-flex sm2 px-2>
                <v-text-field
                  required
                  v-model="product.expiry_date"
                  type="date"
                  :validate-on-blur="true"
                  label="Expiry Date (optional)"
                  name="expiry_date"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex sm1 px-0>
            <div class="text-xs-center">
              <v-btn color="info" fab small type="submit">
                <v-icon dark>add</v-icon>
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-form>
      <v-dialog v-model="alertDialog" max-width="290">
        <v-card>
          <v-card-text>
            Please select at least one product.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn flat="flat" @click="alertDialog = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-subheader v-if="!incoming.mass_upload">
        Selected products
      </v-subheader>
      <v-divider></v-divider>
      <div class="selected-products" v-if="!incoming.mass_upload">
        <v-data-table
          item-key="index"
          :items="selectedProducts"
          :rows-per-page-items="[10]"
          :headers="headers.selectedProducts"
        >
          <template slot="no-data">
            No products. Add a new product above.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.index }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.quantity }}</td>
            <!-- <td>{{ props.item.unit }}</td> -->
            <td>{{ props.item.cost_price }}</td>
            <td>{{ props.item.selling_price }}</td>
            <td>{{ props.item.total_cost }}</td>
            <td >
              <span v-if="props.item.expiry_date">{{ props.item.expiry_date }}</span>
              <span v-else>NIL </span>
            </td>
            <td style="cursor: pointer;" @click="removeProduct(props.item.id)">
              <v-icon class="red--text">clear</v-icon>
            </td>
          </template>
        </v-data-table>
      </div>
      <v-card-actions style="justify-content:end">
          <v-btn @click.native="closeDialog" flat :disabled="posting">
              <v-icon>close</v-icon>&nbsp;Close
          </v-btn>
          <v-btn color="green text--white" flat @click="saveReceipt" :disabled="posting">
              Save receipt
          </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DocTypes from "@/data/documentTypes";
import MassIncomingReceipt from "./MassIncomingReceipt.vue";
import incomingInput from "../../../components/warehouse/incoming/incomingInput.vue";
export default {
  components: {
     MassIncomingReceipt,
     incomingInput
  },
  computed: {
    qtyUnits() {
      if (!this.product.id) return []; // Product object is empty

      const product = this.products.find(x => x.id == this.product.id);
      const units = [];
      units.push({
        id: 1,
        name: product.single_unit
      });

      if (product.compound_unit) {
        units.push({
          id: 2,
          name: product.compound_unit
        });
      }

      return units;
    },
    sortedCurrencies() {
        console.log(this.currency);
        //if (this.currency)
        //    this.incoming.currency_id = currency;
        //console.log(this.currency, this.incoming.currency_id);
      if (this.currencies_.length > 0) {
        const currencies = this.currencies_.map((item, index) => {
          const newItem = item;
          newItem.id = index + 1;
          newItem.name = `${item.name} (${item.symbol})`;
          return newItem;
        });

        return currencies;
      } else {
        return [];
      }
    },
    totalCostPrice() {
    return this.selectedProducts.reduce((total, product) => {
      return total + (product.cost_price * product.quantity);
      }, 0).toLocaleString();
    },
    sortedProducts() {
      const products = this.products;
      if (products.length < 1) return [];

      return products
        .filter(x => {
          return !this.selectedProducts.find(y => y.id == x.id);
        })
        //.map(x => {
        //  x.name = this.getProductName_(x);
        //  return x;
        //})
        .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase());
    },
    sortedWarehouses() {
      const warehouses = this.warehouses;
      if (warehouses.length < 1) return [];

      return warehouses.sort(
        (a, b) => a.name.toLowerCase() > b.name.toLowerCase()
      );
    }
  },
  created() {
    this.getProductOptions();
    this.getListOfSuppliers();
    //this.resolveProducts();
  },
  data() {
    return {
      alertDialog: false,
      documentTypes: DocTypes,
      headers: {
        selectedProducts: [
          {
            text: "S/n",
            value: "index"
          },
          {
            text: "Product",
            value: "name"
          },
          {
            text: "Quantity",
            value: "quantity"
          },
          {
            text: "Cost price (per unit)",
            value: "cost_price"
          },
          {
            text: "Selling price (per unit)",
            value: "selling_price"
          },
          {
            text: "Total cost price",
            value: "total_cost"
          },
          {
            text: 'Expiry Date',
            value: "expiry_date"
          }
        ]
      },
      incoming: {
        receipt_date: new Date().toISOString().substring(0, 10),
        mass_upload:false,
        transaction_date: new Date().toISOString().substring(0, 10),
      },
      loading: false,
      posting: false,
      priceChangesData: [],
      product: {
        unit: 1
      },
      suppliers: [],
      productOptions: [],
      selectedProducts: [],
      selectedItemList: [],
      warehouseProducts: {}
    };
  },
  methods: {
    changeType() {
          this.resolveProducts();
    },
    AddselectedProducts(products) {
        var u = [];
        products.forEach(product => {
            //console.log(product);
            if (product.quantity && product.cost_price && product.selling_price)
                this.addProduct(product, false);
            //else u[product.id] = product;
        });
        //this.selectedItemList = [];
        //this.selectedItemList = u;
        //console.log(this.selectedProducts);
    },
    removeProduct(id) {
      const selectedProductIndex = this.selectedProducts
        .findIndex(product => product.id === id)

      if (selectedProductIndex > -1) {
        this.selectedProducts.splice(selectedProductIndex, 1);
      }
    },
    getSupplierById(id) {
      return this.suppliers.find(sup => sup.id ===  id);
    },
    addProduct(product, flag = true) {
      const productDetails = this.products.find(x => x.id == product.id);
      const name = productDetails.name;
      const len = this.selectedProducts.length;
        const { cost_price, selling_price, quantity, expiry_date } = product;
        //console.log(product);
      const total_cost = cost_price * quantity;

      const selectedProduct = {
        id: product.id,
        index: len + 1,
        name: name,
        cost_price,
        selling_price,
        quantity,
        total_cost,
        expiry_date
      };

      this.selectedProducts.push(selectedProduct);

      // Reset selected product
      this.product = {};
      if(flag)
         this.$refs.add_product_form.reset();
    },
    closeDialog() {
      this.resetForm();
      this.$emit("closeDialog");
    },
    changeToggle() {
        this.incoming.mass_upload = !this.incoming.mass_upload;
        this.resolveProducts();
    },
    triggerloadWarehouseProducts() {
        this.loadWarehouseProducts();
    },
    async loadWarehouseProducts() {
      const warehouseId = this.incoming.warehouse_id;
      if (this.warehouseProducts[warehouseId]) return;
      this.showGlobalLoader(true);

      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/storeproducts?storeId=${warehouseId}`
        );
        await this.handleResponse(res, data => {
          this.warehouseProducts[warehouseId] = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "There was an error performing this operation. Please try again later."
        );
      }

      this.showGlobalLoader(false);
    },
    findStoreProduct(id) {
      const activeWareHouse = this.incoming.warehouse_id;
      const warehouseProducts = this.warehouseProducts[activeWareHouse];

      if (!warehouseProducts) return null;

      return warehouseProducts.find(x => x.product_id == id);
    },
    getProductName_(product) {
      return this.getProductName(product, this.productOptions);
    },
    async getProductOptions() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/productattributeoptions`
        );
        await this.handleResponse(res, data => {
          this.productOptions = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We couldn't fetch your product options."
        );
      }
    },
    async getListOfSuppliers() {
      try {
        const res = await this.$http.get(
          `${this.apiBaseUrl}/suppliers`
        );
        await this.handleResponse(res, data => {
          this.suppliers = data;
        });
      } catch (error) {
        await this.handleError(
          error,
          "We couldn't fetch your suppliers options."
        );
      }
    },
    resetForm() {
      this.$refs.new_product_form.reset();
      this.selectedProducts = [];
    },
    async saveReceipt() {
      if (!this.validateForm()) return;
      this.posting = true;
      const incomingReceipt = Object.assign({}, this.incoming);
      incomingReceipt.products = this.selectedProducts.map(x => {
        const product = Object.assign({}, x);
        product.product_id = product.id;
        return product;
      });
        //console.log(incomingReceipt);
        //return;
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/transactions/incoming`,
          incomingReceipt
        );
        this.handleResponse(res, data => {
          this.posting = false;
          this.$emit("receiptAdded", data);
          this.resetForm();
        });
      } catch (error) {
        this.posting = false;
        this.handleError(
          error,
          "There was an error performing this operation. Please try again later."
        );
      }
    },
    selectProductChange() {
      // Check if this product already exists for this store
      const existingProduct = this.findStoreProduct(this.product.id);
      let costPrice = 0;
      let sellingPrice = 0;
      if (existingProduct) {
        costPrice = existingProduct.cost_price;
        sellingPrice = existingProduct.selling_price;
      } else {
        // Find global product and get its default selling price
        const globalProduct = this.products.find(x => x.id == this.product.id);
        sellingPrice = globalProduct.price;
      }

      this.product.cost_price = costPrice;
      this.product.selling_price = sellingPrice;
      },
      resolveProducts() {
          this.sortedProducts.forEach(product => {
              this.selectedItemList[product.id] = { name: product.name, quantity: product.quantity, id: product.id, cost_price: product.cost_price, selling_price: product.price, expiry_date: null };
          });

      },
    validateProduct() {
      if (!this.$refs.add_product_form.validate()) return;
      const product = this.product;
      this.addProduct(product);
    },
    validateForm() {
      if (!this.$refs.new_product_form.validate()) return false;

      if (this.selectedProducts.length < 1) {
        this.alertDialog = true;
        return false;
      }

      return true;
    }
  },
mounted: function () {
    let vm = this;

    vm.$nextTick(function () {
        //console.log(vm.currency);
        this.resolveProducts();
    });
},
  props: {
    currencies: Array,
    products: Array,
    currency: String,
    showDialog: Boolean,
    warehouses: Array
  }
};
</script>

<style scoped>
    .v-toolbar__content{
        height:47px !important;
    }
/* .selected-products {
        max-height: 300px;
        overflow-y: auto;
    } */
</style>
