// Browser polyfill - must be at entry point of application
import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store";
import vuetify from "vuetify";
import moment from "moment";
import config from "./config";
import axios from "axios";
import VueAxios from "vue-axios";
import qs from "qs";
import _config from "./config";
import accounting from "accounting";
import vueHeadful from "vue-headful";
import VueSocialSharing from "vue-social-sharing";
import lodash from "lodash";
import SkeletonLoader from "@/components/shared/SkeletonLoader";

import functions from "./lib/functions";

import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";
import "vuetify-upload-button/dist/vuetify-upload-button";
import "./../public/static/fonts/fonts.css";

import "./../public/static/css/styles.css";
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';

import db from "./db";
import envConfig from "./config/env";

let appConfig = {};
if (process.env.APP_ENV === "airtill") {
  appConfig = envConfig.airtill;
} else {
  appConfig = envConfig.default;
}

store.commit("setAppName", appConfig.appName);

// Extract functions. Add only when necessary
const { generateErrorMessages } = functions;

axios.defaults.withCredentials = true;

// Setup axios interceptors
axios.interceptors.request.use(axiosConfig => {
  // fetch user object from local storage
  const user = db.get(_config.constants.USER);
  if (!user) return axiosConfig;

  // Get access and refresh tokens
  const { accessToken, refreshToken } = user;

  // Add any token that exists to request header
  if (accessToken) axiosConfig.headers.Authorization = `Bearer ${accessToken}`;
  if (refreshToken) axiosConfig.headers["X-Refresh-Token"] = refreshToken;

  return axiosConfig;
});

// This method handles the response from any request made.
const handleAxiosResponse = response => {
  const headers = response.headers;
  const accessToken = headers["x-access-token"];
  const refreshToken = headers["x-refresh-token"];
  const killSwitch = headers["x-kill-session"];

  // Check if the kill switch header exists.
  if (killSwitch) {
    // Exit application
    store.commit("burn");
  }

  // Else...
  const data = {};
  if (accessToken) {
    data.accessToken = accessToken;
  }

  if (refreshToken) {
    data.refreshToken = refreshToken;
  }

  // Update user object
  if (Object.keys(data).length > 0) {
    store.commit("updateUserData", data);
  }
  return response;
};

axios.interceptors.response.use(
  response => handleAxiosResponse(response),
  error => {
    const { status, data } = error.response;
    if (status === 400) error.validationErrors = data;
    //console.log(error.response);
    handleAxiosResponse(error.response);
    return Promise.reject(error);
  }
);

Vue.use(VueSocialSharing);

Vue.use(vuetify, {
  theme: appConfig.theme
});
Vue.use(VueAxios, axios);
Vue.prototype.$qs = qs;
Vue.prototype.$db = db;
Vue.prototype.$moment = moment;
Vue.prototype.$appConfig = _config;
Vue.prototype.$accounting = accounting;
Vue.config.productionTip = false;
Vue.prototype.$_ = lodash;

Vue.mixin({
  computed: {
    globalLoader() {
      return this.$store.getters.globalLoader;
    },
    isLoggedIn() {
      const loggedIn = this.$store.getters.userData;
      return loggedIn;
    },
    authUser() {
      return this.$store.getters.userData;
    },
    selectedStore() {
      return this.$store.getters.selectedStore();
    },
    selectedBusiness() {
      return this.$store.getters.selectedBusiness();
    },
    selectedWarehouse() {
      return this.$store.getters.selectedWarehouse();
    },
    snackbar() {
      return this.$store.getters.snackbar;
    },
    reference() {
        return this.$store.getters.reference;
    }
  },
  data() {
    return {
      alert: store.getters.alert,
      apiBaseUrl: config.apiBaseUrl,
      baseUrl: config.appBaseUrl,
      formRules: {
        all: (...args) => {
          let rules = [];
          args.forEach(x => {
            rules = rules.concat(this.formRules[x]);
          });

          return rules;
        },
        amount: [
          v =>
            (v && !!v.toString().match(/^\d+(\.{1}\d{0,8})?$/)) ||
            "Not a valid amount."
        ],
        cantBeZero: [
          v =>
            (!!v && !!v.toString().match(/^\d+(\.{1}\d+)?$/)) ||
            "Value can't be zero"
        ],
        customMessage: msg => {
          return [() => false || msg];
        },
        dynamic: (bool, rule) => {
          if (bool) {
            return this.formRules[rule] || this.formRules.required;
          } else return [() => true];
        },
        equal: (a, b, msg) => {
          if (a === b) return [() => true];

          return this.formRules.customMessage(msg);
        },
        numnbersOnly: [
          v => (v && !!v.toString().match(/^\d+$/)) || "Not a valid amount."
        ],
        password: [
          v =>
            (v && v.length >= 6) || "Password can't be less than 6 characters."
        ],
        phone: [
          v => (v && v.length >= 11) || "Please enter a valid phone number"
        ],
        pin: [
          v => (v && !!v.match(/^\d{5}/)) || "PIN code must be 5 digits long."
        ],
        token: [
          v => (!!v && v.length >= 6) || "Token can't be less than 6 characters"
        ],
        required: [v => !!v || v === 0 || "This is a required field"]
      },
      posting: false,
      selectedStore_: store.getters.selectedStore(),
      selectedBusiness_: store.getters.selectedBusiness(),
      urls: {
        app: config.appBaseUrl,
        api: config.apiBaseUrl
      }
    };
  },
  methods: {
    dateFromFormat(
      date,
      sourceFormat = "DD-MM-YYYY HH:mm:ss.S",
      destFormat = "DD/MM/YYYY"
    ) {
      return this.$moment(date, sourceFormat).format(destFormat);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getProductName(product, allOptions) {
      const { options, parent_id, name } = product;
      if (!parent_id) return name;

      const optionNames = options
        .map(x => {
          const option = allOptions.find(y => y.id == x.option_id);
          return option ? option.name : "";
        })
        .join(" | ");

      return [name, optionNames].join(" | ");
    },
    async handleError(error, msg, fn) {
      if (fn) fn();
        const { validationErrors, response } = error;
      // Check if there are validation errors
      if (validationErrors) {
          msg = generateErrorMessages(validationErrors);
        return this.showSnackbar(msg, {
          type: "error"
        });
      }

      if (!(response && response.data && response.data.message))
        return this.showSnackbar(msg, {
          type: "error"
        });

      const data = response.data;
      this.showSnackbar(data.message, {
        type: "error"
      });
    },
    async handleResponse(res, fn) {
      const response = await res.data;
      const data = await response.data;
      const message = await response.message;
      if (response.status === 0 || response.status === false) {
        return this.showSnackbar(response.message, {
          type: "error"
        });
      }

      if (fn) fn(data, message);
    },
    maxLength(len) {
      let chars = "";
      [...Array(len)].forEach(() => (chars += "#"));
      return chars;
    },
    momentFormat(dateTime, format = "DD/MM/YYYY") {
      return this.$moment(dateTime).format(format);
    },
    // momentFormat(dateTime, format) {
    //   format = format || "DD/MM/YYYY";
    //   return this.$moment(dateTime).format(format);
    // },
    niceDateFormat(dateTime) {
      return this.$moment(dateTime).format("DD MMM YYYY");
    },
    justDate(dateTime) {
      return dateTime.split(" ")[0];
    },
    justTime(dateTime) {
      return dateTime.split(" ")[1];
    },
    fullDateFormat(dateTime) {
      return this.$moment(dateTime).format("DD MMM YYYY HH:mm");
    },
    dateFormat(dateTime) {
      return this.$moment(dateTime).format("DD MMM YYYY");
    },
    getToday() {
      return this.$moment().format("DD/MM/YYYY");
    },
    moneyFormat(value) {
      return this.$accounting.formatNumber(value, 2, ",");
    },
    numberFormat(value) {
      return this.$accounting.formatNumber(value, 0, ",");
    },
    currentTimeStamp() {
      var date = new Date();
      return date.getTime();
    },
    getBaseType(value) {
      switch (value) {
        case 0:
          return "Before tax";
        case 1:
          return "After tax";
        default:
          return "Unknown";
      }
    },
    saveUser(userData) {
      localStorage.setItem("ecr_user", JSON.stringify(userData));
      this.$store.commit("updateUserData", userData);
    },
    showAlert(msg, type, global = true) {
      this.$store.commit("showAlert", {
        msg,
        type,
        global
      });
    },
    showGlobalLoader(bool) {
      this.$store.commit("showGlobalLoader", bool);
    },
    showSnackbar(_msg, _config) {
      const config = _config || {};
      const type = config.type;
      let defaultMessage = "An error occurred.";
      if (type !== "error") defaultMessage = "Success!";
      const msg = _msg || defaultMessage;
      const timeout = config.timeout;
      const position = config.position;
      const align = config.align;
      this.$store.commit("showSnackbar", {
        msg,
        type,
        timeout,
        position,
        align
      });
    },
    isSuperAdmin(user) {
      if (!user) return false;

      return user.role == "Administrator";

    },
    isBusinessAdmin(user) {
      if (!user) return false;

      return user.role == "Business Admin";
    }
  }
});

Vue.component("vue-headful", vueHeadful);
Vue.component("skeleton-loader", SkeletonLoader);
Vue.component('v-multiselect-listbox', vMultiselectListbox) // multiple select input

router.beforeEach(async (to, from, next) => {
  // console.log(to, from, next);
  return next();
  // if (!storePlugin.store.getters.auth) {
  //   await storePlugin.store.dispatch('updateUser');
  // }

  // if (storePlugin.store.state.showMobileMenu === true) {
  //   storePlugin.store.state.showMobileMenu = false;
  // }

  // if (!to.meta.access.length) {
  //   return next();
  // }

  // const isAllowed = storePlugin.store.getters.hasAccessTo(to.meta.access);

  // if (!isAllowed) {
  //   return next({ path: '/' });
  // } else {
  //   return next();
  // }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
