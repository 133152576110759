<template>
  <v-card class="mx-auto text-xs-center" color="teal" dark>
    <v-card-title class="cyan darken-2 white--text">
      Sales
    </v-card-title>
    <v-card-text>
      <v-sheet color="rgba(0, 0, 0, .12)">
        <v-sparkline
          :value="values"
          color="rgba(255, 255, 255, .7)"
          height="100"
          padding="24"
          stroke-linecap="square"
          auto-draw
          line-width="2"
        >
          <template v-slot:label="item">
            N{{ item ? item.value : "" }}
          </template>
        </v-sparkline>
      </v-sheet>
    </v-card-text>

    <v-card-text>
      <h3 class="font-weight-thin">Sales during this period</h3>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions class="justify-center">
      <v-btn block flat>More</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  props: {
    values: Array
  }
};
</script>
