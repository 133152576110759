<template>
  <v-card>
    <v-card-title class="cyan darken-2 white--text">
      Sales
    </v-card-title>
    <v-card-text class="text-sm-right p-8">
      <h2>{{ value.length }}</h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SalesCount",
  data() {
    return {};
  },
  props: ["value"]
};
</script>
