<template>

    <layout>
        <template>
            <v-layout row wrap>
                <v-spacer></v-spacer>
                <v-flex xs12>
                    <v-card flat>
                        <v-card-title class="pt-0">
                            <v-layout>
                                <v-flex xs6 sm4></v-flex>
                                <v-flex xs6 sm4></v-flex>
                                <v-flex xs6 sm4>
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search"
                                                  append-icon="search"
                                                  label="Search plan"
                                                  single-line
                                                  hide-details
                                                  clearable></v-text-field>
                                </v-flex>
                            </v-layout>
                        </v-card-title>
                        <v-data-table :items="list"
                                      :rows-per-page-items="[20]"
                                      :search="search"
                                      :headers="headers"
                                      :loading="loading">
                            <template slot="no-data">
                                No users.
                            </template>
                            <template slot="items" slot-scope="props">
                                <td>{{ props.item.business_name }}</td>
                                <td>{{ props.item.plan }}</td>
                                <td>{{ props.item.type }}</td>
                                <td>{{ dateFormat(props.item.start_date) }}</td>
                                <td>{{ dateFormat(props.item.end_date) }}</td>
                                <td>
                                    <v-icon small :color="props.item.status ? 'green' : 'red'">
                                        {{ props.item.status ? "check_circle_outline" : "remove_circle" }}
                                    </v-icon>
                                </td>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-flex>
            </v-layout>

        </template>
        <template v-else>
            No Business Selected
        </template>
    </layout>
</template>

<script>
    import Layout from "@/components/Layout";

    export default {
        components: {
            Layout,
        },
        data() {
            return {
                filter: {
                    endDate: new Date().toISOString().substr(0, 10),
                    startDate: new Date().toISOString().substr(0, 10)
                },
                headers: [
                    { text: "Business Name", value: "business_name" },
                    { text: "Subscription Plan", value: "plan" },
                    { text: "Type", value: "type" },
                    { text: "Start date", value: "start_date" },
                    { text: "End date", value: "end_date" },
                    { text: "Status", value: "status" },
                ],
                loading: false,
                search: "",
                list: [],
            };
        },
        mounted() {
            this.fetchData();
        },
        computed: {
        },
        methods: {
            async fetchData() {
                this.loading = true;
                try {
                    const { data } = await this.$http.get(`${this.apiBaseUrl}/superAdmin/subscribe/get-subs`);
                    this.list = data.data;
                } catch (error) {
                    // console.log(error);
                    this.handleError(error, "Error fetching business subscriptions");
                }
                this.loading = false;
            },
        }
    };
</script>
<style scoped>
    .customer-name {
        font-size: 15px;
        margin-bottom: 15px;
        padding: 10px;
    }
</style>