<template>
    <v-card class="dash-card"  v-if="loaded">
        <v-card-title class="blue-grey darken-2 white--text">
            Sales Statistics
        </v-card-title>
        <line-chart
                    text-xs-center
                    :data="chart.data"
                    :options="chart.options"
                    :height="250"></line-chart>
    </v-card>
    <skeleton-loader :color="'blue-grey darken-2 white--text'" :title="'Sales Statistics'" :height="'25px'" :count="6" v-else></skeleton-loader>
</template>

<script>
import PieChart from "@/components/charts/PieChart";
import LineChart from "@/components/charts/Line";

export default {
  name: "TopProducts",
  components: {
    PieChart,
    LineChart
  },
  data() {
    return {
      loaded: false,
      chart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sept",
            "Oct",
            "Nov",
            "Dec"
          ],
          datasets: [
            {
              label: "Sales Statistics",
              //backgroundColor: [
              //  "#f87979",
              //],
              borderColor: '#ff9f40',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              //data: [100, 0]
            }
          ]
        },
        options: {
          height: 360,
        }
      }
    };
  },
  mounted() {
    this.getReceiptStatistics();
  },
    watch: {
        selectedBusiness: {
            handler() {
                this.getReceiptStatistics();
            },
            deep: true
        }
    },
  methods: {
    async getReceiptStatistics() {
      let url = `${this.apiBaseUrl}/superAdmin/analysis/receipt-ratio?businessId=`+this.selectedBusiness.id;
      try {
        this.loaded = false;
        const { data } = await this.$http.get(url);
          const { data: statistics } = data;
          //console.log(statistics);
          for (const stats in statistics) {
              let index = parseInt(stats - 1);
              this.chart.data.datasets[0].data[index] = statistics[stats];
          }
        this.loaded = true;
      } catch (error) {
        console.log("ERROR", error);
      }
    },
  }
};
</script>

<style scoped></style>
