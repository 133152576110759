<template>
    <layout>
        <v-layout row wrap>
            <v-flex sm8 lg6 xl4 offset-sm4 offset-lg6 offset-xl8>
                <v-layout row wrap>
                    <v-spacer></v-spacer>
                    <v-flex sm6 px-2>
                        <v-btn block
                               @click="showOrderDialog" color="teal darken-2 white--text" pre>
                            <v-icon>add_circle_outline</v-icon>&nbsp; New Order
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <br />
        <!-- <hr> -->
        <v-divider></v-divider>
        <br />
        <v-layout row wrap>
            <v-flex xs12>
                <v-card flat>
                    <v-card-title class="pt-0">
                        <v-layout>
                            <v-flex sm6 md4 offset-sm6 offset-md8>
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search.order"
                                              append-icon="search"
                                              label="Search Order"
                                              single-line
                                              hide-details
                                              clearable></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-card-title>
                    <v-data-table :items="ordersList"
                                  :rows-per-page-items="[10]"
                                  :search="search.order"
                                  :headers="headers.orders"
                                  :loading="loading.orders">
                        <template slot="no-data">
                            No Order. Add a new order above.
                        </template>
                        <template slot="items" slot-scope="props">
                            <td>{{ fullDateFormat(props.item.created_at) }}</td>
                            <td>
                                <span :style="{color: colors[props.item.status],fontSize: '14px', fontWeight: 'bold', cursor: 'pointer', fontStyle: 'italic', textDecoration:'underline'}"
                                      @click="$router.push(`/warehouse/customer-order/${props.item.order_number}`)">{{ props.item.order_number }}</span>
                            </td>
                            <!--style="color: rgb(18 53 41); font-size: 14px; font-weight: bold; cursor: pointer; font-style: italic; text-decoration: underline; "-->
                            <td>{{ props.item.customer_name  }}</td>
                            <td>{{ props.item.store }}</td>
                            <td>{{ props.item.author }}</td>
                            <td>
                                <v-chip class="ma-2"
                                        small
                                        :color="colors[props.item.status]"
                                        label
                                        outlined>
                                    {{flags[props.item.status]}}
                                </v-chip>
                            </td>
                            <td>
                                <v-tooltip bottom>
                                    <v-icon slot="activator" @click="showInfo(props.item)">info</v-icon>
                                    <span>View details</span>
                                </v-tooltip>
                            </td>
                            <td>
                                <div v-if="props.item.status == 0" style="display:flex">
                                    <v-btn class="ma-2"
                                           outlined
                                           small
                                           @click="confirm('approve',props.item)"
                                           color="green darken-2 white--text">
                                        Approve
                                    </v-btn>
                                    <v-btn class="ma-2"
                                           outlined
                                           small
                                           @click="confirm('decline',props.item)"
                                           color="red darken-2 white--text">
                                        Cancel
                                    </v-btn>
                                </div>
                                <div v-else>
                                    <span>
                                        {{flags[props.item.status]}} by {{props.item.assingee}}
                                    </span>
                                </div>
                            </td>
                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
        <v-layout>
            <new-order :show-dialog="newOrderDialog"
                       @closeDialog="closeOrderDialog"
                       :stores="stores"
                       :partners="partners"
                       @orderAdded="addOrderToTable">
            </new-order>
        </v-layout>

        <!-- Item dialog -->
        <v-dialog v-model="showItemDialog" max-width="500px" persistent>
            <v-card flat>
                <v-card-title primary-title>
                    <v-flex xs12 sm6 px-2>
                        <h3>Products</h3>
                    </v-flex>
                </v-card-title>
                <template>
                    <v-data-table :headers="headers.productHeaders"
                                  :items="selectedItem.products"
                                  hide-actions
                                  class="elevation-1">
                        <template slot="items" slot-scope="props">
                            <td>{{ props.item.product }}</td>
                            <td class="text-xs-right">{{ props.item.quantity }}</td>
                            <td class="text-xs-right">{{ moneyFormat(props.item.costPrice) }}</td>
                            <td class="text-xs-right">{{ moneyFormat(props.item.sellingPrice) }}</td>
                        </template>
                    </v-data-table>
                </template>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn flat @click.native="showItemDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- Item dialog -->


        <v-dialog v-model="alertDialog" max-width="290">
            <v-card>
                <v-card-text>
                    This Action is irreversible. Are you sure you want to continue.
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn flat="flat" @click="alertDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn flat="flat" @click="confirmEditOrder">
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </layout>
</template>

<script>
import Layout from "@/components/Layout";
import NewOrder from "./NewOrder.vue";
export default {
  components: {
    Layout,
    NewOrder
  },
  computed: {
    saveText() {
      const texts = [
        {
          active: "Saving..",
          default: "Save"
        },
        {
          active: "Updating..",
          default: "Update"
        }
      ];

      const index = this.action == "update" ? 1 : 0;
      return this.posting ? texts[index].active : texts[index].default;
      },
  },
  created() {
      this.getOrders();
      this.getStores();
      this.getPartners();
  },
  data() {
    return {
      action: "save",
      showItemDialog: false,
      flags: [ "Pending", "Cancelled", "Approved"],
      colors: [ "orange", "red", "green"],
      headers: {
        orders: [
          {
            text: "Date created",
            align: "left",
            value: "created_at"
          },
          {
            text: "Order Id",
            value: "order_number"
          },
          {
            text: "Customer Name",
            value: "customer_name"
          },
          {
            text: "Store",
            value: "store"
          },
          {
            text: "Created by",
            value: "author"
          },
          {
            text: "Status",
            value: "status"
          },
          {
            text: "Products",
            value: "products"
          },
          {
            text: "Actions",
            value: ""
          }
        ],
        productHeaders: [
            {
                text: "Name",
                value: "id"
            },
            {
                text: "Quantity",
                value: "quantity",
                align: "right"
            },
            {
                text: "Cost Price",
                value: "price",
                align: "right"
            },
            {
                text: "Selling Price",
                value: "price",
                align: "right"
            }
        ],
      },
      loading: {
        stores:false,
        partners: false,
        orders:false
      },
      posting: false,
      alertDialog: false,
      newOrderDialog:false,
      ordersList:[],
      partners:[],
      stores:[],
      selectedItem: {},
      selectedOrder: {},
      products:[],
      type:"",
      search: {
        order: ""
      }
    };
  },
  methods: {
    addOrderToTable(products) {
      this.showSnackbar("Customer order added successfully.");
      this.getOrders();
      },
      showInfo(product) {
          if (product) {
              this.selectedItem = product;
          }
          this.showItemDialog = true;
      },
      async getOrders() {
          try {
              this.loading.orders = true;
              const res = await this.$http.get(`${this.apiBaseUrl}/customersorder/orders`);
              this.handleResponse(res, data => {
                  this.ordersList = data;
                  this.loading.orders = false;
              });
          } catch (error) {
              this.loading.orders = false;
              this.handleError(
                  error,
                  "We couldn't fetch your orders. Please try again."
              );
          }
          this.loading.orders = false;
      },
      async confirm(type,item) {
          this.selectedOrder = {};
          this.alertDialog = true;
          this.selectedOrder = item;
          this.type = type;
      },
      async confirmEditOrder() {
          this.alertDialog = false;
          let url = "approve-order";
          if (this.type == "decline") url = "decline-order";
          try {
              this.loading.orders = true;
              const res = await this.$http.get(`${this.apiBaseUrl}/customersorder/` + url + `?orderId=${this.selectedOrder.id}`);
              this.handleResponse(res, data => {
                  this.ordersList = data;
                  if (this.type == "decline")
                      this.showSnackbar("Customer order cancelled successfully.");
                  else
                      this.showSnackbar("Customer order approved successfully.");
                  this.loading.orders = false;
              });
          } catch (error) {
              this.loading.orders = false;
              this.handleError(
                  error,
                  "We couldn't fetch your orders. Please try again."
              );
          }
      },
    async getStores() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.loading.stores = false;
        this.handleResponse(res, data => {
          this.stores = data;
          this.$store.commit("updateStores", data);
        });
      } catch (error) {
        this.loading.stores = false;
        this.handleError(
          error,
          "We couldn't fetch your stores. Please try again."
        );
        }
        this.loading.orders = false;
      },
      async getPartners() {
          this.partners = [];
          try {
              const res = await this.$http.get(`${this.apiBaseUrl}/partners/get-partners`);
              //this.loading.partners = false;
              this.handleResponse(res, data => {
                  //console.log(res, data);
                  this.partners = data;
              });
          } catch (error) {
              //this.loader.partners = false;
              this.handleError(
                  error,
                  "We couldn't fetch your partners. Please try again later."
              );
          }
          this.loading.orders = false;
      },
     showOrderDialog() {
        this.newOrderDialog = true;
      },
      closeOrderDialog() {
         this.newOrderDialog = false;
      },
  }
};
</script>
