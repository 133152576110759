<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex xs12>
          <v-layout>
            <v-spacer></v-spacer>
            <v-flex sm6>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search product"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <br />
      <v-data-table
        :items="devices"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No devices.
        </template>
        <template slot="items" slot-scope="props">
          <td>
            <v-icon small>
              devices
            </v-icon>
            &nbsp;
            {{ props.item.device_name || "" }}
          </td>
          <td>
            {{ props.item.device_code }}
          </td>
          <td>{{ getStore(props.item.store_id).name }}</td>
          <td>
            <v-icon small>
              {{ props.item.active ? "check" : "not_interested" }}
            </v-icon>
          </td>
          <td>
            <v-btn icon small @click="showDeviceInfo(props.item)">
              <v-icon small>
                edit
              </v-icon>
            </v-btn>
            <!-- <v-btn icon small>
              <v-icon small>
                delete
              </v-icon>
            </v-btn> -->
          </td>
        </template>
      </v-data-table>
      <v-dialog v-model="deviceDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title primary-title>
            <h3>
              Device details
            </h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form lazy-validation ref="device_form" @submit.prevent="post">
              <v-layout row wrap>
                <v-flex sm12>
                  <v-text-field
                    disabled
                    v-model="device.device_code"
                    label="Device code"
                  ></v-text-field>
                </v-flex>
                <v-flex sm12>
                  <v-select
                    required
                    v-model="device.store_id"
                    :items="stores"
                    item-text="name"
                    item-value="id"
                    label="Store"
                    :rules="formRules.required"
                  ></v-select>
                </v-flex>
                <v-flex sm12>
                  <v-checkbox
                    required
                    v-model="device.active"
                    label="Active"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn @click="closeDeviceDialog" flat :disabled="posting"
                  >Close</v-btn
                >
                <v-btn
                  type="submit"
                  flat
                  :disabled="posting || stores.length < 1"
                  color="primary"
                  >{{ saveText }}</v-btn
                >
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-else>
      No store selected
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
export default {
  components: {
    Layout
  },
  computed: {
    saveText() {
      const texts = [
        {
          active: "Saving",
          default: "Save"
        },
        {
          active: "Updating",
          default: "Update"
        }
      ];

      const index = this.postAction !== "update" ? 0 : 1;
      return this.posting ? texts[index].active : texts[index].default;
    }
  },
  created() {
    if (this.selectedStore || this.isLoggedIn.store_id) this.getDevices();
    if (this.isLoggedIn.store_id) this.getStores();
  },
  data() {
    return {
      device: {},
      devices: [],
      deviceDialog: false,
      headers: [
        {
          text: "Device name",
          value: "device_name"
        },
        {
          text: "Device code",
          value: "device_code"
        },
        {
          text: "Store",
          value: "store"
        },
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Actions",
          align: "left",
          value: ""
        }
      ],
      loading: false,
      postAction: "",
      stores: this.$store.getters.stores,
      search: ""
    };
  },
  methods: {
    closeDeviceDialog() {
      this.postAction = "";
      this.deviceDialog = false;
      this.device = {};
      this.$refs.device_form.reset();
    },
    async getDevices() {
      let url = `${this.apiBaseUrl}/cashregisters`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.devices = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "We couldn't load your devices");
      }
    },
    getStore(id) {
      return this.stores.find(x => x.id == id);
    },
    async getStores() {
      try {
        const res = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.handleResponse(res, data => {
          this.stores = data;
        });
      } catch (error) {
        this.handleError(
          error,
          "We couldn't fetch your stores. Please try again."
        );
      }
    },
    async post() {
      if (!this.$refs.device_form.validate()) return;
      const device = Object.assign({}, this.device);
      this.posting = true;
      try {
        let url = `${this.apiBaseUrl}/cashregisters`;
        const res = await this.$http.post(url, device);
        this.posting = false;
        this.handleResponse(res, data => {
          let action = "added";
          if (this.postAction === "update") {
            action = "updated";
            this.updateDevice(device);
          } else {
            this.devices.push(data);
          }
          this.closeDeviceDialog();
          this.showSnackbar(`Device ${action} successfully.`);
        });
      } catch (error) {
        this.posting = false;
        this.handleError(error, "An error occurred. Please try again.");
      }
      //
    },
    showDeviceDialog() {
      this.deviceDialog = true;
    },
    showDeviceInfo(device) {
      this.postAction = "update";
      this.device = Object.assign({}, device);
      this.deviceDialog = true;
    },
    updateDevice(device) {
      const index = this.devices.findIndex(x => x.id == device.id);
      this.$set(this.devices, index, device);
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getDevices();
      },
      deep: true
    }
  }
};
</script>

<style scoped>
.filtersss {
  background-color: #5a5a5a29;
  padding: 10px;
  border-radius: 4px;
}
</style>
