<template>
    <layout>
        <template>
            <v-layout row wrap style="margin-bottom: 40px;">
                <v-flex xs12 sm3 px-2>
                    <v-btn large color="white" @click="$router.back()">
                        Back
                    </v-btn>
                </v-flex>
                <v-flex xs12 sm6 px-2>
                    <h2 style="text-align: center; padding-top: 5px;">{{selectedCustomer.name}}</h2>
                </v-flex>
                <v-flex xs12 sm3>
                    <v-layout>
                        <v-flex xs12 sm6 text-xs-right v-if="authUser.store_id == 0">
                            <v-btn color="teal" style="color: white" @click="showReversalModal = true">
                                REVERSAL
                            </v-btn>
                        </v-flex>
                        <v-flex xs12 sm6 text-xs-left>
                            <v-btn color="green" style="color: white" @click="showCreditLimitModal = true">
                                Credit Limit
                            </v-btn>

                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <v-layout row wrap style="margin-bottom: 40px;">
                <v-flex xs6 sm4 px-2>
                    <customer :value="selectedCustomer" @updateWalletBalance="showUpdateWalletBalance = true" />
                </v-flex>
                <v-flex xs6 sm4 px-2>
                    <paid :value="moneyFormat(totalPaid)" />
                </v-flex>
                <!-- <v-flex xs6 sm3 px-2>
              <owing :value="moneyFormat(totalOwed)" />
            </v-flex> -->
                <v-flex xs6 sm4 px-2>
                    <limit-balance :value="
                                   selectedCustomer.creditLimit < 0
              ? 'Unlimited'
              : moneyFormat(selectedCustomer.creditLimit)"
          />
                </v-flex>
            </v-layout>

            <v-layout>
                <v-flex xs6 sm6>
                    <div class="text-xs-left">
                        <v-btn color="gray"
                               style="color: black"
                               @click="$router.push(`../view-wallet-log/${$route.params.id}/${selectedCustomer.name}`)">
                            Get Wallet Log
                        </v-btn>
                    </div>
                </v-flex>
                <v-flex xs6 sm6>
                    <div class="text-xs-right">
                        <!--<v-btn
                      color="gray"
                      style="color: black"
                      @click="syncTransaction"
                    >
                     Sync transaction
                    </v-btn>-->
                        <DownloadCSV class="v-btn theme--light white"
                                     :data="filterReceiptsForExport"
                                     :name="'export-customer-ledger-for-' + selectedCustomer.name + '.csv'"
                                     v-if="filterReceiptsForExport.length">
                            Export
                        </DownloadCSV>
                    </div>
                </v-flex>
            </v-layout>

            <v-data-table :expand="true"
                          item-key="id"
                          disable-initial-sort
                          :items="filterReceipts"
                          :rows-per-page-items="[25]"
                          :search="search"
                          :headers="headers"
                          :loading="loading">
                <template slot="no-data">
                    No history.
                </template>
                <template slot="items" slot-scope="props">
                    <tr>
                        <td @click="props.expanded = !props.expanded">{{ props.item.receiptId }}</td>
                        <td @click="props.expanded = !props.expanded">{{ props.item.totalAmount }}</td>
                        <td @click="props.expanded = !props.expanded">
                            {{ props.item.amountPaid }}
                        </td>
                        <td @click="props.expanded = !props.expanded">
                            {{ props.item.store }}
                        </td>
                        <td @click="props.expanded = !props.expanded">
                            {{ props.item.seller }}
                        </td>
                        <td @click="props.expanded = !props.expanded">{{ props.item.date }}</td>
                        <td>
                            <v-icon style="margin-left: 15px;"
                                    small
                                    @click="$router.push(`/reports/invoice/${props.item.id}`)">
                                info
                            </v-icon>
                            &nbsp;&nbsp;
                        </td>
                        <!--<td v-if="">
                      <div v-if="props.item.transactionAmount > props.item.amountTendered">
                        <v-btn small icon >
                          <v-icon @click="chargeCustomerWalletForReceiptDebt(props.item.id)" color="green">
                            send
                          </v-icon>
                        </v-btn>
                      </div>
                    </td>-->
                    </tr>
                </template>
                <template v-slot:expand="props">
                    <product-details :data="props" />
                </template>
            </v-data-table>
        </template>

        <update-wallet-balance :showUpdateWalletBalance="showUpdateWalletBalance"
                               :setWalletBalance="selectedCustomer.walletBalance"
                               @updateWalletBalance="updateWalletBalance"
                               @onClose="showUpdateWalletBalance = false" />

        <v-dialog v-model="showCreditLimitModal" max-width="800px" persistent>
            <v-card>
                <v-card-title primary-title>
                    <h3>
                        Set Credit Limit
                    </h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form lazy-validation ref="set_credit_limit" @submit.prevent="updateCreditLimit">
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="setCreditLimit"
                                              label="Credit Limit (NGN)"
                                              autofocus
                                              :rules="formRules.required"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeCreditLimitDialog" flat>Close</v-btn>
                            <v-btn type="submit"
                                   flat
                                   :disabled="setCreditLimit < 0"
                                   color="primary">Set Limit</v-btn>
                        </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="showReversalModal" max-width="700px" persistent>
            <v-card>
                <v-card-title primary-title>
                    <h3>
                        Reversal Request
                    </h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form lazy-validation ref="reversal_form" @submit.prevent="proceedReversal">
                        <v-layout row wrap>
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="setReversalAmount"
                                              label="Amount (NGN)"
                                              autofocus
                                              :rules="formRules.required"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeReversalDialog" flat>Close</v-btn>
                            <v-btn type="submit"
                                   flat
                                   :disabled="setReversalAmount < 0"
                                   :loading="loading"
                                   color="primary">Proceed</v-btn>
                        </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showReversalPinModal" max-width="500px" persistent>
            <v-card>
                <v-card-title primary-title>
                    <h3>
                        One-Time Password
                    </h3>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form lazy-validation ref="complete_reversal_form" @submit.prevent="completeReversal">
                        <v-layout row wrap>
                            {{msg}}
                            <v-flex sm6 px-2>
                                <v-text-field required
                                              v-model="otp"
                                              label="One-time password"
                                              autofocus
                                              :rules="formRules.required"></v-text-field>
                            </v-flex>
                        </v-layout>
                        <v-layout>
                            <v-spacer></v-spacer>
                            <v-btn @click="closeConfirmReversalDialog" flat>Close</v-btn>
                            <v-btn type="submit"
                                   flat
                                   :disabled="otp == ''"
                                   :loading="loading"
                                   color="primary">Proceed</v-btn>
                        </v-layout>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </layout>
</template>

<script>
import JsonCSV from "vue-json-csv";
import Layout from "@/components/Layout";
import Paid from "@/components/partner/Paid";
import Owing from "@/components/partner/Owing";
import LimitBalance from "@/components/partner/LimitBalance";
import Customer from "@/components/partner/Customer";
import ProductDetails from "./ProductDetails";
import UpdateWalletBalance from "@/components/partner/UpdateWalletBalance";

export default {
  components: {
    Layout,
    Owing,
    LimitBalance,
    Paid,
    Customer,
    ProductDetails,
    UpdateWalletBalance,
    DownloadCSV: JsonCSV
  },
  data() {
    return {
      filter: {
        endDate: new Date().toISOString().substr(0, 10),
        startDate: new Date().toISOString().substr(0, 10)
      },
      headers: [
        { text: "Receipt Id", value: "receiptId" },
        { text: "Total Amount (NGN)", value: "totalAmount" },
        { text: "Amount Tendered (NGN)", value: "amountPaid" },
        { text: "Store", value: "store" },
        { text: "Seller", value: "seller" },
        { text: "Date", value: "date" },
        { text: "View Invoice", value: "" },
        //{ text: "Settle Debt", value: "" },
      ],
      loading: false,
      search: "",
      msg: '',
      otp:'',
      history: [],
      products: [],
      stores: [],
      selectedCustomer: {},
      setCreditLimit: 0,
      setReversalAmount: 0,
      otp: 0,
      showCreditLimitModal: false,
      showUpdateWalletBalance: false,
      showReversalModal: false,
      showReversalPinModal: false,
    };
  },
  mounted() {
    this.msg = `For security, We've sent one-time password to your email address at ${this.authUser.email}`
    this.fetchStores();
    this.fetchCustomerHistory();
  },
  computed: {
    filterReceipts () {
      return this.history.map((item) => ({
          id: item.id,
          receiptId: item.number,
          totalAmount: this.moneyFormat(item.transactionAmount),
          amountPaid: this.numberFormat(item.amountTendered),
          store: this.getStore(item.storeId).name,
          seller: item.sellerName,
          products: item.products,
          // discountAmount: this.moneyFormat(item.discountAmount),
          date: this.fullDateFormat(item.createdAt),
          transactionAmount: item.transactionAmount,
          amountTendered: item.amountTendered,
      }));
    },
    filterReceiptsForExport () {
      const extractedData = [];

      this.history.forEach((item) => {
        item.products.forEach(product => {
          extractedData.push({
            receiptId: item.id,
            productName: product.productName,
            salePrice: this.moneyFormat(product.salePrice),
            totalPrice: this.moneyFormat(product.salePrice * product.quantity),
            quantity: product.quantity,
            discount: product.totalDiscount,
            isPaidInFull: item.amountTendered >= item.transactionAmount,
            store: this.getStore(item.storeId).name,
            seller: item.sellerName,
            date: this.fullDateFormat(item.createdAt),
          })
        })
      });

      return extractedData;
    },
    totalPaid () {
      let totalPaid = 0;
      this.history.forEach(receipt => {
        totalPaid += receipt.amountTendered;
      })
      return totalPaid > 0 ? totalPaid : 0;
    },
    total () {
      let total = 0;
      this.history.forEach(receipt => {
        total += receipt.transactionAmount;
      })
      return total
    },
    totalOwed () {
      const owed = (this.total - this.totalPaid);
      return owed > 0 ? owed : 0;
    },
  },
  methods: {
    async fetchStores() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/stores`);
        this.stores = data.data;
        // console.log('this.stores', this.stores);
      } catch (error) {
        // console.log(error);
        this.handleError(error, "Error fetching stores");
      }
      this.loading = false;
    },
    async syncTransaction() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(`${this.apiBaseUrl}/receipts/wallet-correction`);
        //this.stores = data.data;
        this.fetchCustomerHistory();
        // console.log('this.stores', this.stores);
      } catch (error) {
        // console.log(error);
        this.handleError(error, "Error fetching stores");
      }
      this.loading = false;
    },
    async fetchCustomerHistory() {
      const id = this.$route.params.id;
      this.loading = true;
      try {
        const { data } =
          await this.$http.get(`${this.apiBaseUrl}/partners/customer_receipts?customerId=${id}`);

        this.history = data.data.receipts;
        this.selectedCustomer = data.data.partner;
        // Set Wallet Balance
        this.setWalletBalance = this.selectedCustomer.walletBalance;
        this.setCreditLimit =
          this.selectedCustomer.creditLimit > 0
            ? this.selectedCustomer.creditLimit
            : 0;
      } catch (error) {
        this.handleError(error, "Error fetching products");
      }
      this.loading = false;
    },
    async proceedReversal() {
      if (isNaN(this.setReversalAmount)) {
        this.showSnackbar("Amount set must be a number", {
          type: "error"
        });
        return;
      }

        try {

            this.loading = true;
            const res = await this.$http.put(
              `${this.apiBaseUrl}/partners/activate_reversal`,
              {
                "amount": this.setReversalAmount,
                "partner_id": this.$route.params.id
              }
            );

        this.loading = false;
        this.handleResponse(res, () => {
          this.showSnackbar("Reversal initiated");
          this.closeReversalDialog();
          this.showReversalPinModal = true;
        });
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Operation failed. Please try again later.");
      }
    },
    async updateCreditLimit() {
      if (isNaN(this.setCreditLimit)) {
        this.showSnackbar("Credit Limit must be a number", {
          type: "error"
        });
        return;
      }

      try {
        const res = await this.$http.put(
          `${this.apiBaseUrl}/partners/update_credit_limit`,
          {
            "credit_limit": this.setCreditLimit,
            "partner_id": this.$route.params.id
          }
        );

        this.loading = true;
        
        this.handleResponse(res, () => {
          this.loading = false;
          this.closeCreditLimitDialog();
          this.fetchCustomerHistory();
        });
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Operation failed. Please try again later.");
      }
    },
    closeCreditLimitDialog() {
      this.showCreditLimitModal = false;
      this.$refs.set_credit_limit.reset();
    },
    closeReversalDialog() {
      this.showReversalModal = false;
    },
    closeConfirmReversalDialog() {
      this.showReversalPinModal = false;
      this.$refs.complete_reversal_form.reset();
      this.$refs.reversal_form.reset();
    },
    async updateWalletBalance({modalRef, newValue}) {
      if (isNaN(this.setWalletBalance)) {
        this.showSnackbar("Wallet Balance must be a number", {
          type: "error"
        });
        return;
      }

      try {
        this.loading = true;
        this.closeWalletBalanceDialog(modalRef);
        const res = await this.$http.put(
          `${this.apiBaseUrl}/partners/update_wallet_balance`,
          {
            "amount": newValue,
            "partner_id": this.$route.params.id
          }
        );
        
        this.handleResponse(res, () => {
          this.loading = false;
          this.fetchCustomerHistory();
        });
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Operation failed. Please try again later.");
      }
    },
    closeWalletBalanceDialog(modalRef) {
      this.showUpdateWalletBalance = false;
      modalRef.reset();
    },
    async chargeCustomerWalletForReceiptDebt(receiptId) {
      try {
        this.loading = true;
        const res = await this.$http.patch(
          `${this.apiBaseUrl}/receipts/debit_from_balance/${receiptId}`);

        this.loading = false;
        this.handleResponse(res, () => {
          this.fetchCustomerHistory();
        });
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Operation failed. Please try again later.");
      }
      },
      async completeReversal() {
          if (isNaN(this.otp)) {
              this.showSnackbar("Amount set must be a number", {
                  type: "error"
              });
              return;
          }

          try {
              this.loading = true;
              const res = await this.$http.put(
                  `${this.apiBaseUrl}/partners/complete_reversal`,
                  {
                      "token": this.otp,
                      "partner_id": this.$route.params.id,
                      "amount": this.setReversalAmount,
                  }
              );
              this.loading = false;
              this.handleResponse(res, ()  => {
                  this.showSnackbar("Reversal completed");
                  this.closeConfirmReversalDialog();
                  this.fetchCustomerHistory();
              });
          } catch (error) {
              this.loading = false;
              this.handleError(error, "Operation failed. Please try again later.");
          }
      },
    getProduct(id) {
      const product = this.products.find(x => x.id == id);

      return product || {};
    },
    getStore(storeId) {
      return this.stores.find(x => x.id == storeId) || {name: 'Admin'};
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.customer-name {
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px;
}
</style>