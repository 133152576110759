<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-menu
            ref="dayMenu"
            v-model="dayMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="selectedDay"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="selectedDay"
                label="Select day"
                prepend-icon="event"
                hint="YYYY/MM/DD format"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="selectedDay" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="dayMenu = false"
                >Cancel</v-btn
              >
              <v-btn
                flat
                color="primary"
                @click="$refs.dayMenu.save(selectedDay)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-flex>

        <v-flex xs12 sm3>
          <v-btn color="info" @click="filterData">
            Filter
          </v-btn>
        </v-flex>
      </v-layout>

      <v-data-table
        disable-initial-sort
        :items="sales"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No sales.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.name }}</td>
          <td class="text-xs-right">
            {{ numberFormat(props.item.totalProducts) }}
          </td>
          <td class="text-xs-right">
            {{ moneyFormat(props.item.totalAmount) }}
          </td>
          <td class="text-xs-right">
            {{ numberFormat(props.item.totalQuantity) }}
          </td>
          <td>{{ props.item.sellerName }}</td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    let date = this.$moment().format("DD/MM/YYYY");
    this.getData(date);
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
      },
      headers: [
        { text: "Cashier", value: "name" },
        { text: "Total Sale", value: "totalProducts", align: "right" },
        { text: "Total Amount", value: "totalAmount", align: "right" },
        { text: "No of products", value: "totalQuantity", align: "right" }
      ],
      loading: false,
      sales: [],
      search: "",
      selectedDay: new Date().toISOString().substr(0, 10),
      dayMenu: false
    };
  },
  computed: {
    pickedDay() {
      return this.$moment(this.selectedDay, "YYYY/MM/DD").format("DD/MM/YYYY");
    }
  },
  methods: {
    async getData(date) {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let url = `${
        this.apiBaseUrl
      }/receipts/by-cashier?storeId=${storeId}&day=${date}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.sales = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    },
    filterData() {
      this.getData(this.pickedDay);
    },
    totalQty(items) {
      return items.reduce(function(a, b) {
        return a + b["quantity"];
      }, 0);
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData(this.pickedDay);
      },
      deep: true
    }
  }
};
</script>
