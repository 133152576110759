<template>
  <layout requires-store>
    <v-layout row wrap>
      <v-flex xs12>
        <template v-if="selectedStore || isLoggedIn.store_id">
          <v-tabs slider-color="green" light grow>
            <v-tab>
              <h4>Table Settings</h4>
            </v-tab>
            <v-tab>
              <h4>Other Settings</h4>
            </v-tab>
            <v-tab-item>
              <table-settings :settings="tableSettings"></table-settings>
            </v-tab-item>
            <v-tab-item>
              <other-settings :settings="otherSettings"></other-settings>
            </v-tab-item>
          </v-tabs>
        </template>
        <template v-else>
          <p>Please select a store</p>
        </template>
      </v-flex>
    </v-layout>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";
import TableSettings from "./table_other_settings/TableSettings.vue";
import OtherSettings from "./table_other_settings/OtherSettings.vue";
export default {
  components: {
    Layout,
    TableSettings,
    OtherSettings
  },
  computed: {
    //
  },
  data() {
    return {
      tableSettings: {},
      otherSettings: {}
    };
  }
};
</script>
