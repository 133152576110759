<template>
  <v-card>
    <v-card-title class="orange darken-2 white--text">
      Customers
    </v-card-title>
    <v-card-text class="text-sm-right p-8">
      <h2>{{ count }}</h2>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PartnersCount",
  data() {
    return {
      count: "-"
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let url = `${this.apiBaseUrl}/dashboard/partners-count`;

      try {
        const res = await this.$http.get(url);
        this.count = res.data.data;
      } catch (error) {
        this.count = "X";
      }
    }
  }
};
</script>

<style scoped></style>
