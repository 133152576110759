<template>
  <v-dialog persistent max-width="700px" v-model="showDialog">
      <v-card flat>
          <v-toolbar dark color="blue-grey darken-4">
              <!-- <v-toolbar dark color="black"> -->
              <v-btn @click.native="closeDialog" flat>
                  <v-icon>close</v-icon>&nbsp;Close
              </v-btn>
          </v-toolbar>
          <br />
          <v-form @submit.prevent="validateProduct" ref="add_product_form">
              <v-subheader>
                  <h4>Add products</h4>
              </v-subheader>
              <!-- <br> -->
              <v-layout row>
                  <v-flex xs12 px-2>
                      <v-autocomplete v-model="product.id"
                                      :items="productsList"
                                      required
                                      :rules="formRules.required"
                                      item-text="name"
                                      item-value="id"
                                      :validate-on-blur="true"
                                      label="Select product"></v-autocomplete>
                  </v-flex>
                  <v-flex xs12 px-2>
                      <v-text-field required
                                    v-model="product.quantity"
                                    type="number"
                                    :validate-on-blur="true"
                                    label="Quantity"
                                    :rules="formRules.amount"
                                    name="quantity"></v-text-field>
                  </v-flex>
              </v-layout>

              <v-layout>
                  <v-flex text-xs-right>
                      <v-btn medium color="success" type="submit">
                          <v-icon dark>add</v-icon>
                      </v-btn>

                      <v-btn @click.native="closeDialog" color="warning">
                          Close
                      </v-btn>
                  </v-flex>
              </v-layout>
          </v-form>
          <br />
          <!--<selected-simple-products v-if="selectedSimpleProducts.length > 0" :products="selectedSimpleProducts"></selected-simple-products>-->
      </v-card>
  </v-dialog>
</template>

<script>
import SelectedSimpleProducts from "./SelectedSimpleProducts.vue";
export default {
  components: {
    SelectedSimpleProducts
  },
  data() {
    return {
      product: {},
      products_: this.products || [],
      selectedSimpleProducts: []
    };
  },
  methods: {
    closeDialog() {
      this.$refs.add_product_form.reset();
      this.$emit("closeComplexDialog");
      },
    getProductName_(product) {
      return this.getProductName(product, this.options);
    },
    validateProduct() {
      const form = this.$refs.add_product_form;
      if (!form.validate()) return;
      const product = Object.assign({}, this.product);
      const matchingProduct = this.productsList.find(x => x.id == product.id);
      product.name = matchingProduct.name;
      this.$emit("simpleProductAdded", product);
      form.reset();
    }
  },
  computed: {
      productsList() {
          var list = [];
          this.products.forEach(product => {
              const item = Object.assign({}, product);
              item.name = this.getProductName(product, this.options);
              list.push(item);
          });
          return list;
      }
  },
  props: {
    products: Array,
    "show-dialog": Boolean,
    options: Array
  }
};
</script>
