<template>
  <v-card>
    <v-toolbar dark color="teal darken-2">
      <v-btn icon dark @click.native="$emit('result')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>Details</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark flat @click.native="save" :disabled="!allowSave"
          >Save</v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
    <v-flex xs12>
          <v-layout>
            <v-spacer></v-spacer>
            <v-flex sm3 px-2>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search product"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
    <v-layout row wrap class="pt-3">
      <v-flex sm12>
        <v-data-table
          :items="listItems"
          :rows-per-page-items="[20]"
          :search="search"
          :headers="productHeaders"
          :loading="loading || posting"
          item-key="id"
        >
          <template slot="no-data">
            No products.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.code || "" }}</td>
            <td>{{ props.item.product_group || "" }}</td>
            <td>{{ props.item.unit_of_measurement || "" }}</td>
            <td>{{ props.item.current_situation || 0 }}</td>
            <td>
              <template v-if="!allowSave">
                {{ props.item.actual_situation || 0 }}
              </template>
              <template v-else>
                <v-edit-dialog
                  large
                  persistent
                  save-text="Update"
                  lazy
                  @save="
                    updateSituation({
                      id: props.item.id,
                      value: parseInt(props.item.actual_situation)
                    })
                  "
                >
                  {{ `${props.item.actual_situation} ` }}
                  <v-icon small class="pl-2">
                    edit
                  </v-icon>
                  <v-text-field
                    slot="input"
                    v-model="props.item.actual_situation"
                    :rules="formRules.numbersOnly"
                    label="Edit"
                    single-line
                    counter
                    autofocus
                  ></v-text-field>
                </v-edit-dialog>
              </template>
            </td>
            <td>{{ props.item.surplus || 0 }}</td>
            <td>{{ props.item.deficit || 0 }}</td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      currentItem: {},
      listItems: [],
      originalItems: [],
      productHeaders: [
        {
          text: "Product name",
          align: "left",
          value: "name"
        },
        {
          text: "Product code",
          value: "code"
        },
        {
          text: "Product group",
          value: "product_group"
        },
        {
          text: "Unit of measure",
          value: "unit_of_measurement"
        },
        {
          text: "Current situation",
          value: "current_situation"
        },
        {
          text: "Actual situation",
          value: "actual_situation"
        },
        {
          text: "Surplus",
          value: "surplus"
        },
        {
          text: "Deficit",
          value: "deficit"
        }
      ],
      posting: false,
      search: "",
      updatedItems: []
    };
  },
  methods: {
    getUpdatedItems() {
      this.updatedItems = [];
      this.listItems.forEach(x => {
        const item = this.originalItems.find(y => y.id === x.id);
        if (!item) return;

        if (x.actual_situation !== item.actual_situation)
          this.updatedItems.push(Object.assign({}, x));
      });
    },
    save() {
      this.getUpdatedItems();
      const keys = Object.keys(this.updatedItems);
      if (keys.length < 1) return alert("No item(s) updated.");
      this.$emit("result", this.listItems);
    },
    updateSituation(item) {
      const { id, value } = item;
      const listItem = this.listItems.find(x => x.id === id);
      const { current_situation } = listItem;

      // Re-setting the actual situation because the editable field returns
      // a string value. The value in the item object meanwhile is an int.
      listItem.actual_situation = value;

      const extra = value - current_situation;
      const extraAbsolute = Math.abs(extra); // Convert to positive value

      if (extra > 0) {
        listItem.deficit = 0;
        listItem.surplus = extraAbsolute;
      } else {
        listItem.deficit = extraAbsolute;
        listItem.surplus = 0;
      }
    }
  },
  props: {
    items: Array,
    loading: Boolean,
    allowSave: Boolean
  },
  watch: {
    items: {
      handler(items) {
        // this.listItems = items.map(x => Object.assign({}, x))
        // this.originalItems = this.listItems.map(x => Object.assign({}, x))

        // This implementation should have better performance than the one above
        this.listItems = [];
        this.originalItems = [];

        items.forEach(x => {
          this.listItems.push(Object.assign({}, x));
          this.originalItems.push(Object.assign({}, x));
        });
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
