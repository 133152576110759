<template>
  <layout requires-warehouse>
    <template v-if="!selectedWarehouse">
      No warehouse selected
    </template>
    <template v-else>
        <v-layout>
            <v-flex sm6 md4 style="padding:2px">
                <v-spacer></v-spacer>
                <DownloadCSV class="v-btn theme--light primary"
                             :data="filterLogs"
                             name="export-storage-status.csv"
                             v-if="filterLogs.length">
                    <v-icon>cloud_download</v-icon>&nbsp; Download report
                </DownloadCSV>
            </v-flex>
            <v-flex sm6 md4 style="padding:2px">
                <v-spacer></v-spacer>
                <DownloadCSV class="v-btn theme--light warning"
                             :data="filterLogsLowStock"
                             name="export-storage-low-stock-status.csv"
                             v-if="filterLogsLowStock.length">
                    <v-icon>cloud_download</v-icon>&nbsp; Low stock report
                </DownloadCSV>
            </v-flex>
            <v-flex sm6 md4 offset-sm3 offset-md5>
                <v-spacer></v-spacer>
                <v-text-field v-model="search"
                              append-icon="search"
                              label="Search product"
                              single-line
                              hide-details
                              clearable></v-text-field>
            </v-flex>
        </v-layout>
      <v-layout row wrap>
        <v-flex sm12>
          <v-data-table
            :items="products_"
            :rows-per-page-items="[20]"
            :search="search"
            :headers="productHeaders"
            :loading="loading"
          >
            <template slot="no-data">
              No products.
            </template>
            <template slot="items" slot-scope="props">
              <tr
                :class="
                  props.item.is_low_stock ? 'red accent-3 white--text' : ''
                "
              >
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.code || "" }}</td>
                <td>{{ props.item.product_group.name || "" }}</td>
                <td>{{ props.item.unit_of_measurement.name || "" }}</td>
                <!-- <td>{{ props.item.is_subscribed ? "Yes" : "No" }}</td> -->
                <td>{{ props.item.in_stock || 0 }}</td>
                <td>
                  <v-edit-dialog
                    large
                    persistent
                    save-text="Update"
                    lazy
                    @save="
                      updateMinimumStock({
                        id: props.item.id,
                        value: props.item.minimum_stock
                      })
                    "
                  >
                    {{ `${props.item.minimum_stock} ` }}
                    <v-icon small class="pl-2">
                      edit
                    </v-icon>
                    <v-text-field
                      slot="input"
                      v-model="props.item.minimum_stock"
                      :rules="formRules.numbersOnly"
                      label="Edit"
                      single-line
                      counter
                      autofocus
                    ></v-text-field>
                  </v-edit-dialog>
                </td>
                <td>
                  <v-icon
                    size="20"
                    :color="
                      props.item.is_complex ? 'green darken-2' : 'red darken-2'
                    "
                  >
                    {{ props.item.is_complex ? "check_circle" : "clear" }}
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </template>
  </layout>
</template>

<script>
    import Layout from "@/components/Layout";
    import JsonCSV from "vue-json-csv";
export default {
  components: {
    Layout,
    DownloadCSV: JsonCSV
  },
  computed: {
    products_() {
      if (!this.products) return [];

      const products = this.products.map(x => {
        const productData = Object.assign({}, x.product);
        productData.in_stock = x.in_stock;
        productData.minimum_stock = x.minimum_stock;
        productData.is_low_stock = x.is_low_stock;
        productData.id = x.id;
        return productData;
      });

      return products;
    },
      
    filterLogs () {

        if (!this.products) return [];

        const filter = this.products.map(x => {
            const productData = Object.assign({}, x.product);
            productData.in_stock = x.in_stock;
            productData.minimum_stock = x.minimum_stock;
            productData.is_low_stock = x.is_low_stock;
            return productData;
        });

        return filter.map((item) => ({
            Name: item.name,
            ProductCode: item.code,
            ProductGroup: item.product_group.name,
            QuantityInStock: item.in_stock,
            MinimumStock: item.minimum_stock,
            UnitOfMeasurement: item.unit_of_measurement.name
        }));
    },
    
      
    filterLogsLowStock() {

        if (!this.products) return [];

        const filter = this.products.map(x => {
            const productData = Object.assign({}, x.product);
            productData.in_stock = x.in_stock;
            productData.minimum_stock = x.minimum_stock;
            productData.is_low_stock = x.is_low_stock;
            return productData;
        });

        const low_stock = filter.filter(item => item.in_stock <= item.minimum_stock);
        if (!low_stock) return [];

        return low_stock.map((item) => ({
            Name: item.name,
            ProductCode: item.code,
            ProductGroup: item.product_group.name,
            QuantityInStock: item.in_stock,
            MinimumStock: item.minimum_stock,
            UnitOfMeasurement: item.unit_of_measurement.name
        }));
    }
  },
  data() {
    return {
      loading: false,
      productHeaders: [
        {
          text: "Product name",
          align: "left",
          value: "name"
        },
        {
          text: "Product code",
          value: "code"
        },
        {
          text: "Product group",
          value: "product_group.name"
        },
        {
          text: "Unit of measure",
          value: "unit_of_measurement"
        },
        // {
        //   text: "Is Subscribed",
        //   value: "is_subscribed"
        // },
        {
          text: "In stock",
          value: "in_stock"
        },
        {
          text: "Re-order level",
          value: "product.minimum_stock"
        },
        {
          text: "Complex product",
          value: "is_complex"
        }
      ],
      products: [],
      posting: false,
      search: "",
      warehouseId: null
    };
  },
  methods: {
    async getInventory() {
      let url = `${this.apiBaseUrl}/inventory/storage-status`;
      if (!this.selectedWarehouse) return;

      url += `?warehouseId=${this.selectedWarehouse.id}`;

      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => {
          this.products = data;
        });
      } catch (error) {
        this.loading = false;
        this.handleError(error, "We couldn't get your proucts");
      }
    },
    async updateMinimumStock(item) {
      const { id, value } = item;

      const index = this.products.findIndex(x => x.id === id);

      if (index < 0) return;

      // return
      this.loading = true;

      const warehouse = Object.assign({}, this.products[index]);
      try {
        const payload = {
          entry_id: id,
          minimum_stock: value
        };
        const res = await this.$http.post(
          `${this.apiBaseUrl}/products/storage`,
          payload
        );
        this.loading = false;
        await this.handleResponse(res, (data, message) => {
          warehouse.minimum_stock = value;
          this.showSnackbar(message || "Update was successful.");
        });
      } catch (error) {
        console.log(error);
        this.loading = false;
        await this.handleError(
          error,
          "There was an error while updating this product. Please try again later."
        );
      }
      this.$set(this.products, index, warehouse);
    }
  },
  watch: {
    selectedWarehouse: {
      handler() {
        this.getInventory();
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
