<template>
  <v-card flat>
    <v-card-title primary-title>
      <v-flex xs12 sm12>
        <div class="text-xs-left">
          <strong>Product Details</strong>
        </div>
        <div class="text-xs-right">
          <DownloadCSV
            class="v-btn theme--light success"
            :data="prepareForExport"
            :name="data.item.companyName + '_' + data.item.name + '_incoming_receipt.csv'"
            v-if="prepareForExport.length"
          >
            Export
          </DownloadCSV>
        </div>
      </v-flex>
    </v-card-title>

    <v-data-table :headers="headers" :items="prepareForExport" class="elevation-1">
      <template v-slot:items="props">
        <td>{{ props.item.productName }}</td>
        <td class="text-xs-right">{{ props.item.cost_price }}</td>
        <td class="text-xs-right">{{ props.item.selling_price }}</td>
        <td class="text-xs-right">{{ props.item.quantity }}</td>
        <td class="text-xs-right">
          {{
            props.item.expiry_date == null
              ? "No Set Date"
              : new Date(props.item.expiry_date).toDateString()
          }}
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import JsonCSV from "vue-json-csv";

export default {
  props: ["data"],
  components: {
    DownloadCSV: JsonCSV
  },
  computed: {
    prepareForExport () {
      return this.products.map(product => ({
        name: this.data.item.name,
        supplier: this.data.item.companyName,
        warehouse: this.data.item.warehouse,
        ...product,
        receipt_date: this.data.item.receipt_date,
      }))
    }
  },
  data() {
    return {
      products: JSON.parse(this.data.item.products),
      headers: [
        { text: "Name", value: "name" },
        { text: "Cost price", value: "cost_price", align: "right" },
        { text: "Selling price", value: "selling_price", align: "right" },
        { text: "Quantity", value: "quantity", align: "right" },
        { text: "Expiry Date", value: "expiry_date", align: "right" }
      ]
    };
  }
};
</script>
