<template>
  <layout requires-store>
    <template v-if="selectedStore || isLoggedIn.store_id">
      <v-data-table
        disable-initial-sort
        :items="dailyStatus"
        :rows-per-page-items="[10]"
        :search="search"
        :headers="headers"
        :loading="loading"
      >
        <template slot="no-data">
          No receipts.
        </template>
        <template slot="items" slot-scope="props">
          <td>{{ props.item.openDate }}</td>
          <td>{{ props.item.updatedDate }}</td>
          <td>{{ props.item.totalTurnover }}</td>
          <td>{{ props.item.availableCash }}</td>
          <td>{{ props.item.sellerName }}</td>
          <td>{{ props.item.deviceCode }}</td>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      No store selected
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      filter: {
        endDate: "",
        startDate: "",
        paymentMethod: ""
      },
      headers: [
        {
          text: "Open Date",
          value: "openDate"
        },
        {
          text: "Update Date",
          value: "updatedDate"
        },
        {
          text: "Turnover",
          value: "totalTurnover"
        },
        {
          text: "Available Cash",
          value: "availableCash"
        },
        {
          text: "Seller Name",
          value: "sellerName"
        },
        {
          text: "Device Code",
          value: "deviceCode"
        }
      ],
      loading: false,
      dailyStatus: [],
      search: ""
    };
  },
  methods: {
    async getData() {
      let storeId = this.selectedStore
        ? this.selectedStore.id
        : this.isLoggedIn.store_id;
      let date = this.$moment().format("DD/MM/YYYY");
      let url = `${
        this.apiBaseUrl
      }/dailystatus?storeId=${storeId}&openDate=${date}`;
      this.loading = true;
      try {
        const res = await this.$http.get(url);
        this.loading = false;
        this.handleResponse(res, data => (this.dailyStatus = data));
      } catch (error) {
        this.loading = false;
        this.handleError(error, "Error loading data");
      }
    }
  },
  watch: {
    selectedStore: {
      handler() {
        this.getData();
      },
      deep: true
    }
  }
};
</script>
