<template>
  <v-card flat>
    <v-card-title class="pt-0">
      <v-layout row wrap>
        <v-flex xs12>
          <v-layout>
            <v-spacer></v-spacer>
            <v-btn @click="productGroupDialog = true" color="primary">
              <v-icon>add_circle_outline</v-icon>&nbsp; Add product group
            </v-btn>
          </v-layout>
          <v-divider></v-divider>
          <br />
        </v-flex>
        <v-flex xs12>
          <v-layout>
            <v-spacer></v-spacer>
            <v-flex sm6>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search product groups"
                single-line
                hide-details
                clearable
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-title>

    <v-data-table
      :items="groups"
      :rows-per-page-items="[10]"
      :search="search"
      :headers="productGroupHeaders"
    >
      <template slot="no-data">
        No product groups. Add a new group above.
      </template>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name }}</td>
        <td>{{ props.item.code }}</td>
        <td style="text-align:center">
          <v-icon small :color="props.item.use_discount ? 'green' : 'red'">
              {{ props.item.use_discount ? "check_circle" : "remove_circle" }}
          </v-icon>
        </td>
        <td style="text-align:center">
          <v-icon small :color="props.item.active ? 'green' : 'red'">
              {{ props.item.active ? "check_circle" : "remove_circle" }}
           </v-icon>
        </td>
        <td>
          <v-icon
            v-show="props.item.business_id"
            small
            @click="showGroupInfo(props.item)"
          >
            edit
          </v-icon>
        </td>
      </template>
    </v-data-table>

    <v-dialog v-model="productGroupDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title primary-title>
          <h3>
            Enter product group details
          </h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form lazy-validation ref="new_product_group_form">
            <v-layout row wrap>
              <v-flex sm12 px-2>
                <v-text-field
                  required
                  v-model="productGroup.name"
                  :validate-on-blur="true"
                  label="Name"
                  :rules="formRules.required"
                  name="name"
                >
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap>
              <v-flex sm12 px-2>
                <v-text-field
                  required
                  v-model="productGroup.code"
                  :validate-on-blur="true"
                  label="Code"
                  counter="10"
                  :rules="formRules.required"
                  name="code"
                >
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-layout row wrap>
              <v-flex sm12 px-2>
                <v-autocomplete
                  v-model="productGroup.discount_id"
                  :disabled="productGroup.use_discount !== true"
                  :required="productGroup.use_discount == true"
                  :items="discounts"
                  item-text="name"
                  item-value="id"
                  label="Discount"
                >
                </v-autocomplete>
              </v-flex>
            </v-layout>

            <!-- <v-layout row wrap>
                            <v-flex sm12 px-2>
                                <v-autocomplete v-model="productGroup.payment_method_id"
                                    :items="paymentMethods" item-text="name"
                                    item-value="id" label="Payment method">
                                </v-autocomplete>
                            </v-flex>
                        </v-layout> -->

            <v-layout row wrap>
              <v-flex sm6 px-2>
                <v-switch label="Status" v-model="productGroup.active">
                </v-switch>
              </v-flex>
              <v-flex sm6 px-2>
                <v-switch label="Use Discount?" v-model="productGroup.use_discount">
                </v-switch>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-spacer></v-spacer>
              <v-btn @click="closeProductGroupDialog" flat :disabled="posting"
                >Close</v-btn
              >
              <v-btn
                @click="saveProductGroup"
                flat
                :disabled="posting"
                color="primary"
                >{{ saveText }}</v-btn
              >
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  mounted() {
    this.getDiscounts();
    this.getPaymentMethods();
  },
  data() {
    return {
      postAction: "",
      productGroup: {
        active: true,
        use_discount: true,
      },
      productGroupDialog: false,
      productGroupHeaders: [
        {
          text: "Group Name",
          value: "name",
        },
        {
          text: "Group Code",
          value: "code",
        },
        {
          text: "Discount",
          value: "use_discount",
          align: "center",

        },
        {
          text: "Status",
          value: "code",
          align: "center",
        },
        {
          text: "Actions",
          value: "",
        },
      ],
      rebateTypes: [
        {
          id: 0,
          name: "Flat",
        },
        {
          id: 1,
          name: "Percentage (%)",
        },
      ],
      search: "",
      groupImg: "",
      discounts: [],
      paymentMethods: [],
    };
  },
  computed: {
    saveText() {
      const texts = [
        {
          active: "Saving",
          default: "Save",
        },
        {
          active: "Updating",
          default: "Update",
        },
      ];

      const index = this.postAction !== "update" ? 0 : 1;
      return this.posting ? texts[index].active : texts[index].default;
    },
    activeGroups() {
      return this.groups
        .filter((x) => x.active == true)
        .sort((a, b) => a.name > b.name);
    },
    inactiveGroups() {
      return this.groups
        .filter((x) => x.active == false)
        .sort((a, b) => a.name > b.name);
    },
  },
  methods: {
    async getDiscounts() {
      this.loaded = false;
      let url = `${this.apiBaseUrl}/discounts`;
      if (this.selectedStore) url += `?storeId=${this.selectedStore.id}`;
      const res = await this.$http.get(url);
      this.loaded = true;
      this.handleResponse(res, (data) => {
        this.discounts = data;
      });
    },
    async getPaymentMethods() {
      this.loaded = false;
      let url = `${this.apiBaseUrl}/paymentmethods`;
      const res = await this.$http.get(url);
      this.loaded = true;
      this.handleResponse(res, (data) => {
        this.paymentMethods = data;
      });
    },
    closeProductGroupDialog() {
      this.productGroupDialog = false;
      this.postAction = "";
      this.productGroup = {};
    },
    getRebateType(id) {
      return this.rebateTypes.find((x) => x.id == id);
    },
    async saveProductGroup() {
      if (isNaN(this.productGroup.code)) {
        this.showSnackbar("Product group code must be number", {
          type: "error",
        });
        return;
      } else if (String(this.productGroup.code).substr(0, 1) == "0") {
        this.showSnackbar("Product group code cannot start with zero", {
          type: "error",
        });
        return;
      }

      this.posting = true;
      const group = Object.assign({}, this.productGroup);
      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/productsgroup`,
          group
        );
        this.posting = false;
        this.handleResponse(res, (data) => {
          if (this.postAction == "update") {
            this.$emit("groupUpdated", data);
          } else {
            this.$emit("groupAdded", data);
          }
          this.closeProductGroupDialog();
        });
      } catch (error) {
        this.posting = false;
        this.showSnackbar(
          "We couldn't add your product group. Please try again.",
          {
            type: "error",
          }
        );
      }
    },
    showGroupInfo(group) {
      this.postAction = "update";
      this.productGroup = Object.assign({}, group);
      this.productGroupDialog = true;
    },
    showProductGroupDialog() {
      this.productGroupDialog = true;
    },
    showFilePicker() {
      const filePicker = document.getElementById("img-picker");
      filePicker.click();
    },
    photoPreview(e) {
      const self = this;
      var file = e.target.files[0];
      if (!file) return;

      if (file.size < 4096000) {
        if (file.type.match("image.*")) {
          var reader = new FileReader();
          reader.onload = (() => {
            return function(evt) {
              // Set image source
              self.productImg_ = evt.target.result;
            };
          })(file);

          reader.readAsDataURL(file);
        } else {
          alert("The file you selected is not a valid image file");
        }
      } else {
        alert(
          "The file you selected is too large. Images shouldn't be bigger than 4MB."
        );
      }
    },
  },
  props: {
    groups: Array,
  },
};
</script>

<style scoped>
.product-img {
  width: 200px;
  height: 200px;
  position: relative;
  text-align: center;
}

#img-picker {
  height: 0;
  visibility: hidden;
}

.product-img:hover {
  cursor: pointer;
}

.product-img > span {
  font-style: italic;
  font-weight: bold;
  display: block;
}

.product-img > img {
  width: auto;
  max-width: 100%;
  max-height: 200px;
  text-align: center;
  margin: 0 auto;
  text-align: center;
}

.product-img:hover .inverse {
  opacity: 0.2;
}

.inverse,
.img-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
  transition: all ease-in-out 0.4s;
}

.img-loader {
  background-color: #fff;
  opacity: 0.85;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
}
</style>
