<template>
  <div>
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex sm4>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search attributes"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <br />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex sm12>
        <v-data-table
          :items="attributes"
          :rows-per-page-items="[20]"
          :search="search"
          :headers="headers"
          :loading="loading"
        >
          <template slot="no-data">
            No attributes. Add a new attribute above.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td>
              <v-icon :class="`${props.item.active ? 'primary' : 'red'}--text`">
                {{ props.item.active ? "check" : "clear" }}
              </v-icon>
            </td>
            <!-- <td>{{ momentFormat(props.item.code, "DD-MMM-YYYY") }}</td> -->
            <td>
              <v-icon small @click="$emit('show', props.item)">
                edit
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      search: ""
    };
  },
  methods: {},
  props: {
    attributes: Array,
    loading: Boolean
  }
};
</script>
