<template>
    <v-card outlined>
        <v-card-title class="grey darken-2 white--text">Password Change</v-card-title>
        <v-layout row wrap>
            <v-flex xs12  class="pa-3">
                <v-form lazy-validation ref="password_change_form">
                    <v-text-field required
                                  v-model="userData.old_pin_code"
                                  :validate-on-blur="true"
                                  label="Old Pin"
                                  :rules="formRules.required"
                                  name="old_pin_code"
                                  type="password"></v-text-field>

                    <v-text-field required
                                  v-model="userData.pin_code"
                                  :validate-on-blur="true"
                                  label="New Pin"
                                  :rules="formRules.required"
                                  name="pin_code"
                                  type="password"></v-text-field>

                    <v-text-field required
                                  v-model="userData.pin_code_confirm"
                                  :validate-on-blur="true"
                                  label="Confirm New Pin"
                                  :rules="formRules.required"
                                  name="pin_code_confirm"
                                  type="password"></v-text-field>

                    <v-layout>
                        <v-spacer></v-spacer>
                        <v-btn @click="updatePinCode" :disabled="posting">Update password</v-btn>
                    </v-layout>
                </v-form>
            </v-flex>
        </v-layout>
    </v-card>
</template>

<script>
import Layout from "@/components/Layout";

export default {
  components: {
    Layout
  },
  data() {
    return {
      userData: {},
      posting: false,
      user: this.$store.getters.userData
    };
  },
  methods: {
    async updatePinCode() {
      this.posting = true;
      this.userData.phone_number = this.user.phone_number;

      if (this.userData.pin_code != this.userData.pin_code_confirm) {
        this.showSnackbar("Pin code and Pin code confirmation are not equal", {
          type: "error"
        });
        this.posting = false;
        return;
      }

      try {
        const res = await this.$http.post(
          `${this.apiBaseUrl}/auth/pin`,
          this.userData
        );
        this.handleResponse(res, data => {
          this.partners = data;
          this.posting = false;
          this.userData = {};
          this.showSnackbar(res.data.message);
        });
      } catch (error) {
        let err = error.response;
        if (err.status == 400) {
          this.handleError(error, Object.values(err.data)[0][0]);
        } else {
          this.handleError(error, "An error occured");
        }
        this.posting = false;
      }
    }
  }
};
</script>
