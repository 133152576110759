import Vue from "vue";
import Router from "vue-router";
// import Home from "@/views/Home.vue";
// import About from "@/views/About.vue";
// // import Products from "@/views/stores/Products.vue";
// import Partners from "@/views/partners/Index.vue";
// import Messages from "@/views/Messages.vue";
// import Register from "@/views/Register.vue";
// import DiscountActions from "@/views/stores/DiscountActions.vue";
// import AuthRoutes from "./auth";

// import SetupRoutes from "./setup";
// import StoreRoutes from "./stores";
// import UsersRoutes from "./users";
// import SettingsRoutes from "./settings";
// import WarehouseRoutes from "./warehouse";
// import ProductRoutes from "./products";
// import ReportRoutes from "./reports";
// import ProfileRoutes from "./profile";
// import SubscriptionRoutes from "./subscription";

import routes from "@/routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
  // routes: [
  //   {
  //     path: "/",
  //     name: "Dashboard",
  //     component: Home
  //   },
  //   {
  //     path: "/about",
  //     name: "About",
  //     component: About
  //   },
  //   {
  //     path: "/messages",
  //     name: "Messages",
  //     component: Messages
  //   },
  //   {
  //     path: "/partners",
  //     name: "Customers",
  //     component: Partners
  //   },
  //   {
  //     path: "/register",
  //     name: "Register",
  //     component: Register
  //   },
  //   {
  //     path: "/discounts_actions",
  //     name: "Discounts",
  //     // name: "Discounts/Actions",
  //     component: DiscountActions
  //   },
  //   ...AuthRoutes,
  //   ...ProductRoutes,
  //   ...SetupRoutes,
  //   ...SettingsRoutes,
  //   ...StoreRoutes,
  //   ...UsersRoutes,
  //   ...WarehouseRoutes,
  //   ...ReportRoutes,
  //   ...ProfileRoutes,
  //   ...SubscriptionRoutes
  // ]
});

export default router;
