<template>
  <div>
    <v-layout row wrap>
      <v-spacer></v-spacer>
      <v-flex sm4>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search options"
          single-line
          hide-details
          clearable
        ></v-text-field>
        <br />
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex sm12>
        <v-data-table
          :items="options"
          :rows-per-page-items="[20]"
          :search="search"
          :headers="headers"
          :loading="loading"
        >
          <template slot="no-data">
            No options. Add a new option above.
          </template>
          <template slot="items" slot-scope="props">
            <td>{{ props.item.name }}</td>
            <td>
              <v-icon :class="`${props.item.active ? 'primary' : 'red'}--text`">
                {{ props.item.active ? "check" : "clear" }}
              </v-icon>
            </td>
            <!-- <td>{{ momentFormat(props.item.code, "DD-MMM-YYYY") }}</td> -->
            <td>
              <v-icon small @click="showOption(props.item)">
                edit
              </v-icon>
            </td>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-dialog v-model="showOptionDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title primary-title>
          <h3>Option Information</h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form
            lazy-validation
            ref="option_form"
            @submit.prevent="updateOption"
          >
            <v-layout row wrap>
              <v-flex xs12 px-2>
                <v-text-field
                  label="Name"
                  v-model="option.name"
                  :rules="formRules.required"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex md6 px-2>
                <v-checkbox
                  required
                  v-model="option.active"
                  label="Active"
                  class="mt-0"
                ></v-checkbox>
              </v-flex>
              <v-flex md6 px-2>
                <v-layout justify-end>
                  <v-btn @click="closeDialog" flat :disabled="posting"
                    >Close</v-btn
                  >
                  <v-btn color="primary" :disabled="posting" type="submit" flat>
                    {{ saveOptionText }}
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  computed: {
    saveOptionText() {
      return this.posting ? "Please wait.." : "Update";
    }
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Actions",
          value: ""
        }
      ],
      option: {},
      posting: false,
      search: "",
      showOptionDialog: false
    };
  },
  methods: {
    closeDialog() {
      this.option = {};
      this.$refs.option_form.reset();
      this.showOptionDialog = false;
    },
    async updateOption() {
      if (!this.$refs.option_form.validate()) return;
      const option = Object.assign({}, this.option);
      this.posting = true;
      try {
        const res = await this.$http.patch(
          `${this.apiBaseUrl}/productattributeoptions`,
          option
        );
        await this.handleResponse(res, data => {
          this.showSnackbar("Option updated successfully.");
          this.$emit("result", data);
          this.showOptionDialog = false;
        });
      } catch (error) {
        console.log(error);
        await this.handleError(
          error,
          "Operation failed. Please try again later."
        );
      }

      this.posting = false;
    },
    showOption(option) {
      this.option = Object.assign({}, option);
      this.showOptionDialog = true;
    }
  },
  props: {
    options: Array,
    loading: Boolean
  }
};
</script>
