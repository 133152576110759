<template>
    <v-card flat>
        <v-card-title primary-title>
            <v-flex xs12 sm12>
                <div class="text-xs-left">
                    <strong>Transaction Details</strong>
                </div>
                <div class="text-xs-right">
                </div>
            </v-flex>
        </v-card-title>
        <div>
                <v-layout row wrap>
                    <v-flex sm4 px-2>
                        <!--<v-select required
                                  v-model="receipt.storeId"
                                  :items="currencies"
                                  item-text="currency.name"
                                  item-value="id"
                                  label="Store"
                                  name="store_id"></v-select>-->

                        <v-autocomplete :items="stores"
                                        item-text="name"
                                        :loading="loading"
                                        autocomplete
                                        item-value="id"
                                        v-model="receipt.store_id"
                                        label="Select Store"
                                        single-line></v-autocomplete>
                    </v-flex>
                    <v-flex sm4 px-2>
                        <v-autocomplete :items="paymentmethods"
                                        item-text="name"
                                        :loading="loading"
                                        autocomplete
                                        item-value="id"
                                        v-model="receipt.payment_method_id"
                                        label="Select payment method"
                                        single-line></v-autocomplete>
                    </v-flex>
                </v-layout>

                <v-layout>
                    <v-spacer></v-spacer>
                    <v-btn type="button" :disabled="posting" :loading="posting" color="info" @click="UpdateReceipts">Update</v-btn>
                </v-layout>
        </div>
    </v-card>s
</template>

<script>
import JsonCSV from "vue-json-csv";

export default {
  props: ["data","stores","paymentmethods"],
  components: {
    DownloadCSV: JsonCSV
  },
  computed: {
  },
  data() {
      return {
          posting:false,
          receipt: this.data.item.receipt,
    };
  },
  methods:{
      UpdateReceipts() {
          this.posting = true;
          this.$emit('refreshList', this.receipt);
          this.posting = false;
      }
}
};
</script>
